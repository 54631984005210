@use '../../../core/app/colors' as colors;

.mat-flat-button,
.mat-mdc-unelevated-button {
  margin: 0 4px;
  text-transform: uppercase;
  &.mat-primary {
    // background-color: colors.$bg-color-primary;
    &:hover {
      // background-color: colors.$bg-color-primary-hover;
    }
    &[disabled] {
      // background-color: colors.$bg-color-primary;
      color: colors.$color-primary-disabled;
    }
  }
  &.mat-accent {
    // background-color: colors.$bg-color-accent;
    &:hover {
      // background-color: colors.$bg-color-accent-hover;
    }
    &[disabled] {
      // background-color: colors.$bg-color-accent;
      color: colors.$color-accent-disabled;
    }
  }
}
