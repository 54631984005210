@use '../core/app/colors' as colors;
@use '../core/app/variables' as variables;

$block-detail-prior-inspection: 'detail';
$block-detail-omission: 'omission-detail';
$block-signatures: 'signatures';
$block-ppc-request-detail: 'ppc-request-detail';
$block-ppc-administrative-file-detail: 'ppc-administrative-file-detail';
$block-ppc-report: 'ppc-report-detail';
$block-ppc-signatures: 'ppc-signatures';

// .two-element-row-element {
//   flex-basis: 50%;
// }

mat-spinner {
  margin: 20px auto;
}

.#{$block-detail-prior-inspection},
.#{$block-detail-omission},
.#{$block-signatures},
.#{$block-ppc-request-detail},
.#{$block-ppc-administrative-file-detail},
.#{$block-ppc-signatures},
.#{$block-ppc-report} {
  display: flex;
  flex-flow: column;

  .mat-icon.mat-icon-inline {
    line-height: unset;
    height: unset;
    font-size: unset;
    width: unset;
  }

  .mat-stroked-button,
  .mat-mdc-outlined-button {
    background: transparent;
    font-weight: bold;
    .file-type {
      line-height: 1;
    }
  }

  .button-back {
    margin-top: 10px;
    margin-left: 10px;
    height: min-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .subtitle {
    color: rgba(0, 0, 0, 0.38);
    font-size: 18px;
  }

  .detail-info {
    margin: auto;
    max-width: 1000px;
    width: 100%;
    margin-bottom: 50px;
  }

  .edit-button {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 15px;
    margin-top: 15px;
    flex-basis: 15%;
  }

  .conclusion-card-button {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 15px;
    margin-top: 15px;
    flex-basis: 15%;
    align-items: center;
  }

  .edit-button-bot {
    margin-top: -10px;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 30px;
    margin-top: 15px;
    flex-basis: 15%;
  }

  .edit-button-top {
    margin-top: -5px;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 30px;
    flex-basis: 15%;
  }

  mat-card {
    margin-bottom: 20px;
    max-width: 1000px;
    margin: auto;
    margin-top: 20px !important;
    box-shadow: 0px 2px 2px -1px colors.$shadow-color,
      -1px -1px 1px 0px colors.$shadow-color,
      0px 1px 3px 0px colors.$shadow-color !important;

    mat-card-title {
      margin: 0 0 20px 0;
    }

    mat-card-header {
      justify-content: space-between;
    }
    mat-card-header-text {
      flex-basis: 80%;
      align-self: center;
    }
    mat-card-subtitle {
      // margin-left: -16px;
      display: flex;
      align-items: center;
      > span {
        cursor: pointer;
      }
    }

    .documents-table-title-text {
      font-size: 20px;
      font-weight: 500;
    }

    .documents-table-title {
      margin-bottom: 10px;
    }

    .new-comments-title-text {
      font-size: 20px;
      font-weight: 500;
    }

    .new-comments-title {
      margin-bottom: 10px;
    }

    .deleted-comments-title-text {
      font-size: 20px;
      font-weight: 500;
    }

    .deleted-comments-title {
      margin-bottom: 10px;
    }

    .modified-comments-title-text {
      font-size: 20px;
      font-weight: 500;
    }

    .modified-comments-title {
      margin-bottom: 10px;
    }

    // .comment {
    //   display: flex;
    //   flex-direction: row;
    //   align-items: center;
    //   width: 100%;
    //   max-height: 100px;
    //   overflow: auto;
    // }

    // .comment-data {
    //   display: flex;
    //   flex-direction: row;
    //   align-items: center;
    //   justify-content: space-between;
    //   width: 100%;
    // }

    // .comment-content {
    //   width: 280px;
    //   line-height: 1.2em;
    //   height: 2.4em; /* twice the line-height */
    //   // overflow: hidden;
    //   display: flex;
    //   align-items: end;
    //   flex-wrap: wrap;
    //   overflow: auto;
    //   word-break: break-all;
    // }

    // .comment-icons {
    //   display: flex;
    //   flex-direction: row;
    //   align-items: center;
    // }

    // .comment-button {
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   margin-left: 8px;
    // }

    .comment-header {
      display: flex;
      flex-flow: row;
      align-items: baseline;
      margin-bottom: 5px;
    }

    .comment {
      display: flex;
      flex-flow: row;
      margin-bottom: 15px;
      background-color: colors.$color-grey-200;
      border-radius: 20px;
      padding: 1.2em 1em;
      // width: 97%;
      justify-content: space-between;
      align-items: center;
      overflow: auto;
      // max-height: 50px;
    }

    .mdc-list-item__content {
      white-space: normal;
    }

    .mdc-list-item__primary-text {
      white-space: normal;
    }

    .comment-blue {
      background-color: colors.$color-bg-card-blue;
    }

    .comment-green {
      background-color: colors.$color-bg-card-green;
    }

    .comment-red {
      background-color: colors.$color-bg-card-red;
    }

    .comment-orange {
      background-color: colors.$color-bg-card-orange;
    }

    .comment-data {
      display: flex;
      flex-flow: column;
    }

    .comment-user {
      font-size: 14px;
    }

    .comment-button {
      cursor: pointer;
    }

    .comment-date {
      font-size: 12px;
      color: colors.$color-primary-disabled;
      margin-left: 5px;
    }

    .blue-text {
      color: colors.$bg-color-primary;
    }

    .comment-content {
      font-weight: 400;
      font-size: 13px;
    }

    .div-comment {
      position: absolute;
      right: 15px;
    }

    .ic-searchbox {
      box-shadow: none;
    }

    mat-list-item {
      height: auto !important;
    }

    > mat-card-content {
      font-size: variables.$font-size-16;
      // .two-element-row {
      //   flex-direction: row;
      //   display: flex;
      //   flex-wrap: wrap;
      // }

      .ic-slide-toggle {
        margin-bottom: 25px;
      }

      .two-element-row-one-element {
        flex-basis: 100%;
      }
    }

    .observations-title {
      font-size: 14px;
      font-weight: 600;
      line-height: 1.5em;
    }

    .favorable-report {
      color: colors.$color-favorable;
    }

    .unfavorable-report {
      color: colors.$color-unfavorable;
    }

    .favorable-but-errors-report {
      color: colors.$color-pending;
    }

    // .two-element-row-element {
    //   @media (max-width: 1024px) {
    //     flex-basis: 100%;
    //   }
    //   flex-basis: 50%;
    //   align-self: baseline;
    //   .mat-mdc-input-element {
    //     letter-spacing: 0;
    //   }
    // }

    // .four-element-row-big-element {
    //   @media (max-width: 1024px) {
    //     flex-basis: 100%;
    //   }
    //   flex-basis: 36%;
    //   .mat-mdc-input-element {
    //     letter-spacing: 0;
    //   }
    // }

    // .four-element-row-small-element {
    //   @media (max-width: 1024px) {
    //     flex-basis: 100%;
    //     max-width: 100%;
    //   }
    //   flex-basis: 14%;
    //   max-width: 14%;
    //   .mat-mdc-input-element {
    //     letter-spacing: 0;
    //   }
    // }

    // .four-element-row-small-element-edit {
    //   @media (max-width: 1024px) {
    //     flex-basis: 100%;
    //     max-width: 100%;
    //   }
    //   flex-basis: 15%;
    //   align-self: baseline;
    //   min-width: 0px;
    //   .mat-form-field,
    //   .mat-mdc-form-field {
    //     width: 120px;
    //     .mat-form-field-wrapper,
    //     .mat-mdc-text-field-wrapper {
    //       // padding-bottom: 1.25em;
    //       width: 120px;
    //       .mat-form-field-flex,
    //       .mat-mdc-form-field-flex {
    //         .mat-form-field-infix,
    //         .mat-mdc-form-field-infix {
    //           width: 120px;
    //           .mat-mdc-input-element {
    //             letter-spacing: 0;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }

    // .four-element-row-big-element-edit {
    //   @media (max-width: 1024px) {
    //     flex-basis: 100%;
    //     max-width: 100%;
    //   }
    //   flex-basis: 35%;
    //   align-self: baseline;
    //   min-width: 0px;
    //   .mat-form-fiel,
    //   .mat-mdc-form-field {
    //     .mat-form-field-wrapper,
    //     .mat-mdc-text-field-wrapper {
    //       // padding-bottom: 1.25em;
    //       .mat-mdc-input-element {
    //         letter-spacing: 0;
    //       }
    //     }
    //   }
    // }

    // .des-row-element {
    //   @media (max-width: 1024px) {
    //     flex-basis: 100%;
    //   }
    //   flex-basis: 37%;
    //   min-width: 0px;
    //   .mat-form-field,
    //   .mat-mdc-form-field {
    //     width: 320px;
    //     .mat-form-field-wrapper,
    //     .mat-mdc-text-field-wrapper {
    //       width: 320px;
    //       .mat-form-field-flex,
    //       .mat-mdc-form-field-flex {
    //         .mat-form-field-infix,
    //         .mat-mdc-form-field-infix {
    //           width: 320px;
    //           .mat-mdc-input-element {
    //             letter-spacing: 0;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }

    // .item-row-element {
    //   @media (max-width: 1024px) {
    //     flex-basis: 100%;
    //   }
    //   flex-basis: 20%;
    //   min-width: 0px;
    //   max-width: 160px;
    //   .mat-form-field,
    //   .mat-mdc-form-field {
    //     width: 150px;
    //     .mat-form-field-wrapper,
    //     .mat-mdc-text-field-wrapper {
    //       width: 150px;
    //       .mat-form-field-flex,
    //       .mat-mdc-form-field-flex {
    //         .mat-form-field-infix,
    //         .mat-mdc-form-field-infix {
    //           width: 150px;
    //           .mat-mdc-input-element {
    //             letter-spacing: 0;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }

    // .item-row-element-edit {
    //   @media (max-width: 1024px) {
    //     flex-basis: 100%;
    //   }
    //   flex-basis: 15%;
    //   min-width: 0px;
    //   max-width: 160px;
    //   .mat-form-field,
    //   .mat-mdc-form-field {
    //     width: 125px;
    //     .mat-form-field-wrapper,
    //     .mat-mdc-text-field-wrapper {
    //       width: 125px;
    //       .mat-form-field-flex,
    //       .mat-mdc-form-field-flex {
    //         .mat-form-field-infix,
    //         .mat-mdc-form-field-infix {
    //           width: 125px;
    //           .mat-mdc-input-element {
    //             letter-spacing: 0;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }

    // .des-row-element-edit {
    //   @media (max-width: 1024px) {
    //     flex-basis: 100%;
    //   }
    //   flex-basis: 36%;
    //   min-width: 0px;
    //   .mat-form-field,
    //   .mat-mdc-form-field {
    //     width: 320px;
    //     .mat-form-field-wrapper,
    //     .mat-mdc-text-field-wrapper {
    //       width: 320px;
    //       .mat-form-field-flex,
    //       .mat-mdc-form-field-flex {
    //         .mat-form-field-infix,
    //         .mat-mdc-form-field-infix {
    //           width: 320px;
    //           .mat-mdc-input-element {
    //             letter-spacing: 0;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }

    // .five-element-row-small-element {
    //   @media (max-width: 1024px) {
    //     flex-basis: 100%;
    //   }
    //   flex-basis: 12.5%;
    //   min-width: 0px;
    //   .mat-form-field,
    //   .mat-mdc-form-field {
    //     min-width: 0px;
    //     max-width: 100px;
    //     width: 95%;
    //     .mat-form-field-wrapper,
    //     .mat-mdc-text-field-wrapper {
    //       min-width: 0px;
    //       max-width: 100px;
    //       width: 95%;
    //       .mat-form-field-flex,
    //       .mat-mdc-form-field-flex {
    //         .mat-form-field-infix,
    //         .mat-mdc-form-field-infix {
    //           min-width: 0px;
    //           max-width: 100px;
    //           width: 95%;
    //           .mat-mdc-input-element {
    //             letter-spacing: 0;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }

    // .five-element-row-element {
    //   @media (max-width: 1024px) {
    //     flex-basis: 100%;
    //   }
    //   flex-basis: 15%;
    //   min-width: 0px;
    //   .mat-form-field,
    //   .mat-mdc-form-field {
    //     width: 95px;
    //     min-width: 0px;
    //     .mat-form-field-wrapper,
    //     .mat-mdc-text-field-wrapper {
    //       width: 95px;
    //       min-width: min-content;
    //       .mat-form-field-flex,
    //       .mat-mdc-form-field-flex {
    //         .mat-form-field-infix,
    //         .mat-mdc-form-field-infix {
    //           width: 95px;
    //           min-width: 0px;
    //           // padding: 0.5em 0;
    //           .mat-mdc-input-element {
    //             letter-spacing: 0;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }

    // .five-element-row-element-edit {
    //   @media (max-width: 1024px) {
    //     flex-basis: 100%;
    //   }
    //   flex-basis: 15%;
    //   min-width: 0px;
    //   .mat-form-field,
    //   .mat-mdc-form-field {
    //     min-width: 0px;
    //     width: 100px;
    //     .mat-form-field-wrapper,
    //     .mat-mdc-text-field-wrapper {
    //       min-width: min-content;
    //       width: 100px;
    //       .mat-form-field-flex,
    //       .mat-mdc-form-field-flex {
    //         .mat-form-field-infix,
    //         .mat-mdc-form-field-infix {
    //           min-width: 0px;
    //           width: 100px;
    //           .mat-mdc-input-element {
    //             letter-spacing: 0;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }

    // .total-label {
    //   font: caption;
    //   text-align: right;
    //   align-self: flex-end;
    //   margin-bottom: 35px;
    //   font-weight: bold;
    // }

    // .add-budgetary {
    //   cursor: pointer;
    //   vertical-align: bottom;
    // }

    // .delete-budgetary {
    //   cursor: pointer;
    //   margin: 10px;
    //   padding: 0.9em 0;
    //   // padding-bottom: 1.25em;
    //   border-top: 0.8437;
    // }
  }
}
