@use '../../core/app/colors' as colors;

ic-menu-list-item {
  display: flex;
  flex-direction: column;
  outline: none;

  .mat-mdc-list-item.active {
    background-color: colors.$bg-color-primary;
  }
  &:hover,
  &:focus {
    color: white;
    > .mat-mdc-list-item:not(.expanded) {
      background-color: colors.$bg-color-primary !important;
    }
  }

  a.menu-list-item {
    &:hover {
      color: white;
      background: colors.$bg-color-primary;
    }
  }

  .mat-mdc-list-item {
    padding-top: 2.5em;
    padding-bottom: 2em;
    display: flex;
    width: auto;
    font-size: 15px;
    &:hover {
      .mdc-list-item__primary-text {
        color: white;
      }
    }

    .mdc-list-item__primary-text {
      color: white;
      display: flex;
      flex-basis: 100%;
      flex-direction: row;
      flex-shrink: 1;
      flex-grow: 1;
      // flex: 1 1 100%;
      align-items: center;
      span {
      }
    }
  }

  .horizontal-line {
    background-color: colors.$color-grey-600;
    width: 85%;
    height: 0.1px;
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
  }

  .list-component-row {
    width: 5.7em;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding-left: 0.7em;
  }

  .list-component-block {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
  }

  .menu-icon {
    height: 1em;
    width: 3em;
  }
}
