@use '../../core/app/colors' as colors;
@use '../../core/fonts/fonts';

$block-table: 'ic-selectable';

mat-spinner {
  margin: 20px auto;
}

.empty-row {
  background: colors.$color-white;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  margin-bottom: 20px;
  height: 30px;
  text-align: center;
  padding-top: 15px;
}

.#{$block-table} {
  &-table {
    overflow: auto;
    max-height: calc(100vh - 234px);

    &.ic-not-cursor-table {
      .mat-row,
      .mat-mdc-row {
        &:hover {
          cursor: default !important;
        }
      }
    }

    mat-header-row.mat-mdc-header-row {
      height: 66px !important;
    }

    mat-row.mat-mdc-row,
    mat-footer-row.mat-mdc-footer-cell {
      height: 58px !important;
    }

    .mat-header-row,
    .mat-mdc-header-row {
      background-color: colors.$color-table-header-background;
      border-bottom-width: 0;
      text-transform: capitalize;
      padding-left: 10px;
      // padding-right: 10px;
      .mat-sort-header-button {
        text-transform: uppercase;
      }
      .mat-sort-header-arrow {
        margin: 0 0 0 2px;
      }
    }

    .prescrivet-icon {
      display: inline-block;
      width: 100px;
      height: 100px;
      background: url('/assets/images/cuadrado.png') no-repeat center top;
      background-size: contain;
    }

    .mat-row:nth-child(odd),
    .mat-mdc-row:nth-child(odd) {
      background-color: colors.$color-bg-row-odd !important;
    }

    .mat-row:nth-child(odd).last-touched,
    .mat-mdc-row:nth-child(odd).last-touched {
      background-color: colors.$color-row-touched !important;
    }

    .mat-row:nth-child(odd).obs-highlight,
    .mat-mdc-row:nth-child(odd).obs-highlight {
      background-color: colors.$color-row-hightlight-odd !important;
    }

    .mat-row:nth-child(even).obs-highlight,
    .mat-mdc-row:nth-child(even).obs-highlight {
      background-color: colors.$color-row-hightlight-even !important;
    }

    .mat-row,
    .mat-mdc-row {
      &.is-touching {
        background-color: colors.$color-row-is-touched-even !important;
        font-weight: bold !important;
      }
      &:hover.is-touching {
        background-color: colors.$color-row-is-touched-even !important;
        font-family: 'OpenSansRegular', sans-serif;
        cursor: pointer !important;
      }
    }

    .mat-row,
    .mat-mdc-row {
      font-family: 'OpenSansLight', sans-serif;
      border-color: rgba(0, 0, 0, 0.12);
      color: colors.$color-grey-700;
      border-width: 0;
      border-style: solid;
      border-bottom-width: 0.5px;
      padding-left: 10px;
      // padding-right: 10px;
      .text-bold {
        font-weight: bold;
        font-family: 'OpenSansRegular', sans-serif;
      }

      &.highlight {
        background-color: colors.$color-grey-100;
        font-family: 'OpenSansRegular', sans-serif;
      }
    }

    .mat-row-non-pointer {
      cursor: default !important;
    }

    .mat-row:hover:not(.ic-not-cursor-table .mat-row:hover),
    .mat-mdc-row:hover:not(.ic-not-cursor-table .mat-mdc-row:hover) {
      background-color: colors.$color-grey-100 !important;
      cursor: pointer;
    }

    .mat-row:hover.last-touched,
    .mat-mdc-row:hover.last-touched {
      background-color: colors.$color-row-touched !important;
      font-family: 'OpenSansRegular', sans-serif;
      cursor: pointer;
    }

    .mat-row:hover.obs-highlight,
    .mat-mdc-row:hover.obs-highlight {
      background-color: colors.$color-row-hightlight-hover !important;
      font-family: 'OpenSansRegular', sans-serif;
      cursor: pointer;
    }

    // .mat-row:hover.is-touching,
    // .mat-mdc-row:hover.is-touching {
    //   background-color: colors.$color-row-hightlight-hover !important;
    //   font-family: 'OpenSansRegular', sans-serif;
    //   cursor: pointer;
    // }

    .observations-table {
      align-items: start;
    }

    .observations-table > span {
      font-size: 12px;
    }

    .observations-table-code > span {
      font-size: 11px;
    }

    .mat-header-cell,
    .mat-mdc-header-cell,
    .mat-cell,
    .mat-mdc-cell {
      // margin: 0 6px;
      // height: 48px;
      padding-top: 5px;
      padding-bottom: 5px;
      .mat-success {
        color: colors.$color-success;
      }
      .mat-warning {
        color: colors.$color-warning;
      }
      .mat-error {
        color: colors.$color-error;
      }
      .mat-out {
        color: colors.$color-out;
      }
      .mat-stopped {
        color: colors.$color-stopped;
      }
      .mat-read {
        color: colors.$color-read;
      }
      .mat-doc {
        color: colors.$color-doc;
      }
      .mat-calc {
        color: colors.$color-calc;
      }
      .mat-image {
        color: colors.$color-image;
      }
      .mat-cross {
        color: colors.$color-cross;
      }
      .mat-general {
        color: colors.$color-general;
      }
      .mat-specific {
        color: colors.$color-specific;
      }
      .mat-no-model {
        color: colors.$color-no-model;
      }
      .mat-pending {
        color: colors.$color-pending;
      }
      .success {
        color: colors.$color-success;
      }
      .warning {
        color: colors.$color-warning;
      }
      .error {
        color: colors.$color-error;
      }
      .out {
        color: colors.$color-out;
      }
      .no-model {
        color: colors.$color-no-model;
      }
    }

    .mat-cell,
    .mat-mdc-cell

    // .mat-header-cell:first-of-type,
    // .mat-cell:first-of-type {
    //   flex: 0 0 40px;
    //   margin: 0;
    //   padding-left: 16px;
    //   justify-content: left;
    // }

    // .mat-header-cell:last-of-type,
    // .mat-cell:last-of-type {
    //   flex: 0 0 48px;
    //   margin: 0;
    //   padding-right: 16px;
    // }

    // .mat-header-cell:nth-child(2),
    // .mat-cell:nth-child(2) {
    //   flex: 0 0 15%;
    //   justify-content: left;
    // }

    // .mat-header-cell:nth-child(3),
    // .mat-cell:nth-child(3) {
    //   flex: 0 0 30%;
    //   justify-content: left;
    // }

    // .mat-header-cell:nth-child(6),
    // .mat-cell:nth-child(6) {
    //   flex: 0 0 10%;
    // }

    // .mat-cell:nth-child(2) .avatar-img {
    //   border-radius: 50%;
    //   display: flex;
    //   height: 24px;
    //   justify-content: center;
    //   overflow: hidden;
    //   position: relative;
    //   text-align: center;
    //   width: 24px;
    //   img.mat-card-image {
    //     height: 100%;
    //     width: auto;
    //     &:first-child {
    //       margin: 0 ;
    //     }
    //   }
    // }


    // TODO: Revisar este estilo con los cambios de Material
    .mat-checkbox,
    .mat-mdc-checkbox {
      .mat-checkbox-inner-container,
      .mdc-checkbox {
        .mat-checkbox-frame,
        .mdc-checkbox__background {
          border-color: colors.$color-grey-600;
          border-width: 1px;
        }
      }
    }

    .mat-header-cell:last-child,
    .mat-mdc-header-cell:last-child,
    .mat-cell:last-child,
    .mat-mdc-cell:last-child {
      margin-right: 10px;
    }

    .mdc-data-table__cell,
    .mdc-data-table__header-cell {
      padding: 0 1px 0 1px;
    }

    mat-cell,
    mat-header-cell {
      border: none;
      letter-spacing: 0px;
      label {
        cursor: pointer;
      }
    }

    mat-header-row {
      justify-content: space-evenly;
      mat-header-cell {
        font-size: 11.5px;
        font-weight: 500;
      }
    }

    mat-row {
      justify-content: space-evenly;

      &:hover {
        mat-cell {
          color: rgba(0, 0, 0, 1);
          font-family: 'OpenSansRegular', sans-serif;
        }
      }
      mat-cell {
        font-family: 'OpenSansLight', sans-serif;
        font-size: 14px;
        letter-spacing: 0px;
        color: rgb(102, 102, 102);
        -webkit-font-smoothing: auto;
        -moz-osx-font-smoothing: auto;
      }
    }

    .ic-input-file-table {
      display: none;
    }
  }
  &-cell-1 {
    flex: 0 0 1%;
    justify-content: flex-start;
  }
  &-cell-3 {
    flex: 0 0 3%;
    justify-content: flex-start;
  }
  &-cell-4 {
    flex: 0 0 4%;
    justify-content: flex-start;
  }
  &-cell-5 {
    flex: 0 0 5%;
    justify-content: flex-start;
  }
  &-cell-6 {
    flex: 0 0 6%;
    justify-content: flex-start;
  }
  &-cell-7 {
    flex: 0 0 7%;
    justify-content: flex-start;
  }
  &-cell-8 {
    flex: 0 0 8%;
    justify-content: flex-start;
  }
  &-cell-9 {
    flex: 0 0 9%;
    justify-content: flex-start;
  }
  &-cell-10 {
    flex: 0 0 10%;
    justify-content: flex-start;
  }
  &-cell-12 {
    flex: 0 0 12%;
    justify-content: flex-start;
  }
  &-cell-15 {
    flex: 0 0 15%;
    justify-content: flex-start;
  }
  &-cell-17 {
    flex: 0 0 17%;
    justify-content: flex-start;
  }
  &-cell-20 {
    flex: 0 0 20%;
    justify-content: flex-start;
  }
  &-cell-25 {
    flex: 0 0 25%;
    justify-content: flex-start;
  }
  &-cell-30 {
    flex: 0 0 30%;
    justify-content: flex-start;
  }
  //Esta cell esta creada para la columna redactado de las observaciones de configuracion FRB
  //Ya que son textos muy grandes y con inline-grid conseguimos que los terxtos pequeños queden centrados
  &-cell-35 {
    flex: 0 0 35%;
    display: inline-grid;
  }
  &-cell-45 {
    display: inline-grid;
  }
  &-right {
    justify-content: flex-end;
  }
  &-center {
    justify-content: center;
  }
  &-baseline-align {
    align-items: baseline;
  }
  &-row-min-width-1500 {
    // min-width: 100%;
    min-width: 1250px;
    flex: 0 0 100%;
  }

  &-ellipsis {
    display: flex;
    justify-content: left;
    > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.last-touched {
  background-color: colors.$color-row-touched !important;
  min-width: 1250px;
  // min-width: 1500px;
}

.observations-height-header {
  min-height: 30px !important;
  .mat-header-cell {
    height: 20px !important;
  }
}

.observations-header {
  height: 20px;
  min-height: 25px;
  top: 40px !important;
}

.administrative-file-types-height-header {
  min-height: 30px !important;
  .mat-header-cell {
    height: 20px !important;
  }
}

.administrative-file-types-header {
  height: 20px;
  min-height: 25px;
  top: 40px !important;
}

.entities-height-header {
  min-height: 30px !important;
  .mat-header-cell {
    height: 20px !important;
  }
}

.entities-header {
  height: 20px;
  min-height: 25px;
  top: 40px !important;
}

.dragCursor {
  margin-right: 16px;
  margin-left: 10px;
  cursor: move;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  background: mat-color(colors.$color-grey-700, background);
}

.cdk-drag-placeholder {
  background: colors.$color-grey-700;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging .mat-row:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
