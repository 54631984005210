.ic-dnd-container {
  width: 450px;
  height: 200px;
  padding: 2rem;
  text-align: center;
  border: dashed 1px #979797;
  position: relative;
  margin: 0 auto;

  input {
    opacity: 0;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  label {
    color: white;
    width: 183px;
    height: 44px;
    border-radius: 21.5px;
    background-color: #db202f;
    padding: 8px 16px;
  }

  h3 {
    font-size: 20px;
    font-weight: 600;
    color: #38424c;
  }
}

.ic-dnd-fileover {
  animation: shake 1s;
  animation-iteration-count: infinite;
}

.ic-dnd-files-list {
  margin-top: 1.5rem;

  .ic-dnd-single-file {
    display: flex;
    padding: 0.5rem;
    justify-content: space-between;
    align-items: center;
    border: dashed 1px #979797;
    margin-bottom: 1rem;

    .ic-dnd-delete {
      display: flex;
      margin-left: 0.5rem;
      cursor: pointer;
      align-self: flex-end;
    }


    display: flex;
    flex-grow: 1;

    .ic-dnd-name {
      font-size: 14px;
      font-weight: 500;
      color: #353f4a;
      margin: 0;
    }

    .ic-dnd-size {
      font-size: 12px;
      font-weight: 500;
      color: #a4a4a4;
      margin: 0;
      margin-bottom: 0.25rem;
    }

    .ic-dnd-info {
      width: 100%
    }
  }
}

/* Shake animation */
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
