.mat-tree-node {
  .mat-mdc-outlined-button {
    background-color: transparent;
    font-weight: bold;
    .file-type {
      line-height: 1;
    }
  }
}

.example-tree-invisible {
  display: none;
}

.example-tree ul,
.example-tree li {
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.mat-tree-node {
  font-size: 13px;
  > .mat-stroked-button,
  > .mat-mdc-outlined-button {
    font-size: 13px;
    padding: 0 8px;
    text-transform: none;
  }
}

.file-type {
  font-size: 20px;
  line-height: 24px;
}
span {
  &.mdc-button__label {
    text-align: left;
  }
}

.text-file-type {
  padding: 0 7px;
}
