@use '../../../core/app/variables' as variables;
@use '../../../core/app/colors' as colors;

.mat-raised-button,
.mat-mdc-raised-button {
  &.mat-primary {
    background-color: colors.$bg-color-primary;
    color: colors.$color-primary;
  }
}

.mat-dialog-container,
.mat-mdc-dialog-container {
  // padding: 16px 24px 8px !important;
  // min-width: 750px !important;
  // margin-left: -150px !important;
  .input-dialog {
    width: 100%;
    max-width: 750px;
    height: 100%;
    max-height: 550px;

    .dialog-title {
      color: colors.$color-grey-800;
      font-size: variables.$font-size-20;
    }

    .mat-mdc-dialog-content {
      overflow-y: hidden;
      padding-bottom: 5px;
    }
    .mat-mdc-dialog-actions {
      justify-content: flex-end;
      min-height: inherit;
      // margin-top: -15px;
      padding-right: 24px;
    }
  }
}
