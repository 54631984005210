// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use 'sass:map';
@use '@angular/material' as mat;
@use 'palettes';
@use 'ppc-theme' as ppc;
@use 'omission-theme' as omission;
@use '../core/app/colors' as colors;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$scipion-primary: mat.define-palette(palettes.$ic-blue, 500);
$scipion-accent: mat.define-palette(palettes.$ic-black, 100, A100, A400);

// The warn palette is optional (defaults to red).
$scipion-warn: mat.define-palette(mat.$red-palette);

$custom-typography: mat.define-typography-config(
  $font-family: '"Open Sans", "Helvetica Neue", sans-serif'
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$scipion-theme: mat.define-light-theme(
  (
    color: (
      primary: $scipion-primary,
      accent: $scipion-accent,
      warn: $scipion-warn
    ),
    typography: $custom-typography
  )
);

@mixin color-scipion($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($scipion-theme);

  // Get the primary color palette from the color-config.
  $custom-palette: map.get($color-config, 'accent');
  $primary-palette: map.get($color-config, 'primary');

  .user-options {
    .material-icons,
    .material-icons-outlined,
    .material-icons-round,
    .material-icons-sharp {
      color: colors.$color-white;
    }
  }

  .material-icons,
  .material-icons-outlined,
  .material-icons-round,
  .material-icons-sharp {
    color: mat.get-color-from-palette($custom-palette, 500);
    &.secondary {
      color: colors.$color-white;
    }
    &.favorable-report {
      color: colors.$color-favorable;
    }
    &.favorable-but-errors-report {
      color: colors.$color-pending;
    }
    &.unfavorable-report {
      color: colors.$color-unfavorable;
    }
    // font-size: 48px;
  }

  .mat-mdc-tab-link.mdc-tab-indicator--active.mat-mdc-tab-disabled {
    .mdc-tab-indicator__content--underline {
      border-color: mat.get-color-from-palette($primary-palette, 500);
    }
  }

  .mat-mdc-button:not(:disabled) {
    background-color: mat.get-color-from-palette($primary-palette, 500);
    color: white;
  }
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($scipion-theme);
@include color-scipion($scipion-theme);
@include ppc.theme();
@include omission.theme();
// @include mat.all-component-themes($scipion-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'OpenSans', 'Helvetica Neue', sans-serif;
}

$dark-text: #343a40;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

body {
  --primary-color: #007bff;
  --primary-lighter-color: #b3d7ff;
  --primary-darker-color: #005eff;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
}

body {
  --accent-color: #e83e8c;
  --accent-lighter-color: #f8c5dd;
  --accent-darker-color: #de286f;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}

body {
  --warn-color: #dc3545;
  --warn-lighter-color: #f5c2c7;
  --warn-darker-color: #ce222e;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}
