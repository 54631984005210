@use '../../core/app/colors' as colors;
@use '../../core/app/breakpoints' as breakpoints;

.mat-mdc-elevation-specific {
  max-width: -moz-fit-content !important;
  max-width: fit-content !important;
}
ic-top-nav {
  .user-options {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
  }

  .toolbar-title {
    color: colors.$color-white;
  }

  ic-top-nav-picker {
    padding-inline-end: 5%;
  }

  ic-top-nav-autocomplete {
    padding-inline-end: 5%;
  }

  // Existen dos top-nav, uno superior fixed y otro a nivel del sidebar.
  // Usamos el fixed en pantallas pequeñas para no perder el botón de abrir y
  // cerrar el sidebar cuando el sidebar se posiciona por encima del top-nav
  // en pantallas pequeñas

  // Mostramos el top-nav interno inferior cuando estamos en pantalla grande
  &.xs {
    display: block;
  }

  // Ocultamos el top-nav externo superior cuando estamos em pantalla grande
  &.gt-xs {
    display: none;
  }

  @include breakpoints.breakpoint-max(breakpoints.$tablet) {
    // Ocultamos el top-nav interno inferior cuando estamos em pantalla pequeña
    &.xs {
      display: none;
    }
    // Mostramos el top-nav externo superior cuando estamos en pantalla pequeñas
    &.gt-xs {
      display: block;
    }
  }
}
