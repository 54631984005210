@use '../../../core/app/colors' as colors;

.mat-stroked-button,
.mat-mdc-outlined-button {
  // border-radius: 4px;
  // box-shadow: none;
  margin: 0 4px;
  text-transform: uppercase;
  &.mat-primary {
    // background-color: colors.$bg-color-primary;
    color: colors.$color-primary;
    &:hover {
      // background-color: colors.$bg-color-primary-hover;
      color: colors.$color-primary-hover;
    }
    &[disabled] {
      // background-color: colors.$bg-color-primary;
      color: colors.$color-primary-disabled;
    }
  }
  &.mat-accent {
    // background-color: colors.$bg-color-accent;
    color: colors.$color-accent;
    &:hover {
      // background-color: colors.$bg-color-accent-hover;
      color: colors.$color-accent-hover;
    }
    &[disabled] {
      // background-color: colors.$bg-color-accent;
      color: colors.$color-accent-disabled;
    }
  }
}
