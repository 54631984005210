@use '../../core/app/colors' as colors;
@use '../../core/fonts/fonts';
@use '../../core/app/variables' as variables;

$block-table-dragndrop: 'ic-table-dragndrop';

.#{$block-table-dragndrop} {
  .mat-mdc-form-field {
    margin: 10px !important;
    line-height: 2.2 !important;
    .mat-mdc-text-field-wrapper,
    .mat-mdc-form-field-wrapper {
      padding: 0 10px !important;
      .mat-mdc-form-field-flex {
        display: flex;
        align-items: flex-end;
        padding-top: 0 !important;
        height: 30px !important;

        .mat-mdc-form-field-infix {
          display: flex;
          align-items: flex-end;
          padding-bottom: 0px;

          .#{$block-table-dragndrop}-form-input {
            font-size: variables.$font-size-12 !important;
            line-height: 15px !important;
          }

          select {
            width: 100%;
            text-align: center; /* Centra el texto del select */
            -webkit-appearance: none; /* Remueve el estilo por defecto de algunos navegadores */
            -moz-appearance: none;
            appearance: none;
          }

          option {
            text-align: center; /* Centra el texto dentro de las opciones */
          }
        }
      }
      .mat-form-field-underline,
      .mdc-line-ripple,
      .mat-mdc-form-field-underline {
        background-color: colors.$color-grey-400 !important;
      }
    }
  }
}

.example-container {
  margin: 0 25px 25px 0;

  h2 {
    font-size: 1.5em;
    font-family: 'OpenSansLight', sans-serif;
    margin-bottom: 10px;
  }

  .drop-list {
    border: solid 1px #ccc;
    min-height: 60px;
    background: white;
    border-radius: 4px;
    overflow: hidden;
    display: block;
  }

  .box-table {
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border-bottom: solid 1px #ccc;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    cursor: move;
    font-size: 14px;

    &:hover {
      background-color: #f0f0f0;
    }
  }

  .row-color {
    background-color: colors.$color-bg-card-blue !important;
  }
  .row-color-reused {
    background-color: colors.$color-row-hightlight-odd !important;
  }
}

.cdk-drag-preview,
.cdk-drag-placeholder {
  background: white;
  border-bottom: solid 1px #ccc;
  padding-top: 10px;
  padding-left: 15px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  cursor: move;
  font-size: 14px;
  width: 100%;
}

.cdk-drag-preview {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-preview.row-color,
.cdk-drag-placeholder.row-color {
  background-color: colors.$color-bg-card-blue !important;
}

.example-box:last-child {
  border: none;
}

.iconWithWording {
  display: flex;
  align-items: center;
  margin-right: 10px; /* Espacio mínimo entre icono y select */
  flex-grow: 1; /* Permite que este div crezca para ocupar el espacio disponible */
}

.select-acm-el {
  width: 110px; /* Ancho fijo para el select */
  margin-right: 10px; /* Espacio entre select e iconWithWording */
  flex-shrink: 0;
  padding-right: 20px;
  padding-top: 20px;

  select {
    width: 100%;
    text-align: center; /* Centra el texto del select */
    -webkit-appearance: none; /* Remueve el estilo por defecto de algunos navegadores */
    -moz-appearance: none;
    appearance: none;
  }

  option {
    text-align: center; /* Centra el texto dentro de las opciones */
  }
}

.line-through {
  text-decoration: line-through;
}

.icon-button-container {
  margin-left: auto; /* Empuja el contenedor de iconos al final */
  display: flex;
  align-items: center; /* Alinea los iconos verticalmente al centro */
}
