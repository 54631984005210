@use 'app/colors' as colors;
@use 'app/variables' as variables;
@forward 'app';
@forward 'fonts';

body {
  font-family: OpenSansRegular, sans-serif;
}

.material-icons-outlined,
.material-icons.material-icons-outlined,
.material-icons-two-tone,
.material-icons.material-icons-two-tone,
.material-icons-round,
.material-icons.material-icons-round,
.material-icons-sharp,
.material-icons.material-icons-sharp {
  font-weight: normal;
  font-style: normal;
  // font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.material-icons-two-tone {
  filter: invert(0.5) sepia(1) saturate(10) hue-rotate(180deg);
  // font-size: 48px;
}

.material-icons,
.material-icons-outlined,
.material-icons-round,
.material-icons-sharp {
  color: #4050b6;
  &.secondary {
    color: colors.$color-white;
  }
  &.favorable-report {
    color: colors.$color-favorable;
  }
  &.favorable-but-errors-report {
    color: colors.$color-pending;
  }
  &.unfavorable-report {
    color: colors.$color-unfavorable;
  }
  // font-size: 48px;
}

.material-icons-outlined,
.material-icons.material-icons-outlined {
  font-family: 'Material Icons Outlined';
}

.material-icons-two-tone,
.material-icons.material-icons-two-tone {
  font-family: 'Material Icons Two Tone';
}

.material-icons-round,
.material-icons.material-icons-round {
  font-family: 'Material Icons Round';
}

.material-icons-sharp,
.material-icons.material-icons-sharp {
  font-family: 'Material Icons Sharp';
}

input::placeholder {
  font-weight: 100;
  font-size: variables.$font-size-14;
}

textarea::placeholder {
  font-weight: 100;
  font-size: variables.$font-size-14;
}

.mat-tooltip-autocomplete {
  font-size: 13px;
}

a.mat-mdc-tab-link.ic-tab {
  flex-grow: 0 !important;
  min-width: 160px;
  letter-spacing: 0.5px;
}

.mat-mdc-card-header .mat-mdc-card-title {
  margin-bottom: 12px;
}

.ic-display-flex-fa-icon {
  display: inline-flex;
  color: colors.$bg-color-primary;
}

.mat-mdc-icon-button {
  &.ic-border-button {
    border: 1px solid colors.$color-grey-300;
    color: colors.$bg-color-primary;
    &:hover {
      background-color: colors.$bg-color-primary;
      color: colors.$color-white;
      .mat-icon {
        &.mat-primary {
          color: colors.$color-white;
        }
      }
    }
    .mat-icon {
      &.mat-primary {
        color: colors.$bg-color-primary;
        // vertical-align: middle;
        text-align: center;
        &:hover {
          color: colors.$color-white;
        }
        &:active {
          color: colors.$color-white;
        }
      }
    }
  }
}

.border-none {
  border: none !important;
}
