@use '../../core/app/colors' as colors;

.tree-invisible {
  display: none;
}

.tree ul,
.tree li {
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
  //padding-inline-start: 0px;
  // margin-left: 40px;
}

.tree li {
  padding: 0.75rem 0.25rem;
}

.tree-button {
  min-width: 130px;
  margin-left: auto;
}

.tree-comments-buttons {
  float: right;
  vertical-align: bottom;
  cursor: pointer;
  font-family: 'Material Icons Outlined';
}

.tree-comments-buttons-disable {
  float: right;
  opacity: 0.4;
  &:hover {
    background-color: transparent;
  }
  vertical-align: bottom;
  font-family: 'Material Icons Outlined';
}

.tree-file-icons {
  vertical-align: bottom;
}

.tree-visibility-comment-icons {
  vertical-align: bottom;
  cursor: pointer;
  float: right;
}

.tree-all-comments {
  float: right;
}

.tree-visibility-all-comments-icon {
  vertical-align: middle;
  cursor: pointer;
}

.tree-file-name {
  font-family: 'OpenSansLight', sans-serif;
  font-size: 14px;
  cursor: pointer;
  background-color: whitesmoke;
}

.mat-tree-position {
  margin: 0.5em;
}

.mat-tree-node {
  min-height: 0;
  width: 100%;
  // font-weight: 600;

  .mat-icon {
    overflow: visible;
  }
}

.mat-cross {
  color: colors.$color-cross;
  opacity: 0.9;
  min-width: 24px;
}

.mat-specific {
  color: colors.$color-specific;
  opacity: 0.9;
  min-width: 24px;
}

.mat-general {
  color: colors.$color-general;
  opacity: 0.7;
  min-width: 24px;
}

.mat-stopped {
  color: colors.$color-stopped;
  opacity: 0.9;
  min-width: 24px;
}

.mat-read {
  color: colors.$color-read;
}

.mat-doc {
  color: colors.$color-doc;
}

.mat-calc {
  color: colors.$color-calc;
}

.mat-image {
  color: colors.$color-image;
}

.mat-image {
  color: colors.$color-image;
}

.mat-pending {
  color: colors.$color-pending;
}

.legal-source-icon {
  min-height: 32px;
  min-width: 32px;
}

.legal-source-icon-invisible {
  visibility: hidden;
}

.legal-source-icons-div {
  display: flex;
}

.tree-label-result {
  padding: 0.5em 2em 0.5em 0.5em;
  text-align: justify;
  font-size: 14px;
  margin-left: 0.5em;
}

.tree-label {
  padding: 0.5em 2em 0.5em 0;
  text-align: justify;
}

.tree-parent {
  border: 1px dotted grey;
  border-width: 0 0 1px 0;
  margin-bottom: 2em;
  width: 100%;
}

.tree-node {
  width: 100%;
}

.tree-parent-node {
  width: 100%;
  border: 1px dotted grey;
  border-width: 0 0 1px 0;
  margin-bottom: 2em;
}

.ppc-tree-parent-node {
  margin-left: -25px;
  width: 100%;
  border: 1px dotted grey;
  border-width: 0 0 1px 0;
  margin-bottom: 2em;
}

.text-bold {
  font-weight: bold;
}

.root-node > li > span {
  font-size: 1.5em;
  padding-bottom: 1em;
  padding-top: 1em;
  text-decoration: underline grey;
}

.comment-header {
  display: flex;
  flex-flow: row;
  align-items: baseline;
  margin-bottom: 5px;
}

.comment-data {
  display: flex;
  flex-flow: column;
}

.comment-user {
  font-size: 14px;
}

.comment-date {
  font-size: 12px;
  color: colors.$color-primary-disabled;
  margin-left: 5px;
}

.comment-blue {
  background-color: colors.$color-bg-card-blue;
  display: flex;
  flex-flow: row;
  margin-bottom: 15px;
  border-radius: 20px;
  padding: 1.2em 1em;
  // width: 97%;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
  // max-height: 50px;
}

.comment {
  display: flex;
  flex-flow: row;
  margin-bottom: 15px;
  background-color: colors.$color-grey-200;
  border-radius: 20px;
  padding: 1.2em 1em;
  // width: 97%;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
  // max-height: 50px;
}

.blue-text {
  color: colors.$bg-color-primary;
}

.comment-content {
  font-weight: 400;
  font-size: 13px;
  white-space: normal;
}

mat-list-item {
  height: auto !important;
}

.dragable-div-child-node {
  display: flex;
  align-items: center;
}

.dragable-div-parent-node {
  display: flex;
  align-items: center;
}

.dragable-cursor {
  cursor: grab;
}

.node-locked {
  cursor: default; /* Cambia el cursor a default para los elementos bloqueados del drag and drop */
}
