@use '../../../core/app/colors' as colors;
@use '../../../core/fonts/fonts';

.mat-icon-button,
.mat-mdc-icon-button {
  // border-radius: 4px;
  // box-shadow: none;
  // margin: 0 4px;
  text-transform: uppercase;
  &[disabled] {
    opacity: 0.7 !important;
  }

  &.mat-primary {
    // background-color: colors.$bg-color-primary;
    &:hover {
      // background-color: colors.$bg-color-primary-hover;
    }
    &[disabled] {
      // background-color: colors.$bg-color-primary;
      color: colors.$color-primary-disabled;
    }
  }
  &.mat-accent {
    // background-color: colors.$bg-color-accent;
    &:hover {
      // background-color: colors.$bg-color-accent-hover;
    }
    &[disabled] {
      // background-color: colors.$bg-color-accent;
      color: colors.$color-accent-disabled;
    }
  }
  &.bc-transparent {
    background-color: transparent;
  }
}

.actionIcons {
  height: 40px;
  width: 40px;
  font-size: 30px;
  margin-right: 0px;
  margin-left: 0px;
}
