@use '../../../core/app/colors' as colors;

.mat-dialog-content.dialog-pdf,
.mat-mdc-dialog-content.dialog-pdf {
  height: 100%;
  overflow: hidden;
}

.mat-raised-button,
.mat-mdc-raised-button {
  &.mat-primary {
    background-color: colors.$bg-color-primary;
    color: colors.$color-primary;
  }
}
