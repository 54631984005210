@use '../../../core/fonts/fonts';

$block-toolbar-list: 'ic-searchbox';

.#{$block-toolbar-list} {
  background-color: white;
  box-shadow: none;
  height: 66px;
  display: flex;
  align-items: center;
  font-family: 'OpenSansBold', sans-serif;
  padding-left: 10px;
  @media (max-width: 499px) {
    overflow: scroll;
  }
  .#{$block-toolbar-list}-title {
    @media (max-width: 599px) {
      display: none;
    }
    flex-basis: 312px;
    display: inline-flex;
    align-items: center;
    &-logo {
      width: 32px;
      height: 32px;
      display: inline-block;
    }
  }
  .#{$block-toolbar-list}-searchbox {
    flex-grow: 1;
    flex-basis: 720px;
    margin: 10px;
  }
  .#{$block-toolbar-list}-options {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    button.mat-icon-button:hover,
    button.mat-mdc-icon-button:hover {
      // background-color: #f5f5f5;
    }
  }
}
