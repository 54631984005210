@use '../../core/app/colors' as colors;
@use '../../core/app/variables' as variables;

$block-settings-budget-apps-panel: 'settings-budget-apps-panel';
ic-settings-budget-apps-panel {
  .#{$block-settings-budget-apps-panel} {
    .ic-selectable-table mat-header-row {
      justify-content: space-between;
      &.ic-selectable-row {
        height: 40px !important;
        top: 66px !important;
        .mat-mdc-header-cell:first-of-type {
          padding: 0 0.4em;
        }
      }
      .mat-mdc-header-cell:first-of-type {
        padding: 0 0 0 1.2em;
        label {
          height: 24px;
        }
      }

      .mat-mdc-header-cell {
        min-width: min-content;
      }

      .mat-mdc-header-cell:nth-of-type(2) {
        padding: 0 0.5em 0 2em;
      }

      .mat-mdc-header-cell:nth-of-type(3) {
        min-width: 93px;
      }

      .mat-mdc-header-cell:last-of-type {
        // padding: 0 2em 0 2em;
        min-width: min-content;
      }
    }

    .ic-selectable-table mat-row {
      justify-content: space-between;
      // &.ic-selectable-row {
      //   height: 40px !important;
      //   top: 66px !important;
      // }
      .mat-mdc-cell:first-of-type {
        padding: 0 0.4em;
        min-width: min-content;
      }

      .mat-mdc-cell:nth-of-type(2) {
        min-width: min-content;
      }

      .mat-mdc-cell:nth-of-type(3) {
        min-width: 93px;
      }

      .mat-mdc-cell:last-of-type {
        // padding: 0 2em 0 2em;
        min-width: min-content;
      }

      .mdc-data-table__cell {
        margin-top: 0 !important;
        > span {
          line-height: 16px;
          padding: 5px 0 5px 0;
        }
      }
    }

    ic-detail-table {
      .mat-mdc-row {
        .mdc-data-table__cell {
          padding: 10px 15px 10px 0px;
          margin: 0;
          margin-top: 0 !important;
          min-width: 103px;
          span {
            // min-width: 120px;
          }
        }
      }

      mat-row,
      mat-header-row,
      mat-footer-row {
        // column-gap: 1%;
      }

      .mat-header-row,
      .mat-mdc-header-row {
        .mdc-data-table__header-cell {
          font-size: 12px;
          font-weight: 600;
          min-width: 103px;
          padding: 10px 15px 10px 0px;
          margin: 0;
          margin-top: 0 !important;
        }
      }
    }

    .#{$block-settings-budget-apps-panel}-toolbar {
      background-color: colors.$color-white;
      color: colors.$color-grey-700;
      font-family: 'OpenSansRegular';
      font-size: variables.$font-size-16;
      line-height: 1.19;
      height: variables.$toolbarHeight;
      max-height: variables.$toolbarHeight;
      box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
        0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
      padding: 0 12px 0 4px;
      z-index: 999;

      .hack-autofocus-disable {
        position: absolute;
        height: 0;
      }

      .panel-title {
        flex: auto;
      }

      .panel-close {
        height: 48px;
        margin-right: variables.$separation-16;
        width: 48px;

        &:hover {
          background-color: variables.$icon-button-hover;
        }
      }

      .panel-spacer {
        flex: 1 1 auto;
      }

      .mat-icon.mat-icon-inline {
        line-height: unset;
        height: unset;
        font-size: unset;
        width: unset;
      }
    }

    .#{$block-settings-budget-apps-panel}-info {
      min-height: 100%;
      max-width: 90%;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      background-color: colors.$color-white;

      .budget-app-title {
        font-size: 14px;
        font-weight: 600;
      }

      > mat-card {
        margin-top: 20px;
        margin-bottom: 20px;

        box-shadow: 0px 2px 2px -1px colors.$shadow-color,
          -1px -1px 1px 0px colors.$shadow-color,
          0px 1px 3px 0px colors.$shadow-color;

        > mat-card-content {
          > .two-element-row {
            flex-direction: row;
            display: flex;
          }
        }
      }

      > div {
        flex: 1;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }

      > .#{$block-settings-budget-apps-panel}-title {
        padding-top: 25px;
      }

      .edit-button {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 30px;
        flex-basis: 25%;
      }
    }
  }
}

.dynamic-component-header-text {
  margin-left: 0px;
}

.ic-panel-input {
  margin-top: 0.5em;
}

.ic-slide-toggle {
  margin-bottom: 25px;
}

.two-element-row-element {
  flex-basis: 50%;
}

.row-one-element {
  width: 75%;
}

.text-match {
  color: red;
}
