@use '../core/app/colors' as colors;

.mat-mdc-card.mdc-card {
  padding: 0 10px;
}

.panel-info {
  padding: 20px;
}

mat-card {
  margin-top: 20px;
  margin-bottom: 20px;

  // box-shadow: 0px 2px 2px -1px colors.$shadow-color,
  //   -1px -1px 1px 0px colors.$shadow-color, 0px 1px 3px 0px colors.$shadow-color;
  > mat-card-content {
    > .two-element-row {
      flex-direction: row;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: baseline;
      margin-bottom: 20px;
      > .all-row-element {
        flex-basis: 100%;
        min-width: 100%;
        align-self: baseline;
        .mat-mdc-input-element {
          letter-spacing: 0;
        }
      }
      > .two-element-row-element {
        flex-basis: 50%;
        min-width: 50%;
        align-self: baseline;
        .mat-mdc-input-element {
          letter-spacing: 0;
        }
      }
      > .two-element-row-big-element {
        flex-basis: 70%;
        min-width: 70%;
        align-self: baseline;
        .mat-mdc-input-element {
          letter-spacing: 0;
        }
      }
      > .two-element-row-small-element {
        flex-basis: 30%;
        min-width: 30%;
        align-self: baseline;
        .mat-mdc-input-element {
          letter-spacing: 0;
        }
      }
      > .three-element-row-element {
        flex-basis: 39%;
        min-width: 39%;
        align-self: baseline;
        .mat-mdc-input-element {
          letter-spacing: 0;
        }
      }
      > .four-element-row-small-element,
      > .four-element-row-small-element-edit {
        flex-basis: 19%;
        min-width: 19%;
        .mat-mdc-input-element {
          letter-spacing: 0;
        }
      }
      > .four-element-row-big-element,
      > .four-element-row-big-element-edit {
        flex-basis: 31%;
        min-width: 31%;
        .mat-mdc-input-element {
          letter-spacing: 0;
        }
      }
      > .item-row-element,
      > .item-row-element-edit {
        flex-basis: 22%;
        min-width: 22%;
        .mat-mdc-input-element {
          letter-spacing: 0;
        }
      }
      > .des-row-element,
      > .des-row-element-edit {
        flex-basis: 28%;
        min-width: 28%;
        .mat-mdc-input-element {
          letter-spacing: 0;
        }
      }
      > .five-element-row-small-element {
        flex-basis: 11%;
        min-width: 11%;
        .mat-mdc-input-element {
          letter-spacing: 0;
        }
      }
      > .five-element-row-element,
      > .five-element-row-element-edit {
        flex-basis: 17.5%;
        min-width: 17.5%;
        .mat-mdc-input-element {
          letter-spacing: 0;
        }
      }
      > .tax-base-row-element {
        flex-basis: 25%;
        min-width: 25%;
        .mat-mdc-input-element {
          letter-spacing: 0;
        }
      }
      > .tax-total-row-element {
        flex-basis: 25%;
        min-width: 25%;
        .mat-mdc-input-element {
          letter-spacing: 0;
        }
      }
      > .mat-icon-button {
        flex-basis: 4%;
        min-width: 4%;
        max-width: 4%;
        align-self: flex-start;
      }
      .margin-10 {
        margin: 10px;
      }
    }
  }
}

.add-budgetary {
  cursor: pointer;
  vertical-align: middle;
}

.delete-budgetary {
  cursor: pointer;
  margin: 5px;
  padding: 0.9em 0;
  // padding-bottom: 1.25em;
  border-top: 0.8437;

  // cursor: pointer;
  // margin: 10px;
  // padding: 0.5em 0;
  // padding-bottom: 1.25em;
  // border-top: 0.84375em solid transparent;
}

.buttons-filter {
  display: flex;
  align-self: flex-end;
}

.total-label {
  font: caption;
  text-align: right;
  align-self: center;
  flex-basis: 50% !important;
  min-width: 50% !important;
  margin-bottom: 0;
  padding-top: 20px;
  padding-right: 10px;
  margin-left: -10px;
  font-weight: bold;
  @media (max-width: 1024px) {
    flex-basis: 15% !important;
    min-width: 15% !important;
  }
}

@media (max-width: 1024px) {
  mat-card {
    > mat-card-content {
      > .two-element-row {
        > .two-element-row-element,
        > .two-element-row-big-element,
        > .two-element-row-small-element,
        > .four-element-row-small-element,
        > .four-element-row-small-element-edit,
        > .four-element-row-big-element,
        > .four-element-row-big-element-edit,
        > .item-row-element,
        > .item-row-element-edit,
        > .des-row-element,
        > .des-row-element-edit {
          flex-basis: 100%;
          min-width: 100%;
          max-width: 100%;
        }
        > .five-element-row-small-element {
          flex-basis: 30%;
          min-width: 30%;
          max-width: 100%;
        }
        > .five-element-row-element {
          flex-basis: 35%;
          min-width: 35%;
          max-width: 100%;
        }
        > .five-element-row-element-edit {
          flex-basis: 30%;
          min-width: 30%;
          max-width: 100%;
        }
        > .mat-icon-button {
          flex-basis: 10%;
          min-width: 10%;
          max-width: 100%;
        }
        > .tax-base-row-element {
          flex-basis: 42.5%;
          min-width: 42.5%;
          max-width: 100%;
        }
        > .tax-total-row-element {
          flex-basis: 42.5%;
          min-width: 42.5%;
          max-width: 100%;
        }
      }
    }
  }
}
