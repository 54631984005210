@use '../core/app/colors' as colors;

.fa-layers {
  width: 100%;
}

$block-reports: 'reports';

.#{$block-reports} {
  display: flex;
  flex-flow: column;
  .button-back {
    margin-top: 10px;
    margin-left: 10px;
    height: min-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .reports-content {
    margin-left: 20px;
    // margin:auto;
    // width: 100%;
    // max-width: 1000px;
    width: 50%;
    margin-bottom: 50px;
  }

  .ic-datepicker {
    margin-bottom: 0;
  }

  .mat-mdc-form-field-type-mat-date-range-input .mat-mdc-form-field-infix {
    width: 280px;
    label.mdc-floating-label {
      font-size: 14px;
      letter-spacing: none;
    }
  }

  mat-card {
    margin-bottom: 20px;
    // max-width: 1000px;
    margin: auto;
    margin-top: 20px;

    box-shadow: 0px 2px 2px -1px colors.$shadow-color,
      -1px -1px 1px 0px colors.$shadow-color,
      0px 1px 3px 0px colors.$shadow-color;

    mat-card-title {
      margin: 0 0 20px 0;
    }

    mat-card-header {
      justify-content: space-between;
    }
    mat-card-header-text {
      flex-basis: 80%;
      align-self: center;
    }
    mat-card-subtitle {
      margin-left: 0;
      display: flex;
      align-items: center;
      > span {
        cursor: pointer;
      }
    }
  }

  .filter-panel-row {
    display: flex;
    flex-flow: row;
    margin: 9px;
    align-items: center;
    &-first-column {
      flex-basis: 50%;
      padding: 5px;
      margin-right: 5px;
    }
    &-second-column {
      flex-basis: 50%;
      padding: 5px;
      margin-left: 5px;
    }
  }

  .button-enable {
    color: colors.$bg-color-primary;
  }

  .blue-text {
    color: colors.$bg-color-primary;
    font-size: 17px;
  }

  .downloads-files {
    display: flex;
  }
}
