@use '../../../core/app/colors' as colors;
@use '../../../core/fonts/fonts';

.mat-mdc-button-base {
  font-family: 'OpenSansLight', sans-serif;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.4;
  }

  .mat-icon {
    &.mat-primary {
      color: colors.$color-primary;
      &:hover {
        color: colors.$color-primary-hover;
      }
      &[disabled] {
        color: colors.$color-primary-disabled;
      }
    }
    &.mat-accent {
      // color: colors.$color-accent;
      &:hover {
        // color: colors.$color-accent-hover;
      }
      &[disabled] {
        // color: colors.$color-accent-disabled;
      }
    }
  }
}
