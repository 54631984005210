@use 'variables';
@use 'colors';
@use 'mixings';
@forward 'breakpoints';

h1 {
  color: colors.$color-grey-800;
  font-family: OpenSansLight;
  font-size: variables.$font-size-42;
  line-height: 52px;
  margin: 0;
}
h2 {
  color: colors.$color-grey-800;
  font-family: OpenSansLight;
  font-size: variables.$font-size-36;
  line-height: 44px;
  margin: 0;
}
h3 {
  color: colors.$color-grey-800;
  font-family: OpenSansRegular;
  font-size: variables.$font-size-28;
  line-height: 36px;
  margin: 0;
}
h4 {
  color: colors.$color-grey-800;
  font-family: OpenSansBold;
  font-size: variables.$font-size-24;
  line-height: 30px;
  margin: 0;
}
h5 {
  color: colors.$color-grey-800;
  font-family: OpenSansBold;
  font-size: variables.$font-size-18;
  line-height: 24px;
  margin: 0;
  text-transform: uppercase;
}

.card {
  background-color: white;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: hidden;
  &-outlined {
    border: 2px solid #ddd;
  }
}

$block: 'ic';

.#{$block} {
  //Textos
  &-title-large {
    color: colors.$color-grey-800;
    font-family: OpenSansBold;
    font-size: variables.$font-size-20;
    line-height: 26px;
    margin: 0;
  }
  &-title-medium {
    color: colors.$color-grey-800;
    font-family: OpenSansBold;
    font-size: variables.$font-size-18;
    line-height: 22px;
    margin: 0;
  }
  &-title-small {
    color: colors.$color-grey-800;
    font-family: OpenSansBold;
    font-size: variables.$font-size-16;
    line-height: 20px;
    margin: 0;
  }
  &-row-title {
    color: colors.$color-grey-700;
    font-family: OpenSansSemiBold;
    font-size: variables.$font-size-18;
    line-height: 20px;
    margin: 0;
    text-transform: uppercase;
  }
  &-section-title {
    color: colors.$color-grey-800;
    font-family: OpenSansBold;
    font-size: variables.$font-size-12;
    line-height: 15px;
    margin: 0;
    text-transform: uppercase;
  }
  &-section-title-grey {
    color: colors.$color-grey-600;
    font-family: OpenSansRegular;
    font-size: variables.$font-size-14;
    line-height: 17px;
    margin: 0;
    text-transform: uppercase;
  }
  &-panel-section-title {
    color: colors.$color-grey-800;
    font-family: OpenSansSemiBold;
    font-size: variables.$font-size-16;
    line-height: 22px;
    margin: 0;
    text-transform: uppercase;
  }
  &-basic-text {
    color: colors.$color-grey-600;
    font-family: OpenSansRegular;
    font-size: variables.$font-size-14;
    line-height: 18px;
    margin: 0;
  }

  &-uppercase {
    text-transform: uppercase;
  }

  &-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-truncate1 {
    display: -webkit-box;
    max-width: max-content;
    overflow: hidden;
    word-break: break-word;
    -webkit-line-clamp: 1;
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
  }

  &-truncate2 {
    display: -webkit-box;
    max-width: max-content;
    overflow: hidden;
    word-break: break-word;
    -webkit-line-clamp: 2;
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
  }

  &-vertical-align {
    left: 50%;
    margin-right: -50% !important;
    padding: 0 !important;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
  }

  &-centered {
    @include mixings.centerer;
  }

  &-lineclamp-2 {
    @include mixings.ellipsis-multiline(2);
  }

  &-img-cover {
    @include mixings.img-cover();
  }

  //Display
  &-flex-center {
    align-items: center !important;
    display: flex !important;
    justify-content: center !important;
  }

  //Tamaños fuente
  &-text-10 {
    font-size: variables.$font-size-10 !important;
  }
  &-text-12 {
    font-size: variables.$font-size-12 !important;
  }
  &-text-14 {
    font-size: variables.$font-size-14 !important;
  }
  &-text-16 {
    font-size: variables.$font-size-16 !important;
  }
  &-text-18 {
    font-size: variables.$font-size-18 !important;
  }
  &-text-20 {
    font-size: variables.$font-size-20 !important;
  }
  &-text-22 {
    font-size: variables.$font-size-22 !important;
  }
  &-text-24 {
    font-size: variables.$font-size-24 !important;
  }
  &-text-26 {
    font-size: variables.$font-size-26 !important;
  }
  &-text-28 {
    font-size: variables.$font-size-28 !important;
  }
  &-text-32 {
    font-size: variables.$font-size-32 !important;
  }
  &-text-36 {
    font-size: variables.$font-size-36 !important;
  }
  &-text-42 {
    font-size: variables.$font-size-42 !important;
  }
  &-text-48 {
    font-size: variables.$font-size-48 !important;
  }
  &-text-56 {
    font-size: variables.$font-size-56 !important;
  }

  //Colores
  &-text-black {
    color: colors.$color-black !important;
  }
  &-text-grey-900 {
    color: colors.$color-grey-900 !important;
  }
  &-text-grey-800 {
    color: colors.$color-grey-800 !important;
  }
  &-text-grey-700 {
    color: colors.$color-grey-700 !important;
  }
  &-text-grey-600 {
    color: colors.$color-grey-600 !important;
  }
  &-text-grey-500 {
    color: colors.$color-grey-500 !important;
  }
  &-text-grey-400 {
    color: colors.$color-grey-400 !important;
  }
  &-text-grey-300 {
    color: colors.$color-grey-300 !important;
  }
  &-text-grey-200 {
    color: colors.$color-grey-200 !important;
  }
  &-text-grey-100 {
    color: colors.$color-grey-100 !important;
  }
  &-text-white {
    color: colors.$color-white !important;
  }
  &-text-orange {
    color: colors.$color-orange !important;
  }
  &-text-warning {
    color: colors.$color-warning !important;
  }
  &-text-success {
    color: colors.$color-success !important;
  }
  &-text-error {
    color: colors.$color-error !important;
  }
  &-text-out {
    color: colors.$color-out !important;
  }
  &-text-stopped {
    color: colors.$color-stopped !important;
  }
  &-text-primary {
    color: colors.$color-primary !important;
  }

  //Colores de fondo - Escala de grises
  &-bc-black {
    background-color: colors.$color-black !important;
  }
  &-bc-grey-900 {
    background-color: colors.$color-grey-900 !important;
  }
  &-bc-grey-800 {
    background-color: colors.$color-grey-800 !important;
  }
  &-bc-grey-700 {
    background-color: colors.$color-grey-700 !important;
  }
  &-bc-grey-600 {
    background-color: colors.$color-grey-600 !important;
  }
  &-bc-grey-500 {
    background-color: colors.$color-grey-500 !important;
  }
  &-bc-grey-400 {
    background-color: colors.$color-grey-400 !important;
  }
  &-bc-grey-300 {
    background-color: colors.$color-grey-300 !important;
  }
  &-bc-grey-200 {
    background-color: colors.$color-grey-200 !important;
  }
  &-bc-grey-100 {
    background-color: colors.$color-grey-100 !important;
  }
  &-bc-white {
    background-color: colors.$color-white !important;
  }

  //Colores de fondo - Colores random
  &-bc-random1 {
    background-color: colors.$color-random1 !important;
  }
  &-bc-random2 {
    background-color: colors.$color-random2 !important;
  }
  &-bc-random3 {
    background-color: colors.$color-random3 !important;
  }
  &-bc-random4 {
    background-color: colors.$color-random4 !important;
  }
  &-bc-random5 {
    background-color: colors.$color-random5 !important;
  }
  &-bc-random6 {
    background-color: colors.$color-random6 !important;
  }
  &-bc-random7 {
    background-color: colors.$color-random7 !important;
  }
  &-bc-random8 {
    background-color: colors.$color-random8 !important;
  }
  &-bc-random9 {
    background-color: colors.$color-random9 !important;
  }
  &-bc-random10 {
    background-color: colors.$color-random10 !important;
  }
  &-bc-random11 {
    background-color: colors.$color-random11 !important;
  }

  //Colores de fondo - Colores acción
  &-bc-primary {
    background-color: colors.$color-primary !important;
  }
  &-bc-primary-hover {
    background-color: colors.$color-primary-hover !important;
  }
  &-bc-accent {
    background-color: colors.$color-accent !important;
  }
  &-bc-accent-hover {
    background-color: colors.$color-accent-hover !important;
  }

  //Colores de fondo - Colores estados
  &-bc-success {
    background-color: colors.$color-success !important;
  }
  &-bc-warning {
    background-color: colors.$color-warning !important;
  }
  &-bc-error {
    background-color: colors.$color-error !important;
  }
  &-bc-out {
    background-color: colors.$color-out !important;
  }
  &-bc-stopped {
    background-color: colors.$color-stopped !important;
  }

  //Colores de fondo - Colores labels
  &-bc-orange {
    background-color: colors.$color-orange !important;
  }

  //Márgenes
  &-m-0 {
    margin: variables.$separation-0 !important;
  }
  &-m-4 {
    margin: variables.$separation-4;
  }
  &-m-8 {
    margin: variables.$separation-8;
  }
  &-m-12 {
    margin: variables.$separation-12;
  }
  &-m-16 {
    margin: variables.$separation-16;
  }
  &-m-20 {
    margin: variables.$separation-20;
  }
  &-m-24 {
    margin: variables.$separation-24;
  }
  &-m-28 {
    margin: variables.$separation-28;
  }
  &-m-32 {
    margin: variables.$separation-32;
  }
  &-m-36 {
    margin: variables.$separation-36;
  }
  &-m-40 {
    margin: variables.$separation-40;
  }
  &-m-44 {
    margin: variables.$separation-44;
  }
  &-m-48 {
    margin: variables.$separation-48;
  }
  &-m-60 {
    margin: variables.$separation-60;
  }
  &-m-80 {
    margin: variables.$separation-80;
  }
  &-m-100 {
    margin: variables.$separation-100;
  }
  &-mt-0 {
    margin-top: variables.$separation-0 !important;
  }
  &-mt-4 {
    margin-top: variables.$separation-4;
  }
  &-mt-8 {
    margin-top: variables.$separation-8;
  }
  &-mt-12 {
    margin-top: variables.$separation-12;
  }
  &-mt-16 {
    margin-top: variables.$separation-16;
  }
  &-mt-20 {
    margin-top: variables.$separation-20;
  }
  &-mt-24 {
    margin-top: variables.$separation-24;
  }
  &-mt-28 {
    margin-top: variables.$separation-28;
  }
  &-mt-32 {
    margin-top: variables.$separation-32;
  }
  &-mt-36 {
    margin-top: variables.$separation-36;
  }
  &-mt-40 {
    margin-top: variables.$separation-40;
  }
  &-mt-44 {
    margin-top: variables.$separation-44;
  }
  &-mt-48 {
    margin-top: variables.$separation-48;
  }
  &-mt-60 {
    margin-top: variables.$separation-60;
  }
  &-mt-80 {
    margin-top: variables.$separation-80;
  }
  &-mt-100 {
    margin-top: variables.$separation-100;
  }
  &-mr-auto {
    margin-right: auto !important;
  }
  &-mr-0 {
    margin-right: variables.$separation-0 !important;
  }
  &-mr-4 {
    margin-right: variables.$separation-4;
  }
  &-mr-8 {
    margin-right: variables.$separation-8;
  }
  &-mr-12 {
    margin-right: variables.$separation-12;
  }
  &-mr-16 {
    margin-right: variables.$separation-16;
  }
  &-mr-20 {
    margin-right: variables.$separation-20;
  }
  &-mr-24 {
    margin-right: variables.$separation-24;
  }
  &-mr-28 {
    margin-right: variables.$separation-28;
  }
  &-mr-32 {
    margin-right: variables.$separation-32;
  }
  &-mr-36 {
    margin-right: variables.$separation-36;
  }
  &-mr-40 {
    margin-right: variables.$separation-40;
  }
  &-mr-44 {
    margin-right: variables.$separation-44;
  }
  &-mr-48 {
    margin-right: variables.$separation-48;
  }
  &-mr-60 {
    margin-right: variables.$separation-60;
  }
  &-mr-80 {
    margin-right: variables.$separation-80;
  }
  &-mr-100 {
    margin-right: variables.$separation-100;
  }
  &-mb-0 {
    margin-bottom: variables.$separation-0 !important;
  }
  &-mb-4 {
    margin-bottom: variables.$separation-4;
  }
  &-mb-8 {
    margin-bottom: variables.$separation-8;
  }
  &-mb-12 {
    margin-bottom: variables.$separation-12;
  }
  &-mb-16 {
    margin-bottom: variables.$separation-16;
  }
  &-mb-20 {
    margin-bottom: variables.$separation-20;
  }
  &-mb-24 {
    margin-bottom: variables.$separation-24;
  }
  &-mb-28 {
    margin-bottom: variables.$separation-28;
  }
  &-mb-32 {
    margin-bottom: variables.$separation-32;
  }
  &-mb-36 {
    margin-bottom: variables.$separation-36;
  }
  &-mb-40 {
    margin-bottom: variables.$separation-40;
  }
  &-mb-44 {
    margin-bottom: variables.$separation-44;
  }
  &-mb-48 {
    margin-bottom: variables.$separation-48;
  }
  &-mb-60 {
    margin-bottom: variables.$separation-60;
  }
  &-mb-80 {
    margin-bottom: variables.$separation-80;
  }
  &-mb-100 {
    margin-bottom: variables.$separation-100;
  }
  &-ml-0 {
    margin-left: variables.$separation-0 !important;
  }
  &-ml-auto {
    margin-left: auto !important;
  }
  &-ml-4 {
    margin-left: variables.$separation-4;
  }
  &-ml-8 {
    margin-left: variables.$separation-8;
  }
  &-ml-12 {
    margin-left: variables.$separation-12;
  }
  &-ml-16 {
    margin-left: variables.$separation-16;
  }
  &-ml-20 {
    margin-left: variables.$separation-20;
  }
  &-ml-24 {
    margin-left: variables.$separation-24;
  }
  &-ml-28 {
    margin-left: variables.$separation-28;
  }
  &-ml-32 {
    margin-left: variables.$separation-32;
  }
  &-ml-36 {
    margin-left: variables.$separation-36;
  }
  &-ml-40 {
    margin-left: variables.$separation-40;
  }
  &-ml-44 {
    margin-left: variables.$separation-44;
  }
  &-ml-48 {
    margin-left: variables.$separation-48;
  }
  &-ml-60 {
    margin-left: variables.$separation-60;
  }
  &-ml-80 {
    margin-left: variables.$separation-80;
  }
  &-ml-100 {
    margin-left: variables.$separation-100;
  }

  //Paddings
  &-p-0 {
    padding: variables.$separation-0 !important;
  }
  &-p-4 {
    padding: variables.$separation-4;
  }
  &-p-8 {
    padding: variables.$separation-8;
  }
  &-p-12 {
    padding: variables.$separation-12;
  }
  &-p-16 {
    padding: variables.$separation-16;
  }
  &-p-20 {
    padding: variables.$separation-20;
  }
  &-p-24 {
    padding: variables.$separation-24;
  }
  &-p-28 {
    padding: variables.$separation-28;
  }
  &-p-32 {
    padding: variables.$separation-32;
  }
  &-p-36 {
    padding: variables.$separation-36;
  }
  &-p-40 {
    padding: variables.$separation-40;
  }
  &-p-44 {
    padding: variables.$separation-44;
  }
  &-p-48 {
    padding: variables.$separation-48;
  }
  &-p-60 {
    padding: variables.$separation-60;
  }
  &-p-80 {
    padding: variables.$separation-80;
  }
  &-p-100 {
    padding: variables.$separation-100;
  }
  &-pt-0 {
    padding-top: variables.$separation-0 !important;
  }
  &-pt-4 {
    padding-top: variables.$separation-4;
  }
  &-pt-8 {
    padding-top: variables.$separation-8;
  }
  &-pt-12 {
    padding-top: variables.$separation-12;
  }
  &-pt-16 {
    padding-top: variables.$separation-16;
  }
  &-pt-20 {
    padding-top: variables.$separation-20;
  }
  &-pt-24 {
    padding-top: variables.$separation-24;
  }
  &-pt-28 {
    padding-top: variables.$separation-28;
  }
  &-pt-32 {
    padding-top: variables.$separation-32;
  }
  &-pt-36 {
    padding-top: variables.$separation-36;
  }
  &-pt-40 {
    padding-top: variables.$separation-40;
  }
  &-pt-44 {
    padding-top: variables.$separation-44;
  }
  &-pt-48 {
    padding-top: variables.$separation-48;
  }
  &-pt-60 {
    padding-top: variables.$separation-60;
  }
  &-pt-80 {
    padding-top: variables.$separation-80;
  }
  &-pt-100 {
    padding-top: variables.$separation-100;
  }
  &-pr-0 {
    padding-right: variables.$separation-0 !important;
  }
  &-pr-4 {
    padding-right: variables.$separation-4;
  }
  &-pr-8 {
    padding-right: variables.$separation-8;
  }
  &-pr-12 {
    padding-right: variables.$separation-12;
  }
  &-pr-16 {
    padding-right: variables.$separation-16;
  }
  &-pr-20 {
    padding-right: variables.$separation-20;
  }
  &-pr-24 {
    padding-right: variables.$separation-24;
  }
  &-pr-28 {
    padding-right: variables.$separation-28;
  }
  &-pr-32 {
    padding-right: variables.$separation-32;
  }
  &-pr-36 {
    padding-right: variables.$separation-36;
  }
  &-pr-40 {
    padding-right: variables.$separation-40;
  }
  &-pr-44 {
    padding-right: variables.$separation-44;
  }
  &-pr-48 {
    padding-right: variables.$separation-48;
  }
  &-pr-60 {
    padding-right: variables.$separation-60;
  }
  &-pr-80 {
    padding-right: variables.$separation-80;
  }
  &-pr-100 {
    padding-right: variables.$separation-100;
  }
  &-pb-0 {
    padding-bottom: variables.$separation-0 !important;
  }
  &-pb-4 {
    padding-bottom: variables.$separation-4;
  }
  &-pb-8 {
    padding-bottom: variables.$separation-8;
  }
  &-pb-12 {
    padding-bottom: variables.$separation-12;
  }
  &-pb-16 {
    padding-bottom: variables.$separation-16;
  }
  &-pb-20 {
    padding-bottom: variables.$separation-20;
  }
  &-pb-24 {
    padding-bottom: variables.$separation-24;
  }
  &-pb-28 {
    padding-bottom: variables.$separation-28;
  }
  &-pb-32 {
    padding-bottom: variables.$separation-32;
  }
  &-pb-36 {
    padding-bottom: variables.$separation-36;
  }
  &-pb-40 {
    padding-bottom: variables.$separation-40;
  }
  &-pb-44 {
    padding-bottom: variables.$separation-44;
  }
  &-pb-48 {
    padding-bottom: variables.$separation-48;
  }
  &-pb-60 {
    padding-bottom: variables.$separation-60;
  }
  &-pb-80 {
    padding-bottom: variables.$separation-80;
  }
  &-pb-100 {
    padding-bottom: variables.$separation-100;
  }
  &-pl-0 {
    padding-left: variables.$separation-0 !important;
  }
  &-pl-4 {
    padding-left: variables.$separation-4;
  }
  &-pl-8 {
    padding-left: variables.$separation-8;
  }
  &-pl-12 {
    padding-left: variables.$separation-12;
  }
  &-pl-16 {
    padding-left: variables.$separation-16;
  }
  &-pl-20 {
    padding-left: variables.$separation-20;
  }
  &-pl-24 {
    padding-left: variables.$separation-24;
  }
  &-pl-28 {
    padding-left: variables.$separation-28;
  }
  &-pl-32 {
    padding-left: variables.$separation-32;
  }
  &-pl-36 {
    padding-left: variables.$separation-36;
  }
  &-pl-40 {
    padding-left: variables.$separation-40;
  }
  &-pl-44 {
    padding-left: variables.$separation-44;
  }
  &-pl-48 {
    padding-left: variables.$separation-48;
  }
  &-pl-60 {
    padding-left: variables.$separation-60;
  }
  &-pl-80 {
    padding-left: variables.$separation-80;
  }
  &-pl-100 {
    padding-left: variables.$separation-100;
  }
}
