@use '../core/app/colors' as colors;

.filter-panel {
  display: flex;
  flex-flow: column;
}

.row-one-element {
  width: 50%;
}

.filter-panel-row {
  display: flex;
  flex-flow: row;
  margin: 9px;
  align-items: center;
  &-first-column {
    flex-basis: 50%;
    padding: 5px;
    margin-right: 5px;
  }
  &-second-column {
    flex-basis: 50%;
    padding: 5px;
    margin-left: 5px;
  }
}

.multiple-selection {
  overflow: auto;
}

.buttons {
  flex: 1 0 6%;
  margin: 0 6px;
  flex-wrap: nowrap;
  display: flex;
  min-width: 155px;
}

.button-search {
  min-width: 40px;
  margin-right: 6px;
  text-align: center;
}

.button-disable {
  color: colors.$color-grey-600;
}

.button-create-new {
  flex-grow: 1;
  text-align: end;
}

.button-enable {
  color: colors.$bg-color-primary;
}
