@use '../core/app/variables' as variables;
@use '../core/app/colors' as colors;

mat-form-field {
  &[class*='ic-input-'] {
    // min-width: 230px;
    margin: 0 auto;
    max-width: 100%;
    margin-bottom: 1em;
    &.full-width {
      &.mat-mdc-form-field {
        margin: 5px;
        line-height: 2.2;
        display: block;
        .mat-mdc-text-field-wrapper,
        .mat-mdc-form-field-wrapper {
          padding: 0 10px;
          .mat-mdc-form-field-flex {
            padding-top: 0;
            .mat-mdc-form-field-infix {
              width: auto;
              .mat-mdc-input-element {
                font-size: variables.$font-size-floating-label-above;
                line-height: 24px;
              }
              .mat-mdc-floating-label {
                mat-label {
                  font-size: variables.$font-size-floating-label;
                }
              }
              .mat-mdc-floating-label.mdc-floating-label--float-above {
                mat-label {
                  font-size: variables.$font-size-floating-label-above;
                }
              }
            }
          }
        }
        &.mat-form-field-appearance-legacy,
        &.mat-form-field-appearance-fill {
          margin: 5px;
          line-height: 2.2;
          display: block;
          .mat-mdc-text-field-wrapper,
          .mat-mdc-form-field-wrapper {
            padding: 0 10px;
            .mat-mdc-form-field-flex {
              .mat-mdc-form-field-infix {
                width: auto;
                .mat-mdc-input-element {
                  font-size: variables.$font-size-16;
                  line-height: 24px;
                }
                .mat-mdc-floating-label {
                  mat-label {
                    font-size: variables.$font-size-floating-label;
                  }
                }
                .mat-mdc-floating-label.mdc-floating-label--float-above {
                  mat-label {
                    font-size: variables.$font-size-floating-label-above;
                  }
                }
              }
            }
          }
        }
      }
      &.mat-form-field-disabled {
        .mat-mdc-text-field-wrapper,
        .mat-mdc-form-field-wrapper {
          .mat-mdc-form-field-flex {
            .mat-mdc-form-field-infix {
              .mat-mdc-input-element {
                color: #777;
              }
            }
          }
        }
      }
    }
    &.mat-mdc-form-field {
      margin: 5px;
      line-height: 2.2;
      display: block;
      .mat-mdc-text-field-wrapper,
      .mat-mdc-form-field-wrapper {
        padding: 0 10px;
        .mat-mdc-form-field-flex {
          padding-top: 0;
          .mat-mdc-form-field-infix {
            width: auto;
            .mat-mdc-input-element {
              font-size: variables.$font-size-floating-label-above;
              line-height: 24px;
            }
            .mat-mdc-floating-label {
              mat-label {
                font-size: variables.$font-size-floating-label;
              }
            }
            .mat-mdc-floating-label.mdc-floating-label--float-above {
              mat-label {
                font-size: variables.$font-size-floating-label-above;
              }
            }
          }
        }
      }
      &.mat-form-field-appearance-legacy,
      &.mat-form-field-appearance-fill {
        margin: 5px;
        line-height: 2;
        .mat-mdc-text-field-wrapper,
        .mat-mdc-form-field-wrapper {
          padding: 0 10px;
          .mat-mdc-form-field-flex {
            .mat-mdc-form-field-infix {
              width: auto;
              .mat-mdc-input-element {
                font-size: variables.$font-size-floating-label-above;
                line-height: 24px;
              }
              .mat-mdc-floating-label {
                mat-label {
                  font-size: variables.$font-size-floating-label;
                }
              }
              .mat-mdc-floating-label.mdc-floating-label--float-above {
                mat-label {
                  font-size: variables.$font-size-floating-label-above;
                }
              }
            }
          }
        }
      }
    }
    .mdc-line-ripple,
    .mat-mdc-form-field-underline {
      background-color: colors.$color-grey-400;
    }
  }
}
