// TODO: Revisar scss material
@use '../../core/app/colors' as colors;
@use '../../core/app/variables' as variables;

$block-ppc-return-panel: 'returnpanel';

ic-ppc-return-administrative-file {
  .#{$block-ppc-return-panel} {
    .#{$block-ppc-return-panel}-toolbar {
      background-color: colors.$color-white;
      color: colors.$color-grey-700;
      font-family: 'OpenSansRegular';
      font-size: variables.$font-size-16;
      line-height: 1.19;
      height: variables.$toolbarHeight;
      padding: 0 12px 0 4px;
      white-space: normal;

      .hack-autofocus-disable {
        position: absolute;
        height: 0;
      }

      .panel-close {
        height: 48px;
        margin-right: variables.$separation-16;
        width: 48px;

        &:hover {
          background-color: variables.$icon-button-hover;
        }
      }

      .panel-spacer {
        flex: 1 1 auto;
      }

      .panel-buttons {
        width: max-content;
        min-width: max-content;
      }
    }

    .#{$block-ppc-return-panel}-info {
      min-height: 100%;
      max-width: 90%;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      background-color: colors.$color-white;

      > mat-card {
        > mat-card-content {
          > .two-element-row {
            > ic-input-select {
              > mat-form-field {
                width: -webkit-fill-available;
              }
            }
            // > .two-element-row-element {
            //   flex-basis: 49%;
            // }
            .margin-10 {
              margin: 10px;
            }
          }

          .text-correction {
            color: colors.$color-grey-700;
            background: #ededed;
            border: 1px solid #ededed;
            border-radius: 12px;
            padding: 10px;
          }

          .return-content-header {
            display: flex;
            flex-flow: column;
            margin-bottom: 15px;
            &-senders {
              display: flex;
              flex-flow: row;
              width: 100%;
              justify-content: space-between;
              flex-wrap: wrap;
            }
          }
          .return-content-solicitant {
            flex-basis: 45%;
            background: #ededed;
            padding: 10px;
            border: 1px solid #ededed;
            border-radius: 16px;
            display: flex;
            margin-bottom: 15px;
            @media (max-width: 900px) {
              flex-basis: 100%;
            }
            .first-line,
            .second-line,
            .third-line,
            .fourth-line {
              margin-bottom: 5px;
            }
            &-icon {
              margin-right: 10px;
              .material-icons {
                font-size: 55px;
                color: black;
              }
            }
          }

          .return-content-gestora {
            flex-basis: 45%;
            background: #ededed;
            padding: 10px;
            border: 1px solid #ededed;
            border-radius: 16px;
            margin-bottom: 15px;
            display: flex;
            @media (max-width: 900px) {
              flex-basis: 100%;
            }
            .first-line,
            .second-line,
            .third-line,
            .fourth-line {
              margin-bottom: 5px;
            }
            &-icon {
              margin-right: 10px;
              .material-icons {
                font-size: 55px;
                color: black;
              }
            }
          }

          .fourth-line > .right-text {
            // display: inline-block;
            float: right;
          }

          .fourth-line > .left-text {
            display: inline-block;
          }

          .return-content-title {
            background: #f1dada;
            padding: 10px;
            border: 1px solid #f1dada;
            border-radius: 12px;
            text-align: center;
            font-weight: bold;
          }

          .return-content-subject {
            background: #ededed;
            padding: 10px;
            border: 1px solid #ededed;
            border-radius: 12px;
            margin-top: 15px;
          }

          .textarea-container-form-textarea {
            height: 250px;
          }
        }
      }
      .panel-buttons {
        display: flex;
        flex-flow: row;
        justify-content: flex-end;
        width: 100%;
        margin-bottom: 30px;
      }
    }

    .mat-error-email {
      font-size: 12px;
      margin-bottom: 10px;
    }

    mat-chip-grid {
      .mat-chip,
      .mat-mdc-chip {
        width: auto;
        background-color: colors.$color-grey-200;
        margin-bottom: 15px;
        display: flex;
        height: auto;
        align-self: center;
        .mdc-evolution-chip__text-label {
          width: auto;
          align-items: center;
          // margin-bottom: 7.5px;
          // margin-top: 7.5px;
          display: flex;
          height: auto;

          > .icon-default {
            margin-right: 5px;
          }
          > .text {
            display: flex;
            flex-flow: column;
            padding: 0.25em 0;
            > .email {
              font-size: 14px;
              font-weight: bold;
            }
            > .name {
              font-size: 11px;
              font-weight: bold;
            }
          }
        }
      }

      .mat-chip-list-wrapper,
      .mdc-evolution-chip-set__chips {
        align-items: flex-start;
        flex-direction: row;
        flex-flow: row;
        flex-wrap: wrap;
      }

      .mat-form-field,
      .mat-mdc-form-field {
        margin-left: 1em;
        width: 235px;
      }

      .mat-chip-input,
      .mat-mdc-chip-input {
        margin-left: 6px;
        width: 220px;
        font-size: 12px;
      }
    }

    .panel-alert-empty-contacts {
      color: #977070;
      padding-left: 10px;
    }

    .panel-alert-empty-div {
      background: #ff000029;
      height: 40px;
      margin: auto;
      display: flex;
      margin-top: 20px;
      align-items: center;
      border: 1px solid #ff000033;
      border-radius: 7px;
    }
  }
}
