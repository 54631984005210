@use '../../core/app/colors' as colors;
@use '../../core/fonts/fonts';

$block-dt: 'ic-detail';

mat-spinner {
  margin: 20px auto;
}

.empty-row {
  background: colors.$color-white;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  margin-bottom: 20px;
  height: 30px;
  text-align: center;
  padding-top: 15px;
}

.#{$block-dt} {
  &-table {
    overflow: auto;
    max-height: calc(100vh - 234px);
    .mat-header-row,
    .mat-mdc-header-row {
      background-color: colors.$color-table-header-background;
      border-bottom-width: 0;
      text-transform: capitalize;

      .mat-sort-header-button {
        text-transform: uppercase;
      }
    }

    .mat-row:nth-child(odd),
    .mat-mdc-row:nth-child(odd) {
      background-color: colors.$color-bg-row-odd;
    }
    .mat-row,
    .mat-mdc-row {
      font-family: 'OpenSansLight', sans-serif;

      .text-bold {
        font-weight: bold;
        font-family: 'OpenSansRegular', sans-serif;
      }

      &.highlight {
        background-color: colors.$color-grey-100;
        font-family: 'OpenSansRegular', sans-serif;
      }
    }

    .mat-row:hover,
    .mat-mdc-row:hover {
      background-color: colors.$color-grey-100;
      font-family: 'OpenSansRegular', sans-serif;
      cursor: pointer;
    }

    .observations-table {
      //TODO: esto estaba si anteriormente, se cambió porque no centraba los textos
      //align-items: start;
      align-items: center;
    }

    .observations-table > span {
      font-size: 12px;
    }

    .observations-table-code > span {
      font-size: 11px;
    }

    .mat-header-cell,
    .mat-mdc-header-cell,
    .mat-cell,
    .mat-mdc-cell {
      margin: 0 6px;
      color: colors.$color-grey-700;
      padding-top: 5px;
      padding-bottom: 5px;
      .mat-success {
        color: colors.$color-success;
      }
      .mat-warning {
        color: colors.$color-warning;
      }
      .mat-error {
        color: colors.$color-error;
      }
      .mat-out {
        color: colors.$color-out;
      }
      .mat-stopped {
        color: colors.$color-stopped;
      }
      .mat-read {
        color: colors.$color-read;
      }
      .mat-doc {
        color: colors.$color-doc;
      }
      .mat-calc {
        color: colors.$color-calc;
      }
      .mat-image {
        color: colors.$color-image;
      }
      .mat-cross {
        color: colors.$color-cross;
      }
      .mat-general {
        color: colors.$color-general;
      }
      .mat-specific {
        color: colors.$color-specific;
      }
      .mat-no-model {
        color: colors.$color-no-model;
      }
      .mat-pending {
        color: colors.$color-pending;
      }
      .success {
        color: colors.$color-success;
      }
      .warning {
        color: colors.$color-warning;
      }
      .error {
        color: colors.$color-error;
      }
      .out {
        color: colors.$color-out;
      }
      .no-model {
        color: colors.$color-no-model;
      }
    }

    // TODO: Revisar este estilo con los cambios de Material
    .mat-checkbox,
    .mat-mdc-checkbox {
      .mat-checkbox-inner-container,
      .mdc-checkbox {
        .mat-checkbox-frame,
        .mdc-checkbox__background {
          border-color: colors.$color-grey-600;
          border-width: 1px;
        }
      }
    }

    mat-row.non-detail-row {
      display: none;
    }

    mat-row.note-expanded.note-color {
      background-color: colors.$color-bg-card-blue;
      padding-left: 20%;
      .mat-cell {
        font-size: 12px;
        .element-detail {
          display: flex;
          align-items: center;
        }
      }
    }

    mat-cell {
      label {
        cursor: pointer;
      }
    }
  }
  &-cell-3 {
    flex: 0 0 3%;
    justify-content: flex-start;
  }
  &-cell-5 {
    flex: 0 0 5%;
    justify-content: flex-start;
  }
  &-cell-7 {
    flex: 0 0 7%;
    justify-content: flex-start;
  }
  &-cell-10 {
    flex: 0 0 10%;
    justify-content: flex-start;
  }
  &-cell-15 {
    flex: 0 0 15%;
    justify-content: flex-start;
  }
  &-cell-17 {
    flex: 0 0 17%;
    justify-content: flex-start;
  }
  &-cell-20 {
    flex: 0 0 20%;
    justify-content: flex-start;
  }
  &-cell-25 {
    flex: 0 0 25%;
    justify-content: flex-start;
  }
  &-cell-30 {
    flex: 0 0 30%;
    justify-content: flex-start;
  }
  &-cell-40 {
    flex: 0 0 40%;
    justify-content: flex-start;
  }
  &-right {
    justify-content: flex-end;
  }
  &-center {
    justify-content: center;
  }
  &-baseline-align {
    align-items: baseline;
  }
  &-row-min-width-1500 {
    min-width: 1500px;
  }

  &-ellipsis {
    display: flex;
    justify-content: left;
    > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
