@use '../../core/app/colors' as colors;
@use '../../core/app/variables' as variables;

$block-prior-inspection-category-afts-manage-in-model: 'prior-inspection-category-afts-manage-in-model';
ic-category-afts-manage-in-model {
  .#{$block-prior-inspection-category-afts-manage-in-model} {
    .ic-selectable-table mat-header-row {
      justify-content: space-between;
      &.ic-selectable-row {
        height: 40px !important;
        top: 66px !important;
        .mat-mdc-header-cell:first-of-type {
          padding: 0 0.4em;
        }
      }
      .mat-mdc-header-cell:first-of-type {
        padding: 0 0 0 1.2em;
        label {
          height: 24px;
        }
      }

      .mat-mdc-header-cell {
        min-width: min-content;
      }

      .mat-mdc-header-cell:nth-of-type(2) {
        padding: 0 0.5em 0 2em;
      }

      .mat-mdc-header-cell:nth-of-type(3) {
        min-width: 93px;
      }

      .mat-mdc-header-cell:last-of-type {
        // padding: 0 2em 0 2em;
        min-width: min-content;
      }
    }

    .ic-selectable-table mat-row {
      justify-content: space-between;
      // &.ic-selectable-row {
      //   height: 40px !important;
      //   top: 66px !important;
      // }
      .mat-mdc-cell:first-of-type {
        padding: 0 0.4em;
        min-width: min-content;
      }

      .mat-mdc-cell:nth-of-type(2) {
        min-width: min-content;
      }

      .mat-mdc-cell:nth-of-type(3) {
        min-width: 93px;
      }

      .mat-mdc-cell:last-of-type {
        // padding: 0 2em 0 2em;
        min-width: min-content;
      }

      .mdc-data-table__cell {
        margin-top: 0 !important;
        > span {
          line-height: 16px;
          padding: 5px 0 5px 0;
        }
      }
    }

    ic-detail-table {
      .mat-mdc-row {
        .mdc-data-table__cell {
          padding: 10px 15px 10px 0px;
          margin: 0;
          margin-top: 0 !important;
          min-width: 103px;
        }
      }


      .mat-header-row,
      .mat-mdc-header-row {
        .mdc-data-table__header-cell {
          font-size: 12px;
          font-weight: 600;
          min-width: 103px;
          padding: 10px 15px 10px 0px;
          margin: 0;
          margin-top: 0 !important;
        }
      }
    }
  }
  .#{$block-prior-inspection-category-afts-manage-in-model}-toolbar {
    background-color: colors.$color-white;
    color: colors.$color-grey-700;
    font-family: 'OpenSansRegular';
    font-size: variables.$font-size-16;
    line-height: 1.19;
    height: variables.$toolbarHeight;
    max-height: variables.$toolbarHeight;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
    padding: 0 12px 0 4px;
    z-index: 999;

    .hack-autofocus-disable {
      position: absolute;
      height: 0;
    }

    .panel-title {
      flex: auto;
    }

    .panel-close {
      height: 48px;
      margin-right: variables.$separation-16;
      width: 48px;

      &:hover {
        background-color: variables.$icon-button-hover;
      }
    }

    .panel-spacer {
      flex: 1 1 auto;
    }

    .mat-icon.mat-icon-inline {
      line-height: unset;
      height: unset;
      font-size: unset;
      width: unset;
    }
  }

  .#{$block-prior-inspection-category-afts-manage-in-model}-info {
    min-height: 100%;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    > mat-card {
      margin-top: 20px;
      margin-bottom: 20px;

      box-shadow: 0px 2px 2px -1px colors.$shadow-color,
        -1px -1px 1px 0px colors.$shadow-color,
        0px 1px 3px 0px colors.$shadow-color;

      > mat-card-content {
        > .five-element-row {
          flex-direction: row;
          display: flex;

          > .five-element-row-code {
            flex-basis: 17%;
            min-width: 17%;
            .mat-mdc-input-element {
              letter-spacing: 0;
            }
          }
          > .five-element-row-type {
            flex-basis: 17%;
            min-width: 17%;
            .mat-mdc-input-element {
              letter-spacing: 0;
            }
          }
          > .five-element-row-parent {
            flex-basis: 40%;
            min-width: 40%;
            .mat-mdc-input-element {
              letter-spacing: 0;
            }
          }
          > .five-element-row-legal-base {
            flex-basis: 25%;
            min-width: 25%;
            .mat-mdc-input-element {
              letter-spacing: 0;
            }
          }
        }
        > .two-element-row {
          flex-direction: row;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: baseline;
          margin-bottom: 20px;
          > .all-row-element {
            flex-basis: 100%;
            min-width: 100%;
            align-self: baseline;
            .mat-mdc-input-element {
              letter-spacing: 0;
            }
          }
          > .two-element-row-element {
            flex-basis: 50%;
            min-width: 50%;
            align-self: baseline;
            .mat-mdc-input-element {
              letter-spacing: 0;
            }
          }
          > .two-element-row-medium-element {
            flex-basis: 40%;
            min-width: 40%;
            align-self: baseline;
            .mat-mdc-input-element {
              letter-spacing: 0;
            }
          }
          > .two-element-row-big-element {
            flex-basis: 70%;
            min-width: 70%;
            align-self: baseline;
            .mat-mdc-input-element {
              letter-spacing: 0;
            }
          }
          > .two-element-row-small-element {
            flex-basis: 30%;
            min-width: 30%;
            align-self: baseline;
            .mat-mdc-input-element {
              letter-spacing: 0;
            }
          }
        }
      }
    }

    .mat-icon.mat-icon-inline {
      line-height: unset;
      height: unset;
      font-size: unset;
      width: unset;
    }
  }

  > .#{$block-prior-inspection-category-afts-manage-in-model}-title {
      padding-top: 25px;
  }
}

.dynamic-component-header-text {
  margin-left: 0px;
}

.ic-panel-input {
  margin-top: 0.5em;
}

.ic-slide-toggle {
  margin-bottom: 25px;
}

.row-one-element {
  width: 75%;
}

.add-entity {
  cursor: pointer;
  vertical-align: middle;
}

.add-entity-disabled {
  opacity: 0.4;
   &:hover {
    background-color: transparent;
  }
  vertical-align: middle;
}

.delete-entity {
  cursor: pointer;
  margin: 5px;
  padding: 0.9em 0;
  border-top: 0.8437;
}

.text-match {
  color: red;
}

.empty-wordings {
  color: colors.$color-error;
}

.clean-inputs-button {
  float: right;
}

.buttons-bot {
  margin-top: -5px;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 15px;
  flex-basis: 25%;
}

.edit-button {
  margin-left: auto;
}
