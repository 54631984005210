@use '../../../core/app/variables' as variables;
@use '../../../core/app/colors' as colors;

$block-bic: 'ic-between-inputs-container';

// TODO: Revisar las clases mat-form-field-wrapper y mat-form-field-underline
.#{$block-bic} {
  // min-width: 230px;
  margin: 10px 0 10px;
  max-width: 100%;
  width: 100%;
  display: flex;
  .mat-mdc-form-field {
    margin: 10px;
    line-height: 2.2;
    .mat-mdc-text-field-wrapper,
    .mat-mdc-form-field-wrapper {
      padding: 0 10px;
      .mat-mdc-form-field-flex {
        padding-top: 0;
        .mat-mdc-form-field-infix {
          .#{$block-bic}-form-input {
            font-size: variables.$font-size-16;
            line-height: 24px;
          }
        }
      }
      .mat-form-field-underline,
      .mdc-line-ripple,
      .mat-mdc-form-field-underline {
        background-color: colors.$color-grey-400;
      }
    }
  }
}
