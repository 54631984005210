@use '../../../core/app/variables' as variables;
@use '../../../core/app/colors' as colors;

$block-dp: 'ic-datepicker';

.#{$block-dp} {
  // min-width: 230px;
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
  margin-bottom: 1em;
  display: flex;
  align-items: baseline;
  .ic-datepicker-column {
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
    }
  }

  .standard-datepicker {
    width: 100%;
    margin: auto;
  }
  .mat-mdc-form-field {
    // margin: 0 0 0 10px;
    line-height: 2;
    width: 100%;
    .mat-mdc-form-field-wrapper,
    .mat-mdc-text-field-wrapper {
      padding: 0 10px;
      .mat-mdc-form-field-flex {
        padding-top: 0;
        .mat-form-field-infix,
        .mat-mdc-form-field-infix {
          .#{$block-dp}-form-input {
            font-size: variables.$font-size-16;
            line-height: 24px;
          }
          .mat-mdc-floating-label {
            mat-label {
              font-size: variables.$font-size-floating-label;
            }
          }
          .mat-mdc-floating-label.mdc-floating-label--float-above {
            mat-label {
              font-size: variables.$font-size-floating-label-above;
            }
          }
        }
      }
      .mat-form-field-underline,
      .mdc-line-ripple,
      .mat-mdc-form-field-underline {
        background-color: colors.$color-grey-400;
      }
    } /** No CSS for this example */
    .mat-datepicker-input {
      width: 85%;
      position: relative;
      color: colors.$color-black;
    }
    .mat-date-range-input {
      width: 85%;
      position: relative;
    }
    mat-icon {
      position: absolute;
      right: 0;
      bottom: 18px;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.54);
    }
  }
}
