ic-top-nav-picker {
  .icon-color {
    color: white;
  }

  .mat-mdc-button > .mat-icon {
    width: 24px;
    height: 24px;
    font-size: 24px;
  }
}
