@use '../../../core/app/colors' as colors;

// TODO: Revisar mat-checkbox-inner-container y mat-checkbox-frame
.checkbox {
  &.mat-checkbox,
  &.mat-mdc-checkbox {
    margin-left: 8px;

    &.mat-checkbox-disabled,
    &.mat-mdc-checkbox-disabled {
      cursor: not-allowed;
      opacity: 0.5;

      .mat-checkbox-inner-container,
      .mdc-checkbox {
        background-color: colors.$color-grey-200;
      }
    }

    .mat-checkbox-inner-container,
    .mdc-checkbox {
      .mat-checkbox-frame,
      .mdc-checkbox__background {
        border-color: colors.$color-grey-600;
        border-width: 1px;
      }
    }
  }
}
