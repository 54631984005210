$layout-breakpoint-md: 1480px !default;

//Variables para tamaños de iconos
$font-size-icon-small: 12px;
$font-size-icon: 24px;
$font-size-icon-medium: 36px;
$font-size-icon-big: 48px;
$font-size-icon-large: 60px;
$font-size-icon-xxxlarge: 156px;

//Variables para tamaños de texto
$font-size-10: 10px;
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-22: 22px;
$font-size-24: 24px;
$font-size-26: 26px;
$font-size-28: 28px;
$font-size-32: 32px;
$font-size-36: 36px;
$font-size-42: 42px;
$font-size-48: 48px;
$font-size-56: 56px;

//Variables para márgenes y paddings
$separation-0: 0;
$separation-4: 4px;
$separation-8: 8px;
$separation-12: 12px;
$separation-16: 16px;
$separation-20: 20px;
$separation-24: 24px;
$separation-28: 28px;
$separation-32: 32px;
$separation-36: 36px;
$separation-40: 40px;
$separation-44: 44px;
$separation-48: 48px;
$separation-60: 60px;
$separation-80: 80px;
$separation-100: 100px;

//Sombras
$shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
$shadow-hover: 0 0 14px 0 rgba(0, 0, 0, 0.26);
$shadow-navbar: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

//Hover
$icon-button-hover: rgba(0, 0, 0, 0.16);
$icon-button-toolbar-hover: rgba(255, 255, 255, 0.16);

//Toolbar
$toolbarHeight: 56px;
$headerHeight: 104px;

//Table
$tableEvaluationHeaderHeight: 84px;
$tableEvaluationRowHeight: 80px;
$tableHeaderOptionsHeight: 62px;
$tableHeaderHeight: 48px;
$tableRowHeight: 52px;

$font-size-floating-label: 13px;
$font-size-floating-label-above: 16px;
