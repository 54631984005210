@use '../../../core/app/variables' as variables;
@use '../../../core/app/colors' as colors;

$block-i: 'ic-input-container';

.four-element-row-small-element {
  .#{$block-i} {
    min-width: 0px;
    .mat-mdc-form-field-infix {
      width: 40%;
    }
  }
}

.four-element-row-big-element {
  .#{$block-i} {
    min-width: 0px;
    .mat-mdc-form-field-infix {
      width: 60%;
    }
  }
}

.two-element-row-small-element {
  .#{$block-i} {
    min-width: 0px;
    .mat-mdc-form-field-infix {
      width: 40%;
    }
  }
}

.two-element-row-big-element {
  .#{$block-i} {
    min-width: 0px;
  }
}

.display-block {
  display: block !important;
}
