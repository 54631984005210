@use '../../core/app/colors' as colors;

$block-tree-action-button: 'ic-tree-action-button';
.tree-button {
  .mat-mdc-raised-button[disabled][disabled] {
    color: rgba(0, 0, 0, 1);
    font-weight: bold;
    text-transform: capitalize;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.14),
      0 0 0 0 rgba(0, 0, 0, 0.12);
    margin: 0 0.25px;
  }

  .mat-mdc-raised-button:not(:disabled) {
    color: rgba(0, 0, 0, 1);
    font-weight: bold;
    text-transform: capitalize;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    margin: 0 0.25px;
  }

  .mdc-button__label {
  }

  .#{$block-tree-action-button} {
    &-green {
      background-color: colors.$color-favorable;
      color: colors.$color-white;

      &.#{$block-tree-action-button}-pushed {
        // box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
        /* Lowering the shadow */
        // border: 1px solid rgba(0, 0, 0, 0.75);
        // outline: blue auto 5px;
        background-color: darken($color: colors.$color-favorable, $amount: 10%);
        // outline: darkolivegreen auto 1px;
      }
    }

    &-yellow {
      background-color: colors.$color-pending;
      color: colors.$color-white;
      &.#{$block-tree-action-button}-pushed {
        // box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
        /* Lowering the shadow */
        // border: 1px solid rgba(0, 0, 0, 0.75);
        // outline: blue auto 5px;
        background-color: darken($color: colors.$color-pending, $amount: 10%);
        // outline: darkolivegreen auto 1px;
      }
    }

    &-red {
      background-color: colors.$color-unfavorable;
      &.#{$block-tree-action-button}-pushed {
        // box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
        /* Lowering the shadow */
        // border: 1px solid rgba(0, 0, 0, 0.75);
        // outline: blue auto 5px;
        background-color: darken(
          $color: colors.$color-unfavorable,
          $amount: 10%
        );
        // outline: darkolivegreen auto 1px;
      }
    }

    &-default {
      background-color: colors.$color-white;
      &.#{$block-tree-action-button}-pushed {
        // box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
        /* Lowering the shadow */
        // border: 1px solid rgba(0, 0, 0, 0.75);
        // outline: blue auto 5px;
        background-color: darken($color: colors.$color-white, $amount: 30%);
        // outline: darkolivegreen auto 1px;
      }
    }

    // &-pushed {
    //   /* Scaling button to 0.98 to its original size */

    // }
    // #878787
  }
}
