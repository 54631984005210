@use '../../core/app/colors' as colors;
@use '../../core/app/variables' as variables;

$block-omission-af-correction-response: 'omission-af-correction-response';

// .two-element-row-element {
//   flex-basis: 50%;
// }

mat-spinner {
  margin: 20px auto;
}

.#{$block-omission-af-correction-response} {
  display: flex;
  flex-flow: column;
  .button-back {
    margin-top: 10px;
    margin-left: 10px;
    height: min-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .response-info {
    margin: auto;
    max-width: 1000px;
    width: 100%;
    margin-bottom: 50px;
  }

  .edit-button {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 30px;
    margin-top: 15px;
    flex-basis: 25%;
  }

  mat-card {
    margin-bottom: 20px;
    max-width: 1000px;
    margin: auto;
    margin-top: 20px;

    box-shadow: 0px 2px 2px -1px colors.$shadow-color,
      -1px -1px 1px 0px colors.$shadow-color,
      0px 1px 3px 0px colors.$shadow-color;

    mat-card-title {
      margin: 0 0 20px -16px;
    }

    mat-card-header {
      justify-content: space-between;
    }
    mat-card-header-text {
      flex-basis: 80%;
      align-self: center;
    }
    mat-card-subtitle {
      margin-left: -16px;
      display: flex;
      align-items: center;
      > span {
        cursor: pointer;
      }
    }

    .documents-table-title-text {
      font-size: 20px;
      font-weight: 500;
    }

    .documents-table-title {
      margin-bottom: 10px;
    }

    .ic-searchbox {
      box-shadow: none;
    }

    mat-list-item {
      height: auto;
    }

    > mat-card-content {
      font-size: variables.$font-size-16;
      // .two-element-row {
      // flex-direction: row;
      // display: flex;
      // flex-wrap: wrap;
      // }

      .ic-slide-toggle {
        margin-bottom: 25px;
      }

      // .two-element-row-element {
      //   flex-basis: 50%;
      //   align-self: baseline;
      // }

      .two-element-row-one-element {
        flex-basis: 100%;
      }
    }
  }
}

.panel-alert-empty-documents {
  color: colors.$color-alert-empty-validation;
  padding-left: 10px;
}

.panel-alert-empty-div {
  background: colors.$color-alert-empty-validation-bg;
  height: 40px;
  margin: auto;
  display: flex;
  align-items: center;
  border: 1px solid colors.$color-alert-empty-validation-border;
  border-radius: 7px;
}
