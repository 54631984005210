@use '../../core/app/colors' as colors;
@use '../../core/app/variables' as variables;

$block-settings-prior-inspection-items-panel: 'settings-prior-inspection-items-panel';
ic-settings-prior-inspection-items-panel {
  .#{$block-settings-prior-inspection-items-panel} {
    .ic-selectable-table mat-header-row {
      justify-content: space-between;
      &.ic-selectable-row {
        height: 40px !important;
        top: 66px !important;
        .mat-mdc-header-cell:first-of-type {
          padding: 0 0.4em;
        }
      }
      .mat-mdc-header-cell:first-of-type {
        padding: 0 0 0 1.2em;
        label {
          height: 24px;
        }
      }

      .mat-mdc-header-cell {
        min-width: min-content;
      }

      .mat-mdc-header-cell:nth-of-type(2) {
        padding: 0 0.5em 0 2em;
      }

      .mat-mdc-header-cell:nth-of-type(3) {
        min-width: 93px;
      }

      .mat-mdc-header-cell:last-of-type {
        // padding: 0 2em 0 2em;
        min-width: min-content;
      }
    }

    .ic-selectable-table mat-row {
      justify-content: space-between;
      // &.ic-selectable-row {
      //   height: 40px !important;
      //   top: 66px !important;
      // }
      .mat-mdc-cell:first-of-type {
        padding: 0 0.4em;
        min-width: min-content;
      }

      .mat-mdc-cell:nth-of-type(2) {
        min-width: min-content;
      }

      .mat-mdc-cell:nth-of-type(3) {
        min-width: 93px;
      }

      .mat-mdc-cell:last-of-type {
        // padding: 0 2em 0 2em;
        min-width: min-content;
      }

      .mdc-data-table__cell {
        margin-top: 0 !important;
        > span {
          line-height: 16px;
          padding: 5px 0 5px 0;
        }
      }
    }

    .#{$block-settings-prior-inspection-items-panel}-toolbar {
      background-color: colors.$color-white;
      color: colors.$color-grey-700;
      font-family: 'OpenSansRegular';
      font-size: variables.$font-size-16;
      line-height: 1.19;
      height: variables.$toolbarHeight;
      max-height: variables.$toolbarHeight;
      box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
        0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
      padding: 0 12px 0 4px;
      z-index: 999;

      .hack-autofocus-disable {
        position: absolute;
        height: 0;
      }

      .panel-title {
        flex: auto;
      }

      .panel-close {
        height: 48px;
        margin-right: variables.$separation-16;
        width: 48px;

        &:hover {
          background-color: variables.$icon-button-hover;
        }
      }

      .panel-spacer {
        flex: 1 1 auto;
      }

      .mat-icon.mat-icon-inline {
        line-height: unset;
        height: unset;
        font-size: unset;
        width: unset;
      }
    }

    .#{$block-settings-prior-inspection-items-panel}-info {
      padding: 20px;

      > mat-card {
        margin-top: 20px;
        margin-bottom: 20px;

        box-shadow: 0px 2px 2px -1px colors.$shadow-color,
          -1px -1px 1px 0px colors.$shadow-color,
          0px 1px 3px 0px colors.$shadow-color;

        > mat-card-content {
          > .two-element-row {
            flex-direction: row;
            display: flex;
            flex-wrap: wrap;
            justify-content: unset;
            align-items: baseline;
            margin-bottom: 0px;
          }
        }
      }

      > div {
        flex: 1;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }

      > .#{$block-settings-prior-inspection-items-panel}-title {
        padding-top: 25px;
      }

      .edit-button {
        display: flex;
        flex-flow: row;
        justify-content: flex-end;
        width: 100%;
        flex-basis: 25%;
      }
    }
  }
}

.dynamic-component-header-text {
  margin-left: 0px;
}

.ic-slide-toggle {
  margin-left: 15px;
  margin-right: 25px;
}

.text-match {
  color: red;
}

.select-if-yes-no {
  flex-basis: 20%;
  padding: 5px;
  margin-left: 5px;
  margin-right: 15px;
}

.code-item {
  margin-left: 25px;
}

.father-item {
  width: 60%;
  margin-left: 15px;
}

.two-element-row {
  margin-bottom: 25px !important;
}

.edit-button {
  margin-left: auto;
}
