@use '../../core/app/colors' as colors;
@use '../../core/app/variables' as variables;

$block-omission-panel: 'omission-panel';

.#{$block-omission-panel} {
  .#{$block-omission-panel}-toolbar {
    background-color: colors.$color-white;
    color: colors.$color-grey-700;
    font-family: 'OpenSansRegular';
    font-size: variables.$font-size-16;
    line-height: 1.19;
    height: variables.$toolbarHeight;
    padding: 0 12px 0 4px;

    .hack-autofocus-disable {
      position: absolute;
      height: 0;
    }

    .panel-close {
      height: 48px;
      margin-right: variables.$separation-16;
      width: 48px;

      &:hover {
        background-color: variables.$icon-button-hover;
      }
    }

    .panel-spacer {
      flex: 1 1 auto;
    }

    .mat-icon.mat-icon-inline {
      line-height: unset;
      height: unset;
      font-size: unset;
      width: unset;
    }

    .panel-buttons {
      width: max-content;
      min-width: max-content;
    }
  }

  .#{$block-omission-panel}-info {
    // min-height: 100%;
    // max-width: 90%;
    // display: flex;
    // flex-direction: column;
    // margin: 0 auto;
    // background-color: colors.$color-white;
    padding-top: 1em;
    height: calc(100% - variables.$toolbarHeight);
    max-width: 90%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    background-color: colors.$color-white;

    > mat-card {
      // margin-top: 20px;
      // margin-bottom: 20px;

      // box-shadow: 0px 2px 2px -1px colors.$shadow-color,
      //   -1px -1px 1px 0px colors.$shadow-color,
      //   0px 1px 3px 0px colors.$shadow-color;

      > mat-card-content {
        > .two-element-row {
          // flex-direction: row;
          // display: flex;
          // flex-wrap: wrap;
          > ic-input-select {
            > mat-form-field.ic-input-select {
              // margin-top: 0;
            }
          }
        }
      }

      .observations-title {
        font-size: 14px;
        font-weight: 600;
        line-height: 1.5em;
      }
    }

    .slidetoggles-row {
      flex: 1;
      display: flex;
      justify-content: space-around;
      // flex-direction: row;
      flex-wrap: wrap;
    }

    > div {
      flex: 1;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    .panel-buttons {
      display: flex;
      flex-flow: row;
      justify-content: flex-end;
      width: 100%;
      margin-bottom: 30px;
    }
  }

  .#{$block-omission-panel}-documentation {
    margin-left: 15px;
  }
  .#{$block-omission-panel}-documentation-title {
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 15px;
  }

  // .two-element-row-element {
  //   @media (max-width: 1024px) {
  //     flex-basis: 100%;
  //   }
  //   flex-basis: 50%;
  // }

  // .four-element-row-big-element {
  //   @media (max-width: 1024px) {
  //     flex-basis: 100%;
  //   }
  //   .mat-form-field {
  //     .mat-form-field-wrapper {
  //       padding-bottom: 1.25em;
  //     }
  //   }
  //   flex-basis: 30%;
  // }

  // .four-element-row-small-element {
  //   @media (max-width: 1024px) {
  //     flex-basis: 100%;
  //   }
  //   flex-basis: 20%;
  //   min-width: 0px;
  //   .mat-form-field {
  //     width: 115px;
  //     .mat-form-field-wrapper {
  //       padding-bottom: 1.25em;
  //       width: 115px;
  //       .mat-form-field-flex {
  //         padding-top: 0px;
  //         .mat-form-field-infix {
  //           width: 115px;
  //         }
  //       }
  //     }
  //   }
  // }

  // .two-element-row-element-select {
  //   @media (max-width: 1024px) {
  //     flex-basis: 100%;
  //   }
  //   flex-basis: 50%;
  //   min-width: 0px;
  //   .mat-form-field {
  //     .mat-form-field-wrapper {
  //       padding-bottom: 1.25em;
  //       .mat-form-field-flex {
  //         padding-top: 0px;
  //       }
  //     }
  //   }
  // }

  // .des-row-element {
  //   @media (max-width: 1024px) {
  //     flex-basis: 100%;
  //   }
  //   flex-basis: 31%;
  //   min-width: 0px;
  //   .mat-form-field {
  //     min-width: 0px;
  //     max-width: 250px;
  //     width: 97%;
  //     .mat-form-field-wrapper {
  //       min-width: 0px;
  //       max-width: 250px;
  //       width: 97%;
  //       .mat-form-field-flex {
  //         .mat-form-field-infix {
  //           min-width: 0px;
  //           max-width: 250px;
  //           width: 97%;
  //         }
  //       }
  //     }
  //   }
  // }

  // .item-row-element {
  //   @media (max-width: 1024px) {
  //     flex-basis: 100%;
  //     max-width: 100%;
  //   }
  //   flex-basis: 20%;
  //   min-width: 0px;
  //   max-width: 160px;
  //   .mat-form-field {
  //     width: 125px;
  //     .mat-form-field-wrapper {
  //       width: 125px;
  //       .mat-form-field-flex {
  //         .mat-form-field-infix {
  //           width: 125px;
  //         }
  //       }
  //     }
  //   }
  // }

  // .five-element-row-element {
  //   @media (max-width: 1024px) {
  //     flex-basis: 100%;
  //   }
  //   flex-basis: 15%;
  //   min-width: 0px;
  //   .mat-form-field {
  //     width: 95px;
  //     min-width: 0px;
  //     .mat-form-field-wrapper {
  //       width: 95px;
  //       min-width: 0px;
  //       .mat-form-field-flex {
  //         .mat-form-field-infix {
  //           width: 95px;
  //           min-width: 0px;
  //         }
  //       }
  //     }
  //   }
  // }

  // .five-element-row-small-element {
  //   @media (max-width: 1024px) {
  //     flex-basis: 100%;
  //   }
  //   flex-basis: 12.5%;
  //   min-width: 0px;
  //   .mat-form-field {
  //     min-width: 0px;
  //     max-width: 70px;
  //     width: 95%;
  //     .mat-form-field-wrapper {
  //       min-width: 0px;
  //       max-width: 70px;
  //       width: 95%;
  //       .mat-form-field-flex {
  //         .mat-form-field-infix {
  //           min-width: 0px;
  //           max-width: 70px;
  //           width: 95%;
  //         }
  //       }
  //     }
  //   }
  // }

  // .total-label {
  //   font: caption;
  //   text-align: right;
  //   align-self: flex-end;
  //   margin-bottom: 35px;
  //   font-weight: bold;
  // }

  .dynamic-component-header-text {
    margin-left: 0px;
  }

  .ic-panel-input {
    margin-top: 0.5em;
    // margin-left: 1em;
    // margin-right: 2em;
  }

  .ic-slide-toggle {
    margin-bottom: 25px;
  }

  .panel-alert-empty-documents {
    color: colors.$color-alert-empty-validation;
    padding-left: 10px;
  }

  .panel-alert-empty-div {
    background: colors.$color-alert-empty-validation-bg;
    height: 40px;
    margin: auto;
    display: flex;
    align-items: center;
    border: 1px solid colors.$color-alert-empty-validation-border;
    border-radius: 7px;
  }
}
