@use '../../core/app/colors' as colors;

$block-tree-icon-button: 'ic-tree-icon-button';
.actionIcons {
  height: 20px;
  width: 20px;
  font-size: 30px;
  margin-right: 0px;
  margin-left: 0px;
}
