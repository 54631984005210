$block-panel: 'panel';

.panel-title,
.omission-panel-title,
.returnpanel-title {
  // padding-left: 10px;
}

.panel-toolbar,
.sofrb-panel-toolbar,
.omission-panel-toolbar,
.returnpanel-toolbar,
.amendment-panel-toolbar,
.solppc-panel-toolbar {
  position: sticky;
  top: 0;
  background-color: #fff;
  color: #666;
  font-family: 'OpenSansRegular';
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  max-height: 56px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px 0 4px;
  z-index: 999;
  .panel-close,
  .returnpanel-close {
    height: 48px;
    width: 48px;
    margin-right: 16px;
  }
}

.#{$block-panel} {
  .mat-icon.mat-icon-inline {
    line-height: unset;
    height: unset;
    font-size: unset;
    width: unset;
  }
}
