@use '../../../core/app/variables' as variables;
@use '../../../core/app/colors' as colors;

// $block-is: 'ic-input-select';

// .#{$block-is} {
//   &.mat-form-field,
//   &.mat-mdc-form-field {
//     margin: 10px;
//     line-height: 2.2;
//     // display: block;
//     .mat-form-field-wrapper,
//     .mat-mdc-text-field-wrapper,
//     .mat-mdc-form-field-wrapper {
//       .mat-form-field-flex,
//       .mat-mdc-form-field-flex {
//         padding-top: 0;
//         .mat-form-field-infix,
//         .mat-mdc-form-field-infix {
//           .#{$block-is}-form-input {
//             font-size: variables.$font-size-16;
//             line-height: 24px;
//           }
//           .mat-mdc-floating-label {
//             mat-label {
//               font-size: variables.$font-size-floating-label;
//             }
//           }
//           .mat-mdc-floating-label.mdc-floating-label--float-above {
//             mat-label {
//               font-size: variables.$font-size-floating-label-above;
//             }
//           }
//         }
//       }
//       .mat-form-field-underline,
//       .mdc-line-ripple,
//       .mat-mdc-form-field-underline {
//         background-color: colors.$color-grey-400;
//       }
//     }
//   }
// }

.half-width {
  width: 50%;
}

// mat-form-field {
//   display: block;
//   margin: 10px;
// }

.filter-class {
  width: 96%;
  align-self: center;
  justify-self: center;
}

.margin-10 {
  margin: 10px;
}
