//Colores escala de grises
$color-black: #000;
$color-grey-900: #1a1a1a;
$color-grey-800: #333;
$color-grey-700: #666;
$color-grey-600: #999;
$color-grey-500: #bdbdbd;
$color-grey-400: #ccc;
$color-grey-300: #e2e2e2;
$color-grey-200: #eee;
$color-grey-100: #f9f9f9;
$color-white: #fff;

//Colores tablas
//$color-table-header-background:#F5F6FA;
$color-table-header-background: #e1e1e1;
$color-table-header-text: #a3a6b4;
$color-bg-table: #f3fbff;
$color-bg-row-odd: #f5f5f5;
$color-shadow-table: #4050b633;
$color-row-touched: #e3edff;
$color-row-hightlight-odd: #fff7ef;
$color-row-hightlight-even: #fff5ea;
$color-row-hightlight-hover: #feeedc;
$color-row-is-touched-even: #efd1b0;

//Colores random
$color-random1: #41545d;
$color-random2: #279ed6;
$color-random3: #9b2761;
$color-random4: #e53b78;
$color-random5: #e7575b;
$color-random6: #5cce88;
$color-random7: #199aa8;
$color-random8: #ee9a37;
$color-random9: #b182a6;
$color-random10: #aad355;
$color-random11: #1d5d93;

//Colores acción
$bg-color-primary: #4050b6;
$bg-color-primary-hover: #293375;
$color-primary: #ffffff;
$color-primary-hover: #e2e2e2;
$color-primary-disabled: #999;
$bg-color-accent: #ffffff;
$bg-color-accent-hover: #e2e2e2;
$color-accent: #4050b6;
$color-accent-hover: #293375;
$color-accent-disabled: #000000;
$color-violet: #412890;
$color-violet-hover: #29195d;
$color-basic: #ffffff;
$color-basic-hover: #293375;
$color-operative: #000000;
$color-operative-hover: #999999;

//Colores estados
$color-success: #5cce88;
$color-warning: #ee9a37;
$color-error: #f05060;
$color-out: #000000;
$color-stopped: #999999;
//Colores ficheros
$color-read: #f05060;
$color-doc: #1d5d93;
$color-calc: #5cce88;
$color-image: #279ed6;

//Colores observaciones
$color-cross: #4050b6;
$color-general: #e53b78;
$color-specific: #279ed6;
$color-no-model: #9e5a9e;

//Colores labels
$color-orange: #dc8522;

//Colores shadows cards
$shadow-color: #4050b633;

$color-favorable: #0a8407;
$color-unfavorable: #e80202;
$color-pending: #e58824;

//Colores background cards
$color-bg-card-blue: #e5f0ff;
$color-bg-card-green: #deffda;
$color-bg-card-red: #ffcfcf;
$color-bg-card-orange: #ffe8ca;

//Colores mensajes
$color-alert-empty-validation: #977070;
$color-alert-empty-validation-bg: #ff000029;
$color-alert-empty-validation-border: #ff000033;
