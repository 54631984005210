@use '../../core/app/variables' as variables;
@use '../../core/app/colors' as colors;

.mat-icon-button,
.mat-mdc-icon-button {
  // background-color: aquamarine;
  &.menu-options-button {
    height: 48px;
    line-height: 48px;
    margin: 0 2px;
    width: 48px;
    &:hover {
      background-color: variables.$icon-button-toolbar-hover;
    }
    &[disabled] {
      cursor: not-allowed;
      opacity: 0.4;
      &:hover {
        background-color: transparent;
      }
    }
  }
}

//Desplegable
.mat-menu-panel,
.mat-mdc-menu-panel {
  &.mat-menu-options-simple {
    border-radius: 4px;
    box-shadow: variables.$shadow;
    max-width: 320px;
    min-width: 230px;
    text-align: center;
    .mat-mdc-menu-content {
      .mat-menu-item,
      .mat-mdc-menu-item {
        padding: 0 10px;
        .mdc-list-item__primary-text {
          color: colors.$color-grey-700;
          font-size: variables.$font-size-14 !important;
          letter-spacing: 0 !important;
        }
        &[disabled] {
          cursor: not-allowed;
          opacity: 0.3;
        }
      }
    }
    .mat-raised-button,
    .mat-mdc-raised-button {
      &:first {
        margin: 0;
      }
      border-radius: 4px;
      box-shadow: none;
      margin: 4px 0;
      text-transform: uppercase;
      width: calc(100% - 32px);
    }
    .mat-divider {
      &.mat-divider-horizontal {
        margin: 8px 0;
      }
    }
  }
}
