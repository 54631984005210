ic-slidetoggle {
  .mat-mdc-slide-toggle {
    margin-bottom: 1em;
    .mdc-form-field {
      .mdc-switch {
        position: relative;
        width: 36px;
        height: 14px;
        flex-shrink: 0;
        border-radius: 8px;

        &.mdc-switch--disabled {
          background-color: rgba(64, 80, 182, 0.54);
          .mdc-switch__icons {
            background-color: #4050b6;
            border-radius: 50%;
          }
        }
      }
    }
  }
}

mat-card-title {
  > ic-slidetoggle {
    > .mat-mdc-slide-toggle {
      > .mdc-form-field {
        > label {
          font-size: 20px;
          font-weight: 500;
        }
      }
    }
  }
}
