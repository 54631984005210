@use '../../../core/app/variables' as variables;
@use '../../../core/app/colors' as colors;

$block-iac: 'ic-input-autocomplete-container';

// .#{$block-iac} {
//   min-width: 230px;
//   margin: 0 auto;
//   max-width: 100%;
//   // width: 100%;
//   margin-bottom: 1em;
//   &.full-width {
//     &.mat-form-field,
//     &.mat-mdc-form-field {
//       &.mat-form-field-appearance-legacy,
//       &.mat-form-field-appearance-fill {
//         margin: 10px;
//         line-height: 2.2;
//         display: block;
//         .mat-form-field-wrapper,
//         .mat-mdc-text-field-wrapper,
//         .mat-mdc-form-field-wrapper {
//           .mat-form-field-flex,
//           .mat-mdc-form-field-flex {
//             .mat-form-field-infix,
//             .mat-mdc-form-field-infix {
//               .#{$block-iac}-form-input {
//                 font-size: variables.$font-size-16;
//                 line-height: 24px;
//               }
//               .#{$block-iac}-autocomplete {
//                 font-size: variables.$font-size-18;
//               }
//               .mat-mdc-floating-label {
//                 mat-label {
//                   font-size: variables.$font-size-floating-label;
//                 }
//               }
//               .mat-mdc-floating-label.mdc-floating-label--float-above {
//                 mat-label {
//                   font-size: variables.$font-size-floating-label-above;
//                 }
//               }
//             }
//           }

//           .mat-form-field-underline,
//           .mdc-line-ripple,
//           .mat-mdc-form-field-underline {
//             background-color: colors.$color-grey-400;
//           }
//         }
//       }
//     }
//   }
//   &.mat-form-field,
//   &.mat-mdc-form-field {
//     &.mat-form-field-appearance-legacy,
//     &.mat-form-field-appearance-fill {
//       margin: 10px;
//       line-height: 2;
//       // display: block;
//       .mat-form-field-wrapper,
//       .mat-mdc-text-field-wrapper,
//       .mat-mdc-form-field-wrapper {
//         .mat-form-field-flex,
//         .mat-mdc-form-field-flex {
//           .mat-form-field-infix,
//           .mat-mdc-form-field-infix {
//             .#{$block-iac}-form-input {
//               font-size: variables.$font-size-16;
//               line-height: 24px;
//             }
//             .#{$block-iac}-autocomplete {
//               font-size: variables.$font-size-18;
//             }
//             .mat-mdc-floating-label {
//               mat-label {
//                 font-size: variables.$font-size-floating-label;
//               }
//             }
//             .mat-mdc-floating-label.mdc-floating-label--float-above {
//               mat-label {
//                 font-size: variables.$font-size-floating-label-above;
//               }
//             }
//           }
//         }

//         .mat-form-field-underline,
//         .mdc-line-ripple,
//         .mat-mdc-form-field-underline {
//           background-color: colors.$color-grey-400;
//         }
//       }
//     }
//   }
// }

.#{$block-iac}-autocomplete {
  mat-option {
    span {
      font-size: variables.$font-size-16 !important;
    }
  }
}

.small-font {
  font-size: 12px !important;
}
