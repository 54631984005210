@mixin calc($prop, $val) {
  #{$prop}: calc(#{$val});
  #{$prop}: -moz-calc(#{$val});
  #{$prop}: -webkit-calc(#{$val});
  #{$prop}: -o-calc(#{$val});
}

@mixin calc_important($prop, $val) {
  #{$prop}: calc_important(#{$val} !important);
  #{$prop}: -moz-calc_important(#{$val} !important);
  #{$prop}: -webkit-calc_important(#{$val} !important);
  #{$prop}: -o-calc_important(#{$val} !important);
}

@mixin ellipsis($max-lines: 1) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-lines: $max-lines;
}

@mixin ellipsis-multiline($lines) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  /* autoprefixer: off */
}

@mixin card-white($height) {
  background-color: white;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 0 8px 0 rgba(0,0,0,.16);
  width: 100%;
  height: $height;
}

@mixin centerer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin badge($color, $size) {
  // mixin to render circular badge
  width: #{$size};
  height: #{$size};
  background-color: #{$color};
  border-radius: #{$size*0.5};
  line-height: #{$size};
  text-align: center;
}

@mixin img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
