@use '../../../core/fonts/fonts';
@use '../../../core/app/colors' as colors;

$block-toolbar-searchbox: 'ic-searchbox';

.#{$block-toolbar-searchbox} {
  background-color: white;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  height: 65px;
  display: flex;
  align-items: center;
  font-family: 'OpenSansBold', sans-serif;
  padding-left: 10px;
  @media (max-width: 499px) {
    overflow: scroll;
  }
  .#{$block-toolbar-searchbox}-title {
    @media (max-width: 599px) {
      display: none;
    }
    flex-basis: 312px;
    display: inline-flex;
    align-items: center;
    &-logo {
      width: 32px;
      height: 32px;
      display: inline-block;
    }
  }
  .#{$block-toolbar-searchbox}-searchbox {
    flex-grow: 1;
    flex-basis: 720px;
  }
  .#{$block-toolbar-searchbox}-options {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    button.mat-icon-button:hover,
    button.mat-mdc-icon-button:hover {
      // background-color: #f5f5f5;
      // color: colors.$color-primary;
    }
  }
}
