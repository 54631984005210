@use 'sass:map';
@use '@angular/material' as mat;
@use 'palettes';
@use '../core/app/colors' as colors;

$scipion-omission-primary: mat.define-palette(
  palettes.$ic-omission-primary,
  600
);
$scipion-omission-accent: mat.define-palette(
  palettes.$ic-black,
  100,
  A100,
  A400
);
//   palettes.$ic-yellow,
//   100,
//   A100,
//   A400
// );

// The warn palette is optional (defaults to red).
$scipion-omission-warn: mat.define-palette(mat.$red-palette);

$custom-omission-typography: mat.define-typography-config(
  $font-family: '"Open Sans", "Helvetica Neue", sans-serif'
);

$scipion-omission-theme: mat.define-light-theme(
  (
    color: (
      primary: $scipion-omission-primary,
      accent: $scipion-omission-accent,
      warn: $scipion-omission-warn
    ),
    typography: $custom-omission-typography
  )
);

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($scipion-omission-theme);

  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');

  .user-options {
    .material-icons,
    .material-icons-outlined,
    .material-icons-round,
    .material-icons-sharp {
      color: white;
    }
  }

  .material-icons,
  .material-icons-outlined,
  .material-icons-round,
  .material-icons-sharp {
    color: mat.get-color-from-palette($primary-palette, 600);
    &.secondary {
      color: colors.$color-white;
    }
    &.favorable-report {
      color: colors.$color-favorable;
    }
    &.favorable-but-errors-report {
      color: colors.$color-pending;
    }
    &.unfavorable-report {
      color: colors.$color-unfavorable;
    }
    // font-size: 48px;
  }

  .mat-mdc-tab-link.mdc-tab-indicator--active.mat-mdc-tab-disabled {
    .mdc-tab-indicator__content--underline {
      border-color: mat.get-color-from-palette($primary-palette, 600);
    }
  }

  .mat-mdc-button:not(:disabled) {
    background-color: mat.get-color-from-palette($primary-palette, 600);
    color: white;
  }

  .mat-mdc-raised-button.mat-primary {
    background-color: mat.get-color-from-palette($primary-palette, 600);
    color: white;
  }
}

@mixin theme() {
  .omission-module {
    @include mat.all-component-colors($scipion-omission-theme);
    @include color($scipion-omission-theme);
  }
}
