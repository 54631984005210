@use '../../../core/app/variables' as variables;
@use '../../../core/app/colors' as colors;

// $block-tac: 'ic-input-textarea-container';

// .#{$block-tac} {
//   &.mat-form-field,
//   &.mat-mdc-form-field {
//     &.mat-form-field-appearance-legacy,
//     &.mat-form-field-appearance-fill {
//       margin: 10px;
//       line-height: 2;
//       // display: block;
//       .mat-form-field-wrapper,
//       .mat-mdc-text-field-wrapper,
//       .mat-mdc-form-field-wrapper {
//         .mat-form-field-flex,
//         .mat-mdc-form-field-flex {
//           .mat-form-field-infix,
//           .mat-mdc-form-field-infix {
//             .#{$block-tac}-form-textarea {
//               font-size: variables.$font-size-16;
//               line-height: 24px;
//             }
//             .mat-mdc-floating-label {
//               mat-label {
//                 font-size: variables.$font-size-floating-label;
//               }
//             }
//             .mat-mdc-floating-label.mdc-floating-label--float-above {
//               mat-label {
//                 font-size: variables.$font-size-floating-label-above;
//               }
//             }
//           }
//         }
//         .mat-form-field-underline,
//         .mdc-line-ripple,
//         .mat-mdc-form-field-underline {
//           background-color: colors.$color-grey-400;
//         }
//       }
//     }
//   }
.textarea-height {
  height: 250px;
}

.textarea-extra-height {
  height: 380px !important;
}
// }
