@use '../../core/app/colors' as colors;
@use '../../core/app/breakpoints' as breakpoints;

.mat-sidenav {
  width: auto;
  &.ic-navbar {
    background-color: colors.$color-black;
    width: auto;
    .mat-drawer-inner-container {
      overflow: overlay;
    }
  }
}

.mat-sidenav-container {
  background-color: white !important;
  height: 100vh;

  .mat-sidenav-content {
    display: flex;
    flex-direction: column;
  }
}

.sidenav-toolbar {
  height: 64px;
  background-color: colors.$bg-color-primary;
  display: flex;
  flex-direction: row;

  @media (max-width: 699px) {
    height: 56px;
  }
}

mat-nav-list.mat-mdc-nav-list {
  // padding-top: 0;
}

.mat-mdc-list-base {
  .mat-mdc-list-item {
    .mdc-list-item__content {
      padding-left: 0px !important;
    }
  }
}

.version-info {
  font-size: 8pt;
  padding: 8px;
  align-self: flex-end;
}

@include breakpoints.breakpoint-max(breakpoints.$tablet) {
  .fixed-topnav {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100% !important;
  }

  .mat-drawer-container,
  .mat-drawer {
    padding-top: 3.3em;
  }

  .mat-drawer-opened {
    padding-top: 5px;
  }
}

@media (max-width: 699px) {
  .mat-toolbar-row,
  .mat-toolbar-single-row {
    white-space: normal !important;
    font-size: 17px;
    line-height: 1.5;
  }
}
