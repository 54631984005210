// Breakpoints
$handset: 360px;
$large-handset: 480px;
$tablet: 640px; /* app drawer breakpoint */
$large-tablet: 840px;
$desktop: 960px;
$large-desktop: 1280px;
$x-large-desktop: 1440px;
$xx-large-desktop: 1920px;

// generate min-width breakpoint
@mixin breakpoint($point) {
  @media (min-width: $point) {
    @content;
  }
}
// generate max-width breakpoint (use sparingly)
@mixin breakpoint-max($point) {
  @media (max-width: $point) {
    @content;
  }
}
// generate min-height breakpoint
@mixin height-breakpoint($point) {
  @media (min-height: $point) {
    @content;
  }
}
// generate max-height breakpoint (use sparingly)
@mixin height-breakpoint-max($point) {
  @media (min-height: $point) {
    @content;
  }
}
