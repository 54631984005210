@use '../../../core/app/colors' as colors;
@use '../../../core/fonts/fonts';
:host {
  display: block;
}

$block-list-filter: 'list-filter';

.#{$block-list-filter} {
  position: relative;
  .#{$block-list-filter}-list {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    height: 50px;
    border-radius: 50%;
    border: 1px solid colors.$color-grey-300;
    color: colors.$color-grey-600;
    align-items: center;
    input {
      flex-grow: 1;
    }

    // .mat-mdc-icon-button.mdc-icon-button {
    //   padding: 0;
    //   min-width: 0;
    //   width: 40px;
    //   height: 40px;
    //   flex-shrink: 0;
    //   line-height: 40px;
    //   border-radius: 50%;
    //   // font-size: 14px;
    //   fa-icon {
    //     svg {
    //       width: 55%;
    //       height: 100%;
    //     }
    //   }
    // }

    .#{$block-list-filter}-input {
      font-family: 'OpenSansLight', sans-serif;
      border-style: none;
      background-color: transparent;
      &:hover,
      &:focus {
        outline: none;
      }
    }
  }
  .#{$block-list-filter}-panel {
    position: absolute;
    width: 100%;
    background-color: white;
    left: -68px;
    min-width: min-content;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
    z-index: 999;
    display: flex;
    flex-direction: column;
    padding: 8px;
    &-closeButton {
      align-self: flex-end;
    }
    &-content {
      flex-grow: 1;
      min-width: max-content;
      margin-top: 10px;
      .placeholder {
        border: 1px dashed colors.$color-grey-300;
        font-style: italic;
        border-radius: 2px;
      }
    }
  }
}
