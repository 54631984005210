@use '../../../core/app/variables' as variables;
@use '../../../core/app/colors' as colors;

.dialog-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  gap: 0.5em;
  place-content: center flex-start;
}

.mat-raised-button,
.mat-mdc-raised-button {
  &.mat-primary {
    background-color: colors.$bg-color-primary;
    color: colors.$color-primary;
  }
}

.mat-mdc-dialog-container,
.mat-dialog-container {
  padding: 16px 24px 8px;

  .simple-dialog {
    height: auto;
    max-width: 560px;
    width: 560px;
    padding: 1.5em;

    .dialog-title,
    .mat-mdc-dialog-title {
      color: colors.$color-grey-800;
      font-size: variables.$font-size-20;
    }

    .dialog-content,
    .mat-mdc-dialog-content {
      overflow-y: hidden;
      padding: 0;

      .dialog-content-text {
        color: colors.$color-grey-700;
        font-size: variables.$font-size-14;
        line-height: 24px;
        margin: 2em 0;
      }

      .dialog-actions {
        justify-content: flex-end;
        min-height: inherit;
        padding: 1em 0;

        .dialog-actions-button {
          text-transform: uppercase;
        }
      }
    }
  }
}
