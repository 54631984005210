@use 'sass:map';
@use '@angular/material' as mat;
@use 'palettes';
@use '../core/app/colors' as colors;

$scipion-ppc-primary: mat.define-palette(palettes.$ic-ppc-primary, 400);
$scipion-ppc-accent: mat.define-palette(palettes.$ic-black, 100, A100, A400);

// The warn palette is optional (defaults to red).
$scipion-ppc-warn: mat.define-palette(mat.$red-palette);

$custom-ppc-typography: mat.define-typography-config(
  $font-family: '"Open Sans", "Helvetica Neue", sans-serif'
);

$scipion-ppc-theme: mat.define-light-theme(
  (
    color: (
      primary: $scipion-ppc-primary,
      accent: $scipion-ppc-accent,
      warn: $scipion-ppc-warn
    ),
    s typography: $custom-ppc-typography
  )
);

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($scipion-ppc-theme);

  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');

  .user-options {
    .material-icons,
    .material-icons-outlined,
    .material-icons-round,
    .material-icons-sharp {
      color: white;
    }
  }

  .material-icons,
  .material-icons-outlined,
  .material-icons-round,
  .material-icons-sharp {
    color: mat.get-color-from-palette($primary-palette, 400);
    &.secondary {
      color: colors.$color-white;
    }
    &.favorable-report {
      color: colors.$color-favorable;
    }
    &.favorable-but-errors-report {
      color: colors.$color-pending;
    }
    &.unfavorable-report {
      color: colors.$color-unfavorable;
    }
    // font-size: 48px;
  }

  .mat-mdc-tab-link.mdc-tab-indicator--active.mat-mdc-tab-disabled {
    .mdc-tab-indicator__content--underline {
      border-color: mat.get-color-from-palette($primary-palette, 400);
    }
  }

  .mat-mdc-button:not(:disabled) {
    background-color: mat.get-color-from-palette($primary-palette, 400);
    color: white;
  }
}

@mixin theme() {
  .ppc-module {
    @include mat.all-component-colors($scipion-ppc-theme);
    @include color($scipion-ppc-theme);
  }
}
