@charset "UTF-8";
.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-option:hover:not(.mdc-list-item--disabled), .mat-mdc-option:focus:not(.mdc-list-item--disabled), .mat-mdc-option.mat-mdc-option-active, .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #4050b6);
}

.mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #eeeeee);
}

.mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #4050b6;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #4050b6;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #eeeeee;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #eeeeee;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #eeeeee;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #eeeeee;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-mdc-option {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, "Open Sans", "Helvetica Neue", sans-serif));
  font-size: var(--mdc-typography-body1-font-size, 16px);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, 0.03125em);
}

.mat-mdc-card {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-color: #fff;
}

.mat-mdc-card-outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-outline-color: #e0e0e0;
}

.mat-mdc-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-card-title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, "Open Sans", "Helvetica Neue", sans-serif));
  font-size: var(--mdc-typography-headline6-font-size, 20px);
  line-height: var(--mdc-typography-headline6-line-height, 32px);
  font-weight: var(--mdc-typography-headline6-font-weight, 500);
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, 0.0125em);
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: var(--mdc-typography-headline6-text-transform, none);
}

.mat-mdc-card-subtitle {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, "Open Sans", "Helvetica Neue", sans-serif));
  font-size: var(--mdc-typography-subtitle2-font-size, 14px);
  line-height: var(--mdc-typography-subtitle2-line-height, 22px);
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, 0.0071428571em);
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle2-text-transform, none);
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #4050b6;
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(64, 80, 182, 0.25)'/%3E%3C/svg%3E");
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(64, 80, 182, 0.25);
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #eeeeee;
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(238, 238, 238, 0.25)'/%3E%3C/svg%3E");
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(238, 238, 238, 0.25);
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(244, 67, 54, 0.25)'/%3E%3C/svg%3E");
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(244, 67, 54, 0.25);
}
.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}

.mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-font: "Open Sans", "Helvetica Neue", sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
@media all {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
@media all {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
.mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #4050b6);
}
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field--filled .mdc-text-field__ripple::before,
.mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.mdc-text-field--filled:hover .mdc-text-field__ripple::before, .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #4050b6);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #4050b6);
}
[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mdc-text-field--outlined .mdc-text-field__ripple::before,
.mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(64, 80, 182, 0.87);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}
@media all {
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field-character-counter,
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.mdc-text-field--disabled .mdc-notched-outline__leading,
.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field-character-counter,
  .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-notched-outline__leading,
  .mdc-text-field--disabled .mdc-notched-outline__notch,
  .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fafafa;
}
[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__input, .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}

.mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(64, 80, 182, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(238, 238, 238, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(244, 67, 54, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #eeeeee);
}
.mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #eeeeee);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(238, 238, 238, 0.87);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #eeeeee);
}

.mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(244, 67, 54, 0.87);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}

[dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}

.mat-mdc-form-field-infix {
  min-height: 56px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 28px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -34.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mdc-text-field__input {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, "Open Sans", "Helvetica Neue", sans-serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}
.mdc-text-field__affix {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, "Open Sans", "Helvetica Neue", sans-serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}
[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mdc-text-field--textarea .mdc-text-field__input {
  line-height: 1.5rem;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__input, .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, "Open Sans", "Helvetica Neue", sans-serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}

[dir=rtl] .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, "Open Sans", "Helvetica Neue", sans-serif));
  font-size: var(--mdc-typography-caption-font-size, 12px);
  line-height: var(--mdc-typography-caption-line-height, 20px);
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: var(--mdc-typography-caption-text-transform, none);
}

.mat-mdc-form-field,
.mat-mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, "Open Sans", "Helvetica Neue", sans-serif));
  font-size: var(--mdc-typography-body1-font-size, 16px);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, 0.03125em);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mat-mdc-form-field .mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: calc(16px * var(--mat-mdc-form-field-floating-label-scale, 0.75));
}
.mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 16px;
}

.mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #4050b6);
}
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #4050b6);
}

.mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-divider::after {
  border-bottom-color: white;
}

.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-select-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(64, 80, 182, 0.87);
}
.mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(238, 238, 238, 0.87);
}
.mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(244, 67, 54, 0.87);
}
.mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(244, 67, 54, 0.87);
}
.mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-select-panel {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, "Open Sans", "Helvetica Neue", sans-serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  line-height: var(--mdc-typography-subtitle1-line-height, 28px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 24px;
}

.mat-mdc-select {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, "Open Sans", "Helvetica Neue", sans-serif));
  font-size: var(--mdc-typography-body1-font-size, 16px);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, 0.03125em);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #4050b6);
}
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #4050b6);
}

.mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-divider::after {
  border-bottom-color: white;
}

.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-autocomplete-panel {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, "Open Sans", "Helvetica Neue", sans-serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  line-height: var(--mdc-typography-subtitle1-line-height, 28px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 24px;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-with-divider-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-dialog-container {
  --mdc-dialog-subhead-font: "Open Sans", "Helvetica Neue", sans-serif;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.0125em;
  --mdc-dialog-supporting-text-font: "Open Sans", "Helvetica Neue", sans-serif;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 16px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0.03125em;
}

.mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #4050b6;
  --mdc-chip-elevated-disabled-container-color: #4050b6;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #eeeeee;
  --mdc-chip-elevated-disabled-container-color: #eeeeee;
  --mdc-chip-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-disabled-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-disabled-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-selected-icon-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}

.mat-mdc-chip-focus-overlay {
  background: black;
}

.mat-mdc-chip {
  height: 32px;
}

.mat-mdc-standard-chip {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "Open Sans", "Helvetica Neue", sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}
.mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #3a49af;
  --mdc-switch-selected-handle-color: #3a49af;
  --mdc-switch-selected-hover-state-layer-color: #3a49af;
  --mdc-switch-selected-pressed-state-layer-color: #3a49af;
  --mdc-switch-selected-focus-handle-color: #1c278e;
  --mdc-switch-selected-hover-handle-color: #1c278e;
  --mdc-switch-selected-pressed-handle-color: #1c278e;
  --mdc-switch-selected-focus-track-color: #7985cc;
  --mdc-switch-selected-hover-track-color: #7985cc;
  --mdc-switch-selected-pressed-track-color: #7985cc;
  --mdc-switch-selected-track-color: #7985cc;
}
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #666666;
  --mdc-switch-selected-handle-color: #666666;
  --mdc-switch-selected-hover-state-layer-color: #666666;
  --mdc-switch-selected-pressed-state-layer-color: #666666;
  --mdc-switch-selected-focus-handle-color: black;
  --mdc-switch-selected-hover-handle-color: black;
  --mdc-switch-selected-pressed-handle-color: black;
  --mdc-switch-selected-focus-track-color: #cccccc;
  --mdc-switch-selected-hover-track-color: #cccccc;
  --mdc-switch-selected-pressed-track-color: #cccccc;
  --mdc-switch-selected-track-color: #cccccc;
}
.mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}

.mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}

.mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "Open Sans", "Helvetica Neue", sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #4050b6;
  --mdc-radio-selected-hover-icon-color: #4050b6;
  --mdc-radio-selected-icon-color: #4050b6;
  --mdc-radio-selected-pressed-icon-color: #4050b6;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #4050b6;
}
.mat-mdc-radio-button.mat-primary .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #eeeeee;
  --mdc-radio-selected-hover-icon-color: #eeeeee;
  --mdc-radio-selected-icon-color: #eeeeee;
  --mdc-radio-selected-pressed-icon-color: #eeeeee;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #eeeeee;
}
.mat-mdc-radio-button.mat-accent .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #f44336;
}
.mat-mdc-radio-button.mat-warn .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-radio-button .mdc-radio {
  padding: calc((40px - 20px) / 2);
}
.mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
  top: calc(-1 * (40px - 20px) / 2);
  left: calc(-1 * (40px - 20px) / 2);
  width: 40px;
  height: 40px;
}
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control {
  top: calc((40px - 40px) / 2);
  right: calc((40px - 40px) / 2);
  left: calc((40px - 40px) / 2);
  width: 40px;
  height: 40px;
}

.mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "Open Sans", "Helvetica Neue", sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #4050b6;
  --mdc-slider-focus-handle-color: #4050b6;
  --mdc-slider-hover-handle-color: #4050b6;
  --mdc-slider-active-track-color: #4050b6;
  --mdc-slider-inactive-track-color: #4050b6;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #4050b6;
  --mat-mdc-slider-ripple-color: #4050b6;
  --mat-mdc-slider-hover-ripple-color: rgba(64, 80, 182, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(64, 80, 182, 0.2);
}
.mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #eeeeee;
  --mdc-slider-focus-handle-color: #eeeeee;
  --mdc-slider-hover-handle-color: #eeeeee;
  --mdc-slider-active-track-color: #eeeeee;
  --mdc-slider-inactive-track-color: #eeeeee;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #eeeeee;
  --mat-mdc-slider-ripple-color: #eeeeee;
  --mat-mdc-slider-hover-ripple-color: rgba(238, 238, 238, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(238, 238, 238, 0.2);
}
.mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mat-mdc-slider-ripple-color: #f44336;
  --mat-mdc-slider-hover-ripple-color: rgba(244, 67, 54, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(244, 67, 54, 0.2);
}

.mat-mdc-slider {
  --mdc-slider-label-label-text-font: "Open Sans", "Helvetica Neue", sans-serif;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 22px;
  --mdc-slider-label-label-text-tracking: 0.0071428571em;
  --mdc-slider-label-label-text-weight: 500;
}

.mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #4050b6);
}
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #4050b6);
}

.mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-divider::after {
  border-bottom-color: white;
}

.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-menu-item[disabled],
.mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38));
}

.mat-mdc-menu-item .mat-icon-no-color,
.mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-mdc-menu-item:hover:not([disabled]),
.mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-mdc-menu-content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, "Open Sans", "Helvetica Neue", sans-serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  line-height: var(--mdc-typography-subtitle1-line-height, 28px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 24px;
}
.mat-mdc-menu-content,
.mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, "Open Sans", "Helvetica Neue", sans-serif));
  font-size: var(--mdc-typography-body1-font-size, 16px);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, 0.03125em);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mat-mdc-list-option .mdc-list-item__start, .mat-mdc-list-option .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #4050b6;
  --mdc-checkbox-selected-hover-icon-color: #4050b6;
  --mdc-checkbox-selected-icon-color: #4050b6;
  --mdc-checkbox-selected-pressed-icon-color: #4050b6;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-list-option .mdc-list-item__start, .mat-mdc-list-option .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #4050b6;
  --mdc-radio-selected-hover-icon-color: #4050b6;
  --mdc-radio-selected-icon-color: #4050b6;
  --mdc-radio-selected-pressed-icon-color: #4050b6;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #4050b6;
}
.mat-mdc-list-option .mdc-list-item__start .mdc-radio--disabled + label, .mat-mdc-list-option .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-list-option.mat-accent .mdc-list-item__start, .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #eeeeee;
  --mdc-checkbox-selected-hover-icon-color: #eeeeee;
  --mdc-checkbox-selected-icon-color: #eeeeee;
  --mdc-checkbox-selected-pressed-icon-color: #eeeeee;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-list-option.mat-accent .mdc-list-item__start, .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #eeeeee;
  --mdc-radio-selected-hover-icon-color: #eeeeee;
  --mdc-radio-selected-icon-color: #eeeeee;
  --mdc-radio-selected-pressed-icon-color: #eeeeee;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #eeeeee;
}
.mat-mdc-list-option.mat-accent .mdc-list-item__start .mdc-radio--disabled + label, .mat-mdc-list-option.mat-accent .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-list-option.mat-warn .mdc-list-item__start, .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-list-option.mat-warn .mdc-list-item__start, .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #f44336;
}
.mat-mdc-list-option.mat-warn .mdc-list-item__start .mdc-radio--disabled + label, .mat-mdc-list-option.mat-warn .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #4050b6;
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #4050b6;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

.mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-font: "Open Sans", "Helvetica Neue", sans-serif;
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 16px;
  --mdc-list-list-item-label-text-tracking: 0.03125em;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: "Open Sans", "Helvetica Neue", sans-serif;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: 0.0178571429em;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: "Open Sans", "Helvetica Neue", sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.0333333333em;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  letter-spacing: 0.009375em;
}

.mat-mdc-paginator {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-mdc-paginator-decrement,
.mat-mdc-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-mdc-paginator-first,
.mat-mdc-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.12);
}

.mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}

.mat-mdc-paginator-container {
  min-height: 56px;
}

.mat-mdc-paginator {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, "Open Sans", "Helvetica Neue", sans-serif));
  font-size: var(--mdc-typography-caption-font-size, 12px);
  line-height: var(--mdc-typography-caption-line-height, 20px);
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: var(--mdc-typography-caption-text-transform, none);
}

.mat-mdc-paginator .mat-mdc-select-value {
  font-size: 12px;
}

.mat-mdc-tab, .mat-mdc-tab-link {
  background-color: transparent;
}
.mat-mdc-tab .mdc-tab__text-label, .mat-mdc-tab-link .mdc-tab__text-label {
  color: rgba(0, 0, 0, 0.6);
}
.mat-mdc-tab.mat-mdc-tab-disabled .mdc-tab__ripple::before,
.mat-mdc-tab.mat-mdc-tab-disabled .mat-ripple-element, .mat-mdc-tab-link.mat-mdc-tab-disabled .mdc-tab__ripple::before,
.mat-mdc-tab-link.mat-mdc-tab-disabled .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #4050b6;
}
.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #4050b6);
}
.mdc-tab__ripple::before,
.mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-link .mat-ripple-element {
  background-color: #4050b6;
}

.mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #eeeeee;
}
.mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #eeeeee);
}
.mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element, .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element {
  background-color: #eeeeee;
}
.mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #f44336;
}
.mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #f44336);
}
.mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element, .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element {
  background-color: #f44336;
}

.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-mdc-tab-header-with-background-background-color: #4050b6;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-mdc-tab-header-with-background-background-color: #eeeeee;
  --mat-mdc-tab-header-with-background-foreground-color: #000;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-mdc-tab-header-with-background-background-color: #f44336;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}

.mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-on-surface, #000);
}

.mat-mdc-tab-header .mdc-tab {
  height: 48px;
}

.mdc-tab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, "Open Sans", "Helvetica Neue", sans-serif));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 36px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-checkbox .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-checkbox .mdc-checkbox__ripple {
  background: #000;
}
.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #4050b6;
  --mdc-checkbox-selected-hover-icon-color: #4050b6;
  --mdc-checkbox-selected-icon-color: #4050b6;
  --mdc-checkbox-selected-pressed-icon-color: #4050b6;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(64, 80, 182, 0.1);
}
.mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #4050b6;
}
.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #eeeeee;
  --mdc-checkbox-selected-hover-icon-color: #eeeeee;
  --mdc-checkbox-selected-icon-color: #eeeeee;
  --mdc-checkbox-selected-pressed-icon-color: #eeeeee;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(238, 238, 238, 0.1);
}
.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #eeeeee;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(244, 67, 54, 0.1);
}
.mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #f44336;
}

.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-checkbox .mdc-checkbox {
  padding: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
  margin: calc((var(--mdc-checkbox-touch-target-size, 40px) - 40px) / 2);
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
  top: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
  left: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control {
  top: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  right: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  left: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  width: var(--mdc-checkbox-touch-target-size, 40px);
  height: var(--mdc-checkbox-touch-target-size, 40px);
}

@media all and (-ms-high-contrast: none) {
  .mdc-checkbox .mdc-checkbox__focus-ring {
    display: none;
  }
}

.mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "Open Sans", "Helvetica Neue", sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #4050b6;
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #eeeeee;
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #4050b6;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #eeeeee;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #4050b6;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #eeeeee;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}

.mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #4050b6;
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #eeeeee;
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
}
.mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-button, .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button.mat-primary, .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #4050b6;
  --mat-mdc-button-ripple-color: rgba(64, 80, 182, 0.1);
}
.mat-mdc-button.mat-accent, .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #eeeeee;
  --mat-mdc-button-ripple-color: rgba(238, 238, 238, 0.1);
}
.mat-mdc-button.mat-warn, .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}

.mat-mdc-raised-button, .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button.mat-primary, .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent, .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-warn, .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}

.mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, "Open Sans", "Helvetica Neue", sans-serif));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 36px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}

.mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #4050b6;
  --mat-mdc-button-ripple-color: rgba(64, 80, 182, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #eeeeee;
  --mat-mdc-button-ripple-color: rgba(238, 238, 238, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #4050b6;
}
.mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #eeeeee;
}
.mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
}
.mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  width: 48px;
  height: 48px;
  padding: 12px;
}
.mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 48px;
  max-width: 48px;
}
.mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}
.mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 48px;
  transform: translate(-50%, -50%);
}

.mat-mdc-fab, .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab.mat-primary, .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-accent, .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab.mat-warn, .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-unthemed, .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #fff;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-primary, .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #4050b6;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab.mat-accent, .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #eeeeee;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-warn, .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab[disabled][disabled], .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}

.mdc-fab--extended {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, "Open Sans", "Helvetica Neue", sans-serif));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 36px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: #eeeeee;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-supporting-text-font: "Open Sans", "Helvetica Neue", sans-serif;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

.mdc-data-table {
  background-color: var(--mdc-theme-surface, #fff);
  border-color: rgba(0, 0, 0, 0.12);
}
.mdc-data-table__row {
  background-color: inherit;
}

.mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #fff);
}

.mdc-data-table__row--selected {
  background-color: rgba(64, 80, 182, 0.04);
}

.mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12);
}
.mdc-data-table__cell,
.mdc-data-table__header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mdc-data-table__pagination {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-data-table__pagination-total,
.mdc-data-table__pagination-rows-per-page-label,
.mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.87);
}

[dir=rtl] .mdc-data-table__pagination-button .mdc-button__icon, .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mat-mdc-table {
  background: white;
}

.mat-mdc-table .mdc-data-table__row {
  height: 52px;
}
.mat-mdc-table .mdc-data-table__pagination {
  min-height: 52px;
}
.mat-mdc-table .mdc-data-table__header-row {
  height: 56px;
}

.mdc-data-table__content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "Open Sans", "Helvetica Neue", sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mdc-data-table__cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "Open Sans", "Helvetica Neue", sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mdc-data-table__header-cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, "Open Sans", "Helvetica Neue", sans-serif));
  font-size: var(--mdc-typography-subtitle2-font-size, 14px);
  line-height: var(--mdc-typography-subtitle2-line-height, 22px);
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, 0.0071428571em);
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle2-text-transform, none);
}

[dir=rtl] .mdc-data-table__pagination-button .mdc-button__icon, .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #4050b6;
}
.mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #eeeeee;
}
.mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: white;
  background: #4050b6;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #eeeeee;
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-bottom-sheet-container {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  letter-spacing: 0.0178571429em;
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-button-toggle {
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(64, 80, 182, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(64, 80, 182, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(64, 80, 182, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #4050b6;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(64, 80, 182, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(64, 80, 182, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(64, 80, 182, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(238, 238, 238, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(238, 238, 238, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(238, 238, 238, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(238, 238, 238, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(238, 238, 238, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(238, 238, 238, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #4050b6;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #eeeeee;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px;
  height: 40px;
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 40px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 40px;
  transform: translate(-50%, -50%);
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

.mat-calendar {
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-expansion-panel-header {
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-expansion-panel-content {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  letter-spacing: 0.0178571429em;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

.mat-icon.mat-primary {
  color: #4050b6;
}
.mat-icon.mat-accent {
  color: #eeeeee;
}
.mat-icon.mat-warn {
  color: #f44336;
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #4050b6;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 16px;
}

.mat-step-label-selected {
  font-size: 16px;
  font-weight: 400;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #4050b6;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #eeeeee;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  letter-spacing: 0.0125em;
  margin: 0;
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-tree {
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.user-options .material-icons,
.user-options .material-icons-outlined,
.user-options .material-icons-round,
.user-options .material-icons-sharp {
  color: #fff;
}

.material-icons,
.material-icons-outlined,
.material-icons-round,
.material-icons-sharp {
  color: #999999;
}
.material-icons.secondary,
.material-icons-outlined.secondary,
.material-icons-round.secondary,
.material-icons-sharp.secondary {
  color: #fff;
}
.material-icons.favorable-report,
.material-icons-outlined.favorable-report,
.material-icons-round.favorable-report,
.material-icons-sharp.favorable-report {
  color: #0a8407;
}
.material-icons.favorable-but-errors-report,
.material-icons-outlined.favorable-but-errors-report,
.material-icons-round.favorable-but-errors-report,
.material-icons-sharp.favorable-but-errors-report {
  color: #e58824;
}
.material-icons.unfavorable-report,
.material-icons-outlined.unfavorable-report,
.material-icons-round.unfavorable-report,
.material-icons-sharp.unfavorable-report {
  color: #e80202;
}

.mat-mdc-tab-link.mdc-tab-indicator--active.mat-mdc-tab-disabled .mdc-tab-indicator__content--underline {
  border-color: #4050b6;
}

.mat-mdc-button:not(:disabled) {
  background-color: #4050b6;
  color: white;
}

.ppc-module .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.ppc-module .mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.ppc-module .mat-mdc-option:hover:not(.mdc-list-item--disabled), .ppc-module .mat-mdc-option:focus:not(.mdc-list-item--disabled), .ppc-module .mat-mdc-option.mat-mdc-option-active, .ppc-module .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.ppc-module .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #9e5a9e);
}
.ppc-module .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #eeeeee);
}
.ppc-module .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #f44336);
}
.ppc-module .mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.ppc-module .mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.ppc-module .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.ppc-module .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.ppc-module .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #9e5a9e;
}
.ppc-module .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.ppc-module .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #9e5a9e;
}
.ppc-module .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.ppc-module .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.ppc-module .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.ppc-module .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #eeeeee;
}
.ppc-module .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.ppc-module .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #eeeeee;
}
.ppc-module .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.ppc-module .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.ppc-module .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.ppc-module .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #eeeeee;
}
.ppc-module .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.ppc-module .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #eeeeee;
}
.ppc-module .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.ppc-module .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.ppc-module .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.ppc-module .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
.ppc-module .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.ppc-module .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
.ppc-module .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.ppc-module .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.ppc-module .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.ppc-module .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.ppc-module .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.ppc-module .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}
.ppc-module .mat-app-background, .ppc-module.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.ppc-module .mat-elevation-z0, .ppc-module .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-elevation-z1, .ppc-module .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-elevation-z2, .ppc-module .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-elevation-z3, .ppc-module .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-elevation-z4, .ppc-module .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-elevation-z5, .ppc-module .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-elevation-z6, .ppc-module .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-elevation-z7, .ppc-module .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-elevation-z8, .ppc-module .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-elevation-z9, .ppc-module .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-elevation-z10, .ppc-module .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-elevation-z11, .ppc-module .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-elevation-z12, .ppc-module .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-elevation-z13, .ppc-module .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-elevation-z14, .ppc-module .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-elevation-z15, .ppc-module .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-elevation-z16, .ppc-module .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-elevation-z17, .ppc-module .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-elevation-z18, .ppc-module .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-elevation-z19, .ppc-module .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-elevation-z20, .ppc-module .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-elevation-z21, .ppc-module .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-elevation-z22, .ppc-module .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-elevation-z23, .ppc-module .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-elevation-z24, .ppc-module .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.ppc-module .mat-mdc-card {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-color: #fff;
}
.ppc-module .mat-mdc-card-outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-outline-color: #e0e0e0;
}
.ppc-module .mat-mdc-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.ppc-module .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #9e5a9e;
}
.ppc-module .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(158, 90, 158, 0.25)'/%3E%3C/svg%3E");
}
.ppc-module .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(158, 90, 158, 0.25);
}
.ppc-module .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #eeeeee;
}
.ppc-module .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(238, 238, 238, 0.25)'/%3E%3C/svg%3E");
}
.ppc-module .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(238, 238, 238, 0.25);
}
.ppc-module .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
}
.ppc-module .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(244, 67, 54, 0.25)'/%3E%3C/svg%3E");
}
.ppc-module .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(244, 67, 54, 0.25);
}
.ppc-module .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}
.ppc-module .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.ppc-module .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
@media all {
  .ppc-module .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
@media all {
  .ppc-module .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
.ppc-module .mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #9e5a9e);
}
.ppc-module .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.ppc-module .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.ppc-module .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.ppc-module .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.ppc-module .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.ppc-module .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.ppc-module .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.ppc-module .mdc-text-field--filled .mdc-text-field__ripple::before,
.ppc-module .mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.ppc-module .mdc-text-field--filled:hover .mdc-text-field__ripple::before, .ppc-module .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.ppc-module .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .ppc-module .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.ppc-module .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.ppc-module .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.ppc-module .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.ppc-module .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #9e5a9e);
}
.ppc-module .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.ppc-module .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.ppc-module .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.ppc-module .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.ppc-module .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.ppc-module .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.ppc-module .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.ppc-module .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.ppc-module .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #9e5a9e);
}
[dir=rtl] .ppc-module .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .ppc-module .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .ppc-module .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .ppc-module .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.ppc-module .mdc-text-field--outlined .mdc-text-field__ripple::before,
.ppc-module .mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .ppc-module .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .ppc-module .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.ppc-module .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(158, 90, 158, 0.87);
}
.ppc-module .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.ppc-module .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.ppc-module .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.ppc-module .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.ppc-module .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.ppc-module .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.ppc-module .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.ppc-module .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.ppc-module .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.ppc-module .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.ppc-module .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.ppc-module .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.ppc-module .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.ppc-module .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.ppc-module .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.ppc-module .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.ppc-module .mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}
@media all {
  .ppc-module .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .ppc-module .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.ppc-module .mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.ppc-module .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.ppc-module .mdc-text-field--disabled .mdc-text-field-character-counter,
.ppc-module .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.ppc-module .mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.ppc-module .mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.ppc-module .mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.ppc-module .mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.ppc-module .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.ppc-module .mdc-text-field--disabled .mdc-notched-outline__leading,
.ppc-module .mdc-text-field--disabled .mdc-notched-outline__notch,
.ppc-module .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .ppc-module .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .ppc-module .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .ppc-module .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .ppc-module .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .ppc-module .mdc-text-field--disabled .mdc-text-field-character-counter,
  .ppc-module .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .ppc-module .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .ppc-module .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .ppc-module .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .ppc-module .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .ppc-module .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .ppc-module .mdc-text-field--disabled .mdc-notched-outline__leading,
  .ppc-module .mdc-text-field--disabled .mdc-notched-outline__notch,
  .ppc-module .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.ppc-module .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fafafa;
}
[dir=rtl] .ppc-module .mdc-text-field--end-aligned .mdc-text-field__input, .ppc-module .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .ppc-module .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .ppc-module .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.ppc-module .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #f44336);
}
.ppc-module .mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}
.ppc-module .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}
.ppc-module .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}
.ppc-module .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.ppc-module .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(158, 90, 158, 0.87);
}
.ppc-module .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(238, 238, 238, 0.87);
}
.ppc-module .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(244, 67, 54, 0.87);
}
.ppc-module .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.ppc-module .mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #eeeeee);
}
.ppc-module .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #eeeeee);
}
.ppc-module .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(238, 238, 238, 0.87);
}
.ppc-module .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.ppc-module .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.ppc-module .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.ppc-module .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.ppc-module .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.ppc-module .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.ppc-module .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.ppc-module .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.ppc-module .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.ppc-module .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.ppc-module .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.ppc-module .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.ppc-module .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.ppc-module .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.ppc-module .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.ppc-module .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.ppc-module .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.ppc-module .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.ppc-module .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #eeeeee);
}
.ppc-module .mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.ppc-module .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.ppc-module .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(244, 67, 54, 0.87);
}
.ppc-module .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.ppc-module .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.ppc-module .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.ppc-module .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.ppc-module .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.ppc-module .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.ppc-module .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.ppc-module .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.ppc-module .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.ppc-module .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.ppc-module .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.ppc-module .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.ppc-module .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.ppc-module .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.ppc-module .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.ppc-module .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.ppc-module .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.ppc-module .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.ppc-module .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.ppc-module .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.ppc-module [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.ppc-module .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.ppc-module .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.ppc-module .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.ppc-module .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.ppc-module .mdc-list-item--with-leading-icon .mdc-list-item__start,
.ppc-module .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.ppc-module .mdc-list-item--with-leading-icon .mdc-list-item__start,
.ppc-module .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.ppc-module .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.ppc-module .mdc-list-item--disabled .mdc-list-item__start,
.ppc-module .mdc-list-item--disabled .mdc-list-item__content,
.ppc-module .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.ppc-module .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.ppc-module .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.ppc-module .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.ppc-module .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.ppc-module .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.ppc-module .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.ppc-module .mdc-list-item--selected .mdc-list-item__primary-text,
.ppc-module .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #9e5a9e);
}
.ppc-module .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.ppc-module .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #9e5a9e);
}
.ppc-module .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.ppc-module .mdc-list-divider::after {
  border-bottom-color: white;
}
.ppc-module .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.ppc-module .mat-mdc-select-placeholder {
  color: rgba(0, 0, 0, 0.6);
}
.ppc-module .mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.ppc-module .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.ppc-module .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(158, 90, 158, 0.87);
}
.ppc-module .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(238, 238, 238, 0.87);
}
.ppc-module .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(244, 67, 54, 0.87);
}
.ppc-module .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(244, 67, 54, 0.87);
}
.ppc-module .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.ppc-module .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.ppc-module .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.ppc-module .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.ppc-module .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.ppc-module .mdc-list-item--with-leading-icon .mdc-list-item__start,
.ppc-module .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.ppc-module .mdc-list-item--with-leading-icon .mdc-list-item__start,
.ppc-module .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.ppc-module .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.ppc-module .mdc-list-item--disabled .mdc-list-item__start,
.ppc-module .mdc-list-item--disabled .mdc-list-item__content,
.ppc-module .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.ppc-module .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.ppc-module .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.ppc-module .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.ppc-module .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.ppc-module .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.ppc-module .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.ppc-module .mdc-list-item--selected .mdc-list-item__primary-text,
.ppc-module .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #9e5a9e);
}
.ppc-module .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.ppc-module .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #9e5a9e);
}
.ppc-module .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.ppc-module .mdc-list-divider::after {
  border-bottom-color: white;
}
.ppc-module .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-with-divider-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}
.ppc-module .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
}
.ppc-module .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .ppc-module .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #9e5a9e;
  --mdc-chip-elevated-disabled-container-color: #9e5a9e;
  --mdc-chip-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-disabled-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-disabled-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-selected-icon-color: rgba(0, 0, 0, 0.87);
}
.ppc-module .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .ppc-module .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #eeeeee;
  --mdc-chip-elevated-disabled-container-color: #eeeeee;
  --mdc-chip-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-disabled-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-disabled-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-selected-icon-color: rgba(0, 0, 0, 0.87);
}
.ppc-module .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .ppc-module .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.ppc-module .mat-mdc-chip-focus-overlay {
  background: black;
}
.ppc-module .mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}
.ppc-module .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.ppc-module .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.ppc-module .mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #824087;
  --mdc-switch-selected-handle-color: #824087;
  --mdc-switch-selected-hover-state-layer-color: #824087;
  --mdc-switch-selected-pressed-state-layer-color: #824087;
  --mdc-switch-selected-focus-handle-color: #4c2864;
  --mdc-switch-selected-hover-handle-color: #4c2864;
  --mdc-switch-selected-pressed-handle-color: #4c2864;
  --mdc-switch-selected-focus-track-color: #af74af;
  --mdc-switch-selected-hover-track-color: #af74af;
  --mdc-switch-selected-pressed-track-color: #af74af;
  --mdc-switch-selected-track-color: #af74af;
}
.ppc-module .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #666666;
  --mdc-switch-selected-handle-color: #666666;
  --mdc-switch-selected-hover-state-layer-color: #666666;
  --mdc-switch-selected-pressed-state-layer-color: #666666;
  --mdc-switch-selected-focus-handle-color: black;
  --mdc-switch-selected-hover-handle-color: black;
  --mdc-switch-selected-pressed-handle-color: black;
  --mdc-switch-selected-focus-track-color: #cccccc;
  --mdc-switch-selected-hover-track-color: #cccccc;
  --mdc-switch-selected-pressed-track-color: #cccccc;
  --mdc-switch-selected-track-color: #cccccc;
}
.ppc-module .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}
.ppc-module .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.ppc-module .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #9e5a9e;
  --mdc-radio-selected-hover-icon-color: #9e5a9e;
  --mdc-radio-selected-icon-color: #9e5a9e;
  --mdc-radio-selected-pressed-icon-color: #9e5a9e;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #9e5a9e;
}
.ppc-module .mat-mdc-radio-button.mat-primary .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.ppc-module .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #eeeeee;
  --mdc-radio-selected-hover-icon-color: #eeeeee;
  --mdc-radio-selected-icon-color: #eeeeee;
  --mdc-radio-selected-pressed-icon-color: #eeeeee;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #eeeeee;
}
.ppc-module .mat-mdc-radio-button.mat-accent .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.ppc-module .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #f44336;
}
.ppc-module .mat-mdc-radio-button.mat-warn .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.ppc-module .mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.ppc-module .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #9e5a9e;
  --mdc-slider-focus-handle-color: #9e5a9e;
  --mdc-slider-hover-handle-color: #9e5a9e;
  --mdc-slider-active-track-color: #9e5a9e;
  --mdc-slider-inactive-track-color: #9e5a9e;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #9e5a9e;
  --mat-mdc-slider-ripple-color: #9e5a9e;
  --mat-mdc-slider-hover-ripple-color: rgba(158, 90, 158, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(158, 90, 158, 0.2);
}
.ppc-module .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #eeeeee;
  --mdc-slider-focus-handle-color: #eeeeee;
  --mdc-slider-hover-handle-color: #eeeeee;
  --mdc-slider-active-track-color: #eeeeee;
  --mdc-slider-inactive-track-color: #eeeeee;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #eeeeee;
  --mat-mdc-slider-ripple-color: #eeeeee;
  --mat-mdc-slider-hover-ripple-color: rgba(238, 238, 238, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(238, 238, 238, 0.2);
}
.ppc-module .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mat-mdc-slider-ripple-color: #f44336;
  --mat-mdc-slider-hover-ripple-color: rgba(244, 67, 54, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(244, 67, 54, 0.2);
}
.ppc-module .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.ppc-module .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.ppc-module .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.ppc-module .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.ppc-module .mdc-list-item--with-leading-icon .mdc-list-item__start,
.ppc-module .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.ppc-module .mdc-list-item--with-leading-icon .mdc-list-item__start,
.ppc-module .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.ppc-module .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.ppc-module .mdc-list-item--disabled .mdc-list-item__start,
.ppc-module .mdc-list-item--disabled .mdc-list-item__content,
.ppc-module .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.ppc-module .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.ppc-module .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.ppc-module .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.ppc-module .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.ppc-module .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.ppc-module .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.ppc-module .mdc-list-item--selected .mdc-list-item__primary-text,
.ppc-module .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #9e5a9e);
}
.ppc-module .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.ppc-module .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #9e5a9e);
}
.ppc-module .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.ppc-module .mdc-list-divider::after {
  border-bottom-color: white;
}
.ppc-module .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-mdc-menu-item[disabled],
.ppc-module .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.ppc-module .mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38));
}
.ppc-module .mat-mdc-menu-item .mat-icon-no-color,
.ppc-module .mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.ppc-module .mat-mdc-menu-item:hover:not([disabled]),
.ppc-module .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.ppc-module .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.ppc-module .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.ppc-module .mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}
.ppc-module .mat-mdc-list-option .mdc-list-item__start, .ppc-module .mat-mdc-list-option .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #9e5a9e;
  --mdc-checkbox-selected-hover-icon-color: #9e5a9e;
  --mdc-checkbox-selected-icon-color: #9e5a9e;
  --mdc-checkbox-selected-pressed-icon-color: #9e5a9e;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.ppc-module .mat-mdc-list-option .mdc-list-item__start, .ppc-module .mat-mdc-list-option .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #9e5a9e;
  --mdc-radio-selected-hover-icon-color: #9e5a9e;
  --mdc-radio-selected-icon-color: #9e5a9e;
  --mdc-radio-selected-pressed-icon-color: #9e5a9e;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #9e5a9e;
}
.ppc-module .mat-mdc-list-option .mdc-list-item__start .mdc-radio--disabled + label, .ppc-module .mat-mdc-list-option .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.ppc-module .mat-mdc-list-option.mat-accent .mdc-list-item__start, .ppc-module .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #eeeeee;
  --mdc-checkbox-selected-hover-icon-color: #eeeeee;
  --mdc-checkbox-selected-icon-color: #eeeeee;
  --mdc-checkbox-selected-pressed-icon-color: #eeeeee;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.ppc-module .mat-mdc-list-option.mat-accent .mdc-list-item__start, .ppc-module .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #eeeeee;
  --mdc-radio-selected-hover-icon-color: #eeeeee;
  --mdc-radio-selected-icon-color: #eeeeee;
  --mdc-radio-selected-pressed-icon-color: #eeeeee;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #eeeeee;
}
.ppc-module .mat-mdc-list-option.mat-accent .mdc-list-item__start .mdc-radio--disabled + label, .ppc-module .mat-mdc-list-option.mat-accent .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.ppc-module .mat-mdc-list-option.mat-warn .mdc-list-item__start, .ppc-module .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.ppc-module .mat-mdc-list-option.mat-warn .mdc-list-item__start, .ppc-module .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #f44336;
}
.ppc-module .mat-mdc-list-option.mat-warn .mdc-list-item__start .mdc-radio--disabled + label, .ppc-module .mat-mdc-list-option.mat-warn .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.ppc-module .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.ppc-module .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #9e5a9e;
}
.ppc-module .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.ppc-module .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #9e5a9e;
}
.ppc-module .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.ppc-module .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.ppc-module .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.ppc-module .mat-mdc-paginator {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.ppc-module .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.54);
}
.ppc-module .mat-mdc-paginator-decrement,
.ppc-module .mat-mdc-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.ppc-module .mat-mdc-paginator-first,
.ppc-module .mat-mdc-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.ppc-module .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.ppc-module .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.ppc-module .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.ppc-module .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-mdc-tab, .ppc-module .mat-mdc-tab-link {
  background-color: transparent;
}
.ppc-module .mat-mdc-tab .mdc-tab__text-label, .ppc-module .mat-mdc-tab-link .mdc-tab__text-label {
  color: rgba(0, 0, 0, 0.6);
}
.ppc-module .mat-mdc-tab.mat-mdc-tab-disabled .mdc-tab__ripple::before,
.ppc-module .mat-mdc-tab.mat-mdc-tab-disabled .mat-ripple-element, .ppc-module .mat-mdc-tab-link.mat-mdc-tab-disabled .mdc-tab__ripple::before,
.ppc-module .mat-mdc-tab-link.mat-mdc-tab-disabled .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.38);
}
.ppc-module .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .ppc-module .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #9e5a9e;
}
.ppc-module .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .ppc-module .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #9e5a9e);
}
.ppc-module .mdc-tab__ripple::before,
.ppc-module .mat-mdc-tab .mat-ripple-element,
.ppc-module .mat-mdc-tab-header-pagination .mat-ripple-element,
.ppc-module .mat-mdc-tab-link .mat-ripple-element {
  background-color: #9e5a9e;
}
.ppc-module .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .ppc-module .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .ppc-module .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .ppc-module .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #eeeeee;
}
.ppc-module .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .ppc-module .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .ppc-module .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .ppc-module .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #eeeeee);
}
.ppc-module .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.ppc-module .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.ppc-module .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.ppc-module .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element, .ppc-module .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.ppc-module .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.ppc-module .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.ppc-module .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element {
  background-color: #eeeeee;
}
.ppc-module .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .ppc-module .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .ppc-module .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .ppc-module .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #f44336;
}
.ppc-module .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .ppc-module .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .ppc-module .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .ppc-module .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #f44336);
}
.ppc-module .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.ppc-module .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.ppc-module .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.ppc-module .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element, .ppc-module .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.ppc-module .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.ppc-module .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.ppc-module .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element {
  background-color: #f44336;
}
.ppc-module .mat-mdc-tab-group.mat-background-primary, .ppc-module .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-mdc-tab-header-with-background-background-color: #9e5a9e;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.ppc-module .mat-mdc-tab-group.mat-background-accent, .ppc-module .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-mdc-tab-header-with-background-background-color: #eeeeee;
  --mat-mdc-tab-header-with-background-foreground-color: #000;
}
.ppc-module .mat-mdc-tab-group.mat-background-warn, .ppc-module .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-mdc-tab-header-with-background-background-color: #f44336;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.ppc-module .mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-on-surface, #000);
}
.ppc-module .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.ppc-module .mat-mdc-checkbox .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.ppc-module .mat-mdc-checkbox .mdc-checkbox__ripple {
  background: #000;
}
.ppc-module .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #9e5a9e;
  --mdc-checkbox-selected-hover-icon-color: #9e5a9e;
  --mdc-checkbox-selected-icon-color: #9e5a9e;
  --mdc-checkbox-selected-pressed-icon-color: #9e5a9e;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.ppc-module .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(158, 90, 158, 0.1);
}
.ppc-module .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #9e5a9e;
}
.ppc-module .mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #eeeeee;
  --mdc-checkbox-selected-hover-icon-color: #eeeeee;
  --mdc-checkbox-selected-icon-color: #eeeeee;
  --mdc-checkbox-selected-pressed-icon-color: #eeeeee;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.ppc-module .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(238, 238, 238, 0.1);
}
.ppc-module .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #eeeeee;
}
.ppc-module .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.ppc-module .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(244, 67, 54, 0.1);
}
.ppc-module .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #f44336;
}
.ppc-module .mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}
.ppc-module .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.ppc-module .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #9e5a9e;
}
.ppc-module .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #eeeeee;
}
.ppc-module .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.ppc-module .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.ppc-module .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.ppc-module .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #9e5a9e;
  --mdc-filled-button-label-text-color: #fff;
}
.ppc-module .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #eeeeee;
  --mdc-filled-button-label-text-color: #000;
}
.ppc-module .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
}
.ppc-module .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.ppc-module .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.ppc-module .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #9e5a9e;
  --mdc-protected-button-label-text-color: #fff;
}
.ppc-module .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #eeeeee;
  --mdc-protected-button-label-text-color: #000;
}
.ppc-module .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
}
.ppc-module .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.ppc-module .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.ppc-module .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #9e5a9e;
}
.ppc-module .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #eeeeee;
}
.ppc-module .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
}
.ppc-module .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-mdc-button, .ppc-module .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.ppc-module .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .ppc-module .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.ppc-module .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .ppc-module .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .ppc-module .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .ppc-module .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.ppc-module .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .ppc-module .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.ppc-module .mat-mdc-button.mat-primary, .ppc-module .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #9e5a9e;
  --mat-mdc-button-ripple-color: rgba(158, 90, 158, 0.1);
}
.ppc-module .mat-mdc-button.mat-accent, .ppc-module .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #eeeeee;
  --mat-mdc-button-ripple-color: rgba(238, 238, 238, 0.1);
}
.ppc-module .mat-mdc-button.mat-warn, .ppc-module .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.ppc-module .mat-mdc-raised-button, .ppc-module .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.ppc-module .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .ppc-module .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.ppc-module .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .ppc-module .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .ppc-module .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .ppc-module .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.ppc-module .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .ppc-module .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.ppc-module .mat-mdc-raised-button.mat-primary, .ppc-module .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.ppc-module .mat-mdc-raised-button.mat-accent, .ppc-module .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.ppc-module .mat-mdc-raised-button.mat-warn, .ppc-module .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.ppc-module .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.ppc-module .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.ppc-module .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .ppc-module .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.ppc-module .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.ppc-module .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #9e5a9e;
  --mat-mdc-button-ripple-color: rgba(158, 90, 158, 0.1);
}
.ppc-module .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #eeeeee;
  --mat-mdc-button-ripple-color: rgba(238, 238, 238, 0.1);
}
.ppc-module .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.ppc-module .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #9e5a9e;
}
.ppc-module .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #eeeeee;
}
.ppc-module .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
}
.ppc-module .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
.ppc-module .mat-mdc-fab, .ppc-module .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.ppc-module .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .ppc-module .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.ppc-module .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .ppc-module .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .ppc-module .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .ppc-module .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.ppc-module .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .ppc-module .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.ppc-module .mat-mdc-fab.mat-primary, .ppc-module .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.ppc-module .mat-mdc-fab.mat-accent, .ppc-module .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.ppc-module .mat-mdc-fab.mat-warn, .ppc-module .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.ppc-module .mat-mdc-fab.mat-unthemed, .ppc-module .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #fff;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.ppc-module .mat-mdc-fab.mat-primary, .ppc-module .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #9e5a9e;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.ppc-module .mat-mdc-fab.mat-accent, .ppc-module .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #eeeeee;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.ppc-module .mat-mdc-fab.mat-warn, .ppc-module .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.ppc-module .mat-mdc-fab[disabled][disabled], .ppc-module .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.ppc-module .mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: #eeeeee;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
}
.ppc-module .mdc-data-table {
  background-color: var(--mdc-theme-surface, #fff);
  border-color: rgba(0, 0, 0, 0.12);
}
.ppc-module .mdc-data-table__row {
  background-color: inherit;
}
.ppc-module .mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #fff);
}
.ppc-module .mdc-data-table__row--selected {
  background-color: rgba(158, 90, 158, 0.04);
}
.ppc-module .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.ppc-module .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.ppc-module .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12);
}
.ppc-module .mdc-data-table__cell,
.ppc-module .mdc-data-table__header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.ppc-module .mdc-data-table__pagination {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.ppc-module .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.ppc-module .mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.87);
}
.ppc-module .mdc-data-table__pagination-total,
.ppc-module .mdc-data-table__pagination-rows-per-page-label,
.ppc-module .mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.87);
}
[dir=rtl] .ppc-module .mdc-data-table__pagination-button .mdc-button__icon, .ppc-module .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.ppc-module .mat-mdc-table {
  background: white;
}
.ppc-module .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #9e5a9e;
}
.ppc-module .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #eeeeee;
}
.ppc-module .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}
.ppc-module .mat-badge-content {
  color: rgba(0, 0, 0, 0.87);
  background: #9e5a9e;
}
.cdk-high-contrast-active .ppc-module .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.ppc-module .mat-badge-accent .mat-badge-content {
  background: #eeeeee;
  color: rgba(0, 0, 0, 0.87);
}
.ppc-module .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.ppc-module .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.ppc-module .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.ppc-module .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.ppc-module .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.ppc-module .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.ppc-module .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.ppc-module .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.ppc-module .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.ppc-module .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.ppc-module [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.ppc-module .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.ppc-module .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.ppc-module .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.ppc-module .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.ppc-module .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.ppc-module .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.ppc-module .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.ppc-module .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.ppc-module .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.ppc-module .mat-datepicker-toggle,
.ppc-module .mat-datepicker-content .mat-calendar-next-button,
.ppc-module .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.ppc-module .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-calendar-table-header,
.ppc-module .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.ppc-module .mat-calendar-body-cell-content,
.ppc-module .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.ppc-module .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.ppc-module .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.ppc-module .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.ppc-module .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.ppc-module .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.ppc-module .mat-calendar-body-in-range::before {
  background: rgba(158, 90, 158, 0.2);
}
.ppc-module .mat-calendar-body-comparison-identical,
.ppc-module .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.ppc-module .mat-calendar-body-comparison-bridge-start::before,
.ppc-module [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(158, 90, 158, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.ppc-module .mat-calendar-body-comparison-bridge-end::before,
.ppc-module [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(158, 90, 158, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.ppc-module .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.ppc-module .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.ppc-module .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.ppc-module .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.ppc-module .mat-calendar-body-selected {
  background-color: #9e5a9e;
  color: rgba(0, 0, 0, 0.87);
}
.ppc-module .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(158, 90, 158, 0.4);
}
.ppc-module .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.ppc-module .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.ppc-module .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(158, 90, 158, 0.3);
}
@media (hover: hover) {
  .ppc-module .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(158, 90, 158, 0.3);
  }
}
.ppc-module .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.ppc-module .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(238, 238, 238, 0.2);
}
.ppc-module .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.ppc-module .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.ppc-module .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.ppc-module .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(238, 238, 238, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.ppc-module .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.ppc-module .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(238, 238, 238, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.ppc-module .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.ppc-module .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.ppc-module .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.ppc-module .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.ppc-module .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.87);
}
.ppc-module .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(238, 238, 238, 0.4);
}
.ppc-module .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.ppc-module .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.ppc-module .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(238, 238, 238, 0.3);
}
@media (hover: hover) {
  .ppc-module .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(238, 238, 238, 0.3);
  }
}
.ppc-module .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.ppc-module .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.ppc-module .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.ppc-module .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.ppc-module .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.ppc-module .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.ppc-module .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.ppc-module .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.ppc-module .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.ppc-module .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.ppc-module .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.ppc-module .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.ppc-module .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.ppc-module .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.ppc-module .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.ppc-module .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .ppc-module .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}
.ppc-module .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-datepicker-toggle-active {
  color: #9e5a9e;
}
.ppc-module .mat-datepicker-toggle-active.mat-accent {
  color: #eeeeee;
}
.ppc-module .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.ppc-module .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.ppc-module .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.ppc-module .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .ppc-module .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .ppc-module .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .ppc-module .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.ppc-module .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.ppc-module .mat-expansion-panel-header-description,
.ppc-module .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.ppc-module .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.ppc-module .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.ppc-module .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.ppc-module .mat-icon.mat-primary {
  color: #9e5a9e;
}
.ppc-module .mat-icon.mat-accent {
  color: #eeeeee;
}
.ppc-module .mat-icon.mat-warn {
  color: #f44336;
}
.ppc-module .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.ppc-module .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.ppc-module .mat-drawer.mat-drawer-push {
  background-color: white;
}
.ppc-module .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.ppc-module [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.ppc-module [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.ppc-module .mat-step-header.cdk-keyboard-focused, .ppc-module .mat-step-header.cdk-program-focused, .ppc-module .mat-step-header:hover:not([aria-disabled]), .ppc-module .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.ppc-module .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .ppc-module .mat-step-header:hover {
    background: none;
  }
}
.ppc-module .mat-step-header .mat-step-label,
.ppc-module .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.ppc-module .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: rgba(0, 0, 0, 0.87);
}
.ppc-module .mat-step-header .mat-step-icon-selected,
.ppc-module .mat-step-header .mat-step-icon-state-done,
.ppc-module .mat-step-header .mat-step-icon-state-edit {
  background-color: #9e5a9e;
  color: rgba(0, 0, 0, 0.87);
}
.ppc-module .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.ppc-module .mat-step-header.mat-accent .mat-step-icon-selected,
.ppc-module .mat-step-header.mat-accent .mat-step-icon-state-done,
.ppc-module .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.87);
}
.ppc-module .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.ppc-module .mat-step-header.mat-warn .mat-step-icon-selected,
.ppc-module .mat-step-header.mat-warn .mat-step-icon-state-done,
.ppc-module .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.ppc-module .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.ppc-module .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.ppc-module .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.ppc-module .mat-stepper-horizontal, .ppc-module .mat-stepper-vertical {
  background-color: white;
}
.ppc-module .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-horizontal-stepper-header::before,
.ppc-module .mat-horizontal-stepper-header::after,
.ppc-module .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.ppc-module .mat-sort-header-arrow {
  color: #757575;
}
.ppc-module .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.ppc-module .mat-toolbar.mat-primary {
  background: #9e5a9e;
  color: rgba(0, 0, 0, 0.87);
}
.ppc-module .mat-toolbar.mat-accent {
  background: #eeeeee;
  color: rgba(0, 0, 0, 0.87);
}
.ppc-module .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.ppc-module .mat-toolbar .mat-form-field-underline,
.ppc-module .mat-toolbar .mat-form-field-ripple,
.ppc-module .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.ppc-module .mat-toolbar .mat-form-field-label,
.ppc-module .mat-toolbar .mat-focused .mat-form-field-label,
.ppc-module .mat-toolbar .mat-select-value,
.ppc-module .mat-toolbar .mat-select-arrow,
.ppc-module .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.ppc-module .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.ppc-module .mat-tree {
  background: white;
}
.ppc-module .mat-tree-node,
.ppc-module .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.ppc-module .user-options .material-icons,
.ppc-module .user-options .material-icons-outlined,
.ppc-module .user-options .material-icons-round,
.ppc-module .user-options .material-icons-sharp {
  color: white;
}
.ppc-module .material-icons,
.ppc-module .material-icons-outlined,
.ppc-module .material-icons-round,
.ppc-module .material-icons-sharp {
  color: #9e5a9e;
}
.ppc-module .material-icons.secondary,
.ppc-module .material-icons-outlined.secondary,
.ppc-module .material-icons-round.secondary,
.ppc-module .material-icons-sharp.secondary {
  color: #fff;
}
.ppc-module .material-icons.favorable-report,
.ppc-module .material-icons-outlined.favorable-report,
.ppc-module .material-icons-round.favorable-report,
.ppc-module .material-icons-sharp.favorable-report {
  color: #0a8407;
}
.ppc-module .material-icons.favorable-but-errors-report,
.ppc-module .material-icons-outlined.favorable-but-errors-report,
.ppc-module .material-icons-round.favorable-but-errors-report,
.ppc-module .material-icons-sharp.favorable-but-errors-report {
  color: #e58824;
}
.ppc-module .material-icons.unfavorable-report,
.ppc-module .material-icons-outlined.unfavorable-report,
.ppc-module .material-icons-round.unfavorable-report,
.ppc-module .material-icons-sharp.unfavorable-report {
  color: #e80202;
}
.ppc-module .mat-mdc-tab-link.mdc-tab-indicator--active.mat-mdc-tab-disabled .mdc-tab-indicator__content--underline {
  border-color: #9e5a9e;
}
.ppc-module .mat-mdc-button:not(:disabled) {
  background-color: #9e5a9e;
  color: white;
}

.omission-module .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.omission-module .mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.omission-module .mat-mdc-option:hover:not(.mdc-list-item--disabled), .omission-module .mat-mdc-option:focus:not(.mdc-list-item--disabled), .omission-module .mat-mdc-option.mat-mdc-option-active, .omission-module .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.omission-module .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #279fd6);
}
.omission-module .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #eeeeee);
}
.omission-module .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #f44336);
}
.omission-module .mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.omission-module .mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.omission-module .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.omission-module .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.omission-module .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #279fd6;
}
.omission-module .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.omission-module .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #279fd6;
}
.omission-module .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.omission-module .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.omission-module .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.omission-module .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #eeeeee;
}
.omission-module .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.omission-module .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #eeeeee;
}
.omission-module .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.omission-module .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.omission-module .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.omission-module .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #eeeeee;
}
.omission-module .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.omission-module .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #eeeeee;
}
.omission-module .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.omission-module .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.omission-module .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.omission-module .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
.omission-module .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.omission-module .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
.omission-module .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.omission-module .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.omission-module .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.omission-module .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.omission-module .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.omission-module .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}
.omission-module .mat-app-background, .omission-module.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.omission-module .mat-elevation-z0, .omission-module .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.omission-module .mat-elevation-z1, .omission-module .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.omission-module .mat-elevation-z2, .omission-module .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.omission-module .mat-elevation-z3, .omission-module .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.omission-module .mat-elevation-z4, .omission-module .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.omission-module .mat-elevation-z5, .omission-module .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.omission-module .mat-elevation-z6, .omission-module .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.omission-module .mat-elevation-z7, .omission-module .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.omission-module .mat-elevation-z8, .omission-module .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.omission-module .mat-elevation-z9, .omission-module .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.omission-module .mat-elevation-z10, .omission-module .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.omission-module .mat-elevation-z11, .omission-module .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.omission-module .mat-elevation-z12, .omission-module .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.omission-module .mat-elevation-z13, .omission-module .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.omission-module .mat-elevation-z14, .omission-module .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.omission-module .mat-elevation-z15, .omission-module .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.omission-module .mat-elevation-z16, .omission-module .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.omission-module .mat-elevation-z17, .omission-module .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.omission-module .mat-elevation-z18, .omission-module .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.omission-module .mat-elevation-z19, .omission-module .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.omission-module .mat-elevation-z20, .omission-module .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.omission-module .mat-elevation-z21, .omission-module .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.omission-module .mat-elevation-z22, .omission-module .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.omission-module .mat-elevation-z23, .omission-module .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.omission-module .mat-elevation-z24, .omission-module .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.omission-module .mat-mdc-card {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-color: #fff;
}
.omission-module .mat-mdc-card-outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-outline-color: #e0e0e0;
}
.omission-module .mat-mdc-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.omission-module .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #279fd6;
}
.omission-module .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(39, 159, 214, 0.25)'/%3E%3C/svg%3E");
}
.omission-module .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(39, 159, 214, 0.25);
}
.omission-module .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #eeeeee;
}
.omission-module .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(238, 238, 238, 0.25)'/%3E%3C/svg%3E");
}
.omission-module .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(238, 238, 238, 0.25);
}
.omission-module .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
}
.omission-module .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(244, 67, 54, 0.25)'/%3E%3C/svg%3E");
}
.omission-module .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(244, 67, 54, 0.25);
}
.omission-module .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}
.omission-module .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.omission-module .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
@media all {
  .omission-module .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
@media all {
  .omission-module .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
.omission-module .mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #279fd6);
}
.omission-module .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.omission-module .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.omission-module .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.omission-module .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.omission-module .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.omission-module .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.omission-module .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.omission-module .mdc-text-field--filled .mdc-text-field__ripple::before,
.omission-module .mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.omission-module .mdc-text-field--filled:hover .mdc-text-field__ripple::before, .omission-module .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.omission-module .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .omission-module .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.omission-module .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.omission-module .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.omission-module .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.omission-module .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #279fd6);
}
.omission-module .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.omission-module .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.omission-module .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.omission-module .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.omission-module .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.omission-module .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.omission-module .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.omission-module .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.omission-module .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #279fd6);
}
[dir=rtl] .omission-module .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .omission-module .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .omission-module .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .omission-module .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.omission-module .mdc-text-field--outlined .mdc-text-field__ripple::before,
.omission-module .mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .omission-module .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .omission-module .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.omission-module .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(39, 159, 214, 0.87);
}
.omission-module .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.omission-module .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.omission-module .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.omission-module .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.omission-module .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.omission-module .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.omission-module .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.omission-module .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.omission-module .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.omission-module .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.omission-module .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.omission-module .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.omission-module .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.omission-module .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.omission-module .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.omission-module .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.omission-module .mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}
@media all {
  .omission-module .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .omission-module .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.omission-module .mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.omission-module .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.omission-module .mdc-text-field--disabled .mdc-text-field-character-counter,
.omission-module .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.omission-module .mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.omission-module .mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.omission-module .mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.omission-module .mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.omission-module .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.omission-module .mdc-text-field--disabled .mdc-notched-outline__leading,
.omission-module .mdc-text-field--disabled .mdc-notched-outline__notch,
.omission-module .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .omission-module .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .omission-module .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .omission-module .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .omission-module .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .omission-module .mdc-text-field--disabled .mdc-text-field-character-counter,
  .omission-module .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .omission-module .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .omission-module .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .omission-module .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .omission-module .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .omission-module .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .omission-module .mdc-text-field--disabled .mdc-notched-outline__leading,
  .omission-module .mdc-text-field--disabled .mdc-notched-outline__notch,
  .omission-module .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.omission-module .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fafafa;
}
[dir=rtl] .omission-module .mdc-text-field--end-aligned .mdc-text-field__input, .omission-module .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .omission-module .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .omission-module .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.omission-module .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #f44336);
}
.omission-module .mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}
.omission-module .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}
.omission-module .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}
.omission-module .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.omission-module .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(39, 159, 214, 0.87);
}
.omission-module .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(238, 238, 238, 0.87);
}
.omission-module .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(244, 67, 54, 0.87);
}
.omission-module .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.omission-module .mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #eeeeee);
}
.omission-module .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #eeeeee);
}
.omission-module .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(238, 238, 238, 0.87);
}
.omission-module .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.omission-module .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.omission-module .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.omission-module .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.omission-module .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.omission-module .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.omission-module .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.omission-module .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.omission-module .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.omission-module .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.omission-module .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.omission-module .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.omission-module .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.omission-module .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.omission-module .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.omission-module .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.omission-module .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.omission-module .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.omission-module .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #eeeeee);
}
.omission-module .mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.omission-module .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.omission-module .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(244, 67, 54, 0.87);
}
.omission-module .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.omission-module .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.omission-module .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.omission-module .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.omission-module .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.omission-module .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.omission-module .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.omission-module .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.omission-module .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.omission-module .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.omission-module .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.omission-module .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.omission-module .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.omission-module .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.omission-module .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.omission-module .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.omission-module .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.omission-module .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.omission-module .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.omission-module .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.omission-module [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.omission-module .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.omission-module .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.omission-module .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.omission-module .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.omission-module .mdc-list-item--with-leading-icon .mdc-list-item__start,
.omission-module .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.omission-module .mdc-list-item--with-leading-icon .mdc-list-item__start,
.omission-module .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.omission-module .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.omission-module .mdc-list-item--disabled .mdc-list-item__start,
.omission-module .mdc-list-item--disabled .mdc-list-item__content,
.omission-module .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.omission-module .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.omission-module .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.omission-module .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.omission-module .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.omission-module .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.omission-module .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.omission-module .mdc-list-item--selected .mdc-list-item__primary-text,
.omission-module .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #279fd6);
}
.omission-module .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.omission-module .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #279fd6);
}
.omission-module .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.omission-module .mdc-list-divider::after {
  border-bottom-color: white;
}
.omission-module .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.omission-module .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.omission-module .mat-mdc-select-placeholder {
  color: rgba(0, 0, 0, 0.6);
}
.omission-module .mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.omission-module .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.omission-module .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(39, 159, 214, 0.87);
}
.omission-module .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(238, 238, 238, 0.87);
}
.omission-module .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(244, 67, 54, 0.87);
}
.omission-module .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(244, 67, 54, 0.87);
}
.omission-module .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.omission-module .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.omission-module .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.omission-module .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.omission-module .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.omission-module .mdc-list-item--with-leading-icon .mdc-list-item__start,
.omission-module .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.omission-module .mdc-list-item--with-leading-icon .mdc-list-item__start,
.omission-module .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.omission-module .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.omission-module .mdc-list-item--disabled .mdc-list-item__start,
.omission-module .mdc-list-item--disabled .mdc-list-item__content,
.omission-module .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.omission-module .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.omission-module .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.omission-module .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.omission-module .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.omission-module .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.omission-module .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.omission-module .mdc-list-item--selected .mdc-list-item__primary-text,
.omission-module .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #279fd6);
}
.omission-module .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.omission-module .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #279fd6);
}
.omission-module .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.omission-module .mdc-list-divider::after {
  border-bottom-color: white;
}
.omission-module .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.omission-module .mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-with-divider-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}
.omission-module .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
}
.omission-module .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .omission-module .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #279fd6;
  --mdc-chip-elevated-disabled-container-color: #279fd6;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.omission-module .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .omission-module .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #eeeeee;
  --mdc-chip-elevated-disabled-container-color: #eeeeee;
  --mdc-chip-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-disabled-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-disabled-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-selected-icon-color: rgba(0, 0, 0, 0.87);
}
.omission-module .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .omission-module .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.omission-module .mat-mdc-chip-focus-overlay {
  background: black;
}
.omission-module .mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}
.omission-module .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.omission-module .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.omission-module .mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #279fd6;
  --mdc-switch-selected-handle-color: #279fd6;
  --mdc-switch-selected-hover-state-layer-color: #279fd6;
  --mdc-switch-selected-pressed-state-layer-color: #279fd6;
  --mdc-switch-selected-focus-handle-color: #135b8d;
  --mdc-switch-selected-hover-handle-color: #135b8d;
  --mdc-switch-selected-pressed-handle-color: #135b8d;
  --mdc-switch-selected-focus-track-color: #5dc5ea;
  --mdc-switch-selected-hover-track-color: #5dc5ea;
  --mdc-switch-selected-pressed-track-color: #5dc5ea;
  --mdc-switch-selected-track-color: #5dc5ea;
}
.omission-module .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #666666;
  --mdc-switch-selected-handle-color: #666666;
  --mdc-switch-selected-hover-state-layer-color: #666666;
  --mdc-switch-selected-pressed-state-layer-color: #666666;
  --mdc-switch-selected-focus-handle-color: black;
  --mdc-switch-selected-hover-handle-color: black;
  --mdc-switch-selected-pressed-handle-color: black;
  --mdc-switch-selected-focus-track-color: #cccccc;
  --mdc-switch-selected-hover-track-color: #cccccc;
  --mdc-switch-selected-pressed-track-color: #cccccc;
  --mdc-switch-selected-track-color: #cccccc;
}
.omission-module .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}
.omission-module .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.omission-module .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #279fd6;
  --mdc-radio-selected-hover-icon-color: #279fd6;
  --mdc-radio-selected-icon-color: #279fd6;
  --mdc-radio-selected-pressed-icon-color: #279fd6;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #279fd6;
}
.omission-module .mat-mdc-radio-button.mat-primary .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.omission-module .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #eeeeee;
  --mdc-radio-selected-hover-icon-color: #eeeeee;
  --mdc-radio-selected-icon-color: #eeeeee;
  --mdc-radio-selected-pressed-icon-color: #eeeeee;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #eeeeee;
}
.omission-module .mat-mdc-radio-button.mat-accent .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.omission-module .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #f44336;
}
.omission-module .mat-mdc-radio-button.mat-warn .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.omission-module .mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.omission-module .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #279fd6;
  --mdc-slider-focus-handle-color: #279fd6;
  --mdc-slider-hover-handle-color: #279fd6;
  --mdc-slider-active-track-color: #279fd6;
  --mdc-slider-inactive-track-color: #279fd6;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #279fd6;
  --mat-mdc-slider-ripple-color: #279fd6;
  --mat-mdc-slider-hover-ripple-color: rgba(39, 159, 214, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(39, 159, 214, 0.2);
}
.omission-module .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #eeeeee;
  --mdc-slider-focus-handle-color: #eeeeee;
  --mdc-slider-hover-handle-color: #eeeeee;
  --mdc-slider-active-track-color: #eeeeee;
  --mdc-slider-inactive-track-color: #eeeeee;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #eeeeee;
  --mat-mdc-slider-ripple-color: #eeeeee;
  --mat-mdc-slider-hover-ripple-color: rgba(238, 238, 238, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(238, 238, 238, 0.2);
}
.omission-module .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mat-mdc-slider-ripple-color: #f44336;
  --mat-mdc-slider-hover-ripple-color: rgba(244, 67, 54, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(244, 67, 54, 0.2);
}
.omission-module .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.omission-module .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.omission-module .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.omission-module .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.omission-module .mdc-list-item--with-leading-icon .mdc-list-item__start,
.omission-module .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.omission-module .mdc-list-item--with-leading-icon .mdc-list-item__start,
.omission-module .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.omission-module .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.omission-module .mdc-list-item--disabled .mdc-list-item__start,
.omission-module .mdc-list-item--disabled .mdc-list-item__content,
.omission-module .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.omission-module .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.omission-module .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.omission-module .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.omission-module .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.omission-module .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.omission-module .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.omission-module .mdc-list-item--selected .mdc-list-item__primary-text,
.omission-module .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #279fd6);
}
.omission-module .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.omission-module .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #279fd6);
}
.omission-module .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.omission-module .mdc-list-divider::after {
  border-bottom-color: white;
}
.omission-module .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.omission-module .mat-mdc-menu-item[disabled],
.omission-module .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.omission-module .mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38));
}
.omission-module .mat-mdc-menu-item .mat-icon-no-color,
.omission-module .mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.omission-module .mat-mdc-menu-item:hover:not([disabled]),
.omission-module .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.omission-module .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.omission-module .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.omission-module .mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}
.omission-module .mat-mdc-list-option .mdc-list-item__start, .omission-module .mat-mdc-list-option .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #279fd6;
  --mdc-checkbox-selected-hover-icon-color: #279fd6;
  --mdc-checkbox-selected-icon-color: #279fd6;
  --mdc-checkbox-selected-pressed-icon-color: #279fd6;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.omission-module .mat-mdc-list-option .mdc-list-item__start, .omission-module .mat-mdc-list-option .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #279fd6;
  --mdc-radio-selected-hover-icon-color: #279fd6;
  --mdc-radio-selected-icon-color: #279fd6;
  --mdc-radio-selected-pressed-icon-color: #279fd6;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #279fd6;
}
.omission-module .mat-mdc-list-option .mdc-list-item__start .mdc-radio--disabled + label, .omission-module .mat-mdc-list-option .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.omission-module .mat-mdc-list-option.mat-accent .mdc-list-item__start, .omission-module .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #eeeeee;
  --mdc-checkbox-selected-hover-icon-color: #eeeeee;
  --mdc-checkbox-selected-icon-color: #eeeeee;
  --mdc-checkbox-selected-pressed-icon-color: #eeeeee;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.omission-module .mat-mdc-list-option.mat-accent .mdc-list-item__start, .omission-module .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #eeeeee;
  --mdc-radio-selected-hover-icon-color: #eeeeee;
  --mdc-radio-selected-icon-color: #eeeeee;
  --mdc-radio-selected-pressed-icon-color: #eeeeee;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #eeeeee;
}
.omission-module .mat-mdc-list-option.mat-accent .mdc-list-item__start .mdc-radio--disabled + label, .omission-module .mat-mdc-list-option.mat-accent .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.omission-module .mat-mdc-list-option.mat-warn .mdc-list-item__start, .omission-module .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.omission-module .mat-mdc-list-option.mat-warn .mdc-list-item__start, .omission-module .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #f44336;
}
.omission-module .mat-mdc-list-option.mat-warn .mdc-list-item__start .mdc-radio--disabled + label, .omission-module .mat-mdc-list-option.mat-warn .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.omission-module .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.omission-module .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #279fd6;
}
.omission-module .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.omission-module .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #279fd6;
}
.omission-module .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.omission-module .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.omission-module .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.omission-module .mat-mdc-paginator {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.omission-module .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.54);
}
.omission-module .mat-mdc-paginator-decrement,
.omission-module .mat-mdc-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.omission-module .mat-mdc-paginator-first,
.omission-module .mat-mdc-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.omission-module .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.omission-module .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.omission-module .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.omission-module .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(0, 0, 0, 0.12);
}
.omission-module .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.12);
}
.omission-module .mat-mdc-tab, .omission-module .mat-mdc-tab-link {
  background-color: transparent;
}
.omission-module .mat-mdc-tab .mdc-tab__text-label, .omission-module .mat-mdc-tab-link .mdc-tab__text-label {
  color: rgba(0, 0, 0, 0.6);
}
.omission-module .mat-mdc-tab.mat-mdc-tab-disabled .mdc-tab__ripple::before,
.omission-module .mat-mdc-tab.mat-mdc-tab-disabled .mat-ripple-element, .omission-module .mat-mdc-tab-link.mat-mdc-tab-disabled .mdc-tab__ripple::before,
.omission-module .mat-mdc-tab-link.mat-mdc-tab-disabled .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.38);
}
.omission-module .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .omission-module .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #279fd6;
}
.omission-module .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .omission-module .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #279fd6);
}
.omission-module .mdc-tab__ripple::before,
.omission-module .mat-mdc-tab .mat-ripple-element,
.omission-module .mat-mdc-tab-header-pagination .mat-ripple-element,
.omission-module .mat-mdc-tab-link .mat-ripple-element {
  background-color: #279fd6;
}
.omission-module .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .omission-module .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .omission-module .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .omission-module .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #eeeeee;
}
.omission-module .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .omission-module .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .omission-module .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .omission-module .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #eeeeee);
}
.omission-module .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.omission-module .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.omission-module .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.omission-module .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element, .omission-module .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.omission-module .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.omission-module .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.omission-module .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element {
  background-color: #eeeeee;
}
.omission-module .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .omission-module .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .omission-module .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .omission-module .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #f44336;
}
.omission-module .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .omission-module .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .omission-module .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .omission-module .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #f44336);
}
.omission-module .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.omission-module .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.omission-module .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.omission-module .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element, .omission-module .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.omission-module .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.omission-module .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.omission-module .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element {
  background-color: #f44336;
}
.omission-module .mat-mdc-tab-group.mat-background-primary, .omission-module .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-mdc-tab-header-with-background-background-color: #279fd6;
  --mat-mdc-tab-header-with-background-foreground-color: #000;
}
.omission-module .mat-mdc-tab-group.mat-background-accent, .omission-module .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-mdc-tab-header-with-background-background-color: #eeeeee;
  --mat-mdc-tab-header-with-background-foreground-color: #000;
}
.omission-module .mat-mdc-tab-group.mat-background-warn, .omission-module .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-mdc-tab-header-with-background-background-color: #f44336;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.omission-module .mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-on-surface, #000);
}
.omission-module .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.omission-module .mat-mdc-checkbox .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.omission-module .mat-mdc-checkbox .mdc-checkbox__ripple {
  background: #000;
}
.omission-module .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #279fd6;
  --mdc-checkbox-selected-hover-icon-color: #279fd6;
  --mdc-checkbox-selected-icon-color: #279fd6;
  --mdc-checkbox-selected-pressed-icon-color: #279fd6;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.omission-module .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(39, 159, 214, 0.1);
}
.omission-module .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #279fd6;
}
.omission-module .mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #eeeeee;
  --mdc-checkbox-selected-hover-icon-color: #eeeeee;
  --mdc-checkbox-selected-icon-color: #eeeeee;
  --mdc-checkbox-selected-pressed-icon-color: #eeeeee;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.omission-module .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(238, 238, 238, 0.1);
}
.omission-module .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #eeeeee;
}
.omission-module .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.omission-module .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(244, 67, 54, 0.1);
}
.omission-module .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #f44336;
}
.omission-module .mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}
.omission-module .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.omission-module .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #279fd6;
}
.omission-module .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #eeeeee;
}
.omission-module .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.omission-module .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.omission-module .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.omission-module .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #279fd6;
  --mdc-filled-button-label-text-color: #000;
}
.omission-module .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #eeeeee;
  --mdc-filled-button-label-text-color: #000;
}
.omission-module .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
}
.omission-module .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.omission-module .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.omission-module .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #279fd6;
  --mdc-protected-button-label-text-color: #000;
}
.omission-module .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #eeeeee;
  --mdc-protected-button-label-text-color: #000;
}
.omission-module .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
}
.omission-module .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.omission-module .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.omission-module .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.omission-module .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #279fd6;
}
.omission-module .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #eeeeee;
}
.omission-module .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
}
.omission-module .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.omission-module .mat-mdc-button, .omission-module .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.omission-module .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .omission-module .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.omission-module .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .omission-module .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .omission-module .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .omission-module .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.omission-module .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .omission-module .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.omission-module .mat-mdc-button.mat-primary, .omission-module .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #279fd6;
  --mat-mdc-button-ripple-color: rgba(39, 159, 214, 0.1);
}
.omission-module .mat-mdc-button.mat-accent, .omission-module .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #eeeeee;
  --mat-mdc-button-ripple-color: rgba(238, 238, 238, 0.1);
}
.omission-module .mat-mdc-button.mat-warn, .omission-module .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.omission-module .mat-mdc-raised-button, .omission-module .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.omission-module .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .omission-module .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.omission-module .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .omission-module .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .omission-module .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .omission-module .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.omission-module .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .omission-module .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.omission-module .mat-mdc-raised-button.mat-primary, .omission-module .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.omission-module .mat-mdc-raised-button.mat-accent, .omission-module .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.omission-module .mat-mdc-raised-button.mat-warn, .omission-module .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.omission-module .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.omission-module .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.omission-module .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .omission-module .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.omission-module .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.omission-module .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #279fd6;
  --mat-mdc-button-ripple-color: rgba(39, 159, 214, 0.1);
}
.omission-module .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #eeeeee;
  --mat-mdc-button-ripple-color: rgba(238, 238, 238, 0.1);
}
.omission-module .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.omission-module .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #279fd6;
}
.omission-module .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #eeeeee;
}
.omission-module .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
}
.omission-module .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
.omission-module .mat-mdc-fab, .omission-module .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.omission-module .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .omission-module .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.omission-module .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .omission-module .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .omission-module .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .omission-module .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.omission-module .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .omission-module .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.omission-module .mat-mdc-fab.mat-primary, .omission-module .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.omission-module .mat-mdc-fab.mat-accent, .omission-module .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.omission-module .mat-mdc-fab.mat-warn, .omission-module .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.omission-module .mat-mdc-fab.mat-unthemed, .omission-module .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #fff;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.omission-module .mat-mdc-fab.mat-primary, .omission-module .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #279fd6;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.omission-module .mat-mdc-fab.mat-accent, .omission-module .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #eeeeee;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.omission-module .mat-mdc-fab.mat-warn, .omission-module .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.omission-module .mat-mdc-fab[disabled][disabled], .omission-module .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.omission-module .mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: #eeeeee;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
}
.omission-module .mdc-data-table {
  background-color: var(--mdc-theme-surface, #fff);
  border-color: rgba(0, 0, 0, 0.12);
}
.omission-module .mdc-data-table__row {
  background-color: inherit;
}
.omission-module .mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #fff);
}
.omission-module .mdc-data-table__row--selected {
  background-color: rgba(39, 159, 214, 0.04);
}
.omission-module .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.omission-module .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.omission-module .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12);
}
.omission-module .mdc-data-table__cell,
.omission-module .mdc-data-table__header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.omission-module .mdc-data-table__pagination {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.omission-module .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.omission-module .mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.87);
}
.omission-module .mdc-data-table__pagination-total,
.omission-module .mdc-data-table__pagination-rows-per-page-label,
.omission-module .mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.87);
}
[dir=rtl] .omission-module .mdc-data-table__pagination-button .mdc-button__icon, .omission-module .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.omission-module .mat-mdc-table {
  background: white;
}
.omission-module .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #279fd6;
}
.omission-module .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #eeeeee;
}
.omission-module .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}
.omission-module .mat-badge-content {
  color: white;
  background: #279fd6;
}
.cdk-high-contrast-active .omission-module .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.omission-module .mat-badge-accent .mat-badge-content {
  background: #eeeeee;
  color: rgba(0, 0, 0, 0.87);
}
.omission-module .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.omission-module .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.omission-module .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.omission-module .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.omission-module .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.omission-module .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.omission-module .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.omission-module .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.omission-module .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.omission-module .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.omission-module .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.omission-module .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.omission-module [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.omission-module .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.omission-module .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.omission-module .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.omission-module .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.omission-module .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.omission-module .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.omission-module .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.omission-module .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.omission-module .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.omission-module .mat-datepicker-toggle,
.omission-module .mat-datepicker-content .mat-calendar-next-button,
.omission-module .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.omission-module .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.omission-module .mat-calendar-table-header,
.omission-module .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.omission-module .mat-calendar-body-cell-content,
.omission-module .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.omission-module .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.omission-module .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.omission-module .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.omission-module .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.omission-module .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.omission-module .mat-calendar-body-in-range::before {
  background: rgba(39, 159, 214, 0.2);
}
.omission-module .mat-calendar-body-comparison-identical,
.omission-module .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.omission-module .mat-calendar-body-comparison-bridge-start::before,
.omission-module [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(39, 159, 214, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.omission-module .mat-calendar-body-comparison-bridge-end::before,
.omission-module [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(39, 159, 214, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.omission-module .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.omission-module .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.omission-module .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.omission-module .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.omission-module .mat-calendar-body-selected {
  background-color: #279fd6;
  color: white;
}
.omission-module .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(39, 159, 214, 0.4);
}
.omission-module .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.omission-module .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.omission-module .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(39, 159, 214, 0.3);
}
@media (hover: hover) {
  .omission-module .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(39, 159, 214, 0.3);
  }
}
.omission-module .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.omission-module .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(238, 238, 238, 0.2);
}
.omission-module .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.omission-module .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.omission-module .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.omission-module .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(238, 238, 238, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.omission-module .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.omission-module .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(238, 238, 238, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.omission-module .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.omission-module .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.omission-module .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.omission-module .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.omission-module .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.87);
}
.omission-module .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(238, 238, 238, 0.4);
}
.omission-module .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.omission-module .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.omission-module .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(238, 238, 238, 0.3);
}
@media (hover: hover) {
  .omission-module .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(238, 238, 238, 0.3);
  }
}
.omission-module .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.omission-module .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.omission-module .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.omission-module .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.omission-module .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.omission-module .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.omission-module .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.omission-module .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.omission-module .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.omission-module .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.omission-module .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.omission-module .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.omission-module .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.omission-module .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.omission-module .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.omission-module .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .omission-module .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}
.omission-module .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.omission-module .mat-datepicker-toggle-active {
  color: #279fd6;
}
.omission-module .mat-datepicker-toggle-active.mat-accent {
  color: #eeeeee;
}
.omission-module .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.omission-module .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.omission-module .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.omission-module .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.omission-module .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.omission-module .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.omission-module .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.omission-module .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .omission-module .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .omission-module .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .omission-module .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.omission-module .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.omission-module .mat-expansion-panel-header-description,
.omission-module .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.omission-module .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.omission-module .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.omission-module .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.omission-module .mat-icon.mat-primary {
  color: #279fd6;
}
.omission-module .mat-icon.mat-accent {
  color: #eeeeee;
}
.omission-module .mat-icon.mat-warn {
  color: #f44336;
}
.omission-module .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.omission-module .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.omission-module .mat-drawer.mat-drawer-push {
  background-color: white;
}
.omission-module .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.omission-module .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.omission-module .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.omission-module [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.omission-module [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.omission-module .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.omission-module .mat-step-header.cdk-keyboard-focused, .omission-module .mat-step-header.cdk-program-focused, .omission-module .mat-step-header:hover:not([aria-disabled]), .omission-module .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.omission-module .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .omission-module .mat-step-header:hover {
    background: none;
  }
}
.omission-module .mat-step-header .mat-step-label,
.omission-module .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.omission-module .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.omission-module .mat-step-header .mat-step-icon-selected,
.omission-module .mat-step-header .mat-step-icon-state-done,
.omission-module .mat-step-header .mat-step-icon-state-edit {
  background-color: #279fd6;
  color: white;
}
.omission-module .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.omission-module .mat-step-header.mat-accent .mat-step-icon-selected,
.omission-module .mat-step-header.mat-accent .mat-step-icon-state-done,
.omission-module .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.87);
}
.omission-module .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.omission-module .mat-step-header.mat-warn .mat-step-icon-selected,
.omission-module .mat-step-header.mat-warn .mat-step-icon-state-done,
.omission-module .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.omission-module .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.omission-module .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.omission-module .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.omission-module .mat-stepper-horizontal, .omission-module .mat-stepper-vertical {
  background-color: white;
}
.omission-module .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.omission-module .mat-horizontal-stepper-header::before,
.omission-module .mat-horizontal-stepper-header::after,
.omission-module .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.omission-module .mat-sort-header-arrow {
  color: #757575;
}
.omission-module .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.omission-module .mat-toolbar.mat-primary {
  background: #279fd6;
  color: white;
}
.omission-module .mat-toolbar.mat-accent {
  background: #eeeeee;
  color: rgba(0, 0, 0, 0.87);
}
.omission-module .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.omission-module .mat-toolbar .mat-form-field-underline,
.omission-module .mat-toolbar .mat-form-field-ripple,
.omission-module .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.omission-module .mat-toolbar .mat-form-field-label,
.omission-module .mat-toolbar .mat-focused .mat-form-field-label,
.omission-module .mat-toolbar .mat-select-value,
.omission-module .mat-toolbar .mat-select-arrow,
.omission-module .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.omission-module .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.omission-module .mat-tree {
  background: white;
}
.omission-module .mat-tree-node,
.omission-module .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.omission-module .user-options .material-icons,
.omission-module .user-options .material-icons-outlined,
.omission-module .user-options .material-icons-round,
.omission-module .user-options .material-icons-sharp {
  color: white;
}
.omission-module .material-icons,
.omission-module .material-icons-outlined,
.omission-module .material-icons-round,
.omission-module .material-icons-sharp {
  color: #279fd6;
}
.omission-module .material-icons.secondary,
.omission-module .material-icons-outlined.secondary,
.omission-module .material-icons-round.secondary,
.omission-module .material-icons-sharp.secondary {
  color: #fff;
}
.omission-module .material-icons.favorable-report,
.omission-module .material-icons-outlined.favorable-report,
.omission-module .material-icons-round.favorable-report,
.omission-module .material-icons-sharp.favorable-report {
  color: #0a8407;
}
.omission-module .material-icons.favorable-but-errors-report,
.omission-module .material-icons-outlined.favorable-but-errors-report,
.omission-module .material-icons-round.favorable-but-errors-report,
.omission-module .material-icons-sharp.favorable-but-errors-report {
  color: #e58824;
}
.omission-module .material-icons.unfavorable-report,
.omission-module .material-icons-outlined.unfavorable-report,
.omission-module .material-icons-round.unfavorable-report,
.omission-module .material-icons-sharp.unfavorable-report {
  color: #e80202;
}
.omission-module .mat-mdc-tab-link.mdc-tab-indicator--active.mat-mdc-tab-disabled .mdc-tab-indicator__content--underline {
  border-color: #279fd6;
}
.omission-module .mat-mdc-button:not(:disabled) {
  background-color: #279fd6;
  color: white;
}
.omission-module .mat-mdc-raised-button.mat-primary {
  background-color: #279fd6;
  color: white;
}

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "OpenSans", "Helvetica Neue", sans-serif;
}

body {
  --primary-color: #007bff;
  --primary-lighter-color: #b3d7ff;
  --primary-darker-color: #005eff;
  --text-primary-color: #ffffff;
  --text-primary-lighter-color: rgba(52, 58, 64, 0.87);
  --text-primary-darker-color: #ffffff;
}

body {
  --accent-color: #e83e8c;
  --accent-lighter-color: #f8c5dd;
  --accent-darker-color: #de286f;
  --text-accent-color: #ffffff;
  --text-accent-lighter-color: rgba(52, 58, 64, 0.87);
  --text-accent-darker-color: #ffffff;
}

body {
  --warn-color: #dc3545;
  --warn-lighter-color: #f5c2c7;
  --warn-darker-color: #ce222e;
  --text-warn-color: #ffffff;
  --text-warn-lighter-color: rgba(52, 58, 64, 0.87);
  --text-warn-darker-color: #ffffff;
}

/* app drawer breakpoint */
h1 {
  color: #333;
  font-family: OpenSansLight;
  font-size: 42px;
  line-height: 52px;
  margin: 0;
}

h2 {
  color: #333;
  font-family: OpenSansLight;
  font-size: 36px;
  line-height: 44px;
  margin: 0;
}

h3 {
  color: #333;
  font-family: OpenSansRegular;
  font-size: 28px;
  line-height: 36px;
  margin: 0;
}

h4 {
  color: #333;
  font-family: OpenSansBold;
  font-size: 24px;
  line-height: 30px;
  margin: 0;
}

h5 {
  color: #333;
  font-family: OpenSansBold;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  text-transform: uppercase;
}

.card {
  background-color: white;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: hidden;
}
.card-outlined {
  border: 2px solid #ddd;
}

.ic-title-large {
  color: #333;
  font-family: OpenSansBold;
  font-size: 20px;
  line-height: 26px;
  margin: 0;
}
.ic-title-medium {
  color: #333;
  font-family: OpenSansBold;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
}
.ic-title-small {
  color: #333;
  font-family: OpenSansBold;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
}
.ic-row-title {
  color: #666;
  font-family: OpenSansSemiBold;
  font-size: 18px;
  line-height: 20px;
  margin: 0;
  text-transform: uppercase;
}
.ic-section-title {
  color: #333;
  font-family: OpenSansBold;
  font-size: 12px;
  line-height: 15px;
  margin: 0;
  text-transform: uppercase;
}
.ic-section-title-grey {
  color: #999;
  font-family: OpenSansRegular;
  font-size: 14px;
  line-height: 17px;
  margin: 0;
  text-transform: uppercase;
}
.ic-panel-section-title {
  color: #333;
  font-family: OpenSansSemiBold;
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  text-transform: uppercase;
}
.ic-basic-text {
  color: #999;
  font-family: OpenSansRegular;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
}
.ic-uppercase {
  text-transform: uppercase;
}
.ic-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ic-truncate1 {
  display: -webkit-box;
  max-width: max-content;
  overflow: hidden;
  word-break: break-word;
  -webkit-line-clamp: 1;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
}
.ic-truncate2 {
  display: -webkit-box;
  max-width: max-content;
  overflow: hidden;
  word-break: break-word;
  -webkit-line-clamp: 2;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
}
.ic-vertical-align {
  left: 50%;
  margin-right: -50% !important;
  padding: 0 !important;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
}
.ic-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ic-lineclamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  /* autoprefixer: off */
}
.ic-img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ic-flex-center {
  align-items: center !important;
  display: flex !important;
  justify-content: center !important;
}
.ic-text-10 {
  font-size: 10px !important;
}
.ic-text-12 {
  font-size: 12px !important;
}
.ic-text-14 {
  font-size: 14px !important;
}
.ic-text-16 {
  font-size: 16px !important;
}
.ic-text-18 {
  font-size: 18px !important;
}
.ic-text-20 {
  font-size: 20px !important;
}
.ic-text-22 {
  font-size: 22px !important;
}
.ic-text-24 {
  font-size: 24px !important;
}
.ic-text-26 {
  font-size: 26px !important;
}
.ic-text-28 {
  font-size: 28px !important;
}
.ic-text-32 {
  font-size: 32px !important;
}
.ic-text-36 {
  font-size: 36px !important;
}
.ic-text-42 {
  font-size: 42px !important;
}
.ic-text-48 {
  font-size: 48px !important;
}
.ic-text-56 {
  font-size: 56px !important;
}
.ic-text-black {
  color: #000 !important;
}
.ic-text-grey-900 {
  color: #1a1a1a !important;
}
.ic-text-grey-800 {
  color: #333 !important;
}
.ic-text-grey-700 {
  color: #666 !important;
}
.ic-text-grey-600 {
  color: #999 !important;
}
.ic-text-grey-500 {
  color: #bdbdbd !important;
}
.ic-text-grey-400 {
  color: #ccc !important;
}
.ic-text-grey-300 {
  color: #e2e2e2 !important;
}
.ic-text-grey-200 {
  color: #eee !important;
}
.ic-text-grey-100 {
  color: #f9f9f9 !important;
}
.ic-text-white {
  color: #fff !important;
}
.ic-text-orange {
  color: #dc8522 !important;
}
.ic-text-warning {
  color: #ee9a37 !important;
}
.ic-text-success {
  color: #5cce88 !important;
}
.ic-text-error {
  color: #f05060 !important;
}
.ic-text-out {
  color: #000000 !important;
}
.ic-text-stopped {
  color: #999999 !important;
}
.ic-text-primary {
  color: #ffffff !important;
}
.ic-bc-black {
  background-color: #000 !important;
}
.ic-bc-grey-900 {
  background-color: #1a1a1a !important;
}
.ic-bc-grey-800 {
  background-color: #333 !important;
}
.ic-bc-grey-700 {
  background-color: #666 !important;
}
.ic-bc-grey-600 {
  background-color: #999 !important;
}
.ic-bc-grey-500 {
  background-color: #bdbdbd !important;
}
.ic-bc-grey-400 {
  background-color: #ccc !important;
}
.ic-bc-grey-300 {
  background-color: #e2e2e2 !important;
}
.ic-bc-grey-200 {
  background-color: #eee !important;
}
.ic-bc-grey-100 {
  background-color: #f9f9f9 !important;
}
.ic-bc-white {
  background-color: #fff !important;
}
.ic-bc-random1 {
  background-color: #41545d !important;
}
.ic-bc-random2 {
  background-color: #279ed6 !important;
}
.ic-bc-random3 {
  background-color: #9b2761 !important;
}
.ic-bc-random4 {
  background-color: #e53b78 !important;
}
.ic-bc-random5 {
  background-color: #e7575b !important;
}
.ic-bc-random6 {
  background-color: #5cce88 !important;
}
.ic-bc-random7 {
  background-color: #199aa8 !important;
}
.ic-bc-random8 {
  background-color: #ee9a37 !important;
}
.ic-bc-random9 {
  background-color: #b182a6 !important;
}
.ic-bc-random10 {
  background-color: #aad355 !important;
}
.ic-bc-random11 {
  background-color: #1d5d93 !important;
}
.ic-bc-primary {
  background-color: #ffffff !important;
}
.ic-bc-primary-hover {
  background-color: #e2e2e2 !important;
}
.ic-bc-accent {
  background-color: #4050b6 !important;
}
.ic-bc-accent-hover {
  background-color: #293375 !important;
}
.ic-bc-success {
  background-color: #5cce88 !important;
}
.ic-bc-warning {
  background-color: #ee9a37 !important;
}
.ic-bc-error {
  background-color: #f05060 !important;
}
.ic-bc-out {
  background-color: #000000 !important;
}
.ic-bc-stopped {
  background-color: #999999 !important;
}
.ic-bc-orange {
  background-color: #dc8522 !important;
}
.ic-m-0 {
  margin: 0 !important;
}
.ic-m-4 {
  margin: 4px;
}
.ic-m-8 {
  margin: 8px;
}
.ic-m-12 {
  margin: 12px;
}
.ic-m-16 {
  margin: 16px;
}
.ic-m-20 {
  margin: 20px;
}
.ic-m-24 {
  margin: 24px;
}
.ic-m-28 {
  margin: 28px;
}
.ic-m-32 {
  margin: 32px;
}
.ic-m-36 {
  margin: 36px;
}
.ic-m-40 {
  margin: 40px;
}
.ic-m-44 {
  margin: 44px;
}
.ic-m-48 {
  margin: 48px;
}
.ic-m-60 {
  margin: 60px;
}
.ic-m-80 {
  margin: 80px;
}
.ic-m-100 {
  margin: 100px;
}
.ic-mt-0 {
  margin-top: 0 !important;
}
.ic-mt-4 {
  margin-top: 4px;
}
.ic-mt-8 {
  margin-top: 8px;
}
.ic-mt-12 {
  margin-top: 12px;
}
.ic-mt-16 {
  margin-top: 16px;
}
.ic-mt-20 {
  margin-top: 20px;
}
.ic-mt-24 {
  margin-top: 24px;
}
.ic-mt-28 {
  margin-top: 28px;
}
.ic-mt-32 {
  margin-top: 32px;
}
.ic-mt-36 {
  margin-top: 36px;
}
.ic-mt-40 {
  margin-top: 40px;
}
.ic-mt-44 {
  margin-top: 44px;
}
.ic-mt-48 {
  margin-top: 48px;
}
.ic-mt-60 {
  margin-top: 60px;
}
.ic-mt-80 {
  margin-top: 80px;
}
.ic-mt-100 {
  margin-top: 100px;
}
.ic-mr-auto {
  margin-right: auto !important;
}
.ic-mr-0 {
  margin-right: 0 !important;
}
.ic-mr-4 {
  margin-right: 4px;
}
.ic-mr-8 {
  margin-right: 8px;
}
.ic-mr-12 {
  margin-right: 12px;
}
.ic-mr-16 {
  margin-right: 16px;
}
.ic-mr-20 {
  margin-right: 20px;
}
.ic-mr-24 {
  margin-right: 24px;
}
.ic-mr-28 {
  margin-right: 28px;
}
.ic-mr-32 {
  margin-right: 32px;
}
.ic-mr-36 {
  margin-right: 36px;
}
.ic-mr-40 {
  margin-right: 40px;
}
.ic-mr-44 {
  margin-right: 44px;
}
.ic-mr-48 {
  margin-right: 48px;
}
.ic-mr-60 {
  margin-right: 60px;
}
.ic-mr-80 {
  margin-right: 80px;
}
.ic-mr-100 {
  margin-right: 100px;
}
.ic-mb-0 {
  margin-bottom: 0 !important;
}
.ic-mb-4 {
  margin-bottom: 4px;
}
.ic-mb-8 {
  margin-bottom: 8px;
}
.ic-mb-12 {
  margin-bottom: 12px;
}
.ic-mb-16 {
  margin-bottom: 16px;
}
.ic-mb-20 {
  margin-bottom: 20px;
}
.ic-mb-24 {
  margin-bottom: 24px;
}
.ic-mb-28 {
  margin-bottom: 28px;
}
.ic-mb-32 {
  margin-bottom: 32px;
}
.ic-mb-36 {
  margin-bottom: 36px;
}
.ic-mb-40 {
  margin-bottom: 40px;
}
.ic-mb-44 {
  margin-bottom: 44px;
}
.ic-mb-48 {
  margin-bottom: 48px;
}
.ic-mb-60 {
  margin-bottom: 60px;
}
.ic-mb-80 {
  margin-bottom: 80px;
}
.ic-mb-100 {
  margin-bottom: 100px;
}
.ic-ml-0 {
  margin-left: 0 !important;
}
.ic-ml-auto {
  margin-left: auto !important;
}
.ic-ml-4 {
  margin-left: 4px;
}
.ic-ml-8 {
  margin-left: 8px;
}
.ic-ml-12 {
  margin-left: 12px;
}
.ic-ml-16 {
  margin-left: 16px;
}
.ic-ml-20 {
  margin-left: 20px;
}
.ic-ml-24 {
  margin-left: 24px;
}
.ic-ml-28 {
  margin-left: 28px;
}
.ic-ml-32 {
  margin-left: 32px;
}
.ic-ml-36 {
  margin-left: 36px;
}
.ic-ml-40 {
  margin-left: 40px;
}
.ic-ml-44 {
  margin-left: 44px;
}
.ic-ml-48 {
  margin-left: 48px;
}
.ic-ml-60 {
  margin-left: 60px;
}
.ic-ml-80 {
  margin-left: 80px;
}
.ic-ml-100 {
  margin-left: 100px;
}
.ic-p-0 {
  padding: 0 !important;
}
.ic-p-4 {
  padding: 4px;
}
.ic-p-8 {
  padding: 8px;
}
.ic-p-12 {
  padding: 12px;
}
.ic-p-16 {
  padding: 16px;
}
.ic-p-20 {
  padding: 20px;
}
.ic-p-24 {
  padding: 24px;
}
.ic-p-28 {
  padding: 28px;
}
.ic-p-32 {
  padding: 32px;
}
.ic-p-36 {
  padding: 36px;
}
.ic-p-40 {
  padding: 40px;
}
.ic-p-44 {
  padding: 44px;
}
.ic-p-48 {
  padding: 48px;
}
.ic-p-60 {
  padding: 60px;
}
.ic-p-80 {
  padding: 80px;
}
.ic-p-100 {
  padding: 100px;
}
.ic-pt-0 {
  padding-top: 0 !important;
}
.ic-pt-4 {
  padding-top: 4px;
}
.ic-pt-8 {
  padding-top: 8px;
}
.ic-pt-12 {
  padding-top: 12px;
}
.ic-pt-16 {
  padding-top: 16px;
}
.ic-pt-20 {
  padding-top: 20px;
}
.ic-pt-24 {
  padding-top: 24px;
}
.ic-pt-28 {
  padding-top: 28px;
}
.ic-pt-32 {
  padding-top: 32px;
}
.ic-pt-36 {
  padding-top: 36px;
}
.ic-pt-40 {
  padding-top: 40px;
}
.ic-pt-44 {
  padding-top: 44px;
}
.ic-pt-48 {
  padding-top: 48px;
}
.ic-pt-60 {
  padding-top: 60px;
}
.ic-pt-80 {
  padding-top: 80px;
}
.ic-pt-100 {
  padding-top: 100px;
}
.ic-pr-0 {
  padding-right: 0 !important;
}
.ic-pr-4 {
  padding-right: 4px;
}
.ic-pr-8 {
  padding-right: 8px;
}
.ic-pr-12 {
  padding-right: 12px;
}
.ic-pr-16 {
  padding-right: 16px;
}
.ic-pr-20 {
  padding-right: 20px;
}
.ic-pr-24 {
  padding-right: 24px;
}
.ic-pr-28 {
  padding-right: 28px;
}
.ic-pr-32 {
  padding-right: 32px;
}
.ic-pr-36 {
  padding-right: 36px;
}
.ic-pr-40 {
  padding-right: 40px;
}
.ic-pr-44 {
  padding-right: 44px;
}
.ic-pr-48 {
  padding-right: 48px;
}
.ic-pr-60 {
  padding-right: 60px;
}
.ic-pr-80 {
  padding-right: 80px;
}
.ic-pr-100 {
  padding-right: 100px;
}
.ic-pb-0 {
  padding-bottom: 0 !important;
}
.ic-pb-4 {
  padding-bottom: 4px;
}
.ic-pb-8 {
  padding-bottom: 8px;
}
.ic-pb-12 {
  padding-bottom: 12px;
}
.ic-pb-16 {
  padding-bottom: 16px;
}
.ic-pb-20 {
  padding-bottom: 20px;
}
.ic-pb-24 {
  padding-bottom: 24px;
}
.ic-pb-28 {
  padding-bottom: 28px;
}
.ic-pb-32 {
  padding-bottom: 32px;
}
.ic-pb-36 {
  padding-bottom: 36px;
}
.ic-pb-40 {
  padding-bottom: 40px;
}
.ic-pb-44 {
  padding-bottom: 44px;
}
.ic-pb-48 {
  padding-bottom: 48px;
}
.ic-pb-60 {
  padding-bottom: 60px;
}
.ic-pb-80 {
  padding-bottom: 80px;
}
.ic-pb-100 {
  padding-bottom: 100px;
}
.ic-pl-0 {
  padding-left: 0 !important;
}
.ic-pl-4 {
  padding-left: 4px;
}
.ic-pl-8 {
  padding-left: 8px;
}
.ic-pl-12 {
  padding-left: 12px;
}
.ic-pl-16 {
  padding-left: 16px;
}
.ic-pl-20 {
  padding-left: 20px;
}
.ic-pl-24 {
  padding-left: 24px;
}
.ic-pl-28 {
  padding-left: 28px;
}
.ic-pl-32 {
  padding-left: 32px;
}
.ic-pl-36 {
  padding-left: 36px;
}
.ic-pl-40 {
  padding-left: 40px;
}
.ic-pl-44 {
  padding-left: 44px;
}
.ic-pl-48 {
  padding-left: 48px;
}
.ic-pl-60 {
  padding-left: 60px;
}
.ic-pl-80 {
  padding-left: 80px;
}
.ic-pl-100 {
  padding-left: 100px;
}

/* open-sans-300 - latin */
@font-face {
  font-family: OpenSansLight;
  font-style: normal;
  font-weight: 300;
  src: url("/assets/fonts/OpenSans/open-sans-v18-latin-300.eot"); /* IE9 Compat Modes */
  src: local("Open Sans Light"), local("OpenSans-Light"), url("/assets/fonts/OpenSans/open-sans-v18-latin-300.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/OpenSans/open-sans-v18-latin-300.woff2") format("woff2"), url("/assets/fonts/OpenSans/open-sans-v18-latin-300.woff") format("woff"), url("/assets/fonts/OpenSans/open-sans-v18-latin-300.ttf") format("truetype"), url("/assets/fonts/OpenSans/open-sans-v18-latin-300.svg#OpenSans") format("svg"); /* Legacy iOS */
  text-rendering: optimizeLegibility;
}
/* open-sans-300italic - latin */
@font-face {
  font-family: OpenSansLight;
  font-style: italic;
  font-weight: 300;
  src: url("/assets/fonts/OpenSans/open-sans-v18-latin-300italic.eot"); /* IE9 Compat Modes */
  src: local("Open Sans Light Italic"), local("OpenSans-LightItalic"), url("/assets/fonts/OpenSans/open-sans-v18-latin-300italic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/OpenSans/open-sans-v18-latin-300italic.woff2") format("woff2"), url("/assets/fonts/OpenSans/open-sans-v18-latin-300italic.woff") format("woff"), url("/assets/fonts/OpenSans/open-sans-v18-latin-300italic.ttf") format("truetype"), url("/assets/fonts/OpenSans/open-sans-v18-latin-300italic.svg#OpenSans") format("svg"); /* Legacy iOS */
  text-rendering: optimizeLegibility;
}
/* open-sans-regular - latin */
@font-face {
  font-family: OpenSansRegular;
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/OpenSans/open-sans-v18-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url("/assets/fonts/OpenSans/open-sans-v18-latin-regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/OpenSans/open-sans-v18-latin-regular.woff2") format("woff2"), url("/assets/fonts/OpenSans/open-sans-v18-latin-regular.woff") format("woff"), url("/assets/fonts/OpenSans/open-sans-v18-latin-regular.ttf") format("truetype"), url("/assets/fonts/OpenSans/open-sans-v18-latin-regular.svg#OpenSans") format("svg"); /* Legacy iOS */
  text-rendering: optimizeLegibility;
}
/* open-sans-italic - latin */
@font-face {
  font-family: OpenSansRegular;
  font-style: italic;
  font-weight: 400;
  src: url("/assets/fonts/OpenSans/open-sans-v18-latin-italic.eot"); /* IE9 Compat Modes */
  src: local("Open Sans Italic"), local("OpenSans-Italic"), url("/assets/fonts/OpenSans/open-sans-v18-latin-italic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/OpenSans/open-sans-v18-latin-italic.woff2") format("woff2"), url("/assets/fonts/OpenSans/open-sans-v18-latin-italic.woff") format("woff"), url("/assets/fonts/OpenSans/open-sans-v18-latin-italic.ttf") format("truetype"), url("/assets/fonts/OpenSans/open-sans-v18-latin-italic.svg#OpenSans") format("svg"); /* Legacy iOS */
  text-rendering: optimizeLegibility;
}
/* open-sans-600 - latin */
@font-face {
  font-family: OpenSansSemiBold;
  font-style: normal;
  font-weight: 600;
  src: url("/assets/fonts/OpenSans/open-sans-v18-latin-600.eot"); /* IE9 Compat Modes */
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"), url("/assets/fonts/OpenSans/open-sans-v18-latin-600.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/OpenSans/open-sans-v18-latin-600.woff2") format("woff2"), url("/assets/fonts/OpenSans/open-sans-v18-latin-600.woff") format("woff"), url("/assets/fonts/OpenSans/open-sans-v18-latin-600.ttf") format("truetype"), url("/assets/fonts/OpenSans/open-sans-v18-latin-600.svg#OpenSans") format("svg"); /* Legacy iOS */
  text-rendering: optimizeLegibility;
}
/* open-sans-600italic - latin */
@font-face {
  font-family: OpenSansSemiBold;
  font-style: italic;
  font-weight: 600;
  src: url("/assets/fonts/OpenSans/open-sans-v18-latin-600italic.eot"); /* IE9 Compat Modes */
  src: local("Open Sans SemiBold Italic"), local("OpenSans-SemiBoldItalic"), url("/assets/fonts/OpenSans/open-sans-v18-latin-600italic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/OpenSans/open-sans-v18-latin-600italic.woff2") format("woff2"), url("/assets/fonts/OpenSans/open-sans-v18-latin-600italic.woff") format("woff"), url("/assets/fonts/OpenSans/open-sans-v18-latin-600italic.ttf") format("truetype"), url("/assets/fonts/OpenSans/open-sans-v18-latin-600italic.svg#OpenSans") format("svg"); /* Legacy iOS */
  text-rendering: optimizeLegibility;
}
/* open-sans-700 - latin */
@font-face {
  font-family: OpenSansBold;
  font-style: normal;
  font-weight: 700;
  src: url("/assets/fonts/OpenSans/open-sans-v18-latin-700.eot"); /* IE9 Compat Modes */
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url("/assets/fonts/OpenSans/open-sans-v18-latin-700.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/OpenSans/open-sans-v18-latin-700.woff2") format("woff2"), url("/assets/fonts/OpenSans/open-sans-v18-latin-700.woff") format("woff"), url("/assets/fonts/OpenSans/open-sans-v18-latin-700.ttf") format("truetype"), url("/assets/fonts/OpenSans/open-sans-v18-latin-700.svg#OpenSans") format("svg"); /* Legacy iOS */
  text-rendering: optimizeLegibility;
}
/* open-sans-700italic - latin */
@font-face {
  font-family: OpenSansBold;
  font-style: italic;
  font-weight: 700;
  src: url("/assets/fonts/OpenSans/open-sans-v18-latin-700italic.eot"); /* IE9 Compat Modes */
  src: local("Open Sans Bold Italic"), local("OpenSans-BoldItalic"), url("/assets/fonts/OpenSans/open-sans-v18-latin-700italic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/OpenSans/open-sans-v18-latin-700italic.woff2") format("woff2"), url("/assets/fonts/OpenSans/open-sans-v18-latin-700italic.woff") format("woff"), url("/assets/fonts/OpenSans/open-sans-v18-latin-700italic.ttf") format("truetype"), url("/assets/fonts/OpenSans/open-sans-v18-latin-700italic.svg#OpenSans") format("svg"); /* Legacy iOS */
  text-rendering: optimizeLegibility;
}
/* open-sans-800 - latin */
@font-face {
  font-family: OpenSansHeavy;
  font-style: normal;
  font-weight: 800;
  src: url("/assets/fonts/OpenSans/open-sans-v18-latin-800.eot"); /* IE9 Compat Modes */
  src: local("Open Sans ExtraBold"), local("OpenSans-ExtraBold"), url("/assets/fonts/OpenSans/open-sans-v18-latin-800.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/OpenSans/open-sans-v18-latin-800.woff2") format("woff2"), url("/assets/fonts/OpenSans/open-sans-v18-latin-800.woff") format("woff"), url("/assets/fonts/OpenSans/open-sans-v18-latin-800.ttf") format("truetype"), url("/assets/fonts/OpenSans/open-sans-v18-latin-800.svg#OpenSans") format("svg"); /* Legacy iOS */
  text-rendering: optimizeLegibility;
}
/* open-sans-800italic - latin */
@font-face {
  font-family: OpenSansHeavy;
  font-style: italic;
  font-weight: 800;
  src: url("/assets/fonts/OpenSans/open-sans-v18-latin-800italic.eot"); /* IE9 Compat Modes */
  src: local("Open Sans ExtraBold Italic"), local("OpenSans-ExtraBoldItalic"), url("/assets/fonts/OpenSans/open-sans-v18-latin-800italic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/OpenSans/open-sans-v18-latin-800italic.woff2") format("woff2"), url("/assets/fonts/OpenSans/open-sans-v18-latin-800italic.woff") format("woff"), url("/assets/fonts/OpenSans/open-sans-v18-latin-800italic.ttf") format("truetype"), url("/assets/fonts/OpenSans/open-sans-v18-latin-800italic.svg#OpenSans") format("svg"); /* Legacy iOS */
  text-rendering: optimizeLegibility;
}
body {
  font-family: OpenSansRegular, sans-serif;
}

.material-icons-outlined,
.material-icons.material-icons-outlined,
.material-icons-two-tone,
.material-icons.material-icons-two-tone,
.material-icons-round,
.material-icons.material-icons-round,
.material-icons-sharp,
.material-icons.material-icons-sharp {
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.material-icons-two-tone {
  filter: invert(0.5) sepia(1) saturate(10) hue-rotate(180deg);
}

.material-icons,
.material-icons-outlined,
.material-icons-round,
.material-icons-sharp {
  color: #4050b6;
}
.material-icons.secondary,
.material-icons-outlined.secondary,
.material-icons-round.secondary,
.material-icons-sharp.secondary {
  color: #fff;
}
.material-icons.favorable-report,
.material-icons-outlined.favorable-report,
.material-icons-round.favorable-report,
.material-icons-sharp.favorable-report {
  color: #0a8407;
}
.material-icons.favorable-but-errors-report,
.material-icons-outlined.favorable-but-errors-report,
.material-icons-round.favorable-but-errors-report,
.material-icons-sharp.favorable-but-errors-report {
  color: #e58824;
}
.material-icons.unfavorable-report,
.material-icons-outlined.unfavorable-report,
.material-icons-round.unfavorable-report,
.material-icons-sharp.unfavorable-report {
  color: #e80202;
}

.material-icons-outlined,
.material-icons.material-icons-outlined {
  font-family: "Material Icons Outlined";
}

.material-icons-two-tone,
.material-icons.material-icons-two-tone {
  font-family: "Material Icons Two Tone";
}

.material-icons-round,
.material-icons.material-icons-round {
  font-family: "Material Icons Round";
}

.material-icons-sharp,
.material-icons.material-icons-sharp {
  font-family: "Material Icons Sharp";
}

input::placeholder {
  font-weight: 100;
  font-size: 14px;
}

textarea::placeholder {
  font-weight: 100;
  font-size: 14px;
}

.mat-tooltip-autocomplete {
  font-size: 13px;
}

a.mat-mdc-tab-link.ic-tab {
  flex-grow: 0 !important;
  min-width: 160px;
  letter-spacing: 0.5px;
}

.mat-mdc-card-header .mat-mdc-card-title {
  margin-bottom: 12px;
}

.ic-display-flex-fa-icon {
  display: inline-flex;
  color: #4050b6;
}

.mat-mdc-icon-button.ic-border-button {
  border: 1px solid #e2e2e2;
  color: #4050b6;
}
.mat-mdc-icon-button.ic-border-button:hover {
  background-color: #4050b6;
  color: #fff;
}
.mat-mdc-icon-button.ic-border-button:hover .mat-icon.mat-primary {
  color: #fff;
}
.mat-mdc-icon-button.ic-border-button .mat-icon.mat-primary {
  color: #4050b6;
  text-align: center;
}
.mat-mdc-icon-button.ic-border-button .mat-icon.mat-primary:hover {
  color: #fff;
}
.mat-mdc-icon-button.ic-border-button .mat-icon.mat-primary:active {
  color: #fff;
}

.border-none {
  border: none !important;
}

mat-form-field[class*=ic-input-] {
  margin: 0 auto;
  max-width: 100%;
  margin-bottom: 1em;
}
mat-form-field[class*=ic-input-].full-width.mat-mdc-form-field {
  margin: 5px;
  line-height: 2.2;
  display: block;
}
mat-form-field[class*=ic-input-].full-width.mat-mdc-form-field .mat-mdc-text-field-wrapper,
mat-form-field[class*=ic-input-].full-width.mat-mdc-form-field .mat-mdc-form-field-wrapper {
  padding: 0 10px;
}
mat-form-field[class*=ic-input-].full-width.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex,
mat-form-field[class*=ic-input-].full-width.mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex {
  padding-top: 0;
}
mat-form-field[class*=ic-input-].full-width.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix,
mat-form-field[class*=ic-input-].full-width.mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
  width: auto;
}
mat-form-field[class*=ic-input-].full-width.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-input-element,
mat-form-field[class*=ic-input-].full-width.mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-input-element {
  font-size: 16px;
  line-height: 24px;
}
mat-form-field[class*=ic-input-].full-width.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-floating-label mat-label,
mat-form-field[class*=ic-input-].full-width.mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-floating-label mat-label {
  font-size: 13px;
}
mat-form-field[class*=ic-input-].full-width.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-floating-label.mdc-floating-label--float-above mat-label,
mat-form-field[class*=ic-input-].full-width.mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-floating-label.mdc-floating-label--float-above mat-label {
  font-size: 16px;
}
mat-form-field[class*=ic-input-].full-width.mat-mdc-form-field.mat-form-field-appearance-legacy, mat-form-field[class*=ic-input-].full-width.mat-mdc-form-field.mat-form-field-appearance-fill {
  margin: 5px;
  line-height: 2.2;
  display: block;
}
mat-form-field[class*=ic-input-].full-width.mat-mdc-form-field.mat-form-field-appearance-legacy .mat-mdc-text-field-wrapper,
mat-form-field[class*=ic-input-].full-width.mat-mdc-form-field.mat-form-field-appearance-legacy .mat-mdc-form-field-wrapper, mat-form-field[class*=ic-input-].full-width.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper,
mat-form-field[class*=ic-input-].full-width.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-form-field-wrapper {
  padding: 0 10px;
}
mat-form-field[class*=ic-input-].full-width.mat-mdc-form-field.mat-form-field-appearance-legacy .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix,
mat-form-field[class*=ic-input-].full-width.mat-mdc-form-field.mat-form-field-appearance-legacy .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix, mat-form-field[class*=ic-input-].full-width.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix,
mat-form-field[class*=ic-input-].full-width.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
  width: auto;
}
mat-form-field[class*=ic-input-].full-width.mat-mdc-form-field.mat-form-field-appearance-legacy .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-input-element,
mat-form-field[class*=ic-input-].full-width.mat-mdc-form-field.mat-form-field-appearance-legacy .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-input-element, mat-form-field[class*=ic-input-].full-width.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-input-element,
mat-form-field[class*=ic-input-].full-width.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-input-element {
  font-size: 16px;
  line-height: 24px;
}
mat-form-field[class*=ic-input-].full-width.mat-mdc-form-field.mat-form-field-appearance-legacy .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-floating-label mat-label,
mat-form-field[class*=ic-input-].full-width.mat-mdc-form-field.mat-form-field-appearance-legacy .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-floating-label mat-label, mat-form-field[class*=ic-input-].full-width.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-floating-label mat-label,
mat-form-field[class*=ic-input-].full-width.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-floating-label mat-label {
  font-size: 13px;
}
mat-form-field[class*=ic-input-].full-width.mat-mdc-form-field.mat-form-field-appearance-legacy .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-floating-label.mdc-floating-label--float-above mat-label,
mat-form-field[class*=ic-input-].full-width.mat-mdc-form-field.mat-form-field-appearance-legacy .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-floating-label.mdc-floating-label--float-above mat-label, mat-form-field[class*=ic-input-].full-width.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-floating-label.mdc-floating-label--float-above mat-label,
mat-form-field[class*=ic-input-].full-width.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-floating-label.mdc-floating-label--float-above mat-label {
  font-size: 16px;
}
mat-form-field[class*=ic-input-].full-width.mat-form-field-disabled .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-input-element,
mat-form-field[class*=ic-input-].full-width.mat-form-field-disabled .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-input-element {
  color: #777;
}
mat-form-field[class*=ic-input-].mat-mdc-form-field {
  margin: 5px;
  line-height: 2.2;
  display: block;
}
mat-form-field[class*=ic-input-].mat-mdc-form-field .mat-mdc-text-field-wrapper,
mat-form-field[class*=ic-input-].mat-mdc-form-field .mat-mdc-form-field-wrapper {
  padding: 0 10px;
}
mat-form-field[class*=ic-input-].mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex,
mat-form-field[class*=ic-input-].mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex {
  padding-top: 0;
}
mat-form-field[class*=ic-input-].mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix,
mat-form-field[class*=ic-input-].mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
  width: auto;
}
mat-form-field[class*=ic-input-].mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-input-element,
mat-form-field[class*=ic-input-].mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-input-element {
  font-size: 16px;
  line-height: 24px;
}
mat-form-field[class*=ic-input-].mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-floating-label mat-label,
mat-form-field[class*=ic-input-].mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-floating-label mat-label {
  font-size: 13px;
}
mat-form-field[class*=ic-input-].mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-floating-label.mdc-floating-label--float-above mat-label,
mat-form-field[class*=ic-input-].mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-floating-label.mdc-floating-label--float-above mat-label {
  font-size: 16px;
}
mat-form-field[class*=ic-input-].mat-mdc-form-field.mat-form-field-appearance-legacy, mat-form-field[class*=ic-input-].mat-mdc-form-field.mat-form-field-appearance-fill {
  margin: 5px;
  line-height: 2;
}
mat-form-field[class*=ic-input-].mat-mdc-form-field.mat-form-field-appearance-legacy .mat-mdc-text-field-wrapper,
mat-form-field[class*=ic-input-].mat-mdc-form-field.mat-form-field-appearance-legacy .mat-mdc-form-field-wrapper, mat-form-field[class*=ic-input-].mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper,
mat-form-field[class*=ic-input-].mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-form-field-wrapper {
  padding: 0 10px;
}
mat-form-field[class*=ic-input-].mat-mdc-form-field.mat-form-field-appearance-legacy .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix,
mat-form-field[class*=ic-input-].mat-mdc-form-field.mat-form-field-appearance-legacy .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix, mat-form-field[class*=ic-input-].mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix,
mat-form-field[class*=ic-input-].mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
  width: auto;
}
mat-form-field[class*=ic-input-].mat-mdc-form-field.mat-form-field-appearance-legacy .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-input-element,
mat-form-field[class*=ic-input-].mat-mdc-form-field.mat-form-field-appearance-legacy .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-input-element, mat-form-field[class*=ic-input-].mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-input-element,
mat-form-field[class*=ic-input-].mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-input-element {
  font-size: 16px;
  line-height: 24px;
}
mat-form-field[class*=ic-input-].mat-mdc-form-field.mat-form-field-appearance-legacy .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-floating-label mat-label,
mat-form-field[class*=ic-input-].mat-mdc-form-field.mat-form-field-appearance-legacy .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-floating-label mat-label, mat-form-field[class*=ic-input-].mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-floating-label mat-label,
mat-form-field[class*=ic-input-].mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-floating-label mat-label {
  font-size: 13px;
}
mat-form-field[class*=ic-input-].mat-mdc-form-field.mat-form-field-appearance-legacy .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-floating-label.mdc-floating-label--float-above mat-label,
mat-form-field[class*=ic-input-].mat-mdc-form-field.mat-form-field-appearance-legacy .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-floating-label.mdc-floating-label--float-above mat-label, mat-form-field[class*=ic-input-].mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-floating-label.mdc-floating-label--float-above mat-label,
mat-form-field[class*=ic-input-].mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-floating-label.mdc-floating-label--float-above mat-label {
  font-size: 16px;
}
mat-form-field[class*=ic-input-] .mdc-line-ripple,
mat-form-field[class*=ic-input-] .mat-mdc-form-field-underline {
  background-color: #ccc;
}

.mat-mdc-card.mdc-card {
  padding: 0 10px;
}

.panel-info {
  padding: 20px;
}

mat-card {
  margin-top: 20px;
  margin-bottom: 20px;
}
mat-card > mat-card-content > .two-element-row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
}
mat-card > mat-card-content > .two-element-row > .all-row-element {
  flex-basis: 100%;
  min-width: 100%;
  align-self: baseline;
}
mat-card > mat-card-content > .two-element-row > .all-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
mat-card > mat-card-content > .two-element-row > .two-element-row-element {
  flex-basis: 50%;
  min-width: 50%;
  align-self: baseline;
}
mat-card > mat-card-content > .two-element-row > .two-element-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
mat-card > mat-card-content > .two-element-row > .two-element-row-big-element {
  flex-basis: 70%;
  min-width: 70%;
  align-self: baseline;
}
mat-card > mat-card-content > .two-element-row > .two-element-row-big-element .mat-mdc-input-element {
  letter-spacing: 0;
}
mat-card > mat-card-content > .two-element-row > .two-element-row-small-element {
  flex-basis: 30%;
  min-width: 30%;
  align-self: baseline;
}
mat-card > mat-card-content > .two-element-row > .two-element-row-small-element .mat-mdc-input-element {
  letter-spacing: 0;
}
mat-card > mat-card-content > .two-element-row > .three-element-row-element {
  flex-basis: 39%;
  min-width: 39%;
  align-self: baseline;
}
mat-card > mat-card-content > .two-element-row > .three-element-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
mat-card > mat-card-content > .two-element-row > .four-element-row-small-element,
mat-card > mat-card-content > .two-element-row > .four-element-row-small-element-edit {
  flex-basis: 19%;
  min-width: 19%;
}
mat-card > mat-card-content > .two-element-row > .four-element-row-small-element .mat-mdc-input-element,
mat-card > mat-card-content > .two-element-row > .four-element-row-small-element-edit .mat-mdc-input-element {
  letter-spacing: 0;
}
mat-card > mat-card-content > .two-element-row > .four-element-row-big-element,
mat-card > mat-card-content > .two-element-row > .four-element-row-big-element-edit {
  flex-basis: 31%;
  min-width: 31%;
}
mat-card > mat-card-content > .two-element-row > .four-element-row-big-element .mat-mdc-input-element,
mat-card > mat-card-content > .two-element-row > .four-element-row-big-element-edit .mat-mdc-input-element {
  letter-spacing: 0;
}
mat-card > mat-card-content > .two-element-row > .item-row-element,
mat-card > mat-card-content > .two-element-row > .item-row-element-edit {
  flex-basis: 22%;
  min-width: 22%;
}
mat-card > mat-card-content > .two-element-row > .item-row-element .mat-mdc-input-element,
mat-card > mat-card-content > .two-element-row > .item-row-element-edit .mat-mdc-input-element {
  letter-spacing: 0;
}
mat-card > mat-card-content > .two-element-row > .des-row-element,
mat-card > mat-card-content > .two-element-row > .des-row-element-edit {
  flex-basis: 28%;
  min-width: 28%;
}
mat-card > mat-card-content > .two-element-row > .des-row-element .mat-mdc-input-element,
mat-card > mat-card-content > .two-element-row > .des-row-element-edit .mat-mdc-input-element {
  letter-spacing: 0;
}
mat-card > mat-card-content > .two-element-row > .five-element-row-small-element {
  flex-basis: 11%;
  min-width: 11%;
}
mat-card > mat-card-content > .two-element-row > .five-element-row-small-element .mat-mdc-input-element {
  letter-spacing: 0;
}
mat-card > mat-card-content > .two-element-row > .five-element-row-element,
mat-card > mat-card-content > .two-element-row > .five-element-row-element-edit {
  flex-basis: 17.5%;
  min-width: 17.5%;
}
mat-card > mat-card-content > .two-element-row > .five-element-row-element .mat-mdc-input-element,
mat-card > mat-card-content > .two-element-row > .five-element-row-element-edit .mat-mdc-input-element {
  letter-spacing: 0;
}
mat-card > mat-card-content > .two-element-row > .tax-base-row-element {
  flex-basis: 25%;
  min-width: 25%;
}
mat-card > mat-card-content > .two-element-row > .tax-base-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
mat-card > mat-card-content > .two-element-row > .tax-total-row-element {
  flex-basis: 25%;
  min-width: 25%;
}
mat-card > mat-card-content > .two-element-row > .tax-total-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
mat-card > mat-card-content > .two-element-row > .mat-icon-button {
  flex-basis: 4%;
  min-width: 4%;
  max-width: 4%;
  align-self: flex-start;
}
mat-card > mat-card-content > .two-element-row .margin-10 {
  margin: 10px;
}

.add-budgetary {
  cursor: pointer;
  vertical-align: middle;
}

.delete-budgetary {
  cursor: pointer;
  margin: 5px;
  padding: 0.9em 0;
  border-top: 0.8437;
}

.buttons-filter {
  display: flex;
  align-self: flex-end;
}

.total-label {
  font: caption;
  text-align: right;
  align-self: center;
  flex-basis: 50% !important;
  min-width: 50% !important;
  margin-bottom: 0;
  padding-top: 20px;
  padding-right: 10px;
  margin-left: -10px;
  font-weight: bold;
}
@media (max-width: 1024px) {
  .total-label {
    flex-basis: 15% !important;
    min-width: 15% !important;
  }
}

@media (max-width: 1024px) {
  mat-card > mat-card-content > .two-element-row > .two-element-row-element,
  mat-card > mat-card-content > .two-element-row > .two-element-row-big-element,
  mat-card > mat-card-content > .two-element-row > .two-element-row-small-element,
  mat-card > mat-card-content > .two-element-row > .four-element-row-small-element,
  mat-card > mat-card-content > .two-element-row > .four-element-row-small-element-edit,
  mat-card > mat-card-content > .two-element-row > .four-element-row-big-element,
  mat-card > mat-card-content > .two-element-row > .four-element-row-big-element-edit,
  mat-card > mat-card-content > .two-element-row > .item-row-element,
  mat-card > mat-card-content > .two-element-row > .item-row-element-edit,
  mat-card > mat-card-content > .two-element-row > .des-row-element,
  mat-card > mat-card-content > .two-element-row > .des-row-element-edit {
    flex-basis: 100%;
    min-width: 100%;
    max-width: 100%;
  }
  mat-card > mat-card-content > .two-element-row > .five-element-row-small-element {
    flex-basis: 30%;
    min-width: 30%;
    max-width: 100%;
  }
  mat-card > mat-card-content > .two-element-row > .five-element-row-element {
    flex-basis: 35%;
    min-width: 35%;
    max-width: 100%;
  }
  mat-card > mat-card-content > .two-element-row > .five-element-row-element-edit {
    flex-basis: 30%;
    min-width: 30%;
    max-width: 100%;
  }
  mat-card > mat-card-content > .two-element-row > .mat-icon-button {
    flex-basis: 10%;
    min-width: 10%;
    max-width: 100%;
  }
  mat-card > mat-card-content > .two-element-row > .tax-base-row-element {
    flex-basis: 42.5%;
    min-width: 42.5%;
    max-width: 100%;
  }
  mat-card > mat-card-content > .two-element-row > .tax-total-row-element {
    flex-basis: 42.5%;
    min-width: 42.5%;
    max-width: 100%;
  }
}
.mat-mdc-elevation-specific {
  max-width: -moz-fit-content !important;
  max-width: fit-content !important;
}

ic-top-nav .user-options {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}
ic-top-nav .toolbar-title {
  color: #fff;
}
ic-top-nav ic-top-nav-picker {
  padding-inline-end: 5%;
}
ic-top-nav ic-top-nav-autocomplete {
  padding-inline-end: 5%;
}
ic-top-nav.xs {
  display: block;
}
ic-top-nav.gt-xs {
  display: none;
}
@media (max-width: 640px) {
  ic-top-nav.xs {
    display: none;
  }
  ic-top-nav.gt-xs {
    display: block;
  }
}

.mat-sidenav {
  width: auto;
}
.mat-sidenav.ic-navbar {
  background-color: #000;
  width: auto;
}
.mat-sidenav.ic-navbar .mat-drawer-inner-container {
  overflow: overlay;
}

.mat-sidenav-container {
  background-color: white !important;
  height: 100vh;
}
.mat-sidenav-container .mat-sidenav-content {
  display: flex;
  flex-direction: column;
}

.sidenav-toolbar {
  height: 64px;
  background-color: #4050b6;
  display: flex;
  flex-direction: row;
}
@media (max-width: 699px) {
  .sidenav-toolbar {
    height: 56px;
  }
}

.mat-mdc-list-base .mat-mdc-list-item .mdc-list-item__content {
  padding-left: 0px !important;
}

.version-info {
  font-size: 8pt;
  padding: 8px;
  align-self: flex-end;
}

@media (max-width: 640px) {
  .fixed-topnav {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100% !important;
  }
  .mat-drawer-container,
  .mat-drawer {
    padding-top: 3.3em;
  }
  .mat-drawer-opened {
    padding-top: 5px;
  }
}
@media (max-width: 699px) {
  .mat-toolbar-row,
  .mat-toolbar-single-row {
    white-space: normal !important;
    font-size: 17px;
    line-height: 1.5;
  }
}
ic-menu-list-item {
  display: flex;
  flex-direction: column;
  outline: none;
}
ic-menu-list-item .mat-mdc-list-item.active {
  background-color: #4050b6;
}
ic-menu-list-item:hover, ic-menu-list-item:focus {
  color: white;
}
ic-menu-list-item:hover > .mat-mdc-list-item:not(.expanded), ic-menu-list-item:focus > .mat-mdc-list-item:not(.expanded) {
  background-color: #4050b6 !important;
}
ic-menu-list-item a.menu-list-item:hover {
  color: white;
  background: #4050b6;
}
ic-menu-list-item .mat-mdc-list-item {
  padding-top: 2.5em;
  padding-bottom: 2em;
  display: flex;
  width: auto;
  font-size: 15px;
}
ic-menu-list-item .mat-mdc-list-item:hover .mdc-list-item__primary-text {
  color: white;
}
ic-menu-list-item .mat-mdc-list-item .mdc-list-item__primary-text {
  color: white;
  display: flex;
  flex-basis: 100%;
  flex-direction: row;
  flex-shrink: 1;
  flex-grow: 1;
  align-items: center;
}
ic-menu-list-item .horizontal-line {
  background-color: #999;
  width: 85%;
  height: 0.1px;
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
}
ic-menu-list-item .list-component-row {
  width: 5.7em;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding-left: 0.7em;
}
ic-menu-list-item .list-component-block {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
}
ic-menu-list-item .menu-icon {
  height: 1em;
  width: 3em;
}

.layout {
  background-color: #f9f9f9;
  height: 100%;
}

.overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(74, 74, 74, 0.8);
  z-index: 99999;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mat-mdc-button-base {
  font-family: "OpenSansLight", sans-serif;
}
.mat-mdc-button-base[disabled] {
  cursor: not-allowed;
  opacity: 0.4;
}
.mat-mdc-button-base .mat-icon.mat-primary {
  color: #ffffff;
}
.mat-mdc-button-base .mat-icon.mat-primary:hover {
  color: #e2e2e2;
}
.mat-mdc-button-base .mat-icon.mat-primary[disabled] {
  color: #999;
}
.mat-button,
.mat-mdc-button {
  margin: 0 4px;
  text-transform: uppercase;
}
.mat-button.mat-primary,
.mat-mdc-button.mat-primary {
  color: #ffffff;
}
.mat-button.mat-primary:hover,
.mat-mdc-button.mat-primary:hover {
  color: #e2e2e2;
}
.mat-button.mat-primary[disabled],
.mat-mdc-button.mat-primary[disabled] {
  color: #999;
}
.mat-fab,
.mat-mdc-fab {
  margin: 0 4px;
  text-transform: uppercase;
}
.mat-fab.mat-primary[disabled],
.mat-mdc-fab.mat-primary[disabled] {
  color: #999;
}
.mat-fab.mat-accent[disabled],
.mat-mdc-fab.mat-accent[disabled] {
  color: #000000;
}
.mat-fab.bc-transparent,
.mat-mdc-fab.bc-transparent {
  background-color: transparent;
}

.mat-flat-button,
.mat-mdc-unelevated-button {
  margin: 0 4px;
  text-transform: uppercase;
}
.mat-flat-button.mat-primary[disabled],
.mat-mdc-unelevated-button.mat-primary[disabled] {
  color: #999;
}
.mat-flat-button.mat-accent[disabled],
.mat-mdc-unelevated-button.mat-accent[disabled] {
  color: #000000;
}

.mat-icon-button,
.mat-mdc-icon-button {
  text-transform: uppercase;
}
.mat-icon-button[disabled],
.mat-mdc-icon-button[disabled] {
  opacity: 0.7 !important;
}
.mat-icon-button.mat-primary[disabled],
.mat-mdc-icon-button.mat-primary[disabled] {
  color: #999;
}
.mat-icon-button.mat-accent[disabled],
.mat-mdc-icon-button.mat-accent[disabled] {
  color: #000000;
}
.mat-icon-button.bc-transparent,
.mat-mdc-icon-button.bc-transparent {
  background-color: transparent;
}

.actionIcons {
  height: 40px;
  width: 40px;
  font-size: 30px;
  margin-right: 0px;
  margin-left: 0px;
}

.mat-mini-fab,
.mat-mdc-mini-fab {
  margin: 0 4px;
  text-transform: uppercase;
}
.mat-mini-fab.mat-primary[disabled],
.mat-mdc-mini-fab.mat-primary[disabled] {
  color: #999;
}
.mat-mini-fab.mat-accent[disabled],
.mat-mdc-mini-fab.mat-accent[disabled] {
  color: #000000;
}

.mat-raised-button,
.mat-mdc-raised-button {
  margin: 0 4px;
  text-transform: uppercase;
}
.mat-raised-button.mat-primary,
.mat-mdc-raised-button.mat-primary {
  color: #ffffff;
}
.mat-raised-button.mat-primary:hover,
.mat-mdc-raised-button.mat-primary:hover {
  color: #e2e2e2;
}
.mat-raised-button.mat-primary[disabled],
.mat-mdc-raised-button.mat-primary[disabled] {
  color: #999;
}
.mat-raised-button.mat-accent,
.mat-mdc-raised-button.mat-accent {
  color: #4050b6;
}
.mat-raised-button.mat-accent:hover,
.mat-mdc-raised-button.mat-accent:hover {
  color: #293375;
}
.mat-raised-button.mat-accent[disabled],
.mat-mdc-raised-button.mat-accent[disabled] {
  color: #000000;
}

.mat-stroked-button,
.mat-mdc-outlined-button {
  margin: 0 4px;
  text-transform: uppercase;
}
.mat-stroked-button.mat-primary,
.mat-mdc-outlined-button.mat-primary {
  color: #ffffff;
}
.mat-stroked-button.mat-primary:hover,
.mat-mdc-outlined-button.mat-primary:hover {
  color: #e2e2e2;
}
.mat-stroked-button.mat-primary[disabled],
.mat-mdc-outlined-button.mat-primary[disabled] {
  color: #999;
}
.mat-stroked-button.mat-accent,
.mat-mdc-outlined-button.mat-accent {
  color: #4050b6;
}
.mat-stroked-button.mat-accent:hover,
.mat-mdc-outlined-button.mat-accent:hover {
  color: #293375;
}
.mat-stroked-button.mat-accent[disabled],
.mat-mdc-outlined-button.mat-accent[disabled] {
  color: #000000;
}

mat-spinner {
  margin: 20px auto;
}

.empty-row {
  background: #fff;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  margin-bottom: 20px;
  height: 30px;
  text-align: center;
  padding-top: 15px;
}

.ic-detail-table {
  overflow: auto;
  max-height: calc(100vh - 234px);
}
.ic-detail-table .mat-header-row,
.ic-detail-table .mat-mdc-header-row {
  background-color: #e1e1e1;
  border-bottom-width: 0;
  text-transform: capitalize;
}
.ic-detail-table .mat-header-row .mat-sort-header-button,
.ic-detail-table .mat-mdc-header-row .mat-sort-header-button {
  text-transform: uppercase;
}
.ic-detail-table .mat-row:nth-child(odd),
.ic-detail-table .mat-mdc-row:nth-child(odd) {
  background-color: #f5f5f5;
}
.ic-detail-table .mat-row,
.ic-detail-table .mat-mdc-row {
  font-family: "OpenSansLight", sans-serif;
}
.ic-detail-table .mat-row .text-bold,
.ic-detail-table .mat-mdc-row .text-bold {
  font-weight: bold;
  font-family: "OpenSansRegular", sans-serif;
}
.ic-detail-table .mat-row.highlight,
.ic-detail-table .mat-mdc-row.highlight {
  background-color: #f9f9f9;
  font-family: "OpenSansRegular", sans-serif;
}
.ic-detail-table .mat-row:hover,
.ic-detail-table .mat-mdc-row:hover {
  background-color: #f9f9f9;
  font-family: "OpenSansRegular", sans-serif;
  cursor: pointer;
}
.ic-detail-table .observations-table {
  align-items: center;
}
.ic-detail-table .observations-table > span {
  font-size: 12px;
}
.ic-detail-table .observations-table-code > span {
  font-size: 11px;
}
.ic-detail-table .mat-header-cell,
.ic-detail-table .mat-mdc-header-cell,
.ic-detail-table .mat-cell,
.ic-detail-table .mat-mdc-cell {
  margin: 0 6px;
  color: #666;
  padding-top: 5px;
  padding-bottom: 5px;
}
.ic-detail-table .mat-header-cell .mat-success,
.ic-detail-table .mat-mdc-header-cell .mat-success,
.ic-detail-table .mat-cell .mat-success,
.ic-detail-table .mat-mdc-cell .mat-success {
  color: #5cce88;
}
.ic-detail-table .mat-header-cell .mat-warning,
.ic-detail-table .mat-mdc-header-cell .mat-warning,
.ic-detail-table .mat-cell .mat-warning,
.ic-detail-table .mat-mdc-cell .mat-warning {
  color: #ee9a37;
}
.ic-detail-table .mat-header-cell .mat-error,
.ic-detail-table .mat-mdc-header-cell .mat-error,
.ic-detail-table .mat-cell .mat-error,
.ic-detail-table .mat-mdc-cell .mat-error {
  color: #f05060;
}
.ic-detail-table .mat-header-cell .mat-out,
.ic-detail-table .mat-mdc-header-cell .mat-out,
.ic-detail-table .mat-cell .mat-out,
.ic-detail-table .mat-mdc-cell .mat-out {
  color: #000000;
}
.ic-detail-table .mat-header-cell .mat-stopped,
.ic-detail-table .mat-mdc-header-cell .mat-stopped,
.ic-detail-table .mat-cell .mat-stopped,
.ic-detail-table .mat-mdc-cell .mat-stopped {
  color: #999999;
}
.ic-detail-table .mat-header-cell .mat-read,
.ic-detail-table .mat-mdc-header-cell .mat-read,
.ic-detail-table .mat-cell .mat-read,
.ic-detail-table .mat-mdc-cell .mat-read {
  color: #f05060;
}
.ic-detail-table .mat-header-cell .mat-doc,
.ic-detail-table .mat-mdc-header-cell .mat-doc,
.ic-detail-table .mat-cell .mat-doc,
.ic-detail-table .mat-mdc-cell .mat-doc {
  color: #1d5d93;
}
.ic-detail-table .mat-header-cell .mat-calc,
.ic-detail-table .mat-mdc-header-cell .mat-calc,
.ic-detail-table .mat-cell .mat-calc,
.ic-detail-table .mat-mdc-cell .mat-calc {
  color: #5cce88;
}
.ic-detail-table .mat-header-cell .mat-image,
.ic-detail-table .mat-mdc-header-cell .mat-image,
.ic-detail-table .mat-cell .mat-image,
.ic-detail-table .mat-mdc-cell .mat-image {
  color: #279ed6;
}
.ic-detail-table .mat-header-cell .mat-cross,
.ic-detail-table .mat-mdc-header-cell .mat-cross,
.ic-detail-table .mat-cell .mat-cross,
.ic-detail-table .mat-mdc-cell .mat-cross {
  color: #4050b6;
}
.ic-detail-table .mat-header-cell .mat-general,
.ic-detail-table .mat-mdc-header-cell .mat-general,
.ic-detail-table .mat-cell .mat-general,
.ic-detail-table .mat-mdc-cell .mat-general {
  color: #e53b78;
}
.ic-detail-table .mat-header-cell .mat-specific,
.ic-detail-table .mat-mdc-header-cell .mat-specific,
.ic-detail-table .mat-cell .mat-specific,
.ic-detail-table .mat-mdc-cell .mat-specific {
  color: #279ed6;
}
.ic-detail-table .mat-header-cell .mat-no-model,
.ic-detail-table .mat-mdc-header-cell .mat-no-model,
.ic-detail-table .mat-cell .mat-no-model,
.ic-detail-table .mat-mdc-cell .mat-no-model {
  color: #9e5a9e;
}
.ic-detail-table .mat-header-cell .mat-pending,
.ic-detail-table .mat-mdc-header-cell .mat-pending,
.ic-detail-table .mat-cell .mat-pending,
.ic-detail-table .mat-mdc-cell .mat-pending {
  color: #e58824;
}
.ic-detail-table .mat-header-cell .success,
.ic-detail-table .mat-mdc-header-cell .success,
.ic-detail-table .mat-cell .success,
.ic-detail-table .mat-mdc-cell .success {
  color: #5cce88;
}
.ic-detail-table .mat-header-cell .warning,
.ic-detail-table .mat-mdc-header-cell .warning,
.ic-detail-table .mat-cell .warning,
.ic-detail-table .mat-mdc-cell .warning {
  color: #ee9a37;
}
.ic-detail-table .mat-header-cell .error,
.ic-detail-table .mat-mdc-header-cell .error,
.ic-detail-table .mat-cell .error,
.ic-detail-table .mat-mdc-cell .error {
  color: #f05060;
}
.ic-detail-table .mat-header-cell .out,
.ic-detail-table .mat-mdc-header-cell .out,
.ic-detail-table .mat-cell .out,
.ic-detail-table .mat-mdc-cell .out {
  color: #000000;
}
.ic-detail-table .mat-header-cell .no-model,
.ic-detail-table .mat-mdc-header-cell .no-model,
.ic-detail-table .mat-cell .no-model,
.ic-detail-table .mat-mdc-cell .no-model {
  color: #9e5a9e;
}
.ic-detail-table .mat-checkbox .mat-checkbox-inner-container .mat-checkbox-frame,
.ic-detail-table .mat-checkbox .mat-checkbox-inner-container .mdc-checkbox__background,
.ic-detail-table .mat-checkbox .mdc-checkbox .mat-checkbox-frame,
.ic-detail-table .mat-checkbox .mdc-checkbox .mdc-checkbox__background,
.ic-detail-table .mat-mdc-checkbox .mat-checkbox-inner-container .mat-checkbox-frame,
.ic-detail-table .mat-mdc-checkbox .mat-checkbox-inner-container .mdc-checkbox__background,
.ic-detail-table .mat-mdc-checkbox .mdc-checkbox .mat-checkbox-frame,
.ic-detail-table .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
  border-color: #999;
  border-width: 1px;
}
.ic-detail-table mat-row.non-detail-row {
  display: none;
}
.ic-detail-table mat-row.note-expanded.note-color {
  background-color: #e5f0ff;
  padding-left: 20%;
}
.ic-detail-table mat-row.note-expanded.note-color .mat-cell {
  font-size: 12px;
}
.ic-detail-table mat-row.note-expanded.note-color .mat-cell .element-detail {
  display: flex;
  align-items: center;
}
.ic-detail-table mat-cell label {
  cursor: pointer;
}
.ic-detail-cell-3 {
  flex: 0 0 3%;
  justify-content: flex-start;
}
.ic-detail-cell-5 {
  flex: 0 0 5%;
  justify-content: flex-start;
}
.ic-detail-cell-7 {
  flex: 0 0 7%;
  justify-content: flex-start;
}
.ic-detail-cell-10 {
  flex: 0 0 10%;
  justify-content: flex-start;
}
.ic-detail-cell-15 {
  flex: 0 0 15%;
  justify-content: flex-start;
}
.ic-detail-cell-17 {
  flex: 0 0 17%;
  justify-content: flex-start;
}
.ic-detail-cell-20 {
  flex: 0 0 20%;
  justify-content: flex-start;
}
.ic-detail-cell-25 {
  flex: 0 0 25%;
  justify-content: flex-start;
}
.ic-detail-cell-30 {
  flex: 0 0 30%;
  justify-content: flex-start;
}
.ic-detail-cell-40 {
  flex: 0 0 40%;
  justify-content: flex-start;
}
.ic-detail-right {
  justify-content: flex-end;
}
.ic-detail-center {
  justify-content: center;
}
.ic-detail-baseline-align {
  align-items: baseline;
}
.ic-detail-row-min-width-1500 {
  min-width: 1500px;
}
.ic-detail-ellipsis {
  display: flex;
  justify-content: left;
}
.ic-detail-ellipsis > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ic-between-inputs-container {
  margin: 10px 0 10px;
  max-width: 100%;
  width: 100%;
  display: flex;
}
.ic-between-inputs-container .mat-mdc-form-field {
  margin: 10px;
  line-height: 2.2;
}
.ic-between-inputs-container .mat-mdc-form-field .mat-mdc-text-field-wrapper,
.ic-between-inputs-container .mat-mdc-form-field .mat-mdc-form-field-wrapper {
  padding: 0 10px;
}
.ic-between-inputs-container .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex,
.ic-between-inputs-container .mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex {
  padding-top: 0;
}
.ic-between-inputs-container .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .ic-between-inputs-container-form-input,
.ic-between-inputs-container .mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .ic-between-inputs-container-form-input {
  font-size: 16px;
  line-height: 24px;
}
.ic-between-inputs-container .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-form-field-underline,
.ic-between-inputs-container .mat-mdc-form-field .mat-mdc-text-field-wrapper .mdc-line-ripple,
.ic-between-inputs-container .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-underline,
.ic-between-inputs-container .mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-form-field-underline,
.ic-between-inputs-container .mat-mdc-form-field .mat-mdc-form-field-wrapper .mdc-line-ripple,
.ic-between-inputs-container .mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-underline {
  background-color: #ccc;
}

.checkbox.mat-checkbox, .checkbox.mat-mdc-checkbox {
  margin-left: 8px;
}
.checkbox.mat-checkbox.mat-checkbox-disabled, .checkbox.mat-checkbox.mat-mdc-checkbox-disabled, .checkbox.mat-mdc-checkbox.mat-checkbox-disabled, .checkbox.mat-mdc-checkbox.mat-mdc-checkbox-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.checkbox.mat-checkbox.mat-checkbox-disabled .mat-checkbox-inner-container,
.checkbox.mat-checkbox.mat-checkbox-disabled .mdc-checkbox, .checkbox.mat-checkbox.mat-mdc-checkbox-disabled .mat-checkbox-inner-container,
.checkbox.mat-checkbox.mat-mdc-checkbox-disabled .mdc-checkbox, .checkbox.mat-mdc-checkbox.mat-checkbox-disabled .mat-checkbox-inner-container,
.checkbox.mat-mdc-checkbox.mat-checkbox-disabled .mdc-checkbox, .checkbox.mat-mdc-checkbox.mat-mdc-checkbox-disabled .mat-checkbox-inner-container,
.checkbox.mat-mdc-checkbox.mat-mdc-checkbox-disabled .mdc-checkbox {
  background-color: #eee;
}
.checkbox.mat-checkbox .mat-checkbox-inner-container .mat-checkbox-frame,
.checkbox.mat-checkbox .mat-checkbox-inner-container .mdc-checkbox__background,
.checkbox.mat-checkbox .mdc-checkbox .mat-checkbox-frame,
.checkbox.mat-checkbox .mdc-checkbox .mdc-checkbox__background, .checkbox.mat-mdc-checkbox .mat-checkbox-inner-container .mat-checkbox-frame,
.checkbox.mat-mdc-checkbox .mat-checkbox-inner-container .mdc-checkbox__background,
.checkbox.mat-mdc-checkbox .mdc-checkbox .mat-checkbox-frame,
.checkbox.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
  border-color: #999;
  border-width: 1px;
}

.ic-datepicker {
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
  margin-bottom: 1em;
  display: flex;
  align-items: baseline;
}
.ic-datepicker .ic-datepicker-column {
  margin-right: 5px;
}
.ic-datepicker .ic-datepicker-column:last-child {
  margin-right: 0;
}
.ic-datepicker .standard-datepicker {
  width: 100%;
  margin: auto;
}
.ic-datepicker .mat-mdc-form-field {
  line-height: 2;
  width: 100%;
  /** No CSS for this example */
}
.ic-datepicker .mat-mdc-form-field .mat-mdc-form-field-wrapper,
.ic-datepicker .mat-mdc-form-field .mat-mdc-text-field-wrapper {
  padding: 0 10px;
}
.ic-datepicker .mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex,
.ic-datepicker .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
  padding-top: 0;
}
.ic-datepicker .mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-form-field-infix .ic-datepicker-form-input,
.ic-datepicker .mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .ic-datepicker-form-input,
.ic-datepicker .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-form-field-infix .ic-datepicker-form-input,
.ic-datepicker .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .ic-datepicker-form-input {
  font-size: 16px;
  line-height: 24px;
}
.ic-datepicker .mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-form-field-infix .mat-mdc-floating-label mat-label,
.ic-datepicker .mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-floating-label mat-label,
.ic-datepicker .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-form-field-infix .mat-mdc-floating-label mat-label,
.ic-datepicker .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-floating-label mat-label {
  font-size: 13px;
}
.ic-datepicker .mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-form-field-infix .mat-mdc-floating-label.mdc-floating-label--float-above mat-label,
.ic-datepicker .mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-floating-label.mdc-floating-label--float-above mat-label,
.ic-datepicker .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-form-field-infix .mat-mdc-floating-label.mdc-floating-label--float-above mat-label,
.ic-datepicker .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-floating-label.mdc-floating-label--float-above mat-label {
  font-size: 16px;
}
.ic-datepicker .mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-form-field-underline,
.ic-datepicker .mat-mdc-form-field .mat-mdc-form-field-wrapper .mdc-line-ripple,
.ic-datepicker .mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-underline,
.ic-datepicker .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-form-field-underline,
.ic-datepicker .mat-mdc-form-field .mat-mdc-text-field-wrapper .mdc-line-ripple,
.ic-datepicker .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-underline {
  background-color: #ccc;
}
.ic-datepicker .mat-mdc-form-field .mat-datepicker-input {
  width: 85%;
  position: relative;
  color: #000;
}
.ic-datepicker .mat-mdc-form-field .mat-date-range-input {
  width: 85%;
  position: relative;
}
.ic-datepicker .mat-mdc-form-field mat-icon {
  position: absolute;
  right: 0;
  bottom: 18px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.54);
}

.mat-raised-button.mat-primary,
.mat-mdc-raised-button.mat-primary {
  background-color: #4050b6;
  color: #ffffff;
}

.mat-dialog-container .input-dialog,
.mat-mdc-dialog-container .input-dialog {
  width: 100%;
  max-width: 750px;
  height: 100%;
  max-height: 550px;
}
.mat-dialog-container .input-dialog .dialog-title,
.mat-mdc-dialog-container .input-dialog .dialog-title {
  color: #333;
  font-size: 20px;
}
.mat-dialog-container .input-dialog .mat-mdc-dialog-content,
.mat-mdc-dialog-container .input-dialog .mat-mdc-dialog-content {
  overflow-y: hidden;
  padding-bottom: 5px;
}
.mat-dialog-container .input-dialog .mat-mdc-dialog-actions,
.mat-mdc-dialog-container .input-dialog .mat-mdc-dialog-actions {
  justify-content: flex-end;
  min-height: inherit;
  padding-right: 24px;
}

.mat-dialog-content.dialog-pdf,
.mat-mdc-dialog-content.dialog-pdf {
  height: 100%;
  overflow: hidden;
}

.mat-raised-button.mat-primary,
.mat-mdc-raised-button.mat-primary {
  background-color: #4050b6;
  color: #ffffff;
}

.dialog-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  gap: 0.5em;
  place-content: center flex-start;
}

.mat-raised-button.mat-primary,
.mat-mdc-raised-button.mat-primary {
  background-color: #4050b6;
  color: #ffffff;
}

.mat-mdc-dialog-container,
.mat-dialog-container {
  padding: 16px 24px 8px;
}
.mat-mdc-dialog-container .simple-dialog,
.mat-dialog-container .simple-dialog {
  height: auto;
  max-width: 560px;
  width: 560px;
  padding: 1.5em;
}
.mat-mdc-dialog-container .simple-dialog .dialog-title,
.mat-mdc-dialog-container .simple-dialog .mat-mdc-dialog-title,
.mat-dialog-container .simple-dialog .dialog-title,
.mat-dialog-container .simple-dialog .mat-mdc-dialog-title {
  color: #333;
  font-size: 20px;
}
.mat-mdc-dialog-container .simple-dialog .dialog-content,
.mat-mdc-dialog-container .simple-dialog .mat-mdc-dialog-content,
.mat-dialog-container .simple-dialog .dialog-content,
.mat-dialog-container .simple-dialog .mat-mdc-dialog-content {
  overflow-y: hidden;
  padding: 0;
}
.mat-mdc-dialog-container .simple-dialog .dialog-content .dialog-content-text,
.mat-mdc-dialog-container .simple-dialog .mat-mdc-dialog-content .dialog-content-text,
.mat-dialog-container .simple-dialog .dialog-content .dialog-content-text,
.mat-dialog-container .simple-dialog .mat-mdc-dialog-content .dialog-content-text {
  color: #666;
  font-size: 14px;
  line-height: 24px;
  margin: 2em 0;
}
.mat-mdc-dialog-container .simple-dialog .dialog-content .dialog-actions,
.mat-mdc-dialog-container .simple-dialog .mat-mdc-dialog-content .dialog-actions,
.mat-dialog-container .simple-dialog .dialog-content .dialog-actions,
.mat-dialog-container .simple-dialog .mat-mdc-dialog-content .dialog-actions {
  justify-content: flex-end;
  min-height: inherit;
  padding: 1em 0;
}
.mat-mdc-dialog-container .simple-dialog .dialog-content .dialog-actions .dialog-actions-button,
.mat-mdc-dialog-container .simple-dialog .mat-mdc-dialog-content .dialog-actions .dialog-actions-button,
.mat-dialog-container .simple-dialog .dialog-content .dialog-actions .dialog-actions-button,
.mat-dialog-container .simple-dialog .mat-mdc-dialog-content .dialog-actions .dialog-actions-button {
  text-transform: uppercase;
}

.ic-dnd-container {
  width: 450px;
  height: 200px;
  padding: 2rem;
  text-align: center;
  border: dashed 1px #979797;
  position: relative;
  margin: 0 auto;
}
.ic-dnd-container input {
  opacity: 0;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.ic-dnd-container label {
  color: white;
  width: 183px;
  height: 44px;
  border-radius: 21.5px;
  background-color: #db202f;
  padding: 8px 16px;
}
.ic-dnd-container h3 {
  font-size: 20px;
  font-weight: 600;
  color: #38424c;
}

.ic-dnd-fileover {
  animation: shake 1s;
  animation-iteration-count: infinite;
}

.ic-dnd-files-list {
  margin-top: 1.5rem;
}
.ic-dnd-files-list .ic-dnd-single-file {
  display: flex;
  padding: 0.5rem;
  justify-content: space-between;
  align-items: center;
  border: dashed 1px #979797;
  margin-bottom: 1rem;
  display: flex;
  flex-grow: 1;
}
.ic-dnd-files-list .ic-dnd-single-file .ic-dnd-delete {
  display: flex;
  margin-left: 0.5rem;
  cursor: pointer;
  align-self: flex-end;
}
.ic-dnd-files-list .ic-dnd-single-file .ic-dnd-name {
  font-size: 14px;
  font-weight: 500;
  color: #353f4a;
  margin: 0;
}
.ic-dnd-files-list .ic-dnd-single-file .ic-dnd-size {
  font-size: 12px;
  font-weight: 500;
  color: #a4a4a4;
  margin: 0;
  margin-bottom: 0.25rem;
}
.ic-dnd-files-list .ic-dnd-single-file .ic-dnd-info {
  width: 100%;
}

/* Shake animation */
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
:host {
  display: block;
  color: red;
  font-size: 8pt;
}

.ic-input-autocomplete-container-autocomplete mat-option span {
  font-size: 16px !important;
}

.small-font {
  font-size: 12px !important;
}

.no-min-size {
  min-width: 0px;
}
.no-min-size .mat-form-field-infix {
  width: 40%;
}

.half-width {
  width: 50%;
}

.filter-class {
  width: 96%;
  align-self: center;
  justify-self: center;
}

.margin-10 {
  margin: 10px;
}

.textarea-height {
  height: 250px;
}

.textarea-extra-height {
  height: 380px !important;
}

.mat-tree-node .mat-mdc-outlined-button {
  background-color: transparent;
  font-weight: bold;
}
.mat-tree-node .mat-mdc-outlined-button .file-type {
  line-height: 1;
}

.example-tree-invisible {
  display: none;
}

.example-tree ul,
.example-tree li {
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.mat-tree-node {
  font-size: 13px;
}
.mat-tree-node > .mat-stroked-button,
.mat-tree-node > .mat-mdc-outlined-button {
  font-size: 13px;
  padding: 0 8px;
}

.file-type {
  font-size: 20px;
  line-height: 24px;
}

span.mdc-button__label {
  text-align: left;
}

.text-file-type {
  padding: 0 7px;
}

.four-element-row-small-element .ic-input-container {
  min-width: 0px;
}
.four-element-row-small-element .ic-input-container .mat-mdc-form-field-infix {
  width: 40%;
}

.four-element-row-big-element .ic-input-container {
  min-width: 0px;
}
.four-element-row-big-element .ic-input-container .mat-mdc-form-field-infix {
  width: 60%;
}

.two-element-row-small-element .ic-input-container {
  min-width: 0px;
}
.two-element-row-small-element .ic-input-container .mat-mdc-form-field-infix {
  width: 40%;
}

.two-element-row-big-element .ic-input-container {
  min-width: 0px;
}

.display-block {
  display: block !important;
}

:host {
  display: block;
}

.list-filter {
  position: relative;
}
.list-filter .list-filter-list {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #e2e2e2;
  color: #999;
  align-items: center;
}
.list-filter .list-filter-list input {
  flex-grow: 1;
}
.list-filter .list-filter-list .list-filter-input {
  font-family: "OpenSansLight", sans-serif;
  border-style: none;
  background-color: transparent;
}
.list-filter .list-filter-list .list-filter-input:hover, .list-filter .list-filter-list .list-filter-input:focus {
  outline: none;
}
.list-filter .list-filter-panel {
  position: absolute;
  width: 100%;
  background-color: white;
  left: -68px;
  min-width: min-content;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  z-index: 999;
  display: flex;
  flex-direction: column;
  padding: 8px;
}
.list-filter .list-filter-panel-closeButton {
  align-self: flex-end;
}
.list-filter .list-filter-panel-content {
  flex-grow: 1;
  min-width: max-content;
  margin-top: 10px;
}
.list-filter .list-filter-panel-content .placeholder {
  border: 1px dashed #e2e2e2;
  font-style: italic;
  border-radius: 2px;
}

:host {
  display: block;
}

.searchbox-filter {
  position: relative;
}
.searchbox-filter .searchbox-filter-searchbox {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  border-radius: 26px;
  border: 1px solid #e2e2e2;
  color: #999;
  padding: 0 18px;
  align-items: center;
}
.searchbox-filter .searchbox-filter-searchbox input {
  flex-grow: 1;
}
.searchbox-filter .searchbox-filter-searchbox .searchbox-filter-input {
  font-family: "OpenSansLight", sans-serif;
  border-style: none;
  background-color: transparent;
  min-height: 46px;
}
.searchbox-filter .searchbox-filter-searchbox .searchbox-filter-input:hover, .searchbox-filter .searchbox-filter-searchbox .searchbox-filter-input:focus {
  outline: none;
}
.searchbox-filter .searchbox-filter-panel {
  position: absolute;
  width: 100%;
  background-color: white;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  z-index: 999;
  display: flex;
  flex-direction: column;
  padding: 8px;
}
.searchbox-filter .searchbox-filter-panel-closeButton {
  align-self: flex-end;
}
.searchbox-filter .searchbox-filter-panel-content {
  flex-grow: 1;
}
.searchbox-filter .searchbox-filter-panel-content .placeholder {
  border: 1px dashed #e2e2e2;
  font-style: italic;
  border-radius: 2px;
}

ic-slidetoggle .mat-mdc-slide-toggle {
  margin-bottom: 1em;
}
ic-slidetoggle .mat-mdc-slide-toggle .mdc-form-field .mdc-switch {
  position: relative;
  width: 36px;
  height: 14px;
  flex-shrink: 0;
  border-radius: 8px;
}
ic-slidetoggle .mat-mdc-slide-toggle .mdc-form-field .mdc-switch.mdc-switch--disabled {
  background-color: rgba(64, 80, 182, 0.54);
}
ic-slidetoggle .mat-mdc-slide-toggle .mdc-form-field .mdc-switch.mdc-switch--disabled .mdc-switch__icons {
  background-color: #4050b6;
  border-radius: 50%;
}

mat-card-title > ic-slidetoggle > .mat-mdc-slide-toggle > .mdc-form-field > label {
  font-size: 20px;
  font-weight: 500;
}

.ic-searchbox {
  background-color: white;
  box-shadow: none;
  height: 66px;
  display: flex;
  align-items: center;
  font-family: "OpenSansBold", sans-serif;
  padding-left: 10px;
}
@media (max-width: 499px) {
  .ic-searchbox {
    overflow: scroll;
  }
}
.ic-searchbox .ic-searchbox-title {
  flex-basis: 312px;
  display: inline-flex;
  align-items: center;
}
@media (max-width: 599px) {
  .ic-searchbox .ic-searchbox-title {
    display: none;
  }
}
.ic-searchbox .ic-searchbox-title-logo {
  width: 32px;
  height: 32px;
  display: inline-block;
}
.ic-searchbox .ic-searchbox-searchbox {
  flex-grow: 1;
  flex-basis: 720px;
  margin: 10px;
}
.ic-searchbox .ic-searchbox-options {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}
.ic-searchbox {
  background-color: white;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  height: 65px;
  display: flex;
  align-items: center;
  font-family: "OpenSansBold", sans-serif;
  padding-left: 10px;
}
@media (max-width: 499px) {
  .ic-searchbox {
    overflow: scroll;
  }
}
.ic-searchbox .ic-searchbox-title {
  flex-basis: 312px;
  display: inline-flex;
  align-items: center;
}
@media (max-width: 599px) {
  .ic-searchbox .ic-searchbox-title {
    display: none;
  }
}
.ic-searchbox .ic-searchbox-title-logo {
  width: 32px;
  height: 32px;
  display: inline-block;
}
.ic-searchbox .ic-searchbox-searchbox {
  flex-grow: 1;
  flex-basis: 720px;
}
.ic-searchbox .ic-searchbox-options {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}
.mat-tree-node .mat-mdc-outlined-button {
  background-color: transparent;
  font-weight: bold;
}
.mat-tree-node .mat-mdc-outlined-button .file-type {
  line-height: 1;
}

.example-tree-invisible {
  display: none;
}

.example-tree ul,
.example-tree li {
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.mat-tree-node {
  font-size: 13px;
}
.mat-tree-node > .mat-stroked-button,
.mat-tree-node > .mat-mdc-outlined-button {
  font-size: 13px;
  padding: 0 8px;
  text-transform: none;
}

.file-type {
  font-size: 20px;
  line-height: 24px;
}

span.mdc-button__label {
  text-align: left;
}

.text-file-type {
  padding: 0 7px;
}

.strikethrough {
  text-decoration: line-through;
}

.mat-icon-button.menu-options-button,
.mat-mdc-icon-button.menu-options-button {
  height: 48px;
  line-height: 48px;
  margin: 0 2px;
  width: 48px;
}
.mat-icon-button.menu-options-button:hover,
.mat-mdc-icon-button.menu-options-button:hover {
  background-color: rgba(255, 255, 255, 0.16);
}
.mat-icon-button.menu-options-button[disabled],
.mat-mdc-icon-button.menu-options-button[disabled] {
  cursor: not-allowed;
  opacity: 0.4;
}
.mat-icon-button.menu-options-button[disabled]:hover,
.mat-mdc-icon-button.menu-options-button[disabled]:hover {
  background-color: transparent;
}

.mat-menu-panel.mat-menu-options-simple,
.mat-mdc-menu-panel.mat-menu-options-simple {
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  max-width: 320px;
  min-width: 230px;
  text-align: center;
}
.mat-menu-panel.mat-menu-options-simple .mat-mdc-menu-content .mat-menu-item,
.mat-menu-panel.mat-menu-options-simple .mat-mdc-menu-content .mat-mdc-menu-item,
.mat-mdc-menu-panel.mat-menu-options-simple .mat-mdc-menu-content .mat-menu-item,
.mat-mdc-menu-panel.mat-menu-options-simple .mat-mdc-menu-content .mat-mdc-menu-item {
  padding: 0 10px;
}
.mat-menu-panel.mat-menu-options-simple .mat-mdc-menu-content .mat-menu-item .mdc-list-item__primary-text,
.mat-menu-panel.mat-menu-options-simple .mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text,
.mat-mdc-menu-panel.mat-menu-options-simple .mat-mdc-menu-content .mat-menu-item .mdc-list-item__primary-text,
.mat-mdc-menu-panel.mat-menu-options-simple .mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  color: #666;
  font-size: 14px !important;
  letter-spacing: 0 !important;
}
.mat-menu-panel.mat-menu-options-simple .mat-mdc-menu-content .mat-menu-item[disabled],
.mat-menu-panel.mat-menu-options-simple .mat-mdc-menu-content .mat-mdc-menu-item[disabled],
.mat-mdc-menu-panel.mat-menu-options-simple .mat-mdc-menu-content .mat-menu-item[disabled],
.mat-mdc-menu-panel.mat-menu-options-simple .mat-mdc-menu-content .mat-mdc-menu-item[disabled] {
  cursor: not-allowed;
  opacity: 0.3;
}
.mat-menu-panel.mat-menu-options-simple .mat-raised-button,
.mat-menu-panel.mat-menu-options-simple .mat-mdc-raised-button,
.mat-mdc-menu-panel.mat-menu-options-simple .mat-raised-button,
.mat-mdc-menu-panel.mat-menu-options-simple .mat-mdc-raised-button {
  border-radius: 4px;
  box-shadow: none;
  margin: 4px 0;
  text-transform: uppercase;
  width: calc(100% - 32px);
}
.mat-menu-panel.mat-menu-options-simple .mat-raised-button:first,
.mat-menu-panel.mat-menu-options-simple .mat-mdc-raised-button:first,
.mat-mdc-menu-panel.mat-menu-options-simple .mat-raised-button:first,
.mat-mdc-menu-panel.mat-menu-options-simple .mat-mdc-raised-button:first {
  margin: 0;
}
.mat-menu-panel.mat-menu-options-simple .mat-divider.mat-divider-horizontal,
.mat-mdc-menu-panel.mat-menu-options-simple .mat-divider.mat-divider-horizontal {
  margin: 8px 0;
}

.progress-cont {
  height: 7px;
  width: 100%;
  border-radius: 4px;
  background-color: #d0d0d0;
  position: relative;
}
.progress-cont .progress {
  width: 0;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  border-radius: 4px;
  background-color: #4c97cb;
  transition: 0.5s all;
}

ic-top-nav-picker .icon-color {
  color: white;
}
ic-top-nav-picker .mat-mdc-button > .mat-icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
}

ic-top-nav-autocomplete .top-nav-searchbox {
  margin-top: 22px;
  width: 400px;
}

mat-spinner {
  margin: 20px auto;
}

.empty-row {
  background: #fff;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  margin-bottom: 20px;
  height: 30px;
  text-align: center;
  padding-top: 15px;
}

.ic-selectable-table {
  overflow: auto;
  max-height: calc(100vh - 234px);
}
.ic-selectable-table.ic-not-cursor-table .mat-row:hover,
.ic-selectable-table.ic-not-cursor-table .mat-mdc-row:hover {
  cursor: default !important;
}
.ic-selectable-table mat-header-row.mat-mdc-header-row {
  height: 66px !important;
}
.ic-selectable-table mat-row.mat-mdc-row,
.ic-selectable-table mat-footer-row.mat-mdc-footer-cell {
  height: 58px !important;
}
.ic-selectable-table .mat-header-row,
.ic-selectable-table .mat-mdc-header-row {
  background-color: #e1e1e1;
  border-bottom-width: 0;
  text-transform: capitalize;
  padding-left: 10px;
}
.ic-selectable-table .mat-header-row .mat-sort-header-button,
.ic-selectable-table .mat-mdc-header-row .mat-sort-header-button {
  text-transform: uppercase;
}
.ic-selectable-table .mat-header-row .mat-sort-header-arrow,
.ic-selectable-table .mat-mdc-header-row .mat-sort-header-arrow {
  margin: 0 0 0 2px;
}
.ic-selectable-table .prescrivet-icon {
  display: inline-block;
  width: 100px;
  height: 100px;
  background: url("/assets/images/cuadrado.png") no-repeat center top;
  background-size: contain;
}
.ic-selectable-table .mat-row:nth-child(odd),
.ic-selectable-table .mat-mdc-row:nth-child(odd) {
  background-color: #f5f5f5 !important;
}
.ic-selectable-table .mat-row:nth-child(odd).last-touched,
.ic-selectable-table .mat-mdc-row:nth-child(odd).last-touched {
  background-color: #e3edff !important;
}
.ic-selectable-table .mat-row:nth-child(odd).obs-highlight,
.ic-selectable-table .mat-mdc-row:nth-child(odd).obs-highlight {
  background-color: #fff7ef !important;
}
.ic-selectable-table .mat-row:nth-child(even).obs-highlight,
.ic-selectable-table .mat-mdc-row:nth-child(even).obs-highlight {
  background-color: #fff5ea !important;
}
.ic-selectable-table .mat-row.is-touching,
.ic-selectable-table .mat-mdc-row.is-touching {
  background-color: #efd1b0 !important;
  font-weight: bold !important;
}
.ic-selectable-table .mat-row:hover.is-touching,
.ic-selectable-table .mat-mdc-row:hover.is-touching {
  background-color: #efd1b0 !important;
  font-family: "OpenSansRegular", sans-serif;
  cursor: pointer !important;
}
.ic-selectable-table .mat-row,
.ic-selectable-table .mat-mdc-row {
  font-family: "OpenSansLight", sans-serif;
  border-color: rgba(0, 0, 0, 0.12);
  color: #666;
  border-width: 0;
  border-style: solid;
  border-bottom-width: 0.5px;
  padding-left: 10px;
}
.ic-selectable-table .mat-row .text-bold,
.ic-selectable-table .mat-mdc-row .text-bold {
  font-weight: bold;
  font-family: "OpenSansRegular", sans-serif;
}
.ic-selectable-table .mat-row.highlight,
.ic-selectable-table .mat-mdc-row.highlight {
  background-color: #f9f9f9;
  font-family: "OpenSansRegular", sans-serif;
}
.ic-selectable-table .mat-row-non-pointer {
  cursor: default !important;
}
.ic-selectable-table .mat-row:hover:not(.ic-not-cursor-table .mat-row:hover),
.ic-selectable-table .mat-mdc-row:hover:not(.ic-not-cursor-table .mat-mdc-row:hover) {
  background-color: #f9f9f9 !important;
  cursor: pointer;
}
.ic-selectable-table .mat-row:hover.last-touched,
.ic-selectable-table .mat-mdc-row:hover.last-touched {
  background-color: #e3edff !important;
  font-family: "OpenSansRegular", sans-serif;
  cursor: pointer;
}
.ic-selectable-table .mat-row:hover.obs-highlight,
.ic-selectable-table .mat-mdc-row:hover.obs-highlight {
  background-color: #feeedc !important;
  font-family: "OpenSansRegular", sans-serif;
  cursor: pointer;
}
.ic-selectable-table .observations-table {
  align-items: start;
}
.ic-selectable-table .observations-table > span {
  font-size: 12px;
}
.ic-selectable-table .observations-table-code > span {
  font-size: 11px;
}
.ic-selectable-table .mat-header-cell,
.ic-selectable-table .mat-mdc-header-cell,
.ic-selectable-table .mat-cell,
.ic-selectable-table .mat-mdc-cell {
  padding-top: 5px;
  padding-bottom: 5px;
}
.ic-selectable-table .mat-header-cell .mat-success,
.ic-selectable-table .mat-mdc-header-cell .mat-success,
.ic-selectable-table .mat-cell .mat-success,
.ic-selectable-table .mat-mdc-cell .mat-success {
  color: #5cce88;
}
.ic-selectable-table .mat-header-cell .mat-warning,
.ic-selectable-table .mat-mdc-header-cell .mat-warning,
.ic-selectable-table .mat-cell .mat-warning,
.ic-selectable-table .mat-mdc-cell .mat-warning {
  color: #ee9a37;
}
.ic-selectable-table .mat-header-cell .mat-error,
.ic-selectable-table .mat-mdc-header-cell .mat-error,
.ic-selectable-table .mat-cell .mat-error,
.ic-selectable-table .mat-mdc-cell .mat-error {
  color: #f05060;
}
.ic-selectable-table .mat-header-cell .mat-out,
.ic-selectable-table .mat-mdc-header-cell .mat-out,
.ic-selectable-table .mat-cell .mat-out,
.ic-selectable-table .mat-mdc-cell .mat-out {
  color: #000000;
}
.ic-selectable-table .mat-header-cell .mat-stopped,
.ic-selectable-table .mat-mdc-header-cell .mat-stopped,
.ic-selectable-table .mat-cell .mat-stopped,
.ic-selectable-table .mat-mdc-cell .mat-stopped {
  color: #999999;
}
.ic-selectable-table .mat-header-cell .mat-read,
.ic-selectable-table .mat-mdc-header-cell .mat-read,
.ic-selectable-table .mat-cell .mat-read,
.ic-selectable-table .mat-mdc-cell .mat-read {
  color: #f05060;
}
.ic-selectable-table .mat-header-cell .mat-doc,
.ic-selectable-table .mat-mdc-header-cell .mat-doc,
.ic-selectable-table .mat-cell .mat-doc,
.ic-selectable-table .mat-mdc-cell .mat-doc {
  color: #1d5d93;
}
.ic-selectable-table .mat-header-cell .mat-calc,
.ic-selectable-table .mat-mdc-header-cell .mat-calc,
.ic-selectable-table .mat-cell .mat-calc,
.ic-selectable-table .mat-mdc-cell .mat-calc {
  color: #5cce88;
}
.ic-selectable-table .mat-header-cell .mat-image,
.ic-selectable-table .mat-mdc-header-cell .mat-image,
.ic-selectable-table .mat-cell .mat-image,
.ic-selectable-table .mat-mdc-cell .mat-image {
  color: #279ed6;
}
.ic-selectable-table .mat-header-cell .mat-cross,
.ic-selectable-table .mat-mdc-header-cell .mat-cross,
.ic-selectable-table .mat-cell .mat-cross,
.ic-selectable-table .mat-mdc-cell .mat-cross {
  color: #4050b6;
}
.ic-selectable-table .mat-header-cell .mat-general,
.ic-selectable-table .mat-mdc-header-cell .mat-general,
.ic-selectable-table .mat-cell .mat-general,
.ic-selectable-table .mat-mdc-cell .mat-general {
  color: #e53b78;
}
.ic-selectable-table .mat-header-cell .mat-specific,
.ic-selectable-table .mat-mdc-header-cell .mat-specific,
.ic-selectable-table .mat-cell .mat-specific,
.ic-selectable-table .mat-mdc-cell .mat-specific {
  color: #279ed6;
}
.ic-selectable-table .mat-header-cell .mat-no-model,
.ic-selectable-table .mat-mdc-header-cell .mat-no-model,
.ic-selectable-table .mat-cell .mat-no-model,
.ic-selectable-table .mat-mdc-cell .mat-no-model {
  color: #9e5a9e;
}
.ic-selectable-table .mat-header-cell .mat-pending,
.ic-selectable-table .mat-mdc-header-cell .mat-pending,
.ic-selectable-table .mat-cell .mat-pending,
.ic-selectable-table .mat-mdc-cell .mat-pending {
  color: #e58824;
}
.ic-selectable-table .mat-header-cell .success,
.ic-selectable-table .mat-mdc-header-cell .success,
.ic-selectable-table .mat-cell .success,
.ic-selectable-table .mat-mdc-cell .success {
  color: #5cce88;
}
.ic-selectable-table .mat-header-cell .warning,
.ic-selectable-table .mat-mdc-header-cell .warning,
.ic-selectable-table .mat-cell .warning,
.ic-selectable-table .mat-mdc-cell .warning {
  color: #ee9a37;
}
.ic-selectable-table .mat-header-cell .error,
.ic-selectable-table .mat-mdc-header-cell .error,
.ic-selectable-table .mat-cell .error,
.ic-selectable-table .mat-mdc-cell .error {
  color: #f05060;
}
.ic-selectable-table .mat-header-cell .out,
.ic-selectable-table .mat-mdc-header-cell .out,
.ic-selectable-table .mat-cell .out,
.ic-selectable-table .mat-mdc-cell .out {
  color: #000000;
}
.ic-selectable-table .mat-header-cell .no-model,
.ic-selectable-table .mat-mdc-header-cell .no-model,
.ic-selectable-table .mat-cell .no-model,
.ic-selectable-table .mat-mdc-cell .no-model {
  color: #9e5a9e;
}
.ic-selectable-table .mat-cell .mat-checkbox-inner-container .mat-checkbox-frame,
.ic-selectable-table .mat-cell .mat-checkbox-inner-container .mdc-checkbox__background,
.ic-selectable-table .mat-cell .mdc-checkbox .mat-checkbox-frame,
.ic-selectable-table .mat-cell .mdc-checkbox .mdc-checkbox__background,
.ic-selectable-table .mat-mdc-cell .mat-checkbox .mat-checkbox-inner-container .mat-checkbox-frame,
.ic-selectable-table .mat-mdc-cell .mat-checkbox .mat-checkbox-inner-container .mdc-checkbox__background,
.ic-selectable-table .mat-mdc-cell .mat-checkbox .mdc-checkbox .mat-checkbox-frame,
.ic-selectable-table .mat-mdc-cell .mat-checkbox .mdc-checkbox .mdc-checkbox__background,
.ic-selectable-table .mat-mdc-checkbox .mat-checkbox-inner-container .mat-checkbox-frame,
.ic-selectable-table .mat-mdc-checkbox .mat-checkbox-inner-container .mdc-checkbox__background,
.ic-selectable-table .mat-mdc-checkbox .mdc-checkbox .mat-checkbox-frame,
.ic-selectable-table .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
  border-color: #999;
  border-width: 1px;
}
.ic-selectable-table .mat-header-cell:last-child,
.ic-selectable-table .mat-mdc-header-cell:last-child,
.ic-selectable-table .mat-cell:last-child,
.ic-selectable-table .mat-mdc-cell:last-child {
  margin-right: 10px;
}
.ic-selectable-table .mdc-data-table__cell,
.ic-selectable-table .mdc-data-table__header-cell {
  padding: 0 1px 0 1px;
}
.ic-selectable-table mat-cell,
.ic-selectable-table mat-header-cell {
  border: none;
  letter-spacing: 0px;
}
.ic-selectable-table mat-cell label,
.ic-selectable-table mat-header-cell label {
  cursor: pointer;
}
.ic-selectable-table mat-header-row {
  justify-content: space-evenly;
}
.ic-selectable-table mat-header-row mat-header-cell {
  font-size: 11.5px;
  font-weight: 500;
}
.ic-selectable-table mat-row {
  justify-content: space-evenly;
}
.ic-selectable-table mat-row:hover mat-cell {
  color: rgb(0, 0, 0);
  font-family: "OpenSansRegular", sans-serif;
}
.ic-selectable-table mat-row mat-cell {
  font-family: "OpenSansLight", sans-serif;
  font-size: 14px;
  letter-spacing: 0px;
  color: rgb(102, 102, 102);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}
.ic-selectable-table .ic-input-file-table {
  display: none;
}
.ic-selectable-cell-1 {
  flex: 0 0 1%;
  justify-content: flex-start;
}
.ic-selectable-cell-3 {
  flex: 0 0 3%;
  justify-content: flex-start;
}
.ic-selectable-cell-4 {
  flex: 0 0 4%;
  justify-content: flex-start;
}
.ic-selectable-cell-5 {
  flex: 0 0 5%;
  justify-content: flex-start;
}
.ic-selectable-cell-6 {
  flex: 0 0 6%;
  justify-content: flex-start;
}
.ic-selectable-cell-7 {
  flex: 0 0 7%;
  justify-content: flex-start;
}
.ic-selectable-cell-8 {
  flex: 0 0 8%;
  justify-content: flex-start;
}
.ic-selectable-cell-9 {
  flex: 0 0 9%;
  justify-content: flex-start;
}
.ic-selectable-cell-10 {
  flex: 0 0 10%;
  justify-content: flex-start;
}
.ic-selectable-cell-12 {
  flex: 0 0 12%;
  justify-content: flex-start;
}
.ic-selectable-cell-15 {
  flex: 0 0 15%;
  justify-content: flex-start;
}
.ic-selectable-cell-17 {
  flex: 0 0 17%;
  justify-content: flex-start;
}
.ic-selectable-cell-20 {
  flex: 0 0 20%;
  justify-content: flex-start;
}
.ic-selectable-cell-25 {
  flex: 0 0 25%;
  justify-content: flex-start;
}
.ic-selectable-cell-30 {
  flex: 0 0 30%;
  justify-content: flex-start;
}
.ic-selectable-cell-35 {
  flex: 0 0 35%;
  display: inline-grid;
}
.ic-selectable-cell-45 {
  display: inline-grid;
}
.ic-selectable-right {
  justify-content: flex-end;
}
.ic-selectable-center {
  justify-content: center;
}
.ic-selectable-baseline-align {
  align-items: baseline;
}
.ic-selectable-row-min-width-1500 {
  min-width: 1250px;
  flex: 0 0 100%;
}
.ic-selectable-ellipsis {
  display: flex;
  justify-content: left;
}
.ic-selectable-ellipsis > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.last-touched {
  background-color: #e3edff !important;
  min-width: 1250px;
}

.observations-height-header {
  min-height: 30px !important;
}
.observations-height-header .mat-header-cell {
  height: 20px !important;
}

.observations-header {
  height: 20px;
  min-height: 25px;
  top: 40px !important;
}

.administrative-file-types-height-header {
  min-height: 30px !important;
}
.administrative-file-types-height-header .mat-header-cell {
  height: 20px !important;
}

.administrative-file-types-header {
  height: 20px;
  min-height: 25px;
  top: 40px !important;
}

.entities-height-header {
  min-height: 30px !important;
}
.entities-height-header .mat-header-cell {
  height: 20px !important;
}

.entities-header {
  height: 20px;
  min-height: 25px;
  top: 40px !important;
}

.dragCursor {
  margin-right: 16px;
  margin-left: 10px;
  cursor: move;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  background: mat-color(#666, background);
}

.cdk-drag-placeholder {
  background: #666;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging .mat-row:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.tree-invisible {
  display: none;
}

.tree ul,
.tree li {
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.tree li {
  padding: 0.75rem 0.25rem;
}

.tree-button {
  min-width: 130px;
  margin-left: auto;
}

.tree-comments-buttons {
  float: right;
  vertical-align: bottom;
  cursor: pointer;
  font-family: "Material Icons Outlined";
}

.tree-comments-buttons-disable {
  float: right;
  opacity: 0.4;
  vertical-align: bottom;
  font-family: "Material Icons Outlined";
}
.tree-comments-buttons-disable:hover {
  background-color: transparent;
}

.tree-file-icons {
  vertical-align: bottom;
}

.tree-visibility-comment-icons {
  vertical-align: bottom;
  cursor: pointer;
  float: right;
}

.tree-all-comments {
  float: right;
}

.tree-visibility-all-comments-icon {
  vertical-align: middle;
  cursor: pointer;
}

.tree-file-name {
  font-family: "OpenSansLight", sans-serif;
  font-size: 14px;
  cursor: pointer;
  background-color: whitesmoke;
}

.mat-tree-position {
  margin: 0.5em;
}

.mat-tree-node {
  min-height: 0;
  width: 100%;
}
.mat-tree-node .mat-icon {
  overflow: visible;
}

.mat-cross {
  color: #4050b6;
  opacity: 0.9;
  min-width: 24px;
}

.mat-specific {
  color: #279ed6;
  opacity: 0.9;
  min-width: 24px;
}

.mat-general {
  color: #e53b78;
  opacity: 0.7;
  min-width: 24px;
}

.mat-stopped {
  color: #999999;
  opacity: 0.9;
  min-width: 24px;
}

.mat-read {
  color: #f05060;
}

.mat-doc {
  color: #1d5d93;
}

.mat-calc {
  color: #5cce88;
}

.mat-image {
  color: #279ed6;
}

.mat-image {
  color: #279ed6;
}

.mat-pending {
  color: #e58824;
}

.legal-source-icon {
  min-height: 32px;
  min-width: 32px;
}

.legal-source-icon-invisible {
  visibility: hidden;
}

.legal-source-icons-div {
  display: flex;
}

.tree-label-result {
  padding: 0.5em 2em 0.5em 0.5em;
  text-align: justify;
  font-size: 14px;
  margin-left: 0.5em;
}

.tree-label {
  padding: 0.5em 2em 0.5em 0;
  text-align: justify;
}

.tree-parent {
  border: 1px dotted grey;
  border-width: 0 0 1px 0;
  margin-bottom: 2em;
  width: 100%;
}

.tree-node {
  width: 100%;
}

.tree-parent-node {
  width: 100%;
  border: 1px dotted grey;
  border-width: 0 0 1px 0;
  margin-bottom: 2em;
}

.ppc-tree-parent-node {
  margin-left: -25px;
  width: 100%;
  border: 1px dotted grey;
  border-width: 0 0 1px 0;
  margin-bottom: 2em;
}

.text-bold {
  font-weight: bold;
}

.root-node > li > span {
  font-size: 1.5em;
  padding-bottom: 1em;
  padding-top: 1em;
  text-decoration: underline grey;
}

.comment-header {
  display: flex;
  flex-flow: row;
  align-items: baseline;
  margin-bottom: 5px;
}

.comment-data {
  display: flex;
  flex-flow: column;
}

.comment-user {
  font-size: 14px;
}

.comment-date {
  font-size: 12px;
  color: #999;
  margin-left: 5px;
}

.comment-blue {
  background-color: #e5f0ff;
  display: flex;
  flex-flow: row;
  margin-bottom: 15px;
  border-radius: 20px;
  padding: 1.2em 1em;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
}

.comment {
  display: flex;
  flex-flow: row;
  margin-bottom: 15px;
  background-color: #eee;
  border-radius: 20px;
  padding: 1.2em 1em;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
}

.blue-text {
  color: #4050b6;
}

.comment-content {
  font-weight: 400;
  font-size: 13px;
  white-space: normal;
}

mat-list-item {
  height: auto !important;
}

.dragable-div-child-node {
  display: flex;
  align-items: center;
}

.dragable-div-parent-node {
  display: flex;
  align-items: center;
}

.dragable-cursor {
  cursor: grab;
}

.node-locked {
  cursor: default; /* Cambia el cursor a default para los elementos bloqueados del drag and drop */
}

.tree-button .mat-mdc-raised-button[disabled][disabled] {
  color: rgb(0, 0, 0);
  font-weight: bold;
  text-transform: capitalize;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
  margin: 0 0.25px;
}
.tree-button .mat-mdc-raised-button:not(:disabled) {
  color: rgb(0, 0, 0);
  font-weight: bold;
  text-transform: capitalize;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  margin: 0 0.25px;
}
.tree-button .ic-tree-action-button-green {
  background-color: #0a8407;
  color: #fff;
}
.tree-button .ic-tree-action-button-green.ic-tree-action-button-pushed {
  /* Lowering the shadow */
  background-color: #065404;
}
.tree-button .ic-tree-action-button-yellow {
  background-color: #e58824;
  color: #fff;
}
.tree-button .ic-tree-action-button-yellow.ic-tree-action-button-pushed {
  /* Lowering the shadow */
  background-color: #bf6e17;
}
.tree-button .ic-tree-action-button-red {
  background-color: #e80202;
}
.tree-button .ic-tree-action-button-red.ic-tree-action-button-pushed {
  /* Lowering the shadow */
  background-color: #b50202;
}
.tree-button .ic-tree-action-button-default {
  background-color: #fff;
}
.tree-button .ic-tree-action-button-default.ic-tree-action-button-pushed {
  /* Lowering the shadow */
  background-color: #b3b3b3;
}

.actionIcons {
  height: 20px;
  width: 20px;
  font-size: 30px;
  margin-right: 0px;
  margin-left: 0px;
}

.ic-table-dragndrop .mat-mdc-form-field {
  margin: 10px !important;
  line-height: 2.2 !important;
}
.ic-table-dragndrop .mat-mdc-form-field .mat-mdc-text-field-wrapper,
.ic-table-dragndrop .mat-mdc-form-field .mat-mdc-form-field-wrapper {
  padding: 0 10px !important;
}
.ic-table-dragndrop .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex,
.ic-table-dragndrop .mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex {
  display: flex;
  align-items: flex-end;
  padding-top: 0 !important;
  height: 30px !important;
}
.ic-table-dragndrop .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix,
.ic-table-dragndrop .mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
  display: flex;
  align-items: flex-end;
  padding-bottom: 0px;
}
.ic-table-dragndrop .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .ic-table-dragndrop-form-input,
.ic-table-dragndrop .mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .ic-table-dragndrop-form-input {
  font-size: 12px !important;
  line-height: 15px !important;
}
.ic-table-dragndrop .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix select,
.ic-table-dragndrop .mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix select {
  width: 100%;
  text-align: center; /* Centra el texto del select */
  -webkit-appearance: none; /* Remueve el estilo por defecto de algunos navegadores */
  -moz-appearance: none;
  appearance: none;
}
.ic-table-dragndrop .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix option,
.ic-table-dragndrop .mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix option {
  text-align: center; /* Centra el texto dentro de las opciones */
}
.ic-table-dragndrop .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-form-field-underline,
.ic-table-dragndrop .mat-mdc-form-field .mat-mdc-text-field-wrapper .mdc-line-ripple,
.ic-table-dragndrop .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-underline,
.ic-table-dragndrop .mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-form-field-underline,
.ic-table-dragndrop .mat-mdc-form-field .mat-mdc-form-field-wrapper .mdc-line-ripple,
.ic-table-dragndrop .mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-underline {
  background-color: #ccc !important;
}

.example-container {
  margin: 0 25px 25px 0;
}
.example-container h2 {
  font-size: 1.5em;
  font-family: "OpenSansLight", sans-serif;
  margin-bottom: 10px;
}
.example-container .drop-list {
  border: solid 1px #ccc;
  min-height: 60px;
  background: white;
  border-radius: 4px;
  overflow: hidden;
  display: block;
}
.example-container .box-table {
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  font-size: 14px;
}
.example-container .box-table:hover {
  background-color: #f0f0f0;
}
.example-container .row-color {
  background-color: #e5f0ff !important;
}
.example-container .row-color-reused {
  background-color: #fff7ef !important;
}

.cdk-drag-preview,
.cdk-drag-placeholder {
  background: white;
  border-bottom: solid 1px #ccc;
  padding-top: 10px;
  padding-left: 15px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  cursor: move;
  font-size: 14px;
  width: 100%;
}

.cdk-drag-preview {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-preview.row-color,
.cdk-drag-placeholder.row-color {
  background-color: #e5f0ff !important;
}

.example-box:last-child {
  border: none;
}

.iconWithWording {
  display: flex;
  align-items: center;
  margin-right: 10px; /* Espacio mínimo entre icono y select */
  flex-grow: 1; /* Permite que este div crezca para ocupar el espacio disponible */
}

.select-acm-el {
  width: 110px; /* Ancho fijo para el select */
  margin-right: 10px; /* Espacio entre select e iconWithWording */
  flex-shrink: 0;
  padding-right: 20px;
  padding-top: 20px;
}
.select-acm-el select {
  width: 100%;
  text-align: center; /* Centra el texto del select */
  -webkit-appearance: none; /* Remueve el estilo por defecto de algunos navegadores */
  -moz-appearance: none;
  appearance: none;
}
.select-acm-el option {
  text-align: center; /* Centra el texto dentro de las opciones */
}

.line-through {
  text-decoration: line-through;
}

.icon-button-container {
  margin-left: auto; /* Empuja el contenedor de iconos al final */
  display: flex;
  align-items: center; /* Alinea los iconos verticalmente al centro */
}

.fa-layers {
  width: 100%;
}

mat-spinner {
  margin: 20px auto;
}

.administrative-file-correction-response {
  display: flex;
  flex-flow: column;
}
.administrative-file-correction-response .button-back {
  margin-top: 10px;
  margin-left: 10px;
  height: min-content;
  width: -moz-fit-content;
  width: fit-content;
}
.administrative-file-correction-response .response-info {
  margin: auto;
  max-width: 1000px;
  width: 100%;
  margin-bottom: 50px;
}
.administrative-file-correction-response .edit-button {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 15px;
  flex-basis: 25%;
}
.administrative-file-correction-response mat-card {
  margin-bottom: 20px;
  max-width: 1000px;
  margin: auto;
  margin-top: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2);
}
.administrative-file-correction-response mat-card mat-card-title {
  margin: 0 0 20px -16px;
}
.administrative-file-correction-response mat-card mat-card-header {
  justify-content: space-between;
}
.administrative-file-correction-response mat-card mat-card-header-text {
  flex-basis: 80%;
  align-self: center;
}
.administrative-file-correction-response mat-card mat-card-subtitle {
  margin-left: -16px;
  display: flex;
  align-items: center;
}
.administrative-file-correction-response mat-card mat-card-subtitle > span {
  cursor: pointer;
}
.administrative-file-correction-response mat-card .documents-table-title-text {
  font-size: 20px;
  font-weight: 500;
}
.administrative-file-correction-response mat-card .documents-table-title {
  margin-bottom: 10px;
}
.administrative-file-correction-response mat-card .ic-searchbox {
  box-shadow: none;
}
.administrative-file-correction-response mat-card mat-list-item {
  height: auto;
}
.administrative-file-correction-response mat-card > mat-card-content {
  font-size: 16px;
}
.administrative-file-correction-response mat-card > mat-card-content .ic-slide-toggle {
  margin-bottom: 25px;
}
.administrative-file-correction-response mat-card > mat-card-content .two-element-row-one-element {
  flex-basis: 100%;
}

.panel-alert-empty-documents {
  color: #977070;
  padding-left: 10px;
}

.panel-alert-empty-div {
  background: rgba(255, 0, 0, 0.1607843137);
  height: 40px;
  margin: auto;
  display: flex;
  align-items: center;
  border: 1px solid rgba(255, 0, 0, 0.2);
  border-radius: 7px;
}

.ic-administrative-file-list .button-download {
  padding-right: 47px;
  width: 65%;
  text-align: end;
}

ic-administrative-files-panel-inspection .panel {
  height: 100%;
  width: 100%;
}
ic-administrative-files-panel-inspection .panel .ic-selectable-table mat-header-row {
  justify-content: space-between;
}
ic-administrative-files-panel-inspection .panel .ic-selectable-table mat-header-row.ic-selectable-row {
  height: 40px !important;
  top: 66px !important;
}
ic-administrative-files-panel-inspection .panel .ic-selectable-table mat-header-row.ic-selectable-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-administrative-files-panel-inspection .panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0 0 1.2em;
}
ic-administrative-files-panel-inspection .panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type label {
  height: 24px;
}
ic-administrative-files-panel-inspection .panel .ic-selectable-table mat-header-row .mat-mdc-header-cell {
  min-width: min-content;
}
ic-administrative-files-panel-inspection .panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(2) {
  padding: 0 0.5em 0 2em;
}
ic-administrative-files-panel-inspection .panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-administrative-files-panel-inspection .panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:last-of-type {
  min-width: min-content;
}
ic-administrative-files-panel-inspection .panel .ic-selectable-table mat-row {
  justify-content: space-between;
}
ic-administrative-files-panel-inspection .panel .ic-selectable-table mat-row .mat-mdc-cell:first-of-type {
  padding: 0 0.4em;
  min-width: min-content;
}
ic-administrative-files-panel-inspection .panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(2) {
  min-width: min-content;
}
ic-administrative-files-panel-inspection .panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-administrative-files-panel-inspection .panel .ic-selectable-table mat-row .mat-mdc-cell:last-of-type {
  min-width: min-content;
}
ic-administrative-files-panel-inspection .panel .ic-selectable-table mat-row .mdc-data-table__cell {
  margin-top: 0 !important;
}
ic-administrative-files-panel-inspection .panel .ic-selectable-table mat-row .mdc-data-table__cell > span {
  line-height: 16px;
  padding: 5px 0 5px 0;
}
ic-administrative-files-panel-inspection .panel ic-detail-table .mat-mdc-row .mdc-data-table__cell {
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
  min-width: 103px;
}
ic-administrative-files-panel-inspection .panel ic-detail-table .mat-header-row .mdc-data-table__header-cell,
ic-administrative-files-panel-inspection .panel ic-detail-table .mat-mdc-header-row .mdc-data-table__header-cell {
  font-size: 12px;
  font-weight: 600;
  min-width: 103px;
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
}
ic-administrative-files-panel-inspection .panel .panel-toolbar {
  position: sticky;
  top: 0;
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  max-height: 56px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px 0 4px;
  z-index: 999;
}
ic-administrative-files-panel-inspection .panel .panel-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-administrative-files-panel-inspection .panel .panel-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-administrative-files-panel-inspection .panel .panel-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-administrative-files-panel-inspection .panel .panel-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-administrative-files-panel-inspection .panel .panel-toolbar .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-administrative-files-panel-inspection .panel .panel-info {
  padding-top: 1em;
  height: calc(100% - 56px);
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: #fff;
}
ic-administrative-files-panel-inspection .panel .panel-info .file-type {
  font-weight: bolder;
  font-size: 14px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
ic-administrative-files-panel-inspection .panel .panel-info > mat-card {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2);
}
ic-administrative-files-panel-inspection .panel .panel-info > mat-card > mat-card-content .mat-form-field-infix,
ic-administrative-files-panel-inspection .panel .panel-info > mat-card > mat-card-content .mat-mdc-form-field-infix {
  width: 500px;
}
ic-administrative-files-panel-inspection .panel .panel-info > mat-card > mat-card-content .signs-buttons {
  display: flex;
}
ic-administrative-files-panel-inspection .panel .panel-info > mat-card .observations-table {
  margin-top: 15px;
}
ic-administrative-files-panel-inspection .panel .panel-info > mat-card .observations-selected-table {
  margin-top: 30px;
}
ic-administrative-files-panel-inspection .panel .panel-info > mat-card .observations-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5em;
}
ic-administrative-files-panel-inspection .panel .panel-info > div {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.dynamic-component-header-text {
  margin-left: 0px;
}

.ic-panel-input {
  margin-top: 0.5em;
}

.favorable {
  color: #0a8407;
  font-weight: bolder;
}

.unfavorable {
  color: #e80202;
  font-weight: bolder;
}

.favorable-ob {
  color: #e58824;
  font-weight: bolder;
}

ic-dialog-pdf {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.row-one-element {
  width: 75%;
}

ic-administrative-files-panel-correction .panel .panel-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  padding: 0 12px 0 4px;
  white-space: normal;
}
ic-administrative-files-panel-correction .panel .panel-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-administrative-files-panel-correction .panel .panel-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-administrative-files-panel-correction .panel .panel-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-administrative-files-panel-correction .panel .panel-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-administrative-files-panel-correction .panel .panel-toolbar .panel-buttons {
  width: max-content;
  min-width: max-content;
}
ic-administrative-files-panel-correction .panel .panel-info {
  min-height: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: #fff;
}
ic-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .text-correction {
  color: #666;
  background: #ededed;
  border: 1px solid #ededed;
  border-radius: 12px;
  padding: 10px;
}
ic-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-header {
  display: flex;
  flex-flow: column;
  margin-bottom: 15px;
}
ic-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-header-senders {
  display: flex;
  flex-flow: row;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}
ic-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-solicitant {
  flex-basis: 45%;
  background: #ededed;
  padding: 10px;
  border: 1px solid #ededed;
  border-radius: 16px;
  display: flex;
  margin-bottom: 15px;
}
@media (max-width: 900px) {
  ic-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-solicitant {
    flex-basis: 100%;
  }
}
ic-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-solicitant .first-line,
ic-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-solicitant .second-line,
ic-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-solicitant .third-line,
ic-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-solicitant .fourth-line {
  margin-bottom: 5px;
}
ic-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-solicitant-icon {
  margin-right: 10px;
}
ic-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-solicitant-icon .material-icons {
  font-size: 55px;
  color: black;
}
ic-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-gestora {
  flex-basis: 45%;
  background: #ededed;
  padding: 10px;
  border: 1px solid #ededed;
  border-radius: 16px;
  margin-bottom: 15px;
  display: flex;
}
@media (max-width: 900px) {
  ic-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-gestora {
    flex-basis: 100%;
  }
}
ic-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-gestora .first-line,
ic-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-gestora .second-line,
ic-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-gestora .third-line,
ic-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-gestora .fourth-line {
  margin-bottom: 5px;
}
ic-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-gestora-icon {
  margin-right: 10px;
}
ic-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-gestora-icon .material-icons {
  font-size: 55px;
  color: black;
}
ic-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-gestora .fourth-line > .right-text {
  float: right;
}
ic-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-gestora .fourth-line > .left-text {
  display: inline-block;
}
ic-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-title {
  background: #f1dada;
  padding: 10px;
  border: 1px solid #f1dada;
  border-radius: 12px;
  text-align: center;
  font-weight: bold;
}
ic-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-subject {
  background: #ededed;
  padding: 10px;
  border: 1px solid #ededed;
  border-radius: 12px;
  margin-top: 15px;
}
ic-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .textarea-container-form-textarea {
  height: 250px;
}
ic-administrative-files-panel-correction .panel .panel-info .panel-buttons {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
}
ic-administrative-files-panel-correction .panel .mat-error-email {
  font-size: 12px;
  margin-bottom: 10px;
}
ic-administrative-files-panel-correction .panel mat-chip-grid .mat-chip,
ic-administrative-files-panel-correction .panel mat-chip-grid .mat-mdc-chip {
  width: auto;
  background-color: #eee;
  margin-bottom: 15px;
  display: flex;
  height: auto;
  align-self: center;
}
ic-administrative-files-panel-correction .panel mat-chip-grid .mat-chip .mdc-evolution-chip__text-label,
ic-administrative-files-panel-correction .panel mat-chip-grid .mat-mdc-chip .mdc-evolution-chip__text-label {
  width: auto;
  align-items: center;
  display: flex;
  height: auto;
}
ic-administrative-files-panel-correction .panel mat-chip-grid .mat-chip .mdc-evolution-chip__text-label > .icon-default,
ic-administrative-files-panel-correction .panel mat-chip-grid .mat-mdc-chip .mdc-evolution-chip__text-label > .icon-default {
  margin-right: 5px;
}
ic-administrative-files-panel-correction .panel mat-chip-grid .mat-chip .mdc-evolution-chip__text-label > .text,
ic-administrative-files-panel-correction .panel mat-chip-grid .mat-mdc-chip .mdc-evolution-chip__text-label > .text {
  display: flex;
  flex-flow: column;
  padding: 0.25em 0;
}
ic-administrative-files-panel-correction .panel mat-chip-grid .mat-chip .mdc-evolution-chip__text-label > .text > .email,
ic-administrative-files-panel-correction .panel mat-chip-grid .mat-mdc-chip .mdc-evolution-chip__text-label > .text > .email {
  font-size: 14px;
  font-weight: bold;
}
ic-administrative-files-panel-correction .panel mat-chip-grid .mat-chip .mdc-evolution-chip__text-label > .text > .name,
ic-administrative-files-panel-correction .panel mat-chip-grid .mat-mdc-chip .mdc-evolution-chip__text-label > .text > .name {
  font-size: 11px;
  font-weight: bold;
}
ic-administrative-files-panel-correction .panel mat-chip-grid .mat-chip-list-wrapper,
ic-administrative-files-panel-correction .panel mat-chip-grid .mdc-evolution-chip-set__chips {
  align-items: flex-start;
  flex-direction: row;
  flex-flow: row;
  flex-wrap: wrap;
}
ic-administrative-files-panel-correction .panel mat-chip-grid .mat-form-field,
ic-administrative-files-panel-correction .panel mat-chip-grid .mat-mdc-form-field {
  margin-left: 1em;
  width: 235px;
}
ic-administrative-files-panel-correction .panel mat-chip-grid .mat-chip-input,
ic-administrative-files-panel-correction .panel mat-chip-grid .mat-mdc-chip-input {
  margin-left: 6px;
  width: 220px;
  font-size: 12px;
}
ic-administrative-files-panel-correction .panel .panel-alert-empty-contacts {
  color: #977070;
  padding-left: 10px;
}
ic-administrative-files-panel-correction .panel .panel-alert-empty-div {
  background: rgba(255, 0, 0, 0.1607843137);
  height: 40px;
  margin: auto;
  display: flex;
  margin-top: 20px;
  align-items: center;
  border: 1px solid rgba(255, 0, 0, 0.2);
  border-radius: 7px;
}

ic-administrative-files-return .returnpanel .returnpanel-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  padding: 0 12px 0 4px;
  white-space: normal;
}
ic-administrative-files-return .returnpanel .returnpanel-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-administrative-files-return .returnpanel .returnpanel-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-administrative-files-return .returnpanel .returnpanel-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-administrative-files-return .returnpanel .returnpanel-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-administrative-files-return .returnpanel .returnpanel-toolbar .panel-buttons {
  width: max-content;
  min-width: max-content;
}
ic-administrative-files-return .returnpanel .returnpanel-info {
  min-height: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: #fff;
}
ic-administrative-files-return .returnpanel .returnpanel-info > mat-card > mat-card-content > .two-element-row > ic-input-select > mat-form-field {
  width: -webkit-fill-available;
}
ic-administrative-files-return .returnpanel .returnpanel-info > mat-card > mat-card-content > .two-element-row .margin-10 {
  margin: 10px;
}
ic-administrative-files-return .returnpanel .returnpanel-info > mat-card > mat-card-content .text-correction {
  color: #666;
  background: #ededed;
  border: 1px solid #ededed;
  border-radius: 12px;
  padding: 10px;
}
ic-administrative-files-return .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-header {
  display: flex;
  flex-flow: column;
  margin-bottom: 15px;
}
ic-administrative-files-return .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-header-senders {
  display: flex;
  flex-flow: row;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}
ic-administrative-files-return .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-solicitant {
  flex-basis: 45%;
  background: #ededed;
  padding: 10px;
  border: 1px solid #ededed;
  border-radius: 16px;
  display: flex;
  margin-bottom: 15px;
}
@media (max-width: 900px) {
  ic-administrative-files-return .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-solicitant {
    flex-basis: 100%;
  }
}
ic-administrative-files-return .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-solicitant .first-line,
ic-administrative-files-return .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-solicitant .second-line,
ic-administrative-files-return .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-solicitant .third-line,
ic-administrative-files-return .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-solicitant .fourth-line {
  margin-bottom: 5px;
}
ic-administrative-files-return .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-solicitant-icon {
  margin-right: 10px;
}
ic-administrative-files-return .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-solicitant-icon .material-icons {
  font-size: 55px;
  color: black;
}
ic-administrative-files-return .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-gestora {
  flex-basis: 45%;
  background: #ededed;
  padding: 10px;
  border: 1px solid #ededed;
  border-radius: 16px;
  margin-bottom: 15px;
  display: flex;
}
@media (max-width: 900px) {
  ic-administrative-files-return .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-gestora {
    flex-basis: 100%;
  }
}
ic-administrative-files-return .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-gestora .first-line,
ic-administrative-files-return .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-gestora .second-line,
ic-administrative-files-return .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-gestora .third-line,
ic-administrative-files-return .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-gestora .fourth-line {
  margin-bottom: 5px;
}
ic-administrative-files-return .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-gestora-icon {
  margin-right: 10px;
}
ic-administrative-files-return .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-gestora-icon .material-icons {
  font-size: 55px;
  color: black;
}
ic-administrative-files-return .returnpanel .returnpanel-info > mat-card > mat-card-content .fourth-line > .right-text {
  float: right;
}
ic-administrative-files-return .returnpanel .returnpanel-info > mat-card > mat-card-content .fourth-line > .left-text {
  display: inline-block;
}
ic-administrative-files-return .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-title {
  background: #f1dada;
  padding: 10px;
  border: 1px solid #f1dada;
  border-radius: 12px;
  text-align: center;
  font-weight: bold;
}
ic-administrative-files-return .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-subject {
  background: #ededed;
  padding: 10px;
  border: 1px solid #ededed;
  border-radius: 12px;
  margin-top: 15px;
}
ic-administrative-files-return .returnpanel .returnpanel-info > mat-card > mat-card-content .textarea-container-form-textarea {
  height: 250px;
}
ic-administrative-files-return .returnpanel .returnpanel-info .panel-buttons {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
}
ic-administrative-files-return .returnpanel .mat-error-email {
  font-size: 12px;
  margin-bottom: 10px;
}
ic-administrative-files-return .returnpanel mat-chip-grid .mat-chip,
ic-administrative-files-return .returnpanel mat-chip-grid .mat-mdc-chip {
  width: auto;
  background-color: #eee;
  margin-bottom: 15px;
  display: flex;
  height: auto;
  align-self: center;
}
ic-administrative-files-return .returnpanel mat-chip-grid .mat-chip .mdc-evolution-chip__text-label,
ic-administrative-files-return .returnpanel mat-chip-grid .mat-mdc-chip .mdc-evolution-chip__text-label {
  width: auto;
  align-items: center;
  display: flex;
  height: auto;
}
ic-administrative-files-return .returnpanel mat-chip-grid .mat-chip .mdc-evolution-chip__text-label > .icon-default,
ic-administrative-files-return .returnpanel mat-chip-grid .mat-mdc-chip .mdc-evolution-chip__text-label > .icon-default {
  margin-right: 5px;
}
ic-administrative-files-return .returnpanel mat-chip-grid .mat-chip .mdc-evolution-chip__text-label > .text,
ic-administrative-files-return .returnpanel mat-chip-grid .mat-mdc-chip .mdc-evolution-chip__text-label > .text {
  display: flex;
  flex-flow: column;
  padding: 0.25em 0;
}
ic-administrative-files-return .returnpanel mat-chip-grid .mat-chip .mdc-evolution-chip__text-label > .text > .email,
ic-administrative-files-return .returnpanel mat-chip-grid .mat-mdc-chip .mdc-evolution-chip__text-label > .text > .email {
  font-size: 14px;
  font-weight: bold;
}
ic-administrative-files-return .returnpanel mat-chip-grid .mat-chip .mdc-evolution-chip__text-label > .text > .name,
ic-administrative-files-return .returnpanel mat-chip-grid .mat-mdc-chip .mdc-evolution-chip__text-label > .text > .name {
  font-size: 11px;
  font-weight: bold;
}
ic-administrative-files-return .returnpanel mat-chip-grid .mat-chip-list-wrapper,
ic-administrative-files-return .returnpanel mat-chip-grid .mdc-evolution-chip-set__chips {
  align-items: flex-start;
  flex-direction: row;
  flex-flow: row;
  flex-wrap: wrap;
}
ic-administrative-files-return .returnpanel mat-chip-grid .mat-form-field,
ic-administrative-files-return .returnpanel mat-chip-grid .mat-mdc-form-field {
  margin-left: 1em;
  width: 235px;
}
ic-administrative-files-return .returnpanel mat-chip-grid .mat-chip-input,
ic-administrative-files-return .returnpanel mat-chip-grid .mat-mdc-chip-input {
  margin-left: 6px;
  width: 220px;
  font-size: 12px;
}
ic-administrative-files-return .returnpanel .panel-alert-empty-contacts {
  color: #977070;
  padding-left: 10px;
}
ic-administrative-files-return .returnpanel .panel-alert-empty-div {
  background: rgba(255, 0, 0, 0.1607843137);
  height: 40px;
  margin: auto;
  display: flex;
  margin-top: 20px;
  align-items: center;
  border: 1px solid rgba(255, 0, 0, 0.2);
  border-radius: 7px;
}

.ic-frb-request-list .button-download {
  width: 62.65%;
  text-align: end;
}

ic-frb-request-panel .solfrb-panel .solfrb-panel-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  padding: 0 12px 0 4px;
  white-space: normal;
}
ic-frb-request-panel .solfrb-panel .solfrb-panel-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-frb-request-panel .solfrb-panel .solfrb-panel-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-frb-request-panel .solfrb-panel .solfrb-panel-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-frb-request-panel .solfrb-panel .solfrb-panel-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-frb-request-panel .solfrb-panel .solfrb-panel-toolbar .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-frb-request-panel .solfrb-panel .solfrb-panel-toolbar .panel-buttons {
  width: max-content;
  min-width: max-content;
}
ic-frb-request-panel .solfrb-panel .solfrb-panel-info {
  padding-top: 1em;
  height: calc(100% - 56px);
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: #fff;
}
ic-frb-request-panel .solfrb-panel .solfrb-panel-info .slidetoggles-row {
  flex: 1;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}
ic-frb-request-panel .solfrb-panel .solfrb-panel-info > div {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
ic-frb-request-panel .solfrb-panel .solfrb-panel-info .panel-buttons {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
}
ic-frb-request-panel .solfrb-panel .solfrb-panel-documentation {
  margin-left: 15px;
}
ic-frb-request-panel .solfrb-panel .solfrb-panel-documentation-title {
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 15px;
}
ic-frb-request-panel .solfrb-panel .dynamic-component-header-text {
  margin-left: 0px;
}
ic-frb-request-panel .solfrb-panel .ic-panel-input {
  margin-top: 0.5em;
}
ic-frb-request-panel .solfrb-panel .ic-slide-toggle {
  margin-bottom: 25px;
}
ic-frb-request-panel .solfrb-panel .panel-alert-empty-documents {
  color: #977070;
  padding-left: 10px;
}
ic-frb-request-panel .solfrb-panel .panel-alert-empty-div {
  background: rgba(255, 0, 0, 0.1607843137);
  height: 40px;
  margin: auto;
  display: flex;
  align-items: center;
  border: 1px solid rgba(255, 0, 0, 0.2);
  border-radius: 7px;
}

.amendment-panel .amendment-panel-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  padding: 0 12px 0 4px;
  white-space: normal;
}
.amendment-panel .amendment-panel-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
.amendment-panel .amendment-panel-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
.amendment-panel .amendment-panel-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
.amendment-panel .amendment-panel-toolbar .panel-spacer {
  flex: 1 1 auto;
}
.amendment-panel .amendment-panel-toolbar .panel-buttons {
  width: max-content;
  min-width: max-content;
}
.amendment-panel .documents-table-title-text {
  font-size: 20px;
  font-weight: 500;
}
.amendment-panel .documents-table-title {
  margin-bottom: 10px;
}
.amendment-panel .amendment-panel-info {
  min-height: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: #fff;
}
.amendment-panel .amendment-panel-info > div {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.amendment-panel .amendment-panel-info .panel-buttons {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
}
.amendment-panel .amendment-panel-documentation {
  margin-left: 15px;
}
.amendment-panel .amendment-panel-documentation-title {
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 15px;
}
.amendment-panel .dynamic-component-header-text {
  margin-left: 0px;
}
.amendment-panel .ic-panel-input {
  margin-top: 0.5em;
}
.amendment-panel .ic-slide-toggle {
  margin-bottom: 25px;
}
.amendment-panel .panel-alert-empty-documents {
  color: #977070;
  padding-left: 10px;
}
.amendment-panel .panel-alert-empty-div {
  background: rgba(255, 0, 0, 0.1607843137);
  height: 40px;
  margin: auto;
  display: flex;
  align-items: center;
  border: 1px solid rgba(255, 0, 0, 0.2);
  border-radius: 7px;
}

ic-frb-request-panel-correction .panel .panel-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  padding: 0 12px 0 4px;
  white-space: normal;
}
ic-frb-request-panel-correction .panel .panel-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-frb-request-panel-correction .panel .panel-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-frb-request-panel-correction .panel .panel-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-frb-request-panel-correction .panel .panel-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-frb-request-panel-correction .panel .panel-toolbar .panel-buttons {
  width: max-content;
  min-width: max-content;
}
ic-frb-request-panel-correction .panel .panel-info {
  min-height: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: #fff;
}
ic-frb-request-panel-correction .panel .panel-info > mat-card > mat-card-content .text-correction {
  color: #666;
  background: #ededed;
  border: 1px solid #ededed;
  border-radius: 12px;
  padding: 10px;
}
ic-frb-request-panel-correction .panel .panel-info > mat-card > mat-card-content .content-header {
  display: flex;
  flex-flow: column;
  margin-bottom: 15px;
}
ic-frb-request-panel-correction .panel .panel-info > mat-card > mat-card-content .content-header-senders {
  display: flex;
  flex-flow: row;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}
ic-frb-request-panel-correction .panel .panel-info > mat-card > mat-card-content .content-solicitant {
  flex-basis: 45%;
  background: #ededed;
  padding: 10px;
  border: 1px solid #ededed;
  border-radius: 16px;
  display: flex;
  margin-bottom: 15px;
}
@media (max-width: 900px) {
  ic-frb-request-panel-correction .panel .panel-info > mat-card > mat-card-content .content-solicitant {
    flex-basis: 100%;
  }
}
ic-frb-request-panel-correction .panel .panel-info > mat-card > mat-card-content .content-solicitant .first-line,
ic-frb-request-panel-correction .panel .panel-info > mat-card > mat-card-content .content-solicitant .second-line,
ic-frb-request-panel-correction .panel .panel-info > mat-card > mat-card-content .content-solicitant .third-line,
ic-frb-request-panel-correction .panel .panel-info > mat-card > mat-card-content .content-solicitant .fourth-line {
  margin-bottom: 5px;
}
ic-frb-request-panel-correction .panel .panel-info > mat-card > mat-card-content .content-solicitant-icon {
  margin-right: 10px;
}
ic-frb-request-panel-correction .panel .panel-info > mat-card > mat-card-content .content-solicitant-icon .material-icons {
  font-size: 55px;
  color: black;
}
ic-frb-request-panel-correction .panel .panel-info > mat-card > mat-card-content .content-gestora {
  flex-basis: 45%;
  background: #ededed;
  padding: 10px;
  border: 1px solid #ededed;
  border-radius: 16px;
  margin-bottom: 15px;
  display: flex;
}
@media (max-width: 900px) {
  ic-frb-request-panel-correction .panel .panel-info > mat-card > mat-card-content .content-gestora {
    flex-basis: 100%;
  }
}
ic-frb-request-panel-correction .panel .panel-info > mat-card > mat-card-content .content-gestora .first-line,
ic-frb-request-panel-correction .panel .panel-info > mat-card > mat-card-content .content-gestora .second-line,
ic-frb-request-panel-correction .panel .panel-info > mat-card > mat-card-content .content-gestora .third-line,
ic-frb-request-panel-correction .panel .panel-info > mat-card > mat-card-content .content-gestora .fourth-line {
  margin-bottom: 5px;
}
ic-frb-request-panel-correction .panel .panel-info > mat-card > mat-card-content .content-gestora-icon {
  margin-right: 10px;
}
ic-frb-request-panel-correction .panel .panel-info > mat-card > mat-card-content .content-gestora-icon .material-icons {
  font-size: 55px;
  color: black;
}
ic-frb-request-panel-correction .panel .panel-info > mat-card > mat-card-content .fourth-line > .right-text {
  float: right;
}
ic-frb-request-panel-correction .panel .panel-info > mat-card > mat-card-content .fourth-line > .left-text {
  display: inline-block;
}
ic-frb-request-panel-correction .panel .panel-info > mat-card > mat-card-content .content-title {
  background: #f1dada;
  padding: 10px;
  border: 1px solid #f1dada;
  border-radius: 12px;
  text-align: center;
  font-weight: bold;
}
ic-frb-request-panel-correction .panel .panel-info > mat-card > mat-card-content .content-subject {
  background: #ededed;
  padding: 10px;
  border: 1px solid #ededed;
  border-radius: 12px;
  margin-top: 15px;
}
ic-frb-request-panel-correction .panel .panel-info > mat-card > mat-card-content .textarea-container-form-textarea {
  height: 250px;
}
ic-frb-request-panel-correction .panel .panel-info .panel-buttons {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
}
ic-frb-request-panel-correction .panel .mat-error-email {
  font-size: 12px;
  margin-bottom: 10px;
}
ic-frb-request-panel-correction .panel mat-chip-grid .mat-chip,
ic-frb-request-panel-correction .panel mat-chip-grid .mat-mdc-chip {
  width: auto;
  background-color: #eee;
  margin-bottom: 15px;
  display: flex;
  height: auto;
  align-self: center;
}
ic-frb-request-panel-correction .panel mat-chip-grid .mat-chip .mdc-evolution-chip__text-label,
ic-frb-request-panel-correction .panel mat-chip-grid .mat-mdc-chip .mdc-evolution-chip__text-label {
  width: auto;
  align-items: center;
  display: flex;
  height: auto;
}
ic-frb-request-panel-correction .panel mat-chip-grid .mat-chip .mdc-evolution-chip__text-label > .icon-default,
ic-frb-request-panel-correction .panel mat-chip-grid .mat-mdc-chip .mdc-evolution-chip__text-label > .icon-default {
  margin-right: 5px;
}
ic-frb-request-panel-correction .panel mat-chip-grid .mat-chip .mdc-evolution-chip__text-label > .text,
ic-frb-request-panel-correction .panel mat-chip-grid .mat-mdc-chip .mdc-evolution-chip__text-label > .text {
  display: flex;
  flex-flow: column;
  padding: 0.25em 0;
}
ic-frb-request-panel-correction .panel mat-chip-grid .mat-chip .mdc-evolution-chip__text-label > .text > .email,
ic-frb-request-panel-correction .panel mat-chip-grid .mat-mdc-chip .mdc-evolution-chip__text-label > .text > .email {
  font-size: 14px;
  font-weight: bold;
}
ic-frb-request-panel-correction .panel mat-chip-grid .mat-chip .mdc-evolution-chip__text-label > .text > .name,
ic-frb-request-panel-correction .panel mat-chip-grid .mat-mdc-chip .mdc-evolution-chip__text-label > .text > .name {
  font-size: 11px;
  font-weight: bold;
}
ic-frb-request-panel-correction .panel mat-chip-grid .mat-chip-list-wrapper,
ic-frb-request-panel-correction .panel mat-chip-grid .mdc-evolution-chip-set__chips {
  align-items: flex-start;
  flex-direction: row;
  flex-flow: row;
  flex-wrap: wrap;
}
ic-frb-request-panel-correction .panel mat-chip-grid .mat-form-field,
ic-frb-request-panel-correction .panel mat-chip-grid .mat-mdc-form-field {
  margin-left: 1em;
  width: 235px;
}
ic-frb-request-panel-correction .panel mat-chip-grid .mat-chip-input,
ic-frb-request-panel-correction .panel mat-chip-grid .mat-mdc-chip-input {
  margin-left: 6px;
  width: 220px;
  font-size: 12px;
}
ic-frb-request-panel-correction .panel .panel-alert-empty-contacts {
  color: #977070;
  padding-left: 10px;
}
ic-frb-request-panel-correction .panel .panel-alert-empty-div {
  background: rgba(255, 0, 0, 0.1607843137);
  height: 40px;
  margin: auto;
  display: flex;
  margin-top: 20px;
  align-items: center;
  border: 1px solid rgba(255, 0, 0, 0.2);
  border-radius: 7px;
}

ic-frb-request-aft-tree-panel .panel {
  height: 100%;
  width: 100%;
}
ic-frb-request-aft-tree-panel .panel .ic-selectable-table mat-header-row {
  justify-content: space-between;
}
ic-frb-request-aft-tree-panel .panel .ic-selectable-table mat-header-row.ic-selectable-row {
  height: 40px !important;
  top: 66px !important;
}
ic-frb-request-aft-tree-panel .panel .ic-selectable-table mat-header-row.ic-selectable-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-frb-request-aft-tree-panel .panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0 0 1.2em;
}
ic-frb-request-aft-tree-panel .panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type label {
  height: 24px;
}
ic-frb-request-aft-tree-panel .panel .ic-selectable-table mat-header-row .mat-mdc-header-cell {
  min-width: min-content;
}
ic-frb-request-aft-tree-panel .panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(2) {
  padding: 0 0.5em 0 2em;
}
ic-frb-request-aft-tree-panel .panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-frb-request-aft-tree-panel .panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:last-of-type {
  min-width: min-content;
}
ic-frb-request-aft-tree-panel .panel .ic-selectable-table mat-row {
  justify-content: space-between;
}
ic-frb-request-aft-tree-panel .panel .ic-selectable-table mat-row .mat-mdc-cell:first-of-type {
  padding: 0 0.4em;
  min-width: min-content;
}
ic-frb-request-aft-tree-panel .panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(2) {
  min-width: min-content;
}
ic-frb-request-aft-tree-panel .panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-frb-request-aft-tree-panel .panel .ic-selectable-table mat-row .mat-mdc-cell:last-of-type {
  min-width: min-content;
}
ic-frb-request-aft-tree-panel .panel .ic-selectable-table mat-row .mdc-data-table__cell {
  margin-top: 0 !important;
}
ic-frb-request-aft-tree-panel .panel .ic-selectable-table mat-row .mdc-data-table__cell > span {
  line-height: 16px;
  padding: 5px 0 5px 0;
}
ic-frb-request-aft-tree-panel .panel ic-detail-table .mat-mdc-row .mdc-data-table__cell {
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
  min-width: 103px;
}
ic-frb-request-aft-tree-panel .panel ic-detail-table .mat-header-row .mdc-data-table__header-cell,
ic-frb-request-aft-tree-panel .panel ic-detail-table .mat-mdc-header-row .mdc-data-table__header-cell {
  font-size: 12px;
  font-weight: 600;
  min-width: 103px;
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
}
ic-frb-request-aft-tree-panel .panel .panel-toolbar {
  position: sticky;
  top: 0;
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  max-height: 56px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px 0 4px;
  z-index: 999;
}
ic-frb-request-aft-tree-panel .panel .panel-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-frb-request-aft-tree-panel .panel .panel-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-frb-request-aft-tree-panel .panel .panel-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-frb-request-aft-tree-panel .panel .panel-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-frb-request-aft-tree-panel .panel .panel-toolbar .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-frb-request-aft-tree-panel .panel .panel-info {
  padding-top: 1em;
  height: calc(100% - 56px);
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: #fff;
}
ic-frb-request-aft-tree-panel .panel .panel-info .file-type {
  font-weight: bolder;
  font-size: 14px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
ic-frb-request-aft-tree-panel .panel .panel-info > mat-card {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2);
}
ic-frb-request-aft-tree-panel .panel .panel-info > mat-card > mat-card-content .mat-form-field-infix,
ic-frb-request-aft-tree-panel .panel .panel-info > mat-card > mat-card-content .mat-mdc-form-field-infix {
  width: 500px;
}
ic-frb-request-aft-tree-panel .panel .panel-info > mat-card > mat-card-content .signs-buttons {
  display: flex;
}
ic-frb-request-aft-tree-panel .panel .panel-info > mat-card .observations-table {
  margin-top: 15px;
}
ic-frb-request-aft-tree-panel .panel .panel-info > mat-card .observations-selected-table {
  margin-top: 30px;
}
ic-frb-request-aft-tree-panel .panel .panel-info > mat-card .observations-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5em;
}
ic-frb-request-aft-tree-panel .panel .panel-info > div {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.dynamic-component-header-text {
  margin-left: 0px;
}

.ic-panel-input {
  margin-top: 0.5em;
}

.favorable {
  color: #0a8407;
  font-weight: bolder;
}

.unfavorable {
  color: #e80202;
  font-weight: bolder;
}

.favorable-ob {
  color: #e58824;
  font-weight: bolder;
}

ic-dialog-pdf {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.row-one-element {
  width: 75%;
}

.ic-ppc-request-list .button-download {
  width: 62.65%;
  text-align: end;
}

ic-ppc-request-panel .solppc-panel .solppc-panel-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  padding: 0 12px 0 4px;
  white-space: normal;
}
ic-ppc-request-panel .solppc-panel .solppc-panel-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-ppc-request-panel .solppc-panel .solppc-panel-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-ppc-request-panel .solppc-panel .solppc-panel-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-ppc-request-panel .solppc-panel .solppc-panel-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-ppc-request-panel .solppc-panel .solppc-panel-toolbar .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-ppc-request-panel .solppc-panel .solppc-panel-toolbar .panel-buttons {
  width: max-content;
  min-width: max-content;
}
ic-ppc-request-panel .solppc-panel .solppc-panel-info {
  padding-top: 1em;
  height: calc(100% - 56px);
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: #fff;
}
ic-ppc-request-panel .solppc-panel .solppc-panel-info > mat-card > mat-card-content > .two-element-row {
  padding-bottom: 12px;
}
ic-ppc-request-panel .solppc-panel .solppc-panel-info .slidetoggles-row {
  flex: 1;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}
ic-ppc-request-panel .solppc-panel .solppc-panel-info > div {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
ic-ppc-request-panel .solppc-panel .solppc-panel-info .panel-buttons {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
}
ic-ppc-request-panel .solppc-panel .solppc-panel-documentation {
  margin-left: 15px;
}
ic-ppc-request-panel .solppc-panel .solppc-panel-documentation-title {
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 15px;
}
ic-ppc-request-panel .solppc-panel .dynamic-component-header-text {
  margin-left: 0px;
}
ic-ppc-request-panel .solppc-panel .ic-panel-input {
  margin-top: 0.5em;
}
ic-ppc-request-panel .solppc-panel .ic-slide-toggle {
  margin-bottom: 25px;
}
ic-ppc-request-panel .solppc-panel .panel-alert-empty-documents {
  color: #977070;
  padding-left: 10px;
}
ic-ppc-request-panel .solppc-panel .panel-alert-empty-div {
  background: rgba(255, 0, 0, 0.1607843137);
  height: 40px;
  margin: auto;
  display: flex;
  align-items: center;
  border: 1px solid rgba(255, 0, 0, 0.2);
  border-radius: 7px;
}

ic-ppc-request-aft-tree-panel .panel {
  height: 100%;
  width: 100%;
}
ic-ppc-request-aft-tree-panel .panel .ic-selectable-table mat-header-row {
  justify-content: space-between;
}
ic-ppc-request-aft-tree-panel .panel .ic-selectable-table mat-header-row.ic-selectable-row {
  height: 40px !important;
  top: 66px !important;
}
ic-ppc-request-aft-tree-panel .panel .ic-selectable-table mat-header-row.ic-selectable-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-ppc-request-aft-tree-panel .panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0 0 1.2em;
}
ic-ppc-request-aft-tree-panel .panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type label {
  height: 24px;
}
ic-ppc-request-aft-tree-panel .panel .ic-selectable-table mat-header-row .mat-mdc-header-cell {
  min-width: min-content;
}
ic-ppc-request-aft-tree-panel .panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(2) {
  padding: 0 0.5em 0 2em;
}
ic-ppc-request-aft-tree-panel .panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-ppc-request-aft-tree-panel .panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:last-of-type {
  min-width: min-content;
}
ic-ppc-request-aft-tree-panel .panel .ic-selectable-table mat-row {
  justify-content: space-between;
}
ic-ppc-request-aft-tree-panel .panel .ic-selectable-table mat-row .mat-mdc-cell:first-of-type {
  padding: 0 0.4em;
  min-width: min-content;
}
ic-ppc-request-aft-tree-panel .panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(2) {
  min-width: min-content;
}
ic-ppc-request-aft-tree-panel .panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-ppc-request-aft-tree-panel .panel .ic-selectable-table mat-row .mat-mdc-cell:last-of-type {
  min-width: min-content;
}
ic-ppc-request-aft-tree-panel .panel .ic-selectable-table mat-row .mdc-data-table__cell {
  margin-top: 0 !important;
}
ic-ppc-request-aft-tree-panel .panel .ic-selectable-table mat-row .mdc-data-table__cell > span {
  line-height: 16px;
  padding: 5px 0 5px 0;
}
ic-ppc-request-aft-tree-panel .panel ic-detail-table .mat-mdc-row .mdc-data-table__cell {
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
  min-width: 103px;
}
ic-ppc-request-aft-tree-panel .panel ic-detail-table .mat-header-row .mdc-data-table__header-cell,
ic-ppc-request-aft-tree-panel .panel ic-detail-table .mat-mdc-header-row .mdc-data-table__header-cell {
  font-size: 12px;
  font-weight: 600;
  min-width: 103px;
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
}
ic-ppc-request-aft-tree-panel .panel .panel-toolbar {
  position: sticky;
  top: 0;
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  max-height: 56px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px 0 4px;
  z-index: 999;
}
ic-ppc-request-aft-tree-panel .panel .panel-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-ppc-request-aft-tree-panel .panel .panel-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-ppc-request-aft-tree-panel .panel .panel-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-ppc-request-aft-tree-panel .panel .panel-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-ppc-request-aft-tree-panel .panel .panel-toolbar .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-ppc-request-aft-tree-panel .panel .panel-info {
  padding-top: 1em;
  height: calc(100% - 56px);
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: #fff;
}
ic-ppc-request-aft-tree-panel .panel .panel-info .file-type {
  font-weight: bolder;
  font-size: 14px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
ic-ppc-request-aft-tree-panel .panel .panel-info > mat-card {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2);
}
ic-ppc-request-aft-tree-panel .panel .panel-info > mat-card > mat-card-content .mat-form-field-infix,
ic-ppc-request-aft-tree-panel .panel .panel-info > mat-card > mat-card-content .mat-mdc-form-field-infix {
  width: 500px;
}
ic-ppc-request-aft-tree-panel .panel .panel-info > mat-card > mat-card-content .signs-buttons {
  display: flex;
}
ic-ppc-request-aft-tree-panel .panel .panel-info > mat-card .observations-table {
  margin-top: 15px;
}
ic-ppc-request-aft-tree-panel .panel .panel-info > mat-card .observations-selected-table {
  margin-top: 30px;
}
ic-ppc-request-aft-tree-panel .panel .panel-info > mat-card .observations-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5em;
}
ic-ppc-request-aft-tree-panel .panel .panel-info > div {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.dynamic-component-header-text {
  margin-left: 0px;
}

.ic-panel-input {
  margin-top: 0.5em;
}

.favorable {
  color: #0a8407;
  font-weight: bolder;
}

.unfavorable {
  color: #e80202;
  font-weight: bolder;
}

.favorable-ob {
  color: #e58824;
  font-weight: bolder;
}

ic-dialog-pdf {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.row-one-element {
  width: 75%;
}

ic-ppc-return-request .panel .panel-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  padding: 0 12px 0 4px;
  white-space: normal;
}
ic-ppc-return-request .panel .panel-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-ppc-return-request .panel .panel-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-ppc-return-request .panel .panel-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-ppc-return-request .panel .panel-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-ppc-return-request .panel .panel-toolbar .panel-buttons {
  width: max-content;
  min-width: max-content;
}
ic-ppc-return-request .panel .panel-info {
  min-height: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: #fff;
}
ic-ppc-return-request .panel .panel-info > mat-card > mat-card-content .text-correction {
  color: #666;
  background: #ededed;
  border: 1px solid #ededed;
  border-radius: 12px;
  padding: 10px;
}
ic-ppc-return-request .panel .panel-info > mat-card > mat-card-content .content-header {
  display: flex;
  flex-flow: column;
  margin-bottom: 15px;
}
ic-ppc-return-request .panel .panel-info > mat-card > mat-card-content .content-header-senders {
  display: flex;
  flex-flow: row;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}
ic-ppc-return-request .panel .panel-info > mat-card > mat-card-content .content-solicitant {
  flex-basis: 45%;
  background: #ededed;
  padding: 10px;
  border: 1px solid #ededed;
  border-radius: 16px;
  display: flex;
  margin-bottom: 15px;
}
@media (max-width: 900px) {
  ic-ppc-return-request .panel .panel-info > mat-card > mat-card-content .content-solicitant {
    flex-basis: 100%;
  }
}
ic-ppc-return-request .panel .panel-info > mat-card > mat-card-content .content-solicitant .first-line,
ic-ppc-return-request .panel .panel-info > mat-card > mat-card-content .content-solicitant .second-line,
ic-ppc-return-request .panel .panel-info > mat-card > mat-card-content .content-solicitant .third-line,
ic-ppc-return-request .panel .panel-info > mat-card > mat-card-content .content-solicitant .fourth-line {
  margin-bottom: 5px;
}
ic-ppc-return-request .panel .panel-info > mat-card > mat-card-content .content-solicitant-icon {
  margin-right: 10px;
}
ic-ppc-return-request .panel .panel-info > mat-card > mat-card-content .content-solicitant-icon .material-icons {
  font-size: 55px;
  color: black;
}
ic-ppc-return-request .panel .panel-info > mat-card > mat-card-content .content-gestora {
  flex-basis: 45%;
  background: #ededed;
  padding: 10px;
  border: 1px solid #ededed;
  border-radius: 16px;
  margin-bottom: 15px;
  display: flex;
}
@media (max-width: 900px) {
  ic-ppc-return-request .panel .panel-info > mat-card > mat-card-content .content-gestora {
    flex-basis: 100%;
  }
}
ic-ppc-return-request .panel .panel-info > mat-card > mat-card-content .content-gestora .first-line,
ic-ppc-return-request .panel .panel-info > mat-card > mat-card-content .content-gestora .second-line,
ic-ppc-return-request .panel .panel-info > mat-card > mat-card-content .content-gestora .third-line,
ic-ppc-return-request .panel .panel-info > mat-card > mat-card-content .content-gestora .fourth-line {
  margin-bottom: 5px;
}
ic-ppc-return-request .panel .panel-info > mat-card > mat-card-content .content-gestora-icon {
  margin-right: 10px;
}
ic-ppc-return-request .panel .panel-info > mat-card > mat-card-content .content-gestora-icon .material-icons {
  font-size: 55px;
  color: black;
}
ic-ppc-return-request .panel .panel-info > mat-card > mat-card-content .fourth-line > .right-text {
  float: right;
}
ic-ppc-return-request .panel .panel-info > mat-card > mat-card-content .fourth-line > .left-text {
  display: inline-block;
}
ic-ppc-return-request .panel .panel-info > mat-card > mat-card-content .content-title {
  background: #f1dada;
  padding: 10px;
  border: 1px solid #f1dada;
  border-radius: 12px;
  text-align: center;
  font-weight: bold;
}
ic-ppc-return-request .panel .panel-info > mat-card > mat-card-content .content-subject {
  background: #ededed;
  padding: 10px;
  border: 1px solid #ededed;
  border-radius: 12px;
  margin-top: 15px;
}
ic-ppc-return-request .panel .panel-info > mat-card > mat-card-content .textarea-container-form-textarea {
  height: 250px;
}
ic-ppc-return-request .panel .panel-info .panel-buttons {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
}
ic-ppc-return-request .panel .mat-error-email {
  font-size: 12px;
  margin-bottom: 10px;
}
ic-ppc-return-request .panel mat-chip-grid .mat-chip,
ic-ppc-return-request .panel mat-chip-grid .mat-mdc-chip {
  width: auto;
  background-color: #eee;
  margin-bottom: 15px;
  display: flex;
  height: auto;
  align-self: center;
}
ic-ppc-return-request .panel mat-chip-grid .mat-chip .mdc-evolution-chip__text-label,
ic-ppc-return-request .panel mat-chip-grid .mat-mdc-chip .mdc-evolution-chip__text-label {
  width: auto;
  align-items: center;
  display: flex;
  height: auto;
}
ic-ppc-return-request .panel mat-chip-grid .mat-chip .mdc-evolution-chip__text-label > .icon-default,
ic-ppc-return-request .panel mat-chip-grid .mat-mdc-chip .mdc-evolution-chip__text-label > .icon-default {
  margin-right: 5px;
}
ic-ppc-return-request .panel mat-chip-grid .mat-chip .mdc-evolution-chip__text-label > .text,
ic-ppc-return-request .panel mat-chip-grid .mat-mdc-chip .mdc-evolution-chip__text-label > .text {
  display: flex;
  flex-flow: column;
  padding: 0.25em 0;
}
ic-ppc-return-request .panel mat-chip-grid .mat-chip .mdc-evolution-chip__text-label > .text > .email,
ic-ppc-return-request .panel mat-chip-grid .mat-mdc-chip .mdc-evolution-chip__text-label > .text > .email {
  font-size: 14px;
  font-weight: bold;
}
ic-ppc-return-request .panel mat-chip-grid .mat-chip .mdc-evolution-chip__text-label > .text > .name,
ic-ppc-return-request .panel mat-chip-grid .mat-mdc-chip .mdc-evolution-chip__text-label > .text > .name {
  font-size: 11px;
  font-weight: bold;
}
ic-ppc-return-request .panel mat-chip-grid .mat-chip-list-wrapper,
ic-ppc-return-request .panel mat-chip-grid .mdc-evolution-chip-set__chips {
  align-items: flex-start;
  flex-direction: row;
  flex-flow: row;
  flex-wrap: wrap;
}
ic-ppc-return-request .panel mat-chip-grid .mat-form-field,
ic-ppc-return-request .panel mat-chip-grid .mat-mdc-form-field {
  margin-left: 1em;
  width: 235px;
}
ic-ppc-return-request .panel mat-chip-grid .mat-chip-input,
ic-ppc-return-request .panel mat-chip-grid .mat-mdc-chip-input {
  margin-left: 6px;
  width: 220px;
  font-size: 12px;
}
ic-ppc-return-request .panel .panel-alert-empty-contacts {
  color: #977070;
  padding-left: 10px;
}
ic-ppc-return-request .panel .panel-alert-empty-div {
  background: rgba(255, 0, 0, 0.1607843137);
  height: 40px;
  margin: auto;
  display: flex;
  margin-top: 20px;
  align-items: center;
  border: 1px solid rgba(255, 0, 0, 0.2);
  border-radius: 7px;
}

ic-ppc-correct-request-not-accepted .amendment-panel .amendment-panel-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  padding: 0 12px 0 4px;
  white-space: normal;
}
ic-ppc-correct-request-not-accepted .amendment-panel .amendment-panel-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-ppc-correct-request-not-accepted .amendment-panel .amendment-panel-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-ppc-correct-request-not-accepted .amendment-panel .amendment-panel-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-ppc-correct-request-not-accepted .amendment-panel .amendment-panel-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-ppc-correct-request-not-accepted .amendment-panel .amendment-panel-toolbar .panel-buttons {
  width: max-content;
  min-width: max-content;
}
ic-ppc-correct-request-not-accepted .amendment-panel .documents-table-title-text {
  font-size: 20px;
  font-weight: 500;
}
ic-ppc-correct-request-not-accepted .amendment-panel .documents-table-title {
  margin-bottom: 10px;
}
ic-ppc-correct-request-not-accepted .amendment-panel .amendment-panel-info {
  min-height: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: #fff;
}
ic-ppc-correct-request-not-accepted .amendment-panel .amendment-panel-info > div {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
ic-ppc-correct-request-not-accepted .amendment-panel .amendment-panel-info .panel-buttons {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
}
ic-ppc-correct-request-not-accepted .amendment-panel .amendment-panel-documentation {
  margin-left: 15px;
}
ic-ppc-correct-request-not-accepted .amendment-panel .amendment-panel-documentation-title {
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 15px;
}
ic-ppc-correct-request-not-accepted .amendment-panel .dynamic-component-header-text {
  margin-left: 0px;
}
ic-ppc-correct-request-not-accepted .amendment-panel .ic-panel-input {
  margin-top: 0.5em;
}
ic-ppc-correct-request-not-accepted .amendment-panel .ic-slide-toggle {
  margin-bottom: 25px;
}
ic-ppc-correct-request-not-accepted .amendment-panel .panel-alert-empty-documents {
  color: #977070;
  padding-left: 10px;
}
ic-ppc-correct-request-not-accepted .amendment-panel .panel-alert-empty-div {
  background: rgba(255, 0, 0, 0.1607843137);
  height: 40px;
  margin: auto;
  display: flex;
  align-items: center;
  border: 1px solid rgba(255, 0, 0, 0.2);
  border-radius: 7px;
}

.ic-ppc-administrative-file-list .button-download {
  padding-right: 47px;
  width: 65%;
  text-align: end;
}

ic-ppc-return-administrative-file .returnpanel .returnpanel-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  padding: 0 12px 0 4px;
  white-space: normal;
}
ic-ppc-return-administrative-file .returnpanel .returnpanel-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-ppc-return-administrative-file .returnpanel .returnpanel-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-ppc-return-administrative-file .returnpanel .returnpanel-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-ppc-return-administrative-file .returnpanel .returnpanel-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-ppc-return-administrative-file .returnpanel .returnpanel-toolbar .panel-buttons {
  width: max-content;
  min-width: max-content;
}
ic-ppc-return-administrative-file .returnpanel .returnpanel-info {
  min-height: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: #fff;
}
ic-ppc-return-administrative-file .returnpanel .returnpanel-info > mat-card > mat-card-content > .two-element-row > ic-input-select > mat-form-field {
  width: -webkit-fill-available;
}
ic-ppc-return-administrative-file .returnpanel .returnpanel-info > mat-card > mat-card-content > .two-element-row .margin-10 {
  margin: 10px;
}
ic-ppc-return-administrative-file .returnpanel .returnpanel-info > mat-card > mat-card-content .text-correction {
  color: #666;
  background: #ededed;
  border: 1px solid #ededed;
  border-radius: 12px;
  padding: 10px;
}
ic-ppc-return-administrative-file .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-header {
  display: flex;
  flex-flow: column;
  margin-bottom: 15px;
}
ic-ppc-return-administrative-file .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-header-senders {
  display: flex;
  flex-flow: row;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}
ic-ppc-return-administrative-file .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-solicitant {
  flex-basis: 45%;
  background: #ededed;
  padding: 10px;
  border: 1px solid #ededed;
  border-radius: 16px;
  display: flex;
  margin-bottom: 15px;
}
@media (max-width: 900px) {
  ic-ppc-return-administrative-file .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-solicitant {
    flex-basis: 100%;
  }
}
ic-ppc-return-administrative-file .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-solicitant .first-line,
ic-ppc-return-administrative-file .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-solicitant .second-line,
ic-ppc-return-administrative-file .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-solicitant .third-line,
ic-ppc-return-administrative-file .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-solicitant .fourth-line {
  margin-bottom: 5px;
}
ic-ppc-return-administrative-file .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-solicitant-icon {
  margin-right: 10px;
}
ic-ppc-return-administrative-file .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-solicitant-icon .material-icons {
  font-size: 55px;
  color: black;
}
ic-ppc-return-administrative-file .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-gestora {
  flex-basis: 45%;
  background: #ededed;
  padding: 10px;
  border: 1px solid #ededed;
  border-radius: 16px;
  margin-bottom: 15px;
  display: flex;
}
@media (max-width: 900px) {
  ic-ppc-return-administrative-file .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-gestora {
    flex-basis: 100%;
  }
}
ic-ppc-return-administrative-file .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-gestora .first-line,
ic-ppc-return-administrative-file .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-gestora .second-line,
ic-ppc-return-administrative-file .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-gestora .third-line,
ic-ppc-return-administrative-file .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-gestora .fourth-line {
  margin-bottom: 5px;
}
ic-ppc-return-administrative-file .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-gestora-icon {
  margin-right: 10px;
}
ic-ppc-return-administrative-file .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-gestora-icon .material-icons {
  font-size: 55px;
  color: black;
}
ic-ppc-return-administrative-file .returnpanel .returnpanel-info > mat-card > mat-card-content .fourth-line > .right-text {
  float: right;
}
ic-ppc-return-administrative-file .returnpanel .returnpanel-info > mat-card > mat-card-content .fourth-line > .left-text {
  display: inline-block;
}
ic-ppc-return-administrative-file .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-title {
  background: #f1dada;
  padding: 10px;
  border: 1px solid #f1dada;
  border-radius: 12px;
  text-align: center;
  font-weight: bold;
}
ic-ppc-return-administrative-file .returnpanel .returnpanel-info > mat-card > mat-card-content .return-content-subject {
  background: #ededed;
  padding: 10px;
  border: 1px solid #ededed;
  border-radius: 12px;
  margin-top: 15px;
}
ic-ppc-return-administrative-file .returnpanel .returnpanel-info > mat-card > mat-card-content .textarea-container-form-textarea {
  height: 250px;
}
ic-ppc-return-administrative-file .returnpanel .returnpanel-info .panel-buttons {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
}
ic-ppc-return-administrative-file .returnpanel .mat-error-email {
  font-size: 12px;
  margin-bottom: 10px;
}
ic-ppc-return-administrative-file .returnpanel mat-chip-grid .mat-chip,
ic-ppc-return-administrative-file .returnpanel mat-chip-grid .mat-mdc-chip {
  width: auto;
  background-color: #eee;
  margin-bottom: 15px;
  display: flex;
  height: auto;
  align-self: center;
}
ic-ppc-return-administrative-file .returnpanel mat-chip-grid .mat-chip .mdc-evolution-chip__text-label,
ic-ppc-return-administrative-file .returnpanel mat-chip-grid .mat-mdc-chip .mdc-evolution-chip__text-label {
  width: auto;
  align-items: center;
  display: flex;
  height: auto;
}
ic-ppc-return-administrative-file .returnpanel mat-chip-grid .mat-chip .mdc-evolution-chip__text-label > .icon-default,
ic-ppc-return-administrative-file .returnpanel mat-chip-grid .mat-mdc-chip .mdc-evolution-chip__text-label > .icon-default {
  margin-right: 5px;
}
ic-ppc-return-administrative-file .returnpanel mat-chip-grid .mat-chip .mdc-evolution-chip__text-label > .text,
ic-ppc-return-administrative-file .returnpanel mat-chip-grid .mat-mdc-chip .mdc-evolution-chip__text-label > .text {
  display: flex;
  flex-flow: column;
  padding: 0.25em 0;
}
ic-ppc-return-administrative-file .returnpanel mat-chip-grid .mat-chip .mdc-evolution-chip__text-label > .text > .email,
ic-ppc-return-administrative-file .returnpanel mat-chip-grid .mat-mdc-chip .mdc-evolution-chip__text-label > .text > .email {
  font-size: 14px;
  font-weight: bold;
}
ic-ppc-return-administrative-file .returnpanel mat-chip-grid .mat-chip .mdc-evolution-chip__text-label > .text > .name,
ic-ppc-return-administrative-file .returnpanel mat-chip-grid .mat-mdc-chip .mdc-evolution-chip__text-label > .text > .name {
  font-size: 11px;
  font-weight: bold;
}
ic-ppc-return-administrative-file .returnpanel mat-chip-grid .mat-chip-list-wrapper,
ic-ppc-return-administrative-file .returnpanel mat-chip-grid .mdc-evolution-chip-set__chips {
  align-items: flex-start;
  flex-direction: row;
  flex-flow: row;
  flex-wrap: wrap;
}
ic-ppc-return-administrative-file .returnpanel mat-chip-grid .mat-form-field,
ic-ppc-return-administrative-file .returnpanel mat-chip-grid .mat-mdc-form-field {
  margin-left: 1em;
  width: 235px;
}
ic-ppc-return-administrative-file .returnpanel mat-chip-grid .mat-chip-input,
ic-ppc-return-administrative-file .returnpanel mat-chip-grid .mat-mdc-chip-input {
  margin-left: 6px;
  width: 220px;
  font-size: 12px;
}
ic-ppc-return-administrative-file .returnpanel .panel-alert-empty-contacts {
  color: #977070;
  padding-left: 10px;
}
ic-ppc-return-administrative-file .returnpanel .panel-alert-empty-div {
  background: rgba(255, 0, 0, 0.1607843137);
  height: 40px;
  margin: auto;
  display: flex;
  margin-top: 20px;
  align-items: center;
  border: 1px solid rgba(255, 0, 0, 0.2);
  border-radius: 7px;
}

ic-ppc-administrative-file-complementary-letter .panel .panel-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  padding: 0 12px 0 4px;
  white-space: normal;
}
ic-ppc-administrative-file-complementary-letter .panel .panel-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-ppc-administrative-file-complementary-letter .panel .panel-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-ppc-administrative-file-complementary-letter .panel .panel-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-ppc-administrative-file-complementary-letter .panel .panel-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-ppc-administrative-file-complementary-letter .panel .panel-toolbar .panel-buttons {
  width: max-content;
  min-width: max-content;
}
ic-ppc-administrative-file-complementary-letter .panel .panel-info {
  min-height: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: #fff;
}
ic-ppc-administrative-file-complementary-letter .panel .panel-info > mat-card > mat-card-content .text-correction {
  color: #666;
  background: #ededed;
  border: 1px solid #ededed;
  border-radius: 12px;
  padding: 10px;
}
ic-ppc-administrative-file-complementary-letter .panel .panel-info > mat-card > mat-card-content .correction-content-header {
  display: flex;
  flex-flow: column;
  margin-bottom: 15px;
}
ic-ppc-administrative-file-complementary-letter .panel .panel-info > mat-card > mat-card-content .correction-content-header-senders {
  display: flex;
  flex-flow: row;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}
ic-ppc-administrative-file-complementary-letter .panel .panel-info > mat-card > mat-card-content .correction-content-solicitant {
  flex-basis: 45%;
  background: #ededed;
  padding: 10px;
  border: 1px solid #ededed;
  border-radius: 16px;
  display: flex;
  margin-bottom: 15px;
}
@media (max-width: 900px) {
  ic-ppc-administrative-file-complementary-letter .panel .panel-info > mat-card > mat-card-content .correction-content-solicitant {
    flex-basis: 100%;
  }
}
ic-ppc-administrative-file-complementary-letter .panel .panel-info > mat-card > mat-card-content .correction-content-solicitant .first-line,
ic-ppc-administrative-file-complementary-letter .panel .panel-info > mat-card > mat-card-content .correction-content-solicitant .second-line,
ic-ppc-administrative-file-complementary-letter .panel .panel-info > mat-card > mat-card-content .correction-content-solicitant .third-line,
ic-ppc-administrative-file-complementary-letter .panel .panel-info > mat-card > mat-card-content .correction-content-solicitant .fourth-line {
  margin-bottom: 5px;
}
ic-ppc-administrative-file-complementary-letter .panel .panel-info > mat-card > mat-card-content .correction-content-solicitant-icon {
  margin-right: 10px;
}
ic-ppc-administrative-file-complementary-letter .panel .panel-info > mat-card > mat-card-content .correction-content-solicitant-icon .material-icons {
  font-size: 55px;
  color: black;
}
ic-ppc-administrative-file-complementary-letter .panel .panel-info > mat-card > mat-card-content .correction-content-gestora {
  flex-basis: 45%;
  background: #ededed;
  padding: 10px;
  border: 1px solid #ededed;
  border-radius: 16px;
  margin-bottom: 15px;
  display: flex;
}
@media (max-width: 900px) {
  ic-ppc-administrative-file-complementary-letter .panel .panel-info > mat-card > mat-card-content .correction-content-gestora {
    flex-basis: 100%;
  }
}
ic-ppc-administrative-file-complementary-letter .panel .panel-info > mat-card > mat-card-content .correction-content-gestora .first-line,
ic-ppc-administrative-file-complementary-letter .panel .panel-info > mat-card > mat-card-content .correction-content-gestora .second-line,
ic-ppc-administrative-file-complementary-letter .panel .panel-info > mat-card > mat-card-content .correction-content-gestora .third-line,
ic-ppc-administrative-file-complementary-letter .panel .panel-info > mat-card > mat-card-content .correction-content-gestora .fourth-line {
  margin-bottom: 5px;
}
ic-ppc-administrative-file-complementary-letter .panel .panel-info > mat-card > mat-card-content .correction-content-gestora-icon {
  margin-right: 10px;
}
ic-ppc-administrative-file-complementary-letter .panel .panel-info > mat-card > mat-card-content .correction-content-gestora-icon .material-icons {
  font-size: 55px;
  color: black;
}
ic-ppc-administrative-file-complementary-letter .panel .panel-info > mat-card > mat-card-content .correction-content-gestora .fourth-line > .right-text {
  float: right;
}
ic-ppc-administrative-file-complementary-letter .panel .panel-info > mat-card > mat-card-content .correction-content-gestora .fourth-line > .left-text {
  display: inline-block;
}
ic-ppc-administrative-file-complementary-letter .panel .panel-info > mat-card > mat-card-content .correction-content-title {
  background: #f1dada;
  padding: 10px;
  border: 1px solid #f1dada;
  border-radius: 12px;
  text-align: center;
  font-weight: bold;
}
ic-ppc-administrative-file-complementary-letter .panel .panel-info > mat-card > mat-card-content .correction-content-subject {
  background: #ededed;
  padding: 10px;
  border: 1px solid #ededed;
  border-radius: 12px;
  margin-top: 15px;
}
ic-ppc-administrative-file-complementary-letter .panel .panel-info > mat-card > mat-card-content .textarea-container-form-textarea {
  height: 250px;
}
ic-ppc-administrative-file-complementary-letter .panel .panel-info .panel-buttons {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
}
ic-ppc-administrative-file-complementary-letter .panel .mat-error-email {
  font-size: 12px;
  margin-bottom: 10px;
}
ic-ppc-administrative-file-complementary-letter .panel mat-chip-grid .mat-chip,
ic-ppc-administrative-file-complementary-letter .panel mat-chip-grid .mat-mdc-chip {
  width: auto;
  background-color: #eee;
  margin-bottom: 15px;
  display: flex;
  height: auto;
  align-self: center;
}
ic-ppc-administrative-file-complementary-letter .panel mat-chip-grid .mat-chip .mdc-evolution-chip__text-label,
ic-ppc-administrative-file-complementary-letter .panel mat-chip-grid .mat-mdc-chip .mdc-evolution-chip__text-label {
  width: auto;
  align-items: center;
  display: flex;
  height: auto;
}
ic-ppc-administrative-file-complementary-letter .panel mat-chip-grid .mat-chip .mdc-evolution-chip__text-label > .icon-default,
ic-ppc-administrative-file-complementary-letter .panel mat-chip-grid .mat-mdc-chip .mdc-evolution-chip__text-label > .icon-default {
  margin-right: 5px;
}
ic-ppc-administrative-file-complementary-letter .panel mat-chip-grid .mat-chip .mdc-evolution-chip__text-label > .text,
ic-ppc-administrative-file-complementary-letter .panel mat-chip-grid .mat-mdc-chip .mdc-evolution-chip__text-label > .text {
  display: flex;
  flex-flow: column;
  padding: 0.25em 0;
}
ic-ppc-administrative-file-complementary-letter .panel mat-chip-grid .mat-chip .mdc-evolution-chip__text-label > .text > .email,
ic-ppc-administrative-file-complementary-letter .panel mat-chip-grid .mat-mdc-chip .mdc-evolution-chip__text-label > .text > .email {
  font-size: 14px;
  font-weight: bold;
}
ic-ppc-administrative-file-complementary-letter .panel mat-chip-grid .mat-chip .mdc-evolution-chip__text-label > .text > .name,
ic-ppc-administrative-file-complementary-letter .panel mat-chip-grid .mat-mdc-chip .mdc-evolution-chip__text-label > .text > .name {
  font-size: 11px;
  font-weight: bold;
}
ic-ppc-administrative-file-complementary-letter .panel mat-chip-grid .mat-chip-list-wrapper,
ic-ppc-administrative-file-complementary-letter .panel mat-chip-grid .mdc-evolution-chip-set__chips {
  align-items: flex-start;
  flex-direction: row;
  flex-flow: row;
  flex-wrap: wrap;
}
ic-ppc-administrative-file-complementary-letter .panel mat-chip-grid .mat-form-field,
ic-ppc-administrative-file-complementary-letter .panel mat-chip-grid .mat-mdc-form-field {
  margin-left: 1em;
  width: 235px;
}
ic-ppc-administrative-file-complementary-letter .panel mat-chip-grid .mat-chip-input,
ic-ppc-administrative-file-complementary-letter .panel mat-chip-grid .mat-mdc-chip-input {
  margin-left: 6px;
  width: 220px;
  font-size: 12px;
}
ic-ppc-administrative-file-complementary-letter .panel .panel-alert-empty-contacts {
  color: #977070;
  padding-left: 10px;
}
ic-ppc-administrative-file-complementary-letter .panel .panel-alert-empty-div {
  background: rgba(255, 0, 0, 0.1607843137);
  height: 40px;
  margin: auto;
  display: flex;
  margin-top: 20px;
  align-items: center;
  border: 1px solid rgba(255, 0, 0, 0.2);
  border-radius: 7px;
}

mat-spinner {
  margin: 20px auto;
}

ic-ppc-administrative-file-incorporate-response .ppc-administrative-file-correction-response {
  display: flex;
  flex-flow: column;
}
ic-ppc-administrative-file-incorporate-response .ppc-administrative-file-correction-response .button-back {
  margin-top: 10px;
  margin-left: 10px;
  height: min-content;
  width: -moz-fit-content;
  width: fit-content;
}
ic-ppc-administrative-file-incorporate-response .ppc-administrative-file-correction-response .response-info {
  margin: auto;
  max-width: 1000px;
  width: 100%;
  margin-bottom: 50px;
}
ic-ppc-administrative-file-incorporate-response .ppc-administrative-file-correction-response .edit-button {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 15px;
  flex-basis: 25%;
}
ic-ppc-administrative-file-incorporate-response .ppc-administrative-file-correction-response mat-card {
  margin-bottom: 20px;
  max-width: 1000px;
  margin: auto;
  margin-top: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2);
}
ic-ppc-administrative-file-incorporate-response .ppc-administrative-file-correction-response mat-card mat-card-title {
  margin: 0 0 20px -16px;
}
ic-ppc-administrative-file-incorporate-response .ppc-administrative-file-correction-response mat-card mat-card-header {
  justify-content: space-between;
}
ic-ppc-administrative-file-incorporate-response .ppc-administrative-file-correction-response mat-card mat-card-header-text {
  flex-basis: 80%;
  align-self: center;
}
ic-ppc-administrative-file-incorporate-response .ppc-administrative-file-correction-response mat-card mat-card-subtitle {
  margin-left: -16px;
  display: flex;
  align-items: center;
}
ic-ppc-administrative-file-incorporate-response .ppc-administrative-file-correction-response mat-card mat-card-subtitle > span {
  cursor: pointer;
}
ic-ppc-administrative-file-incorporate-response .ppc-administrative-file-correction-response mat-card .documents-table-title-text {
  font-size: 20px;
  font-weight: 500;
}
ic-ppc-administrative-file-incorporate-response .ppc-administrative-file-correction-response mat-card .documents-table-title {
  margin-bottom: 10px;
}
ic-ppc-administrative-file-incorporate-response .ppc-administrative-file-correction-response mat-card .ic-searchbox {
  box-shadow: none;
}
ic-ppc-administrative-file-incorporate-response .ppc-administrative-file-correction-response mat-card mat-list-item {
  height: auto;
}
ic-ppc-administrative-file-incorporate-response .ppc-administrative-file-correction-response mat-card > mat-card-content {
  font-size: 16px;
}
ic-ppc-administrative-file-incorporate-response .ppc-administrative-file-correction-response mat-card > mat-card-content .ic-slide-toggle {
  margin-bottom: 25px;
}
ic-ppc-administrative-file-incorporate-response .ppc-administrative-file-correction-response mat-card > mat-card-content .two-element-row-one-element {
  flex-basis: 100%;
}
ic-ppc-administrative-file-incorporate-response .panel-alert-empty-documents {
  color: #977070;
  padding-left: 10px;
}
ic-ppc-administrative-file-incorporate-response .panel-alert-empty-div {
  background: rgba(255, 0, 0, 0.1607843137);
  height: 40px;
  margin: auto;
  display: flex;
  align-items: center;
  border: 1px solid rgba(255, 0, 0, 0.2);
  border-radius: 7px;
}

ic-ppc-administrative-file-reopen .reopen-panel .reopen-panel-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  padding: 0 12px 0 4px;
  white-space: normal;
}
ic-ppc-administrative-file-reopen .reopen-panel .reopen-panel-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-ppc-administrative-file-reopen .reopen-panel .reopen-panel-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-ppc-administrative-file-reopen .reopen-panel .reopen-panel-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-ppc-administrative-file-reopen .reopen-panel .reopen-panel-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-ppc-administrative-file-reopen .reopen-panel .reopen-panel-toolbar .panel-buttons {
  width: max-content;
  min-width: max-content;
}
ic-ppc-administrative-file-reopen .reopen-panel .documents-table-title-text {
  font-size: 20px;
  font-weight: 500;
}
ic-ppc-administrative-file-reopen .reopen-panel .documents-table-title {
  margin-bottom: 10px;
}
ic-ppc-administrative-file-reopen .reopen-panel .reopen-panel-info {
  min-height: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: #fff;
}
ic-ppc-administrative-file-reopen .reopen-panel .reopen-panel-info > div {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
ic-ppc-administrative-file-reopen .reopen-panel .reopen-panel-info .panel-buttons {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
}
ic-ppc-administrative-file-reopen .reopen-panel .reopen-panel-documentation {
  margin-left: 15px;
}
ic-ppc-administrative-file-reopen .reopen-panel .reopen-panel-documentation-title {
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 15px;
}
ic-ppc-administrative-file-reopen .reopen-panel .dynamic-component-header-text {
  margin-left: 0px;
}
ic-ppc-administrative-file-reopen .reopen-panel .ic-panel-input {
  margin-top: 0.5em;
}
ic-ppc-administrative-file-reopen .reopen-panel .ic-slide-toggle {
  margin-bottom: 25px;
}
ic-ppc-administrative-file-reopen .reopen-panel .panel-alert-empty-documents {
  color: #977070;
  padding-left: 10px;
}
ic-ppc-administrative-file-reopen .reopen-panel .panel-alert-empty-div {
  background: rgba(255, 0, 0, 0.1607843137);
  height: 40px;
  margin: auto;
  display: flex;
  align-items: center;
  border: 1px solid rgba(255, 0, 0, 0.2);
  border-radius: 7px;
}

ic-ppc-report-check-panel .panel {
  height: 100%;
  width: 100%;
}
ic-ppc-report-check-panel .panel .panel-title {
  white-space: normal;
}
ic-ppc-report-check-panel .panel .ic-selectable-table mat-header-row {
  justify-content: space-between;
}
ic-ppc-report-check-panel .panel .ic-selectable-table mat-header-row.ic-selectable-row {
  height: 40px !important;
  top: 66px !important;
}
ic-ppc-report-check-panel .panel .ic-selectable-table mat-header-row.ic-selectable-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-ppc-report-check-panel .panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0 0 1.2em;
}
ic-ppc-report-check-panel .panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type label {
  height: 24px;
}
ic-ppc-report-check-panel .panel .ic-selectable-table mat-header-row .mat-mdc-header-cell {
  min-width: min-content;
}
ic-ppc-report-check-panel .panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(2) {
  padding: 0 0.5em 0 2em;
}
ic-ppc-report-check-panel .panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-ppc-report-check-panel .panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:last-of-type {
  min-width: min-content;
}
ic-ppc-report-check-panel .panel .ic-selectable-table mat-row {
  justify-content: space-between;
}
ic-ppc-report-check-panel .panel .ic-selectable-table mat-row .mat-mdc-cell:first-of-type {
  padding: 0 0.4em;
  min-width: min-content;
}
ic-ppc-report-check-panel .panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(2) {
  min-width: min-content;
}
ic-ppc-report-check-panel .panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-ppc-report-check-panel .panel .ic-selectable-table mat-row .mat-mdc-cell:last-of-type {
  min-width: min-content;
}
ic-ppc-report-check-panel .panel .ic-selectable-table mat-row .mdc-data-table__cell {
  margin-top: 0 !important;
}
ic-ppc-report-check-panel .panel .ic-selectable-table mat-row .mdc-data-table__cell > span {
  line-height: 16px;
  padding: 5px 0 5px 0;
}
ic-ppc-report-check-panel .panel ic-detail-table .mat-mdc-row .mdc-data-table__cell {
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
  min-width: 103px;
}
ic-ppc-report-check-panel .panel ic-detail-table .mat-header-row .mdc-data-table__header-cell,
ic-ppc-report-check-panel .panel ic-detail-table .mat-mdc-header-row .mdc-data-table__header-cell {
  font-size: 12px;
  font-weight: 600;
  min-width: 103px;
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
}
ic-ppc-report-check-panel .panel .panel-toolbar {
  position: sticky;
  top: 0;
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  max-height: 56px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px 0 4px;
  z-index: 999;
}
ic-ppc-report-check-panel .panel .panel-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-ppc-report-check-panel .panel .panel-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-ppc-report-check-panel .panel .panel-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-ppc-report-check-panel .panel .panel-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-ppc-report-check-panel .panel .panel-toolbar .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-ppc-report-check-panel .panel .panel-info {
  padding: 0;
  padding-top: 1em;
  height: calc(100% - 56px);
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: #fff;
}
ic-ppc-report-check-panel .panel .panel-info .file-type {
  font-weight: bolder;
  font-size: 14px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
ic-ppc-report-check-panel .panel .panel-info > mat-card {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2);
}
ic-ppc-report-check-panel .panel .panel-info > mat-card > mat-card-content .mat-form-field-infix,
ic-ppc-report-check-panel .panel .panel-info > mat-card > mat-card-content .mat-mdc-form-field-infix {
  width: 500px;
}
ic-ppc-report-check-panel .panel .panel-info > mat-card > mat-card-content .signs-buttons {
  display: flex;
}
ic-ppc-report-check-panel .panel .panel-info > mat-card .observations-table {
  margin-top: 15px;
}
ic-ppc-report-check-panel .panel .panel-info > mat-card .observations-selected-table {
  margin-top: 30px;
}
ic-ppc-report-check-panel .panel .panel-info > mat-card .observations-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5em;
}
ic-ppc-report-check-panel .panel .panel-info > mat-card .radio-group {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  margin: 15px 0;
}
ic-ppc-report-check-panel .panel .panel-info > mat-card .opacity {
  opacity: 0.5;
}
ic-ppc-report-check-panel .panel .panel-info > mat-card .conclusion-text {
  font-weight: bold;
  padding-left: 1em;
}
ic-ppc-report-check-panel .panel .panel-info > mat-card .radio-button {
  margin: 5px;
}
ic-ppc-report-check-panel .panel .panel-info > div {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
ic-ppc-report-check-panel .panel .panel-table-info {
  min-height: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: #fff;
}
ic-ppc-report-check-panel .panel .panel-table-info .observations-title {
  font-size: 14px;
  font-weight: 600;
}
ic-ppc-report-check-panel .panel .panel-table-info > mat-card {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2);
}
ic-ppc-report-check-panel .panel .panel-table-info > mat-card > mat-card-content > .two-element-row {
  flex-direction: row;
  display: flex;
}
ic-ppc-report-check-panel .panel .panel-table-info > div {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
ic-ppc-report-check-panel .panel .panel-table-info > .panel-title {
  padding-top: 25px;
}
ic-ppc-report-check-panel .panel .panel-table-info .edit-button {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 30px;
  flex-basis: 25%;
}
ic-ppc-report-check-panel .panel .panel-table-info .panel-table-info {
  padding: 20px;
}
ic-ppc-report-check-panel .dynamic-component-header-text {
  margin-left: 0px;
}
ic-ppc-report-check-panel .ic-panel-input {
  margin-top: 0.5em;
}
ic-ppc-report-check-panel .favorable {
  color: #0a8407;
  font-weight: bolder;
}
ic-ppc-report-check-panel .unfavorable {
  color: #e80202;
  font-weight: bolder;
}
ic-ppc-report-check-panel .favorable-but-errors {
  color: #e58824;
  font-weight: bolder;
}
ic-ppc-report-check-panel ic-dialog-pdf {
  display: flex;
  flex-flow: column;
  height: 100%;
}
ic-ppc-report-check-panel .row-one-element {
  width: 75%;
}

ic-ppc-administrative-file-reformulate .reformulate-panel .reformulate-panel-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  padding: 0 12px 0 4px;
  white-space: normal;
}
ic-ppc-administrative-file-reformulate .reformulate-panel .reformulate-panel-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-ppc-administrative-file-reformulate .reformulate-panel .reformulate-panel-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-ppc-administrative-file-reformulate .reformulate-panel .reformulate-panel-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-ppc-administrative-file-reformulate .reformulate-panel .reformulate-panel-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-ppc-administrative-file-reformulate .reformulate-panel .reformulate-panel-toolbar .panel-buttons {
  width: max-content;
  min-width: max-content;
}
ic-ppc-administrative-file-reformulate .reformulate-panel .documents-table-title-text {
  font-size: 20px;
  font-weight: 500;
}
ic-ppc-administrative-file-reformulate .reformulate-panel .documents-table-title {
  margin-bottom: 10px;
}
ic-ppc-administrative-file-reformulate .reformulate-panel .reformulate-panel-info {
  min-height: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: #fff;
}
ic-ppc-administrative-file-reformulate .reformulate-panel .reformulate-panel-info > div {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
ic-ppc-administrative-file-reformulate .reformulate-panel .reformulate-panel-info .panel-buttons {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
}
ic-ppc-administrative-file-reformulate .reformulate-panel .reformulate-panel-documentation {
  margin-left: 15px;
}
ic-ppc-administrative-file-reformulate .reformulate-panel .reformulate-panel-documentation-title {
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 15px;
}
ic-ppc-administrative-file-reformulate .reformulate-panel .dynamic-component-header-text {
  margin-left: 0px;
}
ic-ppc-administrative-file-reformulate .reformulate-panel .ic-panel-input {
  margin-top: 0.5em;
}
ic-ppc-administrative-file-reformulate .reformulate-panel .ic-slide-toggle {
  margin-bottom: 25px;
}
ic-ppc-administrative-file-reformulate .reformulate-panel .panel-alert-empty-documents {
  color: #977070;
  padding-left: 10px;
}
ic-ppc-administrative-file-reformulate .reformulate-panel .panel-alert-empty-div {
  background: rgba(255, 0, 0, 0.1607843137);
  height: 40px;
  margin: auto;
  display: flex;
  align-items: center;
  border: 1px solid rgba(255, 0, 0, 0.2);
  border-radius: 7px;
}

ic-ppc-report-observation-panel .ppc-report-observation-panel .ic-selectable-table mat-header-row {
  justify-content: space-between;
}
ic-ppc-report-observation-panel .ppc-report-observation-panel .ic-selectable-table mat-header-row.ic-selectable-row {
  height: 40px !important;
  top: 66px !important;
}
ic-ppc-report-observation-panel .ppc-report-observation-panel .ic-selectable-table mat-header-row.ic-selectable-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-ppc-report-observation-panel .ppc-report-observation-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0 0 1.2em;
}
ic-ppc-report-observation-panel .ppc-report-observation-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type label {
  height: 24px;
}
ic-ppc-report-observation-panel .ppc-report-observation-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell {
  min-width: min-content;
}
ic-ppc-report-observation-panel .ppc-report-observation-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(2) {
  padding: 0 0.5em 0 2em;
}
ic-ppc-report-observation-panel .ppc-report-observation-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-ppc-report-observation-panel .ppc-report-observation-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:last-of-type {
  min-width: min-content;
}
ic-ppc-report-observation-panel .ppc-report-observation-panel .ic-selectable-table mat-row {
  justify-content: space-between;
}
ic-ppc-report-observation-panel .ppc-report-observation-panel .ic-selectable-table mat-row .mat-mdc-cell:first-of-type {
  padding: 0 0.4em;
  min-width: min-content;
}
ic-ppc-report-observation-panel .ppc-report-observation-panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(2) {
  min-width: min-content;
}
ic-ppc-report-observation-panel .ppc-report-observation-panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-ppc-report-observation-panel .ppc-report-observation-panel .ic-selectable-table mat-row .mat-mdc-cell:last-of-type {
  min-width: min-content;
}
ic-ppc-report-observation-panel .ppc-report-observation-panel .ic-selectable-table mat-row .mdc-data-table__cell {
  margin-top: 0 !important;
}
ic-ppc-report-observation-panel .ppc-report-observation-panel .ic-selectable-table mat-row .mdc-data-table__cell > span {
  line-height: 16px;
  padding: 5px 0 5px 0;
}
ic-ppc-report-observation-panel .ppc-report-observation-panel ic-detail-table .mat-mdc-row .mdc-data-table__cell {
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
  min-width: 103px;
}
ic-ppc-report-observation-panel .ppc-report-observation-panel ic-detail-table .mat-header-row .mdc-data-table__header-cell,
ic-ppc-report-observation-panel .ppc-report-observation-panel ic-detail-table .mat-mdc-header-row .mdc-data-table__header-cell {
  font-size: 12px;
  font-weight: 600;
  min-width: 103px;
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
}
ic-ppc-report-observation-panel .ppc-report-observation-panel .ppc-report-observation-panel-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  max-height: 56px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px 0 4px;
  z-index: 999;
}
ic-ppc-report-observation-panel .ppc-report-observation-panel .ppc-report-observation-panel-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-ppc-report-observation-panel .ppc-report-observation-panel .ppc-report-observation-panel-toolbar .panel-title {
  flex: auto;
}
ic-ppc-report-observation-panel .ppc-report-observation-panel .ppc-report-observation-panel-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-ppc-report-observation-panel .ppc-report-observation-panel .ppc-report-observation-panel-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-ppc-report-observation-panel .ppc-report-observation-panel .ppc-report-observation-panel-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-ppc-report-observation-panel .ppc-report-observation-panel .ppc-report-observation-panel-toolbar .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-ppc-report-observation-panel .ppc-report-observation-panel .ppc-report-observation-panel-info {
  min-height: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: #fff;
}
ic-ppc-report-observation-panel .ppc-report-observation-panel .ppc-report-observation-panel-info .observations-title {
  font-size: 14px;
  font-weight: 600;
}
ic-ppc-report-observation-panel .ppc-report-observation-panel .ppc-report-observation-panel-info > mat-card {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2);
}
ic-ppc-report-observation-panel .ppc-report-observation-panel .ppc-report-observation-panel-info > mat-card > mat-card-content > .two-element-row {
  flex-direction: row;
  display: flex;
}
ic-ppc-report-observation-panel .ppc-report-observation-panel .ppc-report-observation-panel-info > div {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
ic-ppc-report-observation-panel .ppc-report-observation-panel .ppc-report-observation-panel-info > .ppc-report-observation-panel-title {
  padding-top: 25px;
}
ic-ppc-report-observation-panel .ppc-report-observation-panel .ppc-report-observation-panel-info .edit-button {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 30px;
  flex-basis: 25%;
}

.dynamic-component-header-text {
  margin-left: 0px;
}

.ic-panel-input {
  margin-top: 0.5em;
}

.ic-slide-toggle {
  margin-bottom: 25px;
}

.two-element-row-element {
  flex-basis: 50%;
}

.row-one-element {
  width: 75%;
}

.text-match {
  color: red;
}

ic-ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel .ic-selectable-table mat-header-row {
  justify-content: space-between;
}
ic-ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel .ic-selectable-table mat-header-row.ic-selectable-row {
  height: 40px !important;
  top: 66px !important;
}
ic-ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel .ic-selectable-table mat-header-row.ic-selectable-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0 0 1.2em;
}
ic-ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type label {
  height: 24px;
}
ic-ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell {
  min-width: min-content;
}
ic-ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(2) {
  padding: 0 0.5em 0 2em;
}
ic-ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:last-of-type {
  min-width: min-content;
}
ic-ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel .ic-selectable-table mat-row {
  justify-content: space-between;
}
ic-ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel .ic-selectable-table mat-row .mat-mdc-cell:first-of-type {
  padding: 0 0.4em;
  min-width: min-content;
}
ic-ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(2) {
  min-width: min-content;
}
ic-ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel .ic-selectable-table mat-row .mat-mdc-cell:last-of-type {
  min-width: min-content;
}
ic-ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel .ic-selectable-table mat-row .mdc-data-table__cell {
  margin-top: 0 !important;
}
ic-ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel .ic-selectable-table mat-row .mdc-data-table__cell > span {
  line-height: 16px;
  padding: 5px 0 5px 0;
}
ic-ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel ic-detail-table .mat-mdc-row .mdc-data-table__cell {
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
  min-width: 103px;
}
ic-ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel ic-detail-table .mat-header-row .mdc-data-table__header-cell,
ic-ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel ic-detail-table .mat-mdc-header-row .mdc-data-table__header-cell {
  font-size: 12px;
  font-weight: 600;
  min-width: 103px;
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
}
ic-ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  max-height: 56px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px 0 4px;
  z-index: 999;
}
ic-ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel-toolbar .panel-title {
  flex: auto;
}
ic-ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel-toolbar .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel-info {
  min-height: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: #fff;
}
ic-ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel-info .observations-title {
  font-size: 14px;
  font-weight: 600;
}
ic-ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel-info > mat-card {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2);
}
ic-ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel-info > mat-card > mat-card-content > .two-element-row {
  flex-direction: row;
  display: flex;
}
ic-ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel-info > div {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
ic-ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel-info > .ppc-report-subjective-improvement-panel-title {
  padding-top: 25px;
}
ic-ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel .ppc-report-subjective-improvement-panel-info .edit-button {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 30px;
  flex-basis: 25%;
}

.dynamic-component-header-text {
  margin-left: 0px;
}

.ic-panel-input {
  margin-top: 0.5em;
}

.ic-slide-toggle {
  margin-bottom: 25px;
}

.two-element-row-element {
  flex-basis: 50%;
}

.row-one-element {
  width: 75%;
}

.text-match {
  color: red;
}

ic-ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel .ic-selectable-table mat-header-row {
  justify-content: space-between;
}
ic-ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel .ic-selectable-table mat-header-row.ic-selectable-row {
  height: 40px !important;
  top: 66px !important;
}
ic-ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel .ic-selectable-table mat-header-row.ic-selectable-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0 0 1.2em;
}
ic-ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type label {
  height: 24px;
}
ic-ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell {
  min-width: min-content;
}
ic-ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(2) {
  padding: 0 0.5em 0 2em;
}
ic-ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:last-of-type {
  min-width: min-content;
}
ic-ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel .ic-selectable-table mat-row {
  justify-content: space-between;
}
ic-ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel .ic-selectable-table mat-row .mat-mdc-cell:first-of-type {
  padding: 0 0.4em;
  min-width: min-content;
}
ic-ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(2) {
  min-width: min-content;
}
ic-ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel .ic-selectable-table mat-row .mat-mdc-cell:last-of-type {
  min-width: min-content;
}
ic-ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel .ic-selectable-table mat-row .mdc-data-table__cell {
  margin-top: 0 !important;
}
ic-ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel .ic-selectable-table mat-row .mdc-data-table__cell > span {
  line-height: 16px;
  padding: 5px 0 5px 0;
}
ic-ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel .comment-item-row {
  display: flex;
  margin-bottom: 20px;
}
ic-ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel ic-detail-table .mat-mdc-row .mdc-data-table__cell {
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
  min-width: 103px;
}
ic-ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel ic-detail-table .mat-header-row .mdc-data-table__header-cell,
ic-ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel ic-detail-table .mat-mdc-header-row .mdc-data-table__header-cell {
  font-size: 12px;
  font-weight: 600;
  min-width: 103px;
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
}
ic-ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  max-height: 56px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px 0 4px;
  z-index: 999;
}
ic-ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel-toolbar .panel-title {
  flex: auto;
}
ic-ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel-toolbar .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel-info {
  min-height: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: #fff;
}
ic-ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel-info .observations-title {
  font-size: 14px;
  font-weight: 600;
}
ic-ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel-info > mat-card {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2);
}
ic-ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel-info > mat-card > mat-card-content > .two-element-row {
  flex-direction: row;
  display: flex;
}
ic-ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel-info > div {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
ic-ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel-info > .ppc-report-check-item-comment-panel-title {
  padding-top: 25px;
}
ic-ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel .ppc-report-check-item-comment-panel-info .edit-button {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 30px;
  flex-basis: 25%;
}

.dynamic-component-header-text {
  margin-left: 0px;
}

.ic-panel-input {
  margin-top: 0.5em;
}

.ic-slide-toggle {
  margin-bottom: 25px;
}

.two-element-row-element {
  flex-basis: 50%;
}

.row-one-element {
  width: 75%;
}

.text-match {
  color: red;
}

.ic-omission-administrative-file-list .button-download {
  width: 62.65%;
  text-align: end;
}

ic-omission-administrative-files-panel-inspection .panel {
  height: 100%;
  width: 100%;
}
ic-omission-administrative-files-panel-inspection .panel .ic-selectable-table mat-header-row {
  justify-content: space-between;
}
ic-omission-administrative-files-panel-inspection .panel .ic-selectable-table mat-header-row.ic-selectable-row {
  height: 40px !important;
  top: 66px !important;
}
ic-omission-administrative-files-panel-inspection .panel .ic-selectable-table mat-header-row.ic-selectable-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-omission-administrative-files-panel-inspection .panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0 0 1.2em;
}
ic-omission-administrative-files-panel-inspection .panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type label {
  height: 24px;
}
ic-omission-administrative-files-panel-inspection .panel .ic-selectable-table mat-header-row .mat-mdc-header-cell {
  min-width: min-content;
}
ic-omission-administrative-files-panel-inspection .panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(2) {
  padding: 0 0.5em 0 2em;
}
ic-omission-administrative-files-panel-inspection .panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-omission-administrative-files-panel-inspection .panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:last-of-type {
  min-width: min-content;
}
ic-omission-administrative-files-panel-inspection .panel .ic-selectable-table mat-row {
  justify-content: space-between;
}
ic-omission-administrative-files-panel-inspection .panel .ic-selectable-table mat-row .mat-mdc-cell:first-of-type {
  padding: 0 0.4em;
  min-width: min-content;
}
ic-omission-administrative-files-panel-inspection .panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(2) {
  min-width: min-content;
}
ic-omission-administrative-files-panel-inspection .panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-omission-administrative-files-panel-inspection .panel .ic-selectable-table mat-row .mat-mdc-cell:last-of-type {
  min-width: min-content;
}
ic-omission-administrative-files-panel-inspection .panel .ic-selectable-table mat-row .mdc-data-table__cell {
  margin-top: 0 !important;
}
ic-omission-administrative-files-panel-inspection .panel .ic-selectable-table mat-row .mdc-data-table__cell > span {
  line-height: 16px;
  padding: 5px 0 5px 0;
}
ic-omission-administrative-files-panel-inspection .panel ic-detail-table .mat-mdc-row .mdc-data-table__cell {
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
  min-width: 103px;
}
ic-omission-administrative-files-panel-inspection .panel ic-detail-table .mat-header-row .mdc-data-table__header-cell,
ic-omission-administrative-files-panel-inspection .panel ic-detail-table .mat-mdc-header-row .mdc-data-table__header-cell {
  font-size: 12px;
  font-weight: 600;
  min-width: 103px;
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
}
ic-omission-administrative-files-panel-inspection .panel .panel-toolbar {
  position: sticky;
  top: 0;
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  max-height: 56px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px 0 4px;
  z-index: 999;
}
ic-omission-administrative-files-panel-inspection .panel .panel-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-omission-administrative-files-panel-inspection .panel .panel-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-omission-administrative-files-panel-inspection .panel .panel-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-omission-administrative-files-panel-inspection .panel .panel-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-omission-administrative-files-panel-inspection .panel .panel-toolbar .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-omission-administrative-files-panel-inspection .panel .panel-info {
  padding: 0;
  padding-top: 1em;
  height: calc(100% - 56px);
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: #fff;
}
ic-omission-administrative-files-panel-inspection .panel .panel-info .file-type {
  font-weight: bolder;
  font-size: 14px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
ic-omission-administrative-files-panel-inspection .panel .panel-info > mat-card {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2);
}
ic-omission-administrative-files-panel-inspection .panel .panel-info > mat-card > mat-card-content .mat-form-field-infix,
ic-omission-administrative-files-panel-inspection .panel .panel-info > mat-card > mat-card-content .mat-mdc-form-field-infix {
  width: 500px;
}
ic-omission-administrative-files-panel-inspection .panel .panel-info > mat-card > mat-card-content .signs-buttons {
  display: flex;
}
ic-omission-administrative-files-panel-inspection .panel .panel-info > mat-card .observations-table {
  margin-top: 15px;
}
ic-omission-administrative-files-panel-inspection .panel .panel-info > mat-card .observations-selected-table {
  margin-top: 30px;
}
ic-omission-administrative-files-panel-inspection .panel .panel-info > mat-card .observations-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5em;
}
ic-omission-administrative-files-panel-inspection .panel .panel-info > mat-card .radio-group {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  margin: 15px 0;
}
ic-omission-administrative-files-panel-inspection .panel .panel-info > mat-card .opacity {
  opacity: 0.5;
}
ic-omission-administrative-files-panel-inspection .panel .panel-info > mat-card .conclusion-text {
  font-weight: bold;
  padding-left: 1em;
}
ic-omission-administrative-files-panel-inspection .panel .panel-info > mat-card .radio-button {
  margin: 5px;
}
ic-omission-administrative-files-panel-inspection .panel .panel-info > div {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
ic-omission-administrative-files-panel-inspection .dynamic-component-header-text {
  margin-left: 0px;
}
ic-omission-administrative-files-panel-inspection .ic-panel-input {
  margin-top: 0.5em;
}
ic-omission-administrative-files-panel-inspection .favorable {
  color: #0a8407;
  font-weight: bolder;
}
ic-omission-administrative-files-panel-inspection .unfavorable {
  color: #e80202;
  font-weight: bolder;
}
ic-omission-administrative-files-panel-inspection .favorable-ob {
  color: #e58824;
  font-weight: bolder;
}
ic-omission-administrative-files-panel-inspection ic-dialog-pdf {
  display: flex;
  flex-flow: column;
  height: 100%;
}
ic-omission-administrative-files-panel-inspection .row-one-element {
  width: 75%;
}

.omission-panel .omission-panel-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  padding: 0 12px 0 4px;
}
.omission-panel .omission-panel-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
.omission-panel .omission-panel-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
.omission-panel .omission-panel-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
.omission-panel .omission-panel-toolbar .panel-spacer {
  flex: 1 1 auto;
}
.omission-panel .omission-panel-toolbar .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
.omission-panel .omission-panel-toolbar .panel-buttons {
  width: max-content;
  min-width: max-content;
}
.omission-panel .omission-panel-info {
  padding-top: 1em;
  height: calc(100% - 56px);
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: #fff;
}
.omission-panel .omission-panel-info > mat-card .observations-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5em;
}
.omission-panel .omission-panel-info .slidetoggles-row {
  flex: 1;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.omission-panel .omission-panel-info > div {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.omission-panel .omission-panel-info .panel-buttons {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
}
.omission-panel .omission-panel-documentation {
  margin-left: 15px;
}
.omission-panel .omission-panel-documentation-title {
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 15px;
}
.omission-panel .dynamic-component-header-text {
  margin-left: 0px;
}
.omission-panel .ic-panel-input {
  margin-top: 0.5em;
}
.omission-panel .ic-slide-toggle {
  margin-bottom: 25px;
}
.omission-panel .panel-alert-empty-documents {
  color: #977070;
  padding-left: 10px;
}
.omission-panel .panel-alert-empty-div {
  background: rgba(255, 0, 0, 0.1607843137);
  height: 40px;
  margin: auto;
  display: flex;
  align-items: center;
  border: 1px solid rgba(255, 0, 0, 0.2);
  border-radius: 7px;
}

ic-omission-request-aft-tree-panel .panel {
  height: 100%;
  width: 100%;
}
ic-omission-request-aft-tree-panel .panel .ic-selectable-table mat-header-row {
  justify-content: space-between;
}
ic-omission-request-aft-tree-panel .panel .ic-selectable-table mat-header-row.ic-selectable-row {
  height: 40px !important;
  top: 66px !important;
}
ic-omission-request-aft-tree-panel .panel .ic-selectable-table mat-header-row.ic-selectable-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-omission-request-aft-tree-panel .panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0 0 1.2em;
}
ic-omission-request-aft-tree-panel .panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type label {
  height: 24px;
}
ic-omission-request-aft-tree-panel .panel .ic-selectable-table mat-header-row .mat-mdc-header-cell {
  min-width: min-content;
}
ic-omission-request-aft-tree-panel .panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(2) {
  padding: 0 0.5em 0 2em;
}
ic-omission-request-aft-tree-panel .panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-omission-request-aft-tree-panel .panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:last-of-type {
  min-width: min-content;
}
ic-omission-request-aft-tree-panel .panel .ic-selectable-table mat-row {
  justify-content: space-between;
}
ic-omission-request-aft-tree-panel .panel .ic-selectable-table mat-row .mat-mdc-cell:first-of-type {
  padding: 0 0.4em;
  min-width: min-content;
}
ic-omission-request-aft-tree-panel .panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(2) {
  min-width: min-content;
}
ic-omission-request-aft-tree-panel .panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-omission-request-aft-tree-panel .panel .ic-selectable-table mat-row .mat-mdc-cell:last-of-type {
  min-width: min-content;
}
ic-omission-request-aft-tree-panel .panel .ic-selectable-table mat-row .mdc-data-table__cell {
  margin-top: 0 !important;
}
ic-omission-request-aft-tree-panel .panel .ic-selectable-table mat-row .mdc-data-table__cell > span {
  line-height: 16px;
  padding: 5px 0 5px 0;
}
ic-omission-request-aft-tree-panel .panel ic-detail-table .mat-mdc-row .mdc-data-table__cell {
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
  min-width: 103px;
}
ic-omission-request-aft-tree-panel .panel ic-detail-table .mat-header-row .mdc-data-table__header-cell,
ic-omission-request-aft-tree-panel .panel ic-detail-table .mat-mdc-header-row .mdc-data-table__header-cell {
  font-size: 12px;
  font-weight: 600;
  min-width: 103px;
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
}
ic-omission-request-aft-tree-panel .panel .panel-toolbar {
  position: sticky;
  top: 0;
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  max-height: 56px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px 0 4px;
  z-index: 999;
}
ic-omission-request-aft-tree-panel .panel .panel-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-omission-request-aft-tree-panel .panel .panel-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-omission-request-aft-tree-panel .panel .panel-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-omission-request-aft-tree-panel .panel .panel-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-omission-request-aft-tree-panel .panel .panel-toolbar .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-omission-request-aft-tree-panel .panel .panel-info {
  padding-top: 1em;
  height: calc(100% - 56px);
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: #fff;
}
ic-omission-request-aft-tree-panel .panel .panel-info .file-type {
  font-weight: bolder;
  font-size: 14px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
ic-omission-request-aft-tree-panel .panel .panel-info > mat-card {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2);
}
ic-omission-request-aft-tree-panel .panel .panel-info > mat-card > mat-card-content .mat-form-field-infix,
ic-omission-request-aft-tree-panel .panel .panel-info > mat-card > mat-card-content .mat-mdc-form-field-infix {
  width: 500px;
}
ic-omission-request-aft-tree-panel .panel .panel-info > mat-card > mat-card-content .signs-buttons {
  display: flex;
}
ic-omission-request-aft-tree-panel .panel .panel-info > mat-card .observations-table {
  margin-top: 15px;
}
ic-omission-request-aft-tree-panel .panel .panel-info > mat-card .observations-selected-table {
  margin-top: 30px;
}
ic-omission-request-aft-tree-panel .panel .panel-info > mat-card .observations-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5em;
}
ic-omission-request-aft-tree-panel .panel .panel-info > div {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.dynamic-component-header-text {
  margin-left: 0px;
}

.ic-panel-input {
  margin-top: 0.5em;
}

.favorable {
  color: #0a8407;
  font-weight: bolder;
}

.unfavorable {
  color: #e80202;
  font-weight: bolder;
}

.favorable-ob {
  color: #e58824;
  font-weight: bolder;
}

ic-dialog-pdf {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.row-one-element {
  width: 75%;
}

ic-omission-administrative-files-panel-correction .panel .panel-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  padding: 0 12px 0 4px;
  white-space: normal;
}
ic-omission-administrative-files-panel-correction .panel .panel-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-omission-administrative-files-panel-correction .panel .panel-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-omission-administrative-files-panel-correction .panel .panel-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-omission-administrative-files-panel-correction .panel .panel-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-omission-administrative-files-panel-correction .panel .panel-toolbar .panel-buttons {
  width: max-content;
  min-width: max-content;
}
ic-omission-administrative-files-panel-correction .panel .panel-info {
  min-height: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: #fff;
}
ic-omission-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .text-correction {
  color: #666;
  background: #ededed;
  border: 1px solid #ededed;
  border-radius: 12px;
  padding: 10px;
}
ic-omission-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-header {
  display: flex;
  flex-flow: column;
  margin-bottom: 15px;
}
ic-omission-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-header-senders {
  display: flex;
  flex-flow: row;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}
ic-omission-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-solicitant {
  flex-basis: 45%;
  background: #ededed;
  padding: 10px;
  border: 1px solid #ededed;
  border-radius: 16px;
  display: flex;
  margin-bottom: 15px;
}
@media (max-width: 900px) {
  ic-omission-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-solicitant {
    flex-basis: 100%;
  }
}
ic-omission-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-solicitant .first-line,
ic-omission-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-solicitant .second-line,
ic-omission-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-solicitant .third-line,
ic-omission-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-solicitant .fourth-line {
  margin-bottom: 5px;
}
ic-omission-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-solicitant-icon {
  margin-right: 10px;
}
ic-omission-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-solicitant-icon .material-icons {
  font-size: 55px;
  color: black;
}
ic-omission-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-gestora {
  flex-basis: 45%;
  background: #ededed;
  padding: 10px;
  border: 1px solid #ededed;
  border-radius: 16px;
  margin-bottom: 15px;
  display: flex;
}
@media (max-width: 900px) {
  ic-omission-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-gestora {
    flex-basis: 100%;
  }
}
ic-omission-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-gestora .first-line,
ic-omission-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-gestora .second-line,
ic-omission-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-gestora .third-line,
ic-omission-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-gestora .fourth-line {
  margin-bottom: 5px;
}
ic-omission-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-gestora-icon {
  margin-right: 10px;
}
ic-omission-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-gestora-icon .material-icons {
  font-size: 55px;
  color: black;
}
ic-omission-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-gestora .fourth-line > .right-text {
  float: right;
}
ic-omission-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-gestora .fourth-line > .left-text {
  display: inline-block;
}
ic-omission-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-title {
  background: #f1dada;
  padding: 10px;
  border: 1px solid #f1dada;
  border-radius: 12px;
  text-align: center;
  font-weight: bold;
}
ic-omission-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .correction-content-subject {
  background: #ededed;
  padding: 10px;
  border: 1px solid #ededed;
  border-radius: 12px;
  margin-top: 15px;
}
ic-omission-administrative-files-panel-correction .panel .panel-info > mat-card > mat-card-content .textarea-container-form-textarea {
  height: 250px;
}
ic-omission-administrative-files-panel-correction .panel .panel-info .panel-buttons {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
}
ic-omission-administrative-files-panel-correction .panel .mat-error-email {
  font-size: 12px;
  margin-bottom: 10px;
}
ic-omission-administrative-files-panel-correction .panel mat-chip-grid .mat-chip,
ic-omission-administrative-files-panel-correction .panel mat-chip-grid .mat-mdc-chip {
  width: auto;
  background-color: #eee;
  margin-bottom: 15px;
  display: flex;
  height: auto;
  align-self: center;
}
ic-omission-administrative-files-panel-correction .panel mat-chip-grid .mat-chip .mdc-evolution-chip__text-label,
ic-omission-administrative-files-panel-correction .panel mat-chip-grid .mat-mdc-chip .mdc-evolution-chip__text-label {
  width: auto;
  align-items: center;
  display: flex;
  height: auto;
}
ic-omission-administrative-files-panel-correction .panel mat-chip-grid .mat-chip .mdc-evolution-chip__text-label > .icon-default,
ic-omission-administrative-files-panel-correction .panel mat-chip-grid .mat-mdc-chip .mdc-evolution-chip__text-label > .icon-default {
  margin-right: 5px;
}
ic-omission-administrative-files-panel-correction .panel mat-chip-grid .mat-chip .mdc-evolution-chip__text-label > .text,
ic-omission-administrative-files-panel-correction .panel mat-chip-grid .mat-mdc-chip .mdc-evolution-chip__text-label > .text {
  display: flex;
  flex-flow: column;
  padding: 0.25em 0;
}
ic-omission-administrative-files-panel-correction .panel mat-chip-grid .mat-chip .mdc-evolution-chip__text-label > .text > .email,
ic-omission-administrative-files-panel-correction .panel mat-chip-grid .mat-mdc-chip .mdc-evolution-chip__text-label > .text > .email {
  font-size: 14px;
  font-weight: bold;
}
ic-omission-administrative-files-panel-correction .panel mat-chip-grid .mat-chip .mdc-evolution-chip__text-label > .text > .name,
ic-omission-administrative-files-panel-correction .panel mat-chip-grid .mat-mdc-chip .mdc-evolution-chip__text-label > .text > .name {
  font-size: 11px;
  font-weight: bold;
}
ic-omission-administrative-files-panel-correction .panel mat-chip-grid .mat-chip-list-wrapper,
ic-omission-administrative-files-panel-correction .panel mat-chip-grid .mdc-evolution-chip-set__chips {
  align-items: flex-start;
  flex-direction: row;
  flex-flow: row;
  flex-wrap: wrap;
}
ic-omission-administrative-files-panel-correction .panel mat-chip-grid .mat-form-field,
ic-omission-administrative-files-panel-correction .panel mat-chip-grid .mat-mdc-form-field {
  margin-left: 1em;
  width: 235px;
}
ic-omission-administrative-files-panel-correction .panel mat-chip-grid .mat-chip-input,
ic-omission-administrative-files-panel-correction .panel mat-chip-grid .mat-mdc-chip-input {
  margin-left: 6px;
  width: 220px;
  font-size: 12px;
}
ic-omission-administrative-files-panel-correction .panel .panel-alert-empty-contacts {
  color: #977070;
  padding-left: 10px;
}
ic-omission-administrative-files-panel-correction .panel .panel-alert-empty-div {
  background: rgba(255, 0, 0, 0.1607843137);
  height: 40px;
  margin: auto;
  display: flex;
  margin-top: 20px;
  align-items: center;
  border: 1px solid rgba(255, 0, 0, 0.2);
  border-radius: 7px;
}

mat-spinner {
  margin: 20px auto;
}

.omission-af-correction-response {
  display: flex;
  flex-flow: column;
}
.omission-af-correction-response .button-back {
  margin-top: 10px;
  margin-left: 10px;
  height: min-content;
  width: -moz-fit-content;
  width: fit-content;
}
.omission-af-correction-response .response-info {
  margin: auto;
  max-width: 1000px;
  width: 100%;
  margin-bottom: 50px;
}
.omission-af-correction-response .edit-button {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 15px;
  flex-basis: 25%;
}
.omission-af-correction-response mat-card {
  margin-bottom: 20px;
  max-width: 1000px;
  margin: auto;
  margin-top: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2);
}
.omission-af-correction-response mat-card mat-card-title {
  margin: 0 0 20px -16px;
}
.omission-af-correction-response mat-card mat-card-header {
  justify-content: space-between;
}
.omission-af-correction-response mat-card mat-card-header-text {
  flex-basis: 80%;
  align-self: center;
}
.omission-af-correction-response mat-card mat-card-subtitle {
  margin-left: -16px;
  display: flex;
  align-items: center;
}
.omission-af-correction-response mat-card mat-card-subtitle > span {
  cursor: pointer;
}
.omission-af-correction-response mat-card .documents-table-title-text {
  font-size: 20px;
  font-weight: 500;
}
.omission-af-correction-response mat-card .documents-table-title {
  margin-bottom: 10px;
}
.omission-af-correction-response mat-card .ic-searchbox {
  box-shadow: none;
}
.omission-af-correction-response mat-card mat-list-item {
  height: auto;
}
.omission-af-correction-response mat-card > mat-card-content {
  font-size: 16px;
}
.omission-af-correction-response mat-card > mat-card-content .ic-slide-toggle {
  margin-bottom: 25px;
}
.omission-af-correction-response mat-card > mat-card-content .two-element-row-one-element {
  flex-basis: 100%;
}

.panel-alert-empty-documents {
  color: #977070;
  padding-left: 10px;
}

.panel-alert-empty-div {
  background: rgba(255, 0, 0, 0.1607843137);
  height: 40px;
  margin: auto;
  display: flex;
  align-items: center;
  border: 1px solid rgba(255, 0, 0, 0.2);
  border-radius: 7px;
}

.fa-layers {
  width: 100%;
}

.reports {
  display: flex;
  flex-flow: column;
}
.reports .button-back {
  margin-top: 10px;
  margin-left: 10px;
  height: min-content;
  width: -moz-fit-content;
  width: fit-content;
}
.reports .reports-content {
  margin-left: 20px;
  width: 50%;
  margin-bottom: 50px;
}
.reports .ic-datepicker {
  margin-bottom: 0;
}
.reports .mat-mdc-form-field-type-mat-date-range-input .mat-mdc-form-field-infix {
  width: 280px;
}
.reports .mat-mdc-form-field-type-mat-date-range-input .mat-mdc-form-field-infix label.mdc-floating-label {
  font-size: 14px;
  letter-spacing: none;
}
.reports mat-card {
  margin-bottom: 20px;
  margin: auto;
  margin-top: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2);
}
.reports mat-card mat-card-title {
  margin: 0 0 20px 0;
}
.reports mat-card mat-card-header {
  justify-content: space-between;
}
.reports mat-card mat-card-header-text {
  flex-basis: 80%;
  align-self: center;
}
.reports mat-card mat-card-subtitle {
  margin-left: 0;
  display: flex;
  align-items: center;
}
.reports mat-card mat-card-subtitle > span {
  cursor: pointer;
}
.reports .filter-panel-row {
  display: flex;
  flex-flow: row;
  margin: 9px;
  align-items: center;
}
.reports .filter-panel-row-first-column {
  flex-basis: 50%;
  padding: 5px;
  margin-right: 5px;
}
.reports .filter-panel-row-second-column {
  flex-basis: 50%;
  padding: 5px;
  margin-left: 5px;
}
.reports .button-enable {
  color: #4050b6;
}
.reports .blue-text {
  color: #4050b6;
  font-size: 17px;
}
.reports .downloads-files {
  display: flex;
}

.filter-panel {
  display: flex;
  flex-flow: column;
}

.row-one-element {
  width: 50%;
}

.filter-panel-row {
  display: flex;
  flex-flow: row;
  margin: 9px;
  align-items: center;
}
.filter-panel-row-first-column {
  flex-basis: 50%;
  padding: 5px;
  margin-right: 5px;
}
.filter-panel-row-second-column {
  flex-basis: 50%;
  padding: 5px;
  margin-left: 5px;
}

.multiple-selection {
  overflow: auto;
}

.buttons {
  flex: 1 0 6%;
  margin: 0 6px;
  flex-wrap: nowrap;
  display: flex;
  min-width: 155px;
}

.button-search {
  min-width: 40px;
  margin-right: 6px;
  text-align: center;
}

.button-disable {
  color: #999;
}

.button-create-new {
  flex-grow: 1;
  text-align: end;
}

.button-enable {
  color: #4050b6;
}

mat-spinner {
  margin: 20px auto;
}

.detail,
.omission-detail,
.signatures,
.ppc-request-detail,
.ppc-administrative-file-detail,
.ppc-signatures,
.ppc-report-detail {
  display: flex;
  flex-flow: column;
}
.detail .mat-icon.mat-icon-inline,
.omission-detail .mat-icon.mat-icon-inline,
.signatures .mat-icon.mat-icon-inline,
.ppc-request-detail .mat-icon.mat-icon-inline,
.ppc-administrative-file-detail .mat-icon.mat-icon-inline,
.ppc-signatures .mat-icon.mat-icon-inline,
.ppc-report-detail .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
.detail .mat-stroked-button,
.detail .mat-mdc-outlined-button,
.omission-detail .mat-stroked-button,
.omission-detail .mat-mdc-outlined-button,
.signatures .mat-stroked-button,
.signatures .mat-mdc-outlined-button,
.ppc-request-detail .mat-stroked-button,
.ppc-request-detail .mat-mdc-outlined-button,
.ppc-administrative-file-detail .mat-stroked-button,
.ppc-administrative-file-detail .mat-mdc-outlined-button,
.ppc-signatures .mat-stroked-button,
.ppc-signatures .mat-mdc-outlined-button,
.ppc-report-detail .mat-stroked-button,
.ppc-report-detail .mat-mdc-outlined-button {
  background: transparent;
  font-weight: bold;
}
.detail .mat-stroked-button .file-type,
.detail .mat-mdc-outlined-button .file-type,
.omission-detail .mat-stroked-button .file-type,
.omission-detail .mat-mdc-outlined-button .file-type,
.signatures .mat-stroked-button .file-type,
.signatures .mat-mdc-outlined-button .file-type,
.ppc-request-detail .mat-stroked-button .file-type,
.ppc-request-detail .mat-mdc-outlined-button .file-type,
.ppc-administrative-file-detail .mat-stroked-button .file-type,
.ppc-administrative-file-detail .mat-mdc-outlined-button .file-type,
.ppc-signatures .mat-stroked-button .file-type,
.ppc-signatures .mat-mdc-outlined-button .file-type,
.ppc-report-detail .mat-stroked-button .file-type,
.ppc-report-detail .mat-mdc-outlined-button .file-type {
  line-height: 1;
}
.detail .button-back,
.omission-detail .button-back,
.signatures .button-back,
.ppc-request-detail .button-back,
.ppc-administrative-file-detail .button-back,
.ppc-signatures .button-back,
.ppc-report-detail .button-back {
  margin-top: 10px;
  margin-left: 10px;
  height: min-content;
  width: -moz-fit-content;
  width: fit-content;
}
.detail .subtitle,
.omission-detail .subtitle,
.signatures .subtitle,
.ppc-request-detail .subtitle,
.ppc-administrative-file-detail .subtitle,
.ppc-signatures .subtitle,
.ppc-report-detail .subtitle {
  color: rgba(0, 0, 0, 0.38);
  font-size: 18px;
}
.detail .detail-info,
.omission-detail .detail-info,
.signatures .detail-info,
.ppc-request-detail .detail-info,
.ppc-administrative-file-detail .detail-info,
.ppc-signatures .detail-info,
.ppc-report-detail .detail-info {
  margin: auto;
  max-width: 1000px;
  width: 100%;
  margin-bottom: 50px;
}
.detail .edit-button,
.omission-detail .edit-button,
.signatures .edit-button,
.ppc-request-detail .edit-button,
.ppc-administrative-file-detail .edit-button,
.ppc-signatures .edit-button,
.ppc-report-detail .edit-button {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 15px;
  flex-basis: 15%;
}
.detail .conclusion-card-button,
.omission-detail .conclusion-card-button,
.signatures .conclusion-card-button,
.ppc-request-detail .conclusion-card-button,
.ppc-administrative-file-detail .conclusion-card-button,
.ppc-signatures .conclusion-card-button,
.ppc-report-detail .conclusion-card-button {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 15px;
  flex-basis: 15%;
  align-items: center;
}
.detail .edit-button-bot,
.omission-detail .edit-button-bot,
.signatures .edit-button-bot,
.ppc-request-detail .edit-button-bot,
.ppc-administrative-file-detail .edit-button-bot,
.ppc-signatures .edit-button-bot,
.ppc-report-detail .edit-button-bot {
  margin-top: -10px;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 15px;
  flex-basis: 15%;
}
.detail .edit-button-top,
.omission-detail .edit-button-top,
.signatures .edit-button-top,
.ppc-request-detail .edit-button-top,
.ppc-administrative-file-detail .edit-button-top,
.ppc-signatures .edit-button-top,
.ppc-report-detail .edit-button-top {
  margin-top: -5px;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
  flex-basis: 15%;
}
.detail mat-card,
.omission-detail mat-card,
.signatures mat-card,
.ppc-request-detail mat-card,
.ppc-administrative-file-detail mat-card,
.ppc-signatures mat-card,
.ppc-report-detail mat-card {
  margin-bottom: 20px;
  max-width: 1000px;
  margin: auto;
  margin-top: 20px !important;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2) !important;
}
.detail mat-card mat-card-title,
.omission-detail mat-card mat-card-title,
.signatures mat-card mat-card-title,
.ppc-request-detail mat-card mat-card-title,
.ppc-administrative-file-detail mat-card mat-card-title,
.ppc-signatures mat-card mat-card-title,
.ppc-report-detail mat-card mat-card-title {
  margin: 0 0 20px 0;
}
.detail mat-card mat-card-header,
.omission-detail mat-card mat-card-header,
.signatures mat-card mat-card-header,
.ppc-request-detail mat-card mat-card-header,
.ppc-administrative-file-detail mat-card mat-card-header,
.ppc-signatures mat-card mat-card-header,
.ppc-report-detail mat-card mat-card-header {
  justify-content: space-between;
}
.detail mat-card mat-card-header-text,
.omission-detail mat-card mat-card-header-text,
.signatures mat-card mat-card-header-text,
.ppc-request-detail mat-card mat-card-header-text,
.ppc-administrative-file-detail mat-card mat-card-header-text,
.ppc-signatures mat-card mat-card-header-text,
.ppc-report-detail mat-card mat-card-header-text {
  flex-basis: 80%;
  align-self: center;
}
.detail mat-card mat-card-subtitle,
.omission-detail mat-card mat-card-subtitle,
.signatures mat-card mat-card-subtitle,
.ppc-request-detail mat-card mat-card-subtitle,
.ppc-administrative-file-detail mat-card mat-card-subtitle,
.ppc-signatures mat-card mat-card-subtitle,
.ppc-report-detail mat-card mat-card-subtitle {
  display: flex;
  align-items: center;
}
.detail mat-card mat-card-subtitle > span,
.omission-detail mat-card mat-card-subtitle > span,
.signatures mat-card mat-card-subtitle > span,
.ppc-request-detail mat-card mat-card-subtitle > span,
.ppc-administrative-file-detail mat-card mat-card-subtitle > span,
.ppc-signatures mat-card mat-card-subtitle > span,
.ppc-report-detail mat-card mat-card-subtitle > span {
  cursor: pointer;
}
.detail mat-card .documents-table-title-text,
.omission-detail mat-card .documents-table-title-text,
.signatures mat-card .documents-table-title-text,
.ppc-request-detail mat-card .documents-table-title-text,
.ppc-administrative-file-detail mat-card .documents-table-title-text,
.ppc-signatures mat-card .documents-table-title-text,
.ppc-report-detail mat-card .documents-table-title-text {
  font-size: 20px;
  font-weight: 500;
}
.detail mat-card .documents-table-title,
.omission-detail mat-card .documents-table-title,
.signatures mat-card .documents-table-title,
.ppc-request-detail mat-card .documents-table-title,
.ppc-administrative-file-detail mat-card .documents-table-title,
.ppc-signatures mat-card .documents-table-title,
.ppc-report-detail mat-card .documents-table-title {
  margin-bottom: 10px;
}
.detail mat-card .new-comments-title-text,
.omission-detail mat-card .new-comments-title-text,
.signatures mat-card .new-comments-title-text,
.ppc-request-detail mat-card .new-comments-title-text,
.ppc-administrative-file-detail mat-card .new-comments-title-text,
.ppc-signatures mat-card .new-comments-title-text,
.ppc-report-detail mat-card .new-comments-title-text {
  font-size: 20px;
  font-weight: 500;
}
.detail mat-card .new-comments-title,
.omission-detail mat-card .new-comments-title,
.signatures mat-card .new-comments-title,
.ppc-request-detail mat-card .new-comments-title,
.ppc-administrative-file-detail mat-card .new-comments-title,
.ppc-signatures mat-card .new-comments-title,
.ppc-report-detail mat-card .new-comments-title {
  margin-bottom: 10px;
}
.detail mat-card .deleted-comments-title-text,
.omission-detail mat-card .deleted-comments-title-text,
.signatures mat-card .deleted-comments-title-text,
.ppc-request-detail mat-card .deleted-comments-title-text,
.ppc-administrative-file-detail mat-card .deleted-comments-title-text,
.ppc-signatures mat-card .deleted-comments-title-text,
.ppc-report-detail mat-card .deleted-comments-title-text {
  font-size: 20px;
  font-weight: 500;
}
.detail mat-card .deleted-comments-title,
.omission-detail mat-card .deleted-comments-title,
.signatures mat-card .deleted-comments-title,
.ppc-request-detail mat-card .deleted-comments-title,
.ppc-administrative-file-detail mat-card .deleted-comments-title,
.ppc-signatures mat-card .deleted-comments-title,
.ppc-report-detail mat-card .deleted-comments-title {
  margin-bottom: 10px;
}
.detail mat-card .modified-comments-title-text,
.omission-detail mat-card .modified-comments-title-text,
.signatures mat-card .modified-comments-title-text,
.ppc-request-detail mat-card .modified-comments-title-text,
.ppc-administrative-file-detail mat-card .modified-comments-title-text,
.ppc-signatures mat-card .modified-comments-title-text,
.ppc-report-detail mat-card .modified-comments-title-text {
  font-size: 20px;
  font-weight: 500;
}
.detail mat-card .modified-comments-title,
.omission-detail mat-card .modified-comments-title,
.signatures mat-card .modified-comments-title,
.ppc-request-detail mat-card .modified-comments-title,
.ppc-administrative-file-detail mat-card .modified-comments-title,
.ppc-signatures mat-card .modified-comments-title,
.ppc-report-detail mat-card .modified-comments-title {
  margin-bottom: 10px;
}
.detail mat-card .comment-header,
.omission-detail mat-card .comment-header,
.signatures mat-card .comment-header,
.ppc-request-detail mat-card .comment-header,
.ppc-administrative-file-detail mat-card .comment-header,
.ppc-signatures mat-card .comment-header,
.ppc-report-detail mat-card .comment-header {
  display: flex;
  flex-flow: row;
  align-items: baseline;
  margin-bottom: 5px;
}
.detail mat-card .comment,
.omission-detail mat-card .comment,
.signatures mat-card .comment,
.ppc-request-detail mat-card .comment,
.ppc-administrative-file-detail mat-card .comment,
.ppc-signatures mat-card .comment,
.ppc-report-detail mat-card .comment {
  display: flex;
  flex-flow: row;
  margin-bottom: 15px;
  background-color: #eee;
  border-radius: 20px;
  padding: 1.2em 1em;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
}
.detail mat-card .mdc-list-item__content,
.omission-detail mat-card .mdc-list-item__content,
.signatures mat-card .mdc-list-item__content,
.ppc-request-detail mat-card .mdc-list-item__content,
.ppc-administrative-file-detail mat-card .mdc-list-item__content,
.ppc-signatures mat-card .mdc-list-item__content,
.ppc-report-detail mat-card .mdc-list-item__content {
  white-space: normal;
}
.detail mat-card .mdc-list-item__primary-text,
.omission-detail mat-card .mdc-list-item__primary-text,
.signatures mat-card .mdc-list-item__primary-text,
.ppc-request-detail mat-card .mdc-list-item__primary-text,
.ppc-administrative-file-detail mat-card .mdc-list-item__primary-text,
.ppc-signatures mat-card .mdc-list-item__primary-text,
.ppc-report-detail mat-card .mdc-list-item__primary-text {
  white-space: normal;
}
.detail mat-card .comment-blue,
.omission-detail mat-card .comment-blue,
.signatures mat-card .comment-blue,
.ppc-request-detail mat-card .comment-blue,
.ppc-administrative-file-detail mat-card .comment-blue,
.ppc-signatures mat-card .comment-blue,
.ppc-report-detail mat-card .comment-blue {
  background-color: #e5f0ff;
}
.detail mat-card .comment-green,
.omission-detail mat-card .comment-green,
.signatures mat-card .comment-green,
.ppc-request-detail mat-card .comment-green,
.ppc-administrative-file-detail mat-card .comment-green,
.ppc-signatures mat-card .comment-green,
.ppc-report-detail mat-card .comment-green {
  background-color: #deffda;
}
.detail mat-card .comment-red,
.omission-detail mat-card .comment-red,
.signatures mat-card .comment-red,
.ppc-request-detail mat-card .comment-red,
.ppc-administrative-file-detail mat-card .comment-red,
.ppc-signatures mat-card .comment-red,
.ppc-report-detail mat-card .comment-red {
  background-color: #ffcfcf;
}
.detail mat-card .comment-orange,
.omission-detail mat-card .comment-orange,
.signatures mat-card .comment-orange,
.ppc-request-detail mat-card .comment-orange,
.ppc-administrative-file-detail mat-card .comment-orange,
.ppc-signatures mat-card .comment-orange,
.ppc-report-detail mat-card .comment-orange {
  background-color: #ffe8ca;
}
.detail mat-card .comment-data,
.omission-detail mat-card .comment-data,
.signatures mat-card .comment-data,
.ppc-request-detail mat-card .comment-data,
.ppc-administrative-file-detail mat-card .comment-data,
.ppc-signatures mat-card .comment-data,
.ppc-report-detail mat-card .comment-data {
  display: flex;
  flex-flow: column;
}
.detail mat-card .comment-user,
.omission-detail mat-card .comment-user,
.signatures mat-card .comment-user,
.ppc-request-detail mat-card .comment-user,
.ppc-administrative-file-detail mat-card .comment-user,
.ppc-signatures mat-card .comment-user,
.ppc-report-detail mat-card .comment-user {
  font-size: 14px;
}
.detail mat-card .comment-button,
.omission-detail mat-card .comment-button,
.signatures mat-card .comment-button,
.ppc-request-detail mat-card .comment-button,
.ppc-administrative-file-detail mat-card .comment-button,
.ppc-signatures mat-card .comment-button,
.ppc-report-detail mat-card .comment-button {
  cursor: pointer;
}
.detail mat-card .comment-date,
.omission-detail mat-card .comment-date,
.signatures mat-card .comment-date,
.ppc-request-detail mat-card .comment-date,
.ppc-administrative-file-detail mat-card .comment-date,
.ppc-signatures mat-card .comment-date,
.ppc-report-detail mat-card .comment-date {
  font-size: 12px;
  color: #999;
  margin-left: 5px;
}
.detail mat-card .blue-text,
.omission-detail mat-card .blue-text,
.signatures mat-card .blue-text,
.ppc-request-detail mat-card .blue-text,
.ppc-administrative-file-detail mat-card .blue-text,
.ppc-signatures mat-card .blue-text,
.ppc-report-detail mat-card .blue-text {
  color: #4050b6;
}
.detail mat-card .comment-content,
.omission-detail mat-card .comment-content,
.signatures mat-card .comment-content,
.ppc-request-detail mat-card .comment-content,
.ppc-administrative-file-detail mat-card .comment-content,
.ppc-signatures mat-card .comment-content,
.ppc-report-detail mat-card .comment-content {
  font-weight: 400;
  font-size: 13px;
}
.detail mat-card .div-comment,
.omission-detail mat-card .div-comment,
.signatures mat-card .div-comment,
.ppc-request-detail mat-card .div-comment,
.ppc-administrative-file-detail mat-card .div-comment,
.ppc-signatures mat-card .div-comment,
.ppc-report-detail mat-card .div-comment {
  position: absolute;
  right: 15px;
}
.detail mat-card .ic-searchbox,
.omission-detail mat-card .ic-searchbox,
.signatures mat-card .ic-searchbox,
.ppc-request-detail mat-card .ic-searchbox,
.ppc-administrative-file-detail mat-card .ic-searchbox,
.ppc-signatures mat-card .ic-searchbox,
.ppc-report-detail mat-card .ic-searchbox {
  box-shadow: none;
}
.detail mat-card mat-list-item,
.omission-detail mat-card mat-list-item,
.signatures mat-card mat-list-item,
.ppc-request-detail mat-card mat-list-item,
.ppc-administrative-file-detail mat-card mat-list-item,
.ppc-signatures mat-card mat-list-item,
.ppc-report-detail mat-card mat-list-item {
  height: auto !important;
}
.detail mat-card > mat-card-content,
.omission-detail mat-card > mat-card-content,
.signatures mat-card > mat-card-content,
.ppc-request-detail mat-card > mat-card-content,
.ppc-administrative-file-detail mat-card > mat-card-content,
.ppc-signatures mat-card > mat-card-content,
.ppc-report-detail mat-card > mat-card-content {
  font-size: 16px;
}
.detail mat-card > mat-card-content .ic-slide-toggle,
.omission-detail mat-card > mat-card-content .ic-slide-toggle,
.signatures mat-card > mat-card-content .ic-slide-toggle,
.ppc-request-detail mat-card > mat-card-content .ic-slide-toggle,
.ppc-administrative-file-detail mat-card > mat-card-content .ic-slide-toggle,
.ppc-signatures mat-card > mat-card-content .ic-slide-toggle,
.ppc-report-detail mat-card > mat-card-content .ic-slide-toggle {
  margin-bottom: 25px;
}
.detail mat-card > mat-card-content .two-element-row-one-element,
.omission-detail mat-card > mat-card-content .two-element-row-one-element,
.signatures mat-card > mat-card-content .two-element-row-one-element,
.ppc-request-detail mat-card > mat-card-content .two-element-row-one-element,
.ppc-administrative-file-detail mat-card > mat-card-content .two-element-row-one-element,
.ppc-signatures mat-card > mat-card-content .two-element-row-one-element,
.ppc-report-detail mat-card > mat-card-content .two-element-row-one-element {
  flex-basis: 100%;
}
.detail mat-card .observations-title,
.omission-detail mat-card .observations-title,
.signatures mat-card .observations-title,
.ppc-request-detail mat-card .observations-title,
.ppc-administrative-file-detail mat-card .observations-title,
.ppc-signatures mat-card .observations-title,
.ppc-report-detail mat-card .observations-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5em;
}
.detail mat-card .favorable-report,
.omission-detail mat-card .favorable-report,
.signatures mat-card .favorable-report,
.ppc-request-detail mat-card .favorable-report,
.ppc-administrative-file-detail mat-card .favorable-report,
.ppc-signatures mat-card .favorable-report,
.ppc-report-detail mat-card .favorable-report {
  color: #0a8407;
}
.detail mat-card .unfavorable-report,
.omission-detail mat-card .unfavorable-report,
.signatures mat-card .unfavorable-report,
.ppc-request-detail mat-card .unfavorable-report,
.ppc-administrative-file-detail mat-card .unfavorable-report,
.ppc-signatures mat-card .unfavorable-report,
.ppc-report-detail mat-card .unfavorable-report {
  color: #e80202;
}
.detail mat-card .favorable-but-errors-report,
.omission-detail mat-card .favorable-but-errors-report,
.signatures mat-card .favorable-but-errors-report,
.ppc-request-detail mat-card .favorable-but-errors-report,
.ppc-administrative-file-detail mat-card .favorable-but-errors-report,
.ppc-signatures mat-card .favorable-but-errors-report,
.ppc-report-detail mat-card .favorable-but-errors-report {
  color: #e58824;
}

.panel-toolbar,
.sofrb-panel-toolbar,
.omission-panel-toolbar,
.returnpanel-toolbar,
.amendment-panel-toolbar,
.solppc-panel-toolbar {
  position: sticky;
  top: 0;
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  max-height: 56px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px 0 4px;
  z-index: 999;
}
.panel-toolbar .panel-close,
.panel-toolbar .returnpanel-close,
.sofrb-panel-toolbar .panel-close,
.sofrb-panel-toolbar .returnpanel-close,
.omission-panel-toolbar .panel-close,
.omission-panel-toolbar .returnpanel-close,
.returnpanel-toolbar .panel-close,
.returnpanel-toolbar .returnpanel-close,
.amendment-panel-toolbar .panel-close,
.amendment-panel-toolbar .returnpanel-close,
.solppc-panel-toolbar .panel-close,
.solppc-panel-toolbar .returnpanel-close {
  height: 48px;
  width: 48px;
  margin-right: 16px;
}

.panel .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}

.ic-mat-tab-nav-bar.mat-mdc-tab-header, .ic-mat-tab-nav-bar.mat-mdc-tab-nav-bar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

ic-settings-budget-apps-panel .settings-budget-apps-panel .ic-selectable-table mat-header-row {
  justify-content: space-between;
}
ic-settings-budget-apps-panel .settings-budget-apps-panel .ic-selectable-table mat-header-row.ic-selectable-row {
  height: 40px !important;
  top: 66px !important;
}
ic-settings-budget-apps-panel .settings-budget-apps-panel .ic-selectable-table mat-header-row.ic-selectable-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-settings-budget-apps-panel .settings-budget-apps-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0 0 1.2em;
}
ic-settings-budget-apps-panel .settings-budget-apps-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type label {
  height: 24px;
}
ic-settings-budget-apps-panel .settings-budget-apps-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell {
  min-width: min-content;
}
ic-settings-budget-apps-panel .settings-budget-apps-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(2) {
  padding: 0 0.5em 0 2em;
}
ic-settings-budget-apps-panel .settings-budget-apps-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-settings-budget-apps-panel .settings-budget-apps-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:last-of-type {
  min-width: min-content;
}
ic-settings-budget-apps-panel .settings-budget-apps-panel .ic-selectable-table mat-row {
  justify-content: space-between;
}
ic-settings-budget-apps-panel .settings-budget-apps-panel .ic-selectable-table mat-row .mat-mdc-cell:first-of-type {
  padding: 0 0.4em;
  min-width: min-content;
}
ic-settings-budget-apps-panel .settings-budget-apps-panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(2) {
  min-width: min-content;
}
ic-settings-budget-apps-panel .settings-budget-apps-panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-settings-budget-apps-panel .settings-budget-apps-panel .ic-selectable-table mat-row .mat-mdc-cell:last-of-type {
  min-width: min-content;
}
ic-settings-budget-apps-panel .settings-budget-apps-panel .ic-selectable-table mat-row .mdc-data-table__cell {
  margin-top: 0 !important;
}
ic-settings-budget-apps-panel .settings-budget-apps-panel .ic-selectable-table mat-row .mdc-data-table__cell > span {
  line-height: 16px;
  padding: 5px 0 5px 0;
}
ic-settings-budget-apps-panel .settings-budget-apps-panel ic-detail-table .mat-mdc-row .mdc-data-table__cell {
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
  min-width: 103px;
}
ic-settings-budget-apps-panel .settings-budget-apps-panel ic-detail-table .mat-header-row .mdc-data-table__header-cell,
ic-settings-budget-apps-panel .settings-budget-apps-panel ic-detail-table .mat-mdc-header-row .mdc-data-table__header-cell {
  font-size: 12px;
  font-weight: 600;
  min-width: 103px;
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
}
ic-settings-budget-apps-panel .settings-budget-apps-panel .settings-budget-apps-panel-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  max-height: 56px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px 0 4px;
  z-index: 999;
}
ic-settings-budget-apps-panel .settings-budget-apps-panel .settings-budget-apps-panel-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-settings-budget-apps-panel .settings-budget-apps-panel .settings-budget-apps-panel-toolbar .panel-title {
  flex: auto;
}
ic-settings-budget-apps-panel .settings-budget-apps-panel .settings-budget-apps-panel-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-settings-budget-apps-panel .settings-budget-apps-panel .settings-budget-apps-panel-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-settings-budget-apps-panel .settings-budget-apps-panel .settings-budget-apps-panel-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-settings-budget-apps-panel .settings-budget-apps-panel .settings-budget-apps-panel-toolbar .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-settings-budget-apps-panel .settings-budget-apps-panel .settings-budget-apps-panel-info {
  min-height: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: #fff;
}
ic-settings-budget-apps-panel .settings-budget-apps-panel .settings-budget-apps-panel-info .budget-app-title {
  font-size: 14px;
  font-weight: 600;
}
ic-settings-budget-apps-panel .settings-budget-apps-panel .settings-budget-apps-panel-info > mat-card {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2);
}
ic-settings-budget-apps-panel .settings-budget-apps-panel .settings-budget-apps-panel-info > mat-card > mat-card-content > .two-element-row {
  flex-direction: row;
  display: flex;
}
ic-settings-budget-apps-panel .settings-budget-apps-panel .settings-budget-apps-panel-info > div {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
ic-settings-budget-apps-panel .settings-budget-apps-panel .settings-budget-apps-panel-info > .settings-budget-apps-panel-title {
  padding-top: 25px;
}
ic-settings-budget-apps-panel .settings-budget-apps-panel .settings-budget-apps-panel-info .edit-button {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 30px;
  flex-basis: 25%;
}

.dynamic-component-header-text {
  margin-left: 0px;
}

.ic-panel-input {
  margin-top: 0.5em;
}

.ic-slide-toggle {
  margin-bottom: 25px;
}

.two-element-row-element {
  flex-basis: 50%;
}

.row-one-element {
  width: 75%;
}

.text-match {
  color: red;
}

.ic-settings-authorities .button-back {
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  height: min-content;
  width: fit-content;
}

ic-settings-authorities-panel .settings-authorities-panel .ic-selectable-table mat-header-row {
  justify-content: space-between;
}
ic-settings-authorities-panel .settings-authorities-panel .ic-selectable-table mat-header-row.ic-selectable-row {
  height: 40px !important;
  top: 66px !important;
}
ic-settings-authorities-panel .settings-authorities-panel .ic-selectable-table mat-header-row.ic-selectable-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-settings-authorities-panel .settings-authorities-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0 0 1.2em;
}
ic-settings-authorities-panel .settings-authorities-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type label {
  height: 24px;
}
ic-settings-authorities-panel .settings-authorities-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell {
  min-width: min-content;
}
ic-settings-authorities-panel .settings-authorities-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(2) {
  padding: 0 0 0 0.1em;
}
ic-settings-authorities-panel .settings-authorities-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-settings-authorities-panel .settings-authorities-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:last-of-type {
  min-width: min-content;
}
ic-settings-authorities-panel .settings-authorities-panel .ic-selectable-table mat-row {
  justify-content: space-between;
}
ic-settings-authorities-panel .settings-authorities-panel .ic-selectable-table mat-row .mat-mdc-cell:first-of-type {
  padding: 0 0.4em;
  min-width: min-content;
}
ic-settings-authorities-panel .settings-authorities-panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(2) {
  min-width: min-content;
}
ic-settings-authorities-panel .settings-authorities-panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-settings-authorities-panel .settings-authorities-panel .ic-selectable-table mat-row .mat-mdc-cell:last-of-type {
  min-width: min-content;
}
ic-settings-authorities-panel .settings-authorities-panel .ic-selectable-table mat-row .mdc-data-table__cell {
  margin-top: 0 !important;
}
ic-settings-authorities-panel .settings-authorities-panel .ic-selectable-table mat-row .mdc-data-table__cell > span {
  line-height: 16px;
  padding: 5px 0 5px 0;
}
ic-settings-authorities-panel .settings-authorities-panel ic-detail-table .mat-mdc-row .mdc-data-table__cell {
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
  min-width: 103px;
}
ic-settings-authorities-panel .settings-authorities-panel ic-detail-table .mat-header-row .mdc-data-table__header-cell,
ic-settings-authorities-panel .settings-authorities-panel ic-detail-table .mat-mdc-header-row .mdc-data-table__header-cell {
  font-size: 12px;
  font-weight: 600;
  min-width: 103px;
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
}
ic-settings-authorities-panel .settings-authorities-panel-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  max-height: 56px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px 0 4px;
  z-index: 999;
}
ic-settings-authorities-panel .settings-authorities-panel-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-settings-authorities-panel .settings-authorities-panel-toolbar .panel-title {
  flex: auto;
}
ic-settings-authorities-panel .settings-authorities-panel-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-settings-authorities-panel .settings-authorities-panel-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-settings-authorities-panel .settings-authorities-panel-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-settings-authorities-panel .settings-authorities-panel-toolbar .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-settings-authorities-panel .settings-authorities-panel-info {
  min-height: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
ic-settings-authorities-panel .settings-authorities-panel-info > mat-card {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2);
}
ic-settings-authorities-panel .settings-authorities-panel-info > mat-card > mat-card-content > .six-element-row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}
ic-settings-authorities-panel .settings-authorities-panel-info > mat-card > mat-card-content > .six-element-row > .six-element-row-big-element {
  flex-basis: 25%;
  min-width: 25%;
}
ic-settings-authorities-panel .settings-authorities-panel-info > mat-card > mat-card-content > .six-element-row > .six-element-row-big-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-authorities-panel .settings-authorities-panel-info > mat-card > mat-card-content > .six-element-row > .six-element-row-medium-element {
  flex-basis: 17%;
  min-width: 17%;
}
ic-settings-authorities-panel .settings-authorities-panel-info > mat-card > mat-card-content > .six-element-row > .six-element-row-medium-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-authorities-panel .settings-authorities-panel-info > mat-card > mat-card-content > .six-element-row > .six-element-row-small-element {
  flex-basis: 14%;
  min-width: 14%;
}
ic-settings-authorities-panel .settings-authorities-panel-info > mat-card > mat-card-content > .six-element-row > .six-element-row-small-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-authorities-panel .settings-authorities-panel-info > mat-card > mat-card-content > .two-element-row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
}
ic-settings-authorities-panel .settings-authorities-panel-info > mat-card > mat-card-content > .two-element-row > .all-row-element {
  flex-basis: 100%;
  min-width: 100%;
  align-self: baseline;
}
ic-settings-authorities-panel .settings-authorities-panel-info > mat-card > mat-card-content > .two-element-row > .all-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-authorities-panel .settings-authorities-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-element {
  flex-basis: 50%;
  min-width: 50%;
  align-self: baseline;
}
ic-settings-authorities-panel .settings-authorities-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-authorities-panel .settings-authorities-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element {
  flex-basis: 40%;
  min-width: 40%;
  align-self: baseline;
}
ic-settings-authorities-panel .settings-authorities-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-authorities-panel .settings-authorities-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-big-element {
  flex-basis: 70%;
  min-width: 70%;
  align-self: baseline;
}
ic-settings-authorities-panel .settings-authorities-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-big-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-authorities-panel .settings-authorities-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-small-element {
  flex-basis: 30%;
  min-width: 30%;
  align-self: baseline;
}
ic-settings-authorities-panel .settings-authorities-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-small-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-authorities-panel .settings-authorities-panel-info > mat-card > mat-card-content .slidetoggles-row {
  flex: 1;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}
ic-settings-authorities-panel > .settings-authorities-panel-title {
  padding-top: 25px;
}

.dynamic-component-header-text {
  margin-left: 0px;
}

.ic-panel-input {
  margin-top: 0.5em;
}

.ic-slide-toggle {
  margin-bottom: 25px;
}

.row-one-element {
  width: 75%;
}

.add-contact {
  cursor: pointer;
  vertical-align: middle;
}

.add-contact-disabled {
  opacity: 0.4;
  vertical-align: middle;
}
.add-contact-disabled:hover {
  background-color: transparent;
}

.delete-entity {
  cursor: pointer;
  margin: 5px;
  padding: 0.9em 0;
  border-top: 0.8437;
}

.text-match {
  color: red;
}

.empty-wordings {
  color: #f05060;
}

.clean-inputs-button {
  float: right;
}

.buttons-bot {
  margin-top: -5px;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 15px;
  flex-basis: 25%;
}

.delete-contact {
  cursor: pointer;
  margin: 5px;
  padding: 0.9em 0;
  border-top: 0.8437;
}

.delete-contact-div {
  display: flex;
}

ic-authorities-new-contact .settings-authorities-new-contact-panel .ic-selectable-table mat-header-row {
  justify-content: space-between;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel .ic-selectable-table mat-header-row.ic-selectable-row {
  height: 40px !important;
  top: 66px !important;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel .ic-selectable-table mat-header-row.ic-selectable-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type label {
  height: 24px;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell {
  min-width: 200px;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(2) {
  min-width: 250px;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(3) {
  min-width: 150px;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(4) {
  min-width: 250px;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(5) {
  min-width: 150px;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(6) {
  min-width: 100px;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(7) {
  min-width: 100px;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:last-of-type {
  min-width: min-content;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel .ic-selectable-table mat-row {
  justify-content: space-between;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel .ic-selectable-table mat-row .mat-mdc-cell:first-of-type {
  padding: 0 0.4em;
  min-width: 200px;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(2) {
  min-width: 250px;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(3) {
  min-width: 150px;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(4) {
  min-width: 250px;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(5) {
  min-width: 150px;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(6) {
  min-width: 100px;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(7) {
  min-width: 100px;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel .ic-selectable-table mat-row .mat-mdc-cell:last-of-type {
  min-width: min-content;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel .ic-selectable-table mat-row .mdc-data-table__cell {
  margin-top: 0 !important;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel .ic-selectable-table mat-row .mdc-data-table__cell > span {
  line-height: 16px;
  padding: 5px 0 5px 0;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel ic-detail-table .mat-mdc-row .mdc-data-table__cell {
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
  min-width: 103px;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel ic-detail-table .mat-header-row .mdc-data-table__header-cell,
ic-authorities-new-contact .settings-authorities-new-contact-panel ic-detail-table .mat-mdc-header-row .mdc-data-table__header-cell {
  font-size: 12px;
  font-weight: 600;
  min-width: 103px;
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  max-height: 56px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px 0 4px;
  z-index: 999;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel-toolbar .panel-title {
  flex: auto;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-authorities-new-contact .settings-authorities-new-contact-panel-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel-toolbar .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel-info {
  min-height: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel-info > mat-card {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2);
}
ic-authorities-new-contact .settings-authorities-new-contact-panel-info > mat-card > mat-card-content > .six-element-row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel-info > mat-card > mat-card-content > .six-element-row > .six-element-row-big-element {
  flex-basis: 25%;
  min-width: 25%;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel-info > mat-card > mat-card-content > .six-element-row > .six-element-row-big-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel-info > mat-card > mat-card-content > .six-element-row > .six-element-row-medium-element {
  flex-basis: 17%;
  min-width: 17%;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel-info > mat-card > mat-card-content > .six-element-row > .six-element-row-medium-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel-info > mat-card > mat-card-content > .six-element-row > .six-element-row-small-element {
  flex-basis: 14%;
  min-width: 14%;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel-info > mat-card > mat-card-content > .six-element-row > .six-element-row-small-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel-info > mat-card > mat-card-content > .two-element-row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel-info > mat-card > mat-card-content > .two-element-row > .all-row-element {
  flex-basis: 100%;
  min-width: 100%;
  align-self: baseline;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel-info > mat-card > mat-card-content > .two-element-row > .all-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-element {
  flex-basis: 50%;
  min-width: 50%;
  align-self: baseline;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element {
  flex-basis: 40%;
  min-width: 40%;
  align-self: baseline;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-big-element {
  flex-basis: 70%;
  min-width: 70%;
  align-self: baseline;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-big-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-small-element {
  flex-basis: 30%;
  min-width: 30%;
  align-self: baseline;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-small-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-authorities-new-contact .settings-authorities-new-contact-panel-info > mat-card > mat-card-content .slidetoggles-row {
  flex: 1;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}
ic-authorities-new-contact > .settings-authorities-new-contact-panel-title {
  padding-top: 25px;
}

.dynamic-component-header-text {
  margin-left: 0px;
}

.ic-panel-input {
  margin-top: 0.5em;
}

.ic-slide-toggle {
  margin-bottom: 25px;
}

.row-one-element {
  width: 75%;
}

.add-entity {
  cursor: pointer;
  vertical-align: middle;
}

.add-entity-disabled {
  opacity: 0.4;
  vertical-align: middle;
}
.add-entity-disabled:hover {
  background-color: transparent;
}

.delete-entity {
  cursor: pointer;
  margin: 5px;
  padding: 0.9em 0;
  border-top: 0.8437;
}

.text-match {
  color: red;
}

.empty-wordings {
  color: #f05060;
}

.clean-inputs-button {
  float: right;
}

.buttons-bot {
  margin-top: -5px;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 15px;
  flex-basis: 25%;
}

.delete-contact {
  cursor: pointer;
  margin: 5px;
  padding: 0.9em 0;
  border-top: 0.8437;
}

.delete-contact-div {
  display: flex;
}

ic-authorities-detail .settings-authorities-detail .button-back {
  margin-top: 10px;
  margin-left: 10px;
  height: min-content;
  width: -moz-fit-content;
  width: fit-content;
}
ic-authorities-detail .settings-authorities-detail .detail-info {
  margin: auto;
  max-width: 1000px;
  width: 100%;
  margin-bottom: 50px;
}
ic-authorities-detail .settings-authorities-detail .ic-selectable-table mat-header-row {
  justify-content: space-between;
}
ic-authorities-detail .settings-authorities-detail .ic-selectable-table mat-header-row.ic-selectable-row {
  height: 40px !important;
  top: 66px !important;
}
ic-authorities-detail .settings-authorities-detail .ic-selectable-table mat-header-row.ic-selectable-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-authorities-detail .settings-authorities-detail .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0 0 1.2em;
}
ic-authorities-detail .settings-authorities-detail .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type label {
  height: 24px;
}
ic-authorities-detail .settings-authorities-detail .ic-selectable-table mat-header-row .mat-mdc-header-cell {
  min-width: min-content;
}
ic-authorities-detail .settings-authorities-detail .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(2) {
  padding: 0 0 0 0.1em;
}
ic-authorities-detail .settings-authorities-detail .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-authorities-detail .settings-authorities-detail .ic-selectable-table mat-header-row .mat-mdc-header-cell:last-of-type {
  min-width: min-content;
}
ic-authorities-detail .settings-authorities-detail .ic-selectable-table mat-row {
  justify-content: space-between;
}
ic-authorities-detail .settings-authorities-detail .ic-selectable-table mat-row .mat-mdc-cell:first-of-type {
  padding: 0 0.4em;
  min-width: min-content;
}
ic-authorities-detail .settings-authorities-detail .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(2) {
  min-width: min-content;
}
ic-authorities-detail .settings-authorities-detail .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-authorities-detail .settings-authorities-detail .ic-selectable-table mat-row .mat-mdc-cell:last-of-type {
  min-width: min-content;
}
ic-authorities-detail .settings-authorities-detail .ic-selectable-table mat-row .mdc-data-table__cell {
  margin-top: 0 !important;
}
ic-authorities-detail .settings-authorities-detail .ic-selectable-table mat-row .mdc-data-table__cell > span {
  line-height: 16px;
  padding: 5px 0 5px 0;
}
ic-authorities-detail .settings-authorities-detail ic-detail-table .mat-mdc-row .mdc-data-table__cell {
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
  min-width: 103px;
}
ic-authorities-detail .settings-authorities-detail ic-detail-table .mat-header-row .mdc-data-table__header-cell,
ic-authorities-detail .settings-authorities-detail ic-detail-table .mat-mdc-header-row .mdc-data-table__header-cell {
  font-size: 12px;
  font-weight: 600;
  min-width: 103px;
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
}
ic-authorities-detail .settings-authorities-detail-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  max-height: 56px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px 0 4px;
  z-index: 999;
}
ic-authorities-detail .settings-authorities-detail-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-authorities-detail .settings-authorities-detail-toolbar .panel-title {
  flex: auto;
}
ic-authorities-detail .settings-authorities-detail-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-authorities-detail .settings-authorities-detail-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-authorities-detail .settings-authorities-detail-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-authorities-detail .settings-authorities-detail-toolbar .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-authorities-detail .settings-authorities-detail-info {
  margin: auto;
  max-width: 1000px;
  width: 100%;
  margin-bottom: 50px;
}
ic-authorities-detail .settings-authorities-detail-info > div {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
ic-authorities-detail .settings-authorities-detail-info .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-authorities-detail > .settings-authorities-detail-title {
  padding-top: 25px;
}
ic-authorities-detail mat-card {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2) !important;
}
ic-authorities-detail mat-card > mat-card-content > .five-element-row {
  flex-direction: row;
  display: flex;
}
ic-authorities-detail mat-card > mat-card-content > .five-element-row > .five-element-row-code {
  flex-basis: 17%;
  min-width: 17%;
}
ic-authorities-detail mat-card > mat-card-content > .five-element-row > .five-element-row-code .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-authorities-detail mat-card > mat-card-content > .five-element-row > .five-element-row-type {
  flex-basis: 17%;
  min-width: 17%;
}
ic-authorities-detail mat-card > mat-card-content > .five-element-row > .five-element-row-type .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-authorities-detail mat-card > mat-card-content > .five-element-row > .five-element-row-parent {
  flex-basis: 40%;
  min-width: 40%;
}
ic-authorities-detail mat-card > mat-card-content > .five-element-row > .five-element-row-parent .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-authorities-detail mat-card > mat-card-content > .five-element-row > .five-element-row-legal-base {
  flex-basis: 25%;
  min-width: 25%;
}
ic-authorities-detail mat-card > mat-card-content > .five-element-row > .five-element-row-legal-base .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-authorities-detail mat-card > mat-card-content > .two-element-row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
}
ic-authorities-detail mat-card > mat-card-content > .two-element-row > .all-row-element {
  flex-basis: 100%;
  min-width: 100%;
  align-self: baseline;
}
ic-authorities-detail mat-card > mat-card-content > .two-element-row > .all-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-authorities-detail mat-card > mat-card-content > .two-element-row > .two-element-row-element {
  flex-basis: 50%;
  min-width: 50%;
  align-self: baseline;
}
ic-authorities-detail mat-card > mat-card-content > .two-element-row > .two-element-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-authorities-detail mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element {
  flex-basis: 40%;
  min-width: 40%;
  align-self: baseline;
}
ic-authorities-detail mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-authorities-detail mat-card > mat-card-content > .two-element-row > .two-element-row-big-element {
  flex-basis: 70%;
  min-width: 70%;
  align-self: baseline;
}
ic-authorities-detail mat-card > mat-card-content > .two-element-row > .two-element-row-big-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-authorities-detail mat-card > mat-card-content > .two-element-row > .two-element-row-small-element {
  flex-basis: 30%;
  min-width: 30%;
  align-self: baseline;
}
ic-authorities-detail mat-card > mat-card-content > .two-element-row > .two-element-row-small-element .mat-mdc-input-element {
  letter-spacing: 0;
}

.dynamic-component-header-text {
  margin-left: 0px;
}

.ic-panel-input {
  margin-top: 0.5em;
}

.ic-slide-toggle {
  margin-bottom: 25px;
}

.row-one-element {
  width: 75%;
}

.add-entity {
  cursor: pointer;
  vertical-align: middle;
}

.add-entity-disabled {
  opacity: 0.4;
  vertical-align: middle;
}
.add-entity-disabled:hover {
  background-color: transparent;
}

.delete-entity {
  cursor: pointer;
  margin: 5px;
  padding: 0.9em 0;
  border-top: 0.8437;
}

.text-match {
  color: red;
}

.empty-wordings {
  color: #f05060;
}

.clean-inputs-button {
  float: right;
}

.buttons-bot {
  margin-top: -5px;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 15px;
  flex-basis: 25%;
}

.edit-button {
  margin-left: auto;
}

ic-settings-tenants-panel .settings-tenants-panel .ic-selectable-table mat-header-row {
  justify-content: space-between;
}
ic-settings-tenants-panel .settings-tenants-panel .ic-selectable-table mat-header-row.ic-selectable-row {
  height: 40px !important;
  top: 66px !important;
}
ic-settings-tenants-panel .settings-tenants-panel .ic-selectable-table mat-header-row.ic-selectable-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-settings-tenants-panel .settings-tenants-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-settings-tenants-panel .settings-tenants-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type label {
  height: 24px;
}
ic-settings-tenants-panel .settings-tenants-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell {
  min-width: min-content;
}
ic-settings-tenants-panel .settings-tenants-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(2) {
  padding: 0 0 0 0.5em;
}
ic-settings-tenants-panel .settings-tenants-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(3) {
  padding: 0 0 0 0.5em;
  min-width: 93px;
}
ic-settings-tenants-panel .settings-tenants-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:last-of-type {
  padding: 0 0 0 0.5em;
  min-width: min-content;
}
ic-settings-tenants-panel .settings-tenants-panel .ic-selectable-table mat-row {
  justify-content: space-between;
}
ic-settings-tenants-panel .settings-tenants-panel .ic-selectable-table mat-row .mat-mdc-cell:first-of-type {
  padding: 0 0.4em;
  min-width: min-content;
}
ic-settings-tenants-panel .settings-tenants-panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(2) {
  min-width: min-content;
}
ic-settings-tenants-panel .settings-tenants-panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-settings-tenants-panel .settings-tenants-panel .ic-selectable-table mat-row .mat-mdc-cell:last-of-type {
  min-width: min-content;
}
ic-settings-tenants-panel .settings-tenants-panel .ic-selectable-table mat-row .mdc-data-table__cell {
  margin-top: 0 !important;
}
ic-settings-tenants-panel .settings-tenants-panel .ic-selectable-table mat-row .mdc-data-table__cell > span {
  line-height: 16px;
  padding: 5px 0 5px 0;
}
ic-settings-tenants-panel .settings-tenants-panel ic-detail-table .mat-mdc-row .mdc-data-table__cell {
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
  min-width: 103px;
}
ic-settings-tenants-panel .settings-tenants-panel ic-detail-table .mat-header-row .mdc-data-table__header-cell,
ic-settings-tenants-panel .settings-tenants-panel ic-detail-table .mat-mdc-header-row .mdc-data-table__header-cell {
  font-size: 12px;
  font-weight: 600;
  min-width: 103px;
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
}
ic-settings-tenants-panel .settings-tenants-panel-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  max-height: 56px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px 0 4px;
  z-index: 999;
}
ic-settings-tenants-panel .settings-tenants-panel-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-settings-tenants-panel .settings-tenants-panel-toolbar .panel-title {
  flex: auto;
}
ic-settings-tenants-panel .settings-tenants-panel-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-settings-tenants-panel .settings-tenants-panel-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-settings-tenants-panel .settings-tenants-panel-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-settings-tenants-panel .settings-tenants-panel-toolbar .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-settings-tenants-panel .settings-tenants-panel-info {
  min-height: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
ic-settings-tenants-panel .settings-tenants-panel-info > mat-card {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2);
}
ic-settings-tenants-panel .settings-tenants-panel-info > mat-card > mat-card-content > .six-element-row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}
ic-settings-tenants-panel .settings-tenants-panel-info > mat-card > mat-card-content > .six-element-row > .six-element-row-big-element {
  flex-basis: 25%;
  min-width: 25%;
}
ic-settings-tenants-panel .settings-tenants-panel-info > mat-card > mat-card-content > .six-element-row > .six-element-row-big-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-tenants-panel .settings-tenants-panel-info > mat-card > mat-card-content > .six-element-row > .six-element-row-medium-element {
  flex-basis: 17%;
  min-width: 17%;
}
ic-settings-tenants-panel .settings-tenants-panel-info > mat-card > mat-card-content > .six-element-row > .six-element-row-medium-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-tenants-panel .settings-tenants-panel-info > mat-card > mat-card-content > .six-element-row > .six-element-row-small-element {
  flex-basis: 14%;
  min-width: 14%;
}
ic-settings-tenants-panel .settings-tenants-panel-info > mat-card > mat-card-content > .six-element-row > .six-element-row-small-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-tenants-panel .settings-tenants-panel-info > mat-card > mat-card-content > .two-element-row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
}
ic-settings-tenants-panel .settings-tenants-panel-info > mat-card > mat-card-content > .two-element-row > .all-row-element {
  flex-basis: 100%;
  min-width: 100%;
  align-self: baseline;
}
ic-settings-tenants-panel .settings-tenants-panel-info > mat-card > mat-card-content > .two-element-row > .all-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-tenants-panel .settings-tenants-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-element {
  flex-basis: 50%;
  min-width: 50%;
  align-self: baseline;
}
ic-settings-tenants-panel .settings-tenants-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-tenants-panel .settings-tenants-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element {
  flex-basis: 40%;
  min-width: 40%;
  align-self: baseline;
}
ic-settings-tenants-panel .settings-tenants-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-tenants-panel .settings-tenants-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-big-element {
  flex-basis: 70%;
  min-width: 70%;
  align-self: baseline;
}
ic-settings-tenants-panel .settings-tenants-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-big-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-tenants-panel .settings-tenants-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-small-element {
  flex-basis: 30%;
  min-width: 30%;
  align-self: baseline;
}
ic-settings-tenants-panel .settings-tenants-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-small-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-tenants-panel .settings-tenants-panel-info > mat-card > mat-card-content .slidetoggles-row {
  flex: 1;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}
ic-settings-tenants-panel > .settings-tenants-panel-title {
  padding-top: 25px;
}

.dynamic-component-header-text {
  margin-left: 0px;
}

.ic-panel-input {
  margin-top: 0.5em;
}

.ic-slide-toggle {
  margin-bottom: 25px;
}

.row-one-element {
  width: 75%;
}

.add-entity {
  cursor: pointer;
  vertical-align: middle;
}

.add-entity-disabled {
  opacity: 0.4;
  vertical-align: middle;
}
.add-entity-disabled:hover {
  background-color: transparent;
}

.delete-entity {
  cursor: pointer;
  margin: 5px;
  padding: 0.9em 0;
  border-top: 0.8437;
}

.text-match {
  color: red;
}

.empty-wordings {
  color: #f05060;
}

.clean-inputs-button {
  float: right;
}

.buttons-bot {
  margin-top: -5px;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 15px;
  flex-basis: 25%;
}

.delete-contact {
  cursor: pointer;
  margin: 5px;
  padding: 0.9em 0;
  border-top: 0.8437;
}

.delete-contact-div {
  display: flex;
}

ic-tenants-new-entity .settings-tenants-new-entity-panel .ic-selectable-table mat-header-row {
  justify-content: space-between;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel .ic-selectable-table mat-header-row.ic-selectable-row {
  height: 40px !important;
  top: 66px !important;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel .ic-selectable-table mat-header-row.ic-selectable-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type label {
  height: 24px;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell {
  min-width: 200px;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(2) {
  min-width: 250px;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(3) {
  min-width: 150px;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(4) {
  min-width: 250px;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(5) {
  min-width: 150px;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(6) {
  min-width: 100px;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(7) {
  min-width: 100px;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:last-of-type {
  min-width: min-content;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel .ic-selectable-table mat-row {
  justify-content: space-between;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel .ic-selectable-table mat-row .mat-mdc-cell:first-of-type {
  padding: 0 0.4em;
  min-width: 200px;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(2) {
  min-width: 250px;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(3) {
  min-width: 150px;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(4) {
  min-width: 250px;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(5) {
  min-width: 150px;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(6) {
  min-width: 100px;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(7) {
  min-width: 100px;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel .ic-selectable-table mat-row .mat-mdc-cell:last-of-type {
  min-width: min-content;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel .ic-selectable-table mat-row .mdc-data-table__cell {
  margin-top: 0 !important;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel .ic-selectable-table mat-row .mdc-data-table__cell > span {
  line-height: 16px;
  padding: 5px 0 5px 0;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel ic-detail-table .mat-mdc-row .mdc-data-table__cell {
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
  min-width: 103px;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel ic-detail-table .mat-header-row .mdc-data-table__header-cell,
ic-tenants-new-entity .settings-tenants-new-entity-panel ic-detail-table .mat-mdc-header-row .mdc-data-table__header-cell {
  font-size: 12px;
  font-weight: 600;
  min-width: 103px;
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  max-height: 56px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px 0 4px;
  z-index: 999;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel-toolbar .panel-title {
  flex: auto;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-tenants-new-entity .settings-tenants-new-entity-panel-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel-toolbar .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel-info {
  min-height: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel-info > mat-card {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2);
}
ic-tenants-new-entity .settings-tenants-new-entity-panel-info > mat-card > mat-card-content > .six-element-row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel-info > mat-card > mat-card-content > .six-element-row > .six-element-row-big-element {
  flex-basis: 25%;
  min-width: 25%;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel-info > mat-card > mat-card-content > .six-element-row > .six-element-row-big-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel-info > mat-card > mat-card-content > .six-element-row > .six-element-row-medium-element {
  flex-basis: 17%;
  min-width: 17%;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel-info > mat-card > mat-card-content > .six-element-row > .six-element-row-medium-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel-info > mat-card > mat-card-content > .six-element-row > .six-element-row-small-element {
  flex-basis: 14%;
  min-width: 14%;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel-info > mat-card > mat-card-content > .six-element-row > .six-element-row-small-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel-info > mat-card > mat-card-content > .two-element-row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel-info > mat-card > mat-card-content > .two-element-row > .all-row-element {
  flex-basis: 100%;
  min-width: 100%;
  align-self: baseline;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel-info > mat-card > mat-card-content > .two-element-row > .all-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-element {
  flex-basis: 50%;
  min-width: 50%;
  align-self: baseline;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element {
  flex-basis: 40%;
  min-width: 40%;
  align-self: baseline;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-big-element {
  flex-basis: 70%;
  min-width: 70%;
  align-self: baseline;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-big-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-small-element {
  flex-basis: 30%;
  min-width: 30%;
  align-self: baseline;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-small-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-tenants-new-entity .settings-tenants-new-entity-panel-info > mat-card > mat-card-content .slidetoggles-row {
  flex: 1;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}
ic-tenants-new-entity > .settings-tenants-new-entity-panel-title {
  padding-top: 25px;
}

.dynamic-component-header-text {
  margin-left: 0px;
}

.ic-panel-input {
  margin-top: 0.5em;
}

.ic-slide-toggle {
  margin-bottom: 25px;
}

.row-one-element {
  width: 75%;
}

.add-entity {
  cursor: pointer;
  vertical-align: middle;
}

.add-entity-disabled {
  opacity: 0.4;
  vertical-align: middle;
}
.add-entity-disabled:hover {
  background-color: transparent;
}

.delete-entity {
  cursor: pointer;
  margin: 5px;
  padding: 0.9em 0;
  border-top: 0.8437;
}

.text-match {
  color: red;
}

.empty-wordings {
  color: #f05060;
}

.clean-inputs-button {
  float: right;
}

.buttons-bot {
  margin-top: -5px;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 15px;
  flex-basis: 25%;
}

.delete-contact {
  cursor: pointer;
  margin: 5px;
  padding: 0.9em 0;
  border-top: 0.8437;
}

.delete-contact-div {
  display: flex;
}

ic-tenants-detail .settings-tenants-detail .button-back {
  margin-top: 10px;
  margin-left: 10px;
  height: min-content;
  width: -moz-fit-content;
  width: fit-content;
}
ic-tenants-detail .settings-tenants-detail .detail-info {
  margin: auto;
  max-width: 1000px;
  width: 100%;
  margin-bottom: 50px;
}
ic-tenants-detail .settings-tenants-detail .ic-selectable-table mat-header-row {
  justify-content: space-between;
}
ic-tenants-detail .settings-tenants-detail .ic-selectable-table mat-header-row.ic-selectable-row {
  height: 40px !important;
  top: 66px !important;
}
ic-tenants-detail .settings-tenants-detail .ic-selectable-table mat-header-row.ic-selectable-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-tenants-detail .settings-tenants-detail .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type label {
  height: 24px;
}
ic-tenants-detail .settings-tenants-detail .ic-selectable-table mat-header-row .mat-mdc-header-cell {
  min-width: min-content;
}
ic-tenants-detail .settings-tenants-detail .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(2) {
  padding: 0 0 0 0.1em;
}
ic-tenants-detail .settings-tenants-detail .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-tenants-detail .settings-tenants-detail .ic-selectable-table mat-header-row .mat-mdc-header-cell:last-of-type {
  min-width: min-content;
}
ic-tenants-detail .settings-tenants-detail .ic-selectable-table mat-row {
  justify-content: space-between;
}
ic-tenants-detail .settings-tenants-detail .ic-selectable-table mat-row .mat-mdc-cell:first-of-type {
  padding: 0 0.4em;
  min-width: min-content;
}
ic-tenants-detail .settings-tenants-detail .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(2) {
  min-width: min-content;
}
ic-tenants-detail .settings-tenants-detail .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-tenants-detail .settings-tenants-detail .ic-selectable-table mat-row .mat-mdc-cell:last-of-type {
  min-width: min-content;
}
ic-tenants-detail .settings-tenants-detail .ic-selectable-table mat-row .mdc-data-table__cell {
  margin-top: 0 !important;
}
ic-tenants-detail .settings-tenants-detail .ic-selectable-table mat-row .mdc-data-table__cell > span {
  line-height: 16px;
  padding: 5px 0 5px 0;
}
ic-tenants-detail .settings-tenants-detail ic-detail-table .mat-mdc-row .mdc-data-table__cell {
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
  min-width: 103px;
}
ic-tenants-detail .settings-tenants-detail ic-detail-table .mat-header-row .mdc-data-table__header-cell,
ic-tenants-detail .settings-tenants-detail ic-detail-table .mat-mdc-header-row .mdc-data-table__header-cell {
  font-size: 12px;
  font-weight: 600;
  min-width: 103px;
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
}
ic-tenants-detail .settings-tenants-detail-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  max-height: 56px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px 0 4px;
  z-index: 999;
}
ic-tenants-detail .settings-tenants-detail-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-tenants-detail .settings-tenants-detail-toolbar .panel-title {
  flex: auto;
}
ic-tenants-detail .settings-tenants-detail-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-tenants-detail .settings-tenants-detail-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-tenants-detail .settings-tenants-detail-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-tenants-detail .settings-tenants-detail-toolbar .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-tenants-detail .settings-tenants-detail-info {
  margin: auto;
  max-width: 1000px;
  width: 100%;
  margin-bottom: 50px;
}
ic-tenants-detail .settings-tenants-detail-info > div {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
ic-tenants-detail .settings-tenants-detail-info .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-tenants-detail > .settings-tenants-detail-title {
  padding-top: 25px;
}
ic-tenants-detail mat-card {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2) !important;
}
ic-tenants-detail mat-card > mat-card-content > .five-element-row {
  flex-direction: row;
  display: flex;
}
ic-tenants-detail mat-card > mat-card-content > .five-element-row > .five-element-row-code {
  flex-basis: 17%;
  min-width: 17%;
}
ic-tenants-detail mat-card > mat-card-content > .five-element-row > .five-element-row-code .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-tenants-detail mat-card > mat-card-content > .five-element-row > .five-element-row-type {
  flex-basis: 17%;
  min-width: 17%;
}
ic-tenants-detail mat-card > mat-card-content > .five-element-row > .five-element-row-type .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-tenants-detail mat-card > mat-card-content > .five-element-row > .five-element-row-parent {
  flex-basis: 40%;
  min-width: 40%;
}
ic-tenants-detail mat-card > mat-card-content > .five-element-row > .five-element-row-parent .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-tenants-detail mat-card > mat-card-content > .five-element-row > .five-element-row-legal-base {
  flex-basis: 25%;
  min-width: 25%;
}
ic-tenants-detail mat-card > mat-card-content > .five-element-row > .five-element-row-legal-base .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-tenants-detail mat-card > mat-card-content > .two-element-row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
}
ic-tenants-detail mat-card > mat-card-content > .two-element-row > .all-row-element {
  flex-basis: 100%;
  min-width: 100%;
  align-self: baseline;
}
ic-tenants-detail mat-card > mat-card-content > .two-element-row > .all-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-tenants-detail mat-card > mat-card-content > .two-element-row > .two-element-row-element {
  flex-basis: 50%;
  min-width: 50%;
  align-self: baseline;
}
ic-tenants-detail mat-card > mat-card-content > .two-element-row > .two-element-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-tenants-detail mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element {
  flex-basis: 40%;
  min-width: 40%;
  align-self: baseline;
}
ic-tenants-detail mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-tenants-detail mat-card > mat-card-content > .two-element-row > .two-element-row-big-element {
  flex-basis: 70%;
  min-width: 70%;
  align-self: baseline;
}
ic-tenants-detail mat-card > mat-card-content > .two-element-row > .two-element-row-big-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-tenants-detail mat-card > mat-card-content > .two-element-row > .two-element-row-small-element {
  flex-basis: 30%;
  min-width: 30%;
  align-self: baseline;
}
ic-tenants-detail mat-card > mat-card-content > .two-element-row > .two-element-row-small-element .mat-mdc-input-element {
  letter-spacing: 0;
}

.dynamic-component-header-text {
  margin-left: 0px;
}

.ic-panel-input {
  margin-top: 0.5em;
}

.ic-slide-toggle {
  margin-bottom: 25px;
}

.row-one-element {
  width: 75%;
}

.add-entity {
  cursor: pointer;
  vertical-align: middle;
}

.add-entity-disabled {
  opacity: 0.4;
  vertical-align: middle;
}
.add-entity-disabled:hover {
  background-color: transparent;
}

.delete-entity {
  cursor: pointer;
  margin: 5px;
  padding: 0.9em 0;
  border-top: 0.8437;
}

.text-match {
  color: red;
}

.empty-wordings {
  color: #f05060;
}

.clean-inputs-button {
  float: right;
}

.buttons-bot {
  margin-top: -5px;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 15px;
  flex-basis: 25%;
}

.edit-button {
  margin-left: auto;
}

ic-settings-tenants-edit .settings-tenants-edit .ic-selectable-table mat-header-row {
  justify-content: space-between;
}
ic-settings-tenants-edit .settings-tenants-edit .ic-selectable-table mat-header-row.ic-selectable-row {
  height: 40px !important;
  top: 66px !important;
}
ic-settings-tenants-edit .settings-tenants-edit .ic-selectable-table mat-header-row.ic-selectable-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-settings-tenants-edit .settings-tenants-edit .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-settings-tenants-edit .settings-tenants-edit .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type label {
  height: 24px;
}
ic-settings-tenants-edit .settings-tenants-edit .ic-selectable-table mat-header-row .mat-mdc-header-cell {
  min-width: min-content;
}
ic-settings-tenants-edit .settings-tenants-edit .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(2) {
  padding: 0 0 0 0.5em;
}
ic-settings-tenants-edit .settings-tenants-edit .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(3) {
  padding: 0 0 0 0.5em;
  min-width: 93px;
}
ic-settings-tenants-edit .settings-tenants-edit .ic-selectable-table mat-header-row .mat-mdc-header-cell:last-of-type {
  padding: 0 0 0 0.5em;
  min-width: min-content;
}
ic-settings-tenants-edit .settings-tenants-edit .ic-selectable-table mat-row {
  justify-content: space-between;
}
ic-settings-tenants-edit .settings-tenants-edit .ic-selectable-table mat-row .mat-mdc-cell:first-of-type {
  padding: 0 0.4em;
  min-width: min-content;
}
ic-settings-tenants-edit .settings-tenants-edit .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(2) {
  min-width: min-content;
}
ic-settings-tenants-edit .settings-tenants-edit .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-settings-tenants-edit .settings-tenants-edit .ic-selectable-table mat-row .mat-mdc-cell:last-of-type {
  min-width: min-content;
}
ic-settings-tenants-edit .settings-tenants-edit .ic-selectable-table mat-row .mdc-data-table__cell {
  margin-top: 0 !important;
}
ic-settings-tenants-edit .settings-tenants-edit .ic-selectable-table mat-row .mdc-data-table__cell > span {
  line-height: 16px;
  padding: 5px 0 5px 0;
}
ic-settings-tenants-edit .settings-tenants-edit ic-detail-table .mat-mdc-row .mdc-data-table__cell {
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
  min-width: 103px;
}
ic-settings-tenants-edit .settings-tenants-edit ic-detail-table .mat-header-row .mdc-data-table__header-cell,
ic-settings-tenants-edit .settings-tenants-edit ic-detail-table .mat-mdc-header-row .mdc-data-table__header-cell {
  font-size: 12px;
  font-weight: 600;
  min-width: 103px;
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
}
ic-settings-tenants-edit .settings-tenants-edit-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  max-height: 56px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px 0 4px;
  z-index: 999;
}
ic-settings-tenants-edit .settings-tenants-edit-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-settings-tenants-edit .settings-tenants-edit-toolbar .panel-title {
  flex: auto;
}
ic-settings-tenants-edit .settings-tenants-edit-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-settings-tenants-edit .settings-tenants-edit-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-settings-tenants-edit .settings-tenants-edit-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-settings-tenants-edit .settings-tenants-edit-toolbar .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-settings-tenants-edit .settings-tenants-edit-info {
  min-height: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
ic-settings-tenants-edit .settings-tenants-edit-info > mat-card {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2);
}
ic-settings-tenants-edit .settings-tenants-edit-info > mat-card > mat-card-content > .six-element-row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}
ic-settings-tenants-edit .settings-tenants-edit-info > mat-card > mat-card-content > .six-element-row > .six-element-row-big-element {
  flex-basis: 25%;
  min-width: 25%;
}
ic-settings-tenants-edit .settings-tenants-edit-info > mat-card > mat-card-content > .six-element-row > .six-element-row-big-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-tenants-edit .settings-tenants-edit-info > mat-card > mat-card-content > .six-element-row > .six-element-row-medium-element {
  flex-basis: 17%;
  min-width: 17%;
}
ic-settings-tenants-edit .settings-tenants-edit-info > mat-card > mat-card-content > .six-element-row > .six-element-row-medium-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-tenants-edit .settings-tenants-edit-info > mat-card > mat-card-content > .six-element-row > .six-element-row-small-element {
  flex-basis: 14%;
  min-width: 14%;
}
ic-settings-tenants-edit .settings-tenants-edit-info > mat-card > mat-card-content > .six-element-row > .six-element-row-small-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-tenants-edit .settings-tenants-edit-info > mat-card > mat-card-content > .two-element-row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
}
ic-settings-tenants-edit .settings-tenants-edit-info > mat-card > mat-card-content > .two-element-row > .all-row-element {
  flex-basis: 100%;
  min-width: 100%;
  align-self: baseline;
}
ic-settings-tenants-edit .settings-tenants-edit-info > mat-card > mat-card-content > .two-element-row > .all-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-tenants-edit .settings-tenants-edit-info > mat-card > mat-card-content > .two-element-row > .two-element-row-element {
  flex-basis: 50%;
  min-width: 50%;
  align-self: baseline;
}
ic-settings-tenants-edit .settings-tenants-edit-info > mat-card > mat-card-content > .two-element-row > .two-element-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-tenants-edit .settings-tenants-edit-info > mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element {
  flex-basis: 40%;
  min-width: 40%;
  align-self: baseline;
}
ic-settings-tenants-edit .settings-tenants-edit-info > mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-tenants-edit .settings-tenants-edit-info > mat-card > mat-card-content > .two-element-row > .two-element-row-big-element {
  flex-basis: 70%;
  min-width: 70%;
  align-self: baseline;
}
ic-settings-tenants-edit .settings-tenants-edit-info > mat-card > mat-card-content > .two-element-row > .two-element-row-big-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-tenants-edit .settings-tenants-edit-info > mat-card > mat-card-content > .two-element-row > .two-element-row-small-element {
  flex-basis: 30%;
  min-width: 30%;
  align-self: baseline;
}
ic-settings-tenants-edit .settings-tenants-edit-info > mat-card > mat-card-content > .two-element-row > .two-element-row-small-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-tenants-edit .settings-tenants-edit-info > mat-card > mat-card-content .slidetoggles-row {
  flex: 1;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}
ic-settings-tenants-edit > .settings-tenants-edit-title {
  padding-top: 25px;
}

.dynamic-component-header-text {
  margin-left: 0px;
}

.ic-panel-input {
  margin-top: 0.5em;
}

.ic-slide-toggle {
  margin-bottom: 25px;
}

.row-one-element {
  width: 75%;
}

.add-entity {
  cursor: pointer;
  vertical-align: middle;
}

.add-entity-disabled {
  opacity: 0.4;
  vertical-align: middle;
}
.add-entity-disabled:hover {
  background-color: transparent;
}

.delete-entity {
  cursor: pointer;
  margin: 5px;
  padding: 0.9em 0;
  border-top: 0.8437;
}

.text-match {
  color: red;
}

.empty-wordings {
  color: #f05060;
}

.clean-inputs-button {
  float: right;
}

.buttons-bot {
  margin-top: -5px;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 15px;
  flex-basis: 25%;
}

.delete-contact {
  cursor: pointer;
  margin: 5px;
  padding: 0.9em 0;
  border-top: 0.8437;
}

.delete-contact-div {
  display: flex;
}

ic-entities-detail .settings-entities-detail .button-back {
  margin-top: 10px;
  margin-left: 10px;
  height: min-content;
  width: -moz-fit-content;
  width: fit-content;
}
ic-entities-detail .settings-entities-detail .detail-info {
  margin: auto;
  max-width: 1000px;
  width: 100%;
  margin-bottom: 50px;
}
ic-entities-detail .settings-entities-detail .ic-selectable-table mat-header-row {
  justify-content: space-between;
}
ic-entities-detail .settings-entities-detail .ic-selectable-table mat-header-row.ic-selectable-row {
  height: 40px !important;
  top: 66px !important;
}
ic-entities-detail .settings-entities-detail .ic-selectable-table mat-header-row.ic-selectable-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-entities-detail .settings-entities-detail .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type label {
  height: 24px;
}
ic-entities-detail .settings-entities-detail .ic-selectable-table mat-header-row .mat-mdc-header-cell {
  min-width: min-content;
}
ic-entities-detail .settings-entities-detail .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(2) {
  padding: 0 0 0 0.1em;
}
ic-entities-detail .settings-entities-detail .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-entities-detail .settings-entities-detail .ic-selectable-table mat-header-row .mat-mdc-header-cell:last-of-type {
  min-width: min-content;
}
ic-entities-detail .settings-entities-detail .ic-selectable-table mat-row {
  justify-content: space-between;
}
ic-entities-detail .settings-entities-detail .ic-selectable-table mat-row .mat-mdc-cell:first-of-type {
  padding: 0 0.4em;
  min-width: min-content;
}
ic-entities-detail .settings-entities-detail .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(2) {
  min-width: min-content;
}
ic-entities-detail .settings-entities-detail .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-entities-detail .settings-entities-detail .ic-selectable-table mat-row .mat-mdc-cell:last-of-type {
  min-width: min-content;
}
ic-entities-detail .settings-entities-detail .ic-selectable-table mat-row .mdc-data-table__cell {
  margin-top: 0 !important;
}
ic-entities-detail .settings-entities-detail .ic-selectable-table mat-row .mdc-data-table__cell > span {
  line-height: 16px;
  padding: 5px 0 5px 0;
}
ic-entities-detail .settings-entities-detail ic-detail-table .mat-mdc-row .mdc-data-table__cell {
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
  min-width: 103px;
}
ic-entities-detail .settings-entities-detail ic-detail-table .mat-header-row .mdc-data-table__header-cell,
ic-entities-detail .settings-entities-detail ic-detail-table .mat-mdc-header-row .mdc-data-table__header-cell {
  font-size: 12px;
  font-weight: 600;
  min-width: 103px;
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
}
ic-entities-detail .settings-entities-detail-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  max-height: 56px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px 0 4px;
  z-index: 999;
}
ic-entities-detail .settings-entities-detail-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-entities-detail .settings-entities-detail-toolbar .panel-title {
  flex: auto;
}
ic-entities-detail .settings-entities-detail-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-entities-detail .settings-entities-detail-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-entities-detail .settings-entities-detail-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-entities-detail .settings-entities-detail-toolbar .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-entities-detail .settings-entities-detail-info {
  margin: auto;
  max-width: 1000px;
  width: 100%;
  margin-bottom: 50px;
}
ic-entities-detail .settings-entities-detail-info > div {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
ic-entities-detail .settings-entities-detail-info .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-entities-detail > .settings-entities-detail-title {
  padding-top: 25px;
}
ic-entities-detail mat-card {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2) !important;
}
ic-entities-detail mat-card > mat-card-content > .five-element-row {
  flex-direction: row;
  display: flex;
}
ic-entities-detail mat-card > mat-card-content > .five-element-row > .five-element-row-code {
  flex-basis: 17%;
  min-width: 17%;
}
ic-entities-detail mat-card > mat-card-content > .five-element-row > .five-element-row-code .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-entities-detail mat-card > mat-card-content > .five-element-row > .five-element-row-type {
  flex-basis: 17%;
  min-width: 17%;
}
ic-entities-detail mat-card > mat-card-content > .five-element-row > .five-element-row-type .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-entities-detail mat-card > mat-card-content > .five-element-row > .five-element-row-parent {
  flex-basis: 40%;
  min-width: 40%;
}
ic-entities-detail mat-card > mat-card-content > .five-element-row > .five-element-row-parent .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-entities-detail mat-card > mat-card-content > .five-element-row > .five-element-row-legal-base {
  flex-basis: 25%;
  min-width: 25%;
}
ic-entities-detail mat-card > mat-card-content > .five-element-row > .five-element-row-legal-base .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-entities-detail mat-card > mat-card-content > .two-element-row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
}
ic-entities-detail mat-card > mat-card-content > .two-element-row > .all-row-element {
  flex-basis: 100%;
  min-width: 100%;
  align-self: baseline;
}
ic-entities-detail mat-card > mat-card-content > .two-element-row > .all-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-entities-detail mat-card > mat-card-content > .two-element-row > .two-element-row-element {
  flex-basis: 50%;
  min-width: 50%;
  align-self: baseline;
}
ic-entities-detail mat-card > mat-card-content > .two-element-row > .two-element-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-entities-detail mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element {
  flex-basis: 40%;
  min-width: 40%;
  align-self: baseline;
}
ic-entities-detail mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-entities-detail mat-card > mat-card-content > .two-element-row > .two-element-row-big-element {
  flex-basis: 70%;
  min-width: 70%;
  align-self: baseline;
}
ic-entities-detail mat-card > mat-card-content > .two-element-row > .two-element-row-big-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-entities-detail mat-card > mat-card-content > .two-element-row > .two-element-row-small-element {
  flex-basis: 30%;
  min-width: 30%;
  align-self: baseline;
}
ic-entities-detail mat-card > mat-card-content > .two-element-row > .two-element-row-small-element .mat-mdc-input-element {
  letter-spacing: 0;
}

.dynamic-component-header-text {
  margin-left: 0px;
}

.ic-panel-input {
  margin-top: 0.5em;
}

.ic-slide-toggle {
  margin-bottom: 25px;
}

.row-one-element {
  width: 75%;
}

.add-entity {
  cursor: pointer;
  vertical-align: middle;
}

.add-entity-disabled {
  opacity: 0.4;
  vertical-align: middle;
}
.add-entity-disabled:hover {
  background-color: transparent;
}

.delete-entity {
  cursor: pointer;
  margin: 5px;
  padding: 0.9em 0;
  border-top: 0.8437;
}

.text-match {
  color: red;
}

.empty-wordings {
  color: #f05060;
}

.clean-inputs-button {
  float: right;
}

.buttons-bot {
  margin-top: -5px;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 15px;
  flex-basis: 25%;
}

.edit-button {
  margin-left: auto;
}

ic-entities-edit .settings-entities-edit-panel .ic-selectable-table mat-header-row {
  justify-content: space-between;
}
ic-entities-edit .settings-entities-edit-panel .ic-selectable-table mat-header-row.ic-selectable-row {
  height: 40px !important;
  top: 66px !important;
}
ic-entities-edit .settings-entities-edit-panel .ic-selectable-table mat-header-row.ic-selectable-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-entities-edit .settings-entities-edit-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-entities-edit .settings-entities-edit-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type label {
  height: 24px;
}
ic-entities-edit .settings-entities-edit-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell {
  min-width: 200px;
}
ic-entities-edit .settings-entities-edit-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(2) {
  min-width: 250px;
}
ic-entities-edit .settings-entities-edit-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(3) {
  min-width: 150px;
}
ic-entities-edit .settings-entities-edit-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(4) {
  min-width: 250px;
}
ic-entities-edit .settings-entities-edit-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(5) {
  min-width: 150px;
}
ic-entities-edit .settings-entities-edit-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(6) {
  min-width: 100px;
}
ic-entities-edit .settings-entities-edit-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(7) {
  min-width: 100px;
}
ic-entities-edit .settings-entities-edit-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:last-of-type {
  min-width: min-content;
}
ic-entities-edit .settings-entities-edit-panel .ic-selectable-table mat-row {
  justify-content: space-between;
}
ic-entities-edit .settings-entities-edit-panel .ic-selectable-table mat-row .mat-mdc-cell:first-of-type {
  padding: 0 0.4em;
  min-width: 200px;
}
ic-entities-edit .settings-entities-edit-panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(2) {
  min-width: 250px;
}
ic-entities-edit .settings-entities-edit-panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(3) {
  min-width: 150px;
}
ic-entities-edit .settings-entities-edit-panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(4) {
  min-width: 250px;
}
ic-entities-edit .settings-entities-edit-panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(5) {
  min-width: 150px;
}
ic-entities-edit .settings-entities-edit-panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(6) {
  min-width: 100px;
}
ic-entities-edit .settings-entities-edit-panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(7) {
  min-width: 100px;
}
ic-entities-edit .settings-entities-edit-panel .ic-selectable-table mat-row .mat-mdc-cell:last-of-type {
  min-width: min-content;
}
ic-entities-edit .settings-entities-edit-panel .ic-selectable-table mat-row .mdc-data-table__cell {
  margin-top: 0 !important;
}
ic-entities-edit .settings-entities-edit-panel .ic-selectable-table mat-row .mdc-data-table__cell > span {
  line-height: 16px;
  padding: 5px 0 5px 0;
}
ic-entities-edit .settings-entities-edit-panel ic-detail-table .mat-mdc-row .mdc-data-table__cell {
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
  min-width: 103px;
}
ic-entities-edit .settings-entities-edit-panel ic-detail-table .mat-header-row .mdc-data-table__header-cell,
ic-entities-edit .settings-entities-edit-panel ic-detail-table .mat-mdc-header-row .mdc-data-table__header-cell {
  font-size: 12px;
  font-weight: 600;
  min-width: 103px;
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
}
ic-entities-edit .settings-entities-edit-panel-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  max-height: 56px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px 0 4px;
  z-index: 999;
}
ic-entities-edit .settings-entities-edit-panel-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-entities-edit .settings-entities-edit-panel-toolbar .panel-title {
  flex: auto;
}
ic-entities-edit .settings-entities-edit-panel-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-entities-edit .settings-entities-edit-panel-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-entities-edit .settings-entities-edit-panel-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-entities-edit .settings-entities-edit-panel-toolbar .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-entities-edit .settings-entities-edit-panel-info {
  min-height: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
ic-entities-edit .settings-entities-edit-panel-info > mat-card {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2);
}
ic-entities-edit .settings-entities-edit-panel-info > mat-card > mat-card-content > .six-element-row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}
ic-entities-edit .settings-entities-edit-panel-info > mat-card > mat-card-content > .six-element-row > .six-element-row-big-element {
  flex-basis: 25%;
  min-width: 25%;
}
ic-entities-edit .settings-entities-edit-panel-info > mat-card > mat-card-content > .six-element-row > .six-element-row-big-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-entities-edit .settings-entities-edit-panel-info > mat-card > mat-card-content > .six-element-row > .six-element-row-medium-element {
  flex-basis: 17%;
  min-width: 17%;
}
ic-entities-edit .settings-entities-edit-panel-info > mat-card > mat-card-content > .six-element-row > .six-element-row-medium-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-entities-edit .settings-entities-edit-panel-info > mat-card > mat-card-content > .six-element-row > .six-element-row-small-element {
  flex-basis: 14%;
  min-width: 14%;
}
ic-entities-edit .settings-entities-edit-panel-info > mat-card > mat-card-content > .six-element-row > .six-element-row-small-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-entities-edit .settings-entities-edit-panel-info > mat-card > mat-card-content > .two-element-row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
}
ic-entities-edit .settings-entities-edit-panel-info > mat-card > mat-card-content > .two-element-row > .all-row-element {
  flex-basis: 100%;
  min-width: 100%;
  align-self: baseline;
}
ic-entities-edit .settings-entities-edit-panel-info > mat-card > mat-card-content > .two-element-row > .all-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-entities-edit .settings-entities-edit-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-element {
  flex-basis: 50%;
  min-width: 50%;
  align-self: baseline;
}
ic-entities-edit .settings-entities-edit-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-entities-edit .settings-entities-edit-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element {
  flex-basis: 40%;
  min-width: 40%;
  align-self: baseline;
}
ic-entities-edit .settings-entities-edit-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-entities-edit .settings-entities-edit-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-big-element {
  flex-basis: 70%;
  min-width: 70%;
  align-self: baseline;
}
ic-entities-edit .settings-entities-edit-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-big-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-entities-edit .settings-entities-edit-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-small-element {
  flex-basis: 30%;
  min-width: 30%;
  align-self: baseline;
}
ic-entities-edit .settings-entities-edit-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-small-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-entities-edit .settings-entities-edit-panel-info > mat-card > mat-card-content .slidetoggles-row {
  flex: 1;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}
ic-entities-edit > .settings-entities-edit-panel-title {
  padding-top: 25px;
}

.dynamic-component-header-text {
  margin-left: 0px;
}

.ic-panel-input {
  margin-top: 0.5em;
}

.ic-slide-toggle {
  margin-bottom: 25px;
}

.row-one-element {
  width: 75%;
}

.add-entity {
  cursor: pointer;
  vertical-align: middle;
}

.add-entity-disabled {
  opacity: 0.4;
  vertical-align: middle;
}
.add-entity-disabled:hover {
  background-color: transparent;
}

.delete-entity {
  cursor: pointer;
  margin: 5px;
  padding: 0.9em 0;
  border-top: 0.8437;
}

.text-match {
  color: red;
}

.empty-wordings {
  color: #f05060;
}

.clean-inputs-button {
  float: right;
}

.buttons-bot {
  margin-top: -5px;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 15px;
  flex-basis: 25%;
}

.delete-contact {
  cursor: pointer;
  margin: 5px;
  padding: 0.9em 0;
  border-top: 0.8437;
}

.delete-contact-div {
  display: flex;
}

ic-settings-prior-inspection-items-panel .settings-prior-inspection-items-panel .ic-selectable-table mat-header-row {
  justify-content: space-between;
}
ic-settings-prior-inspection-items-panel .settings-prior-inspection-items-panel .ic-selectable-table mat-header-row.ic-selectable-row {
  height: 40px !important;
  top: 66px !important;
}
ic-settings-prior-inspection-items-panel .settings-prior-inspection-items-panel .ic-selectable-table mat-header-row.ic-selectable-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-settings-prior-inspection-items-panel .settings-prior-inspection-items-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0 0 1.2em;
}
ic-settings-prior-inspection-items-panel .settings-prior-inspection-items-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type label {
  height: 24px;
}
ic-settings-prior-inspection-items-panel .settings-prior-inspection-items-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell {
  min-width: min-content;
}
ic-settings-prior-inspection-items-panel .settings-prior-inspection-items-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(2) {
  padding: 0 0.5em 0 2em;
}
ic-settings-prior-inspection-items-panel .settings-prior-inspection-items-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-settings-prior-inspection-items-panel .settings-prior-inspection-items-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:last-of-type {
  min-width: min-content;
}
ic-settings-prior-inspection-items-panel .settings-prior-inspection-items-panel .ic-selectable-table mat-row {
  justify-content: space-between;
}
ic-settings-prior-inspection-items-panel .settings-prior-inspection-items-panel .ic-selectable-table mat-row .mat-mdc-cell:first-of-type {
  padding: 0 0.4em;
  min-width: min-content;
}
ic-settings-prior-inspection-items-panel .settings-prior-inspection-items-panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(2) {
  min-width: min-content;
}
ic-settings-prior-inspection-items-panel .settings-prior-inspection-items-panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-settings-prior-inspection-items-panel .settings-prior-inspection-items-panel .ic-selectable-table mat-row .mat-mdc-cell:last-of-type {
  min-width: min-content;
}
ic-settings-prior-inspection-items-panel .settings-prior-inspection-items-panel .ic-selectable-table mat-row .mdc-data-table__cell {
  margin-top: 0 !important;
}
ic-settings-prior-inspection-items-panel .settings-prior-inspection-items-panel .ic-selectable-table mat-row .mdc-data-table__cell > span {
  line-height: 16px;
  padding: 5px 0 5px 0;
}
ic-settings-prior-inspection-items-panel .settings-prior-inspection-items-panel .settings-prior-inspection-items-panel-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  max-height: 56px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px 0 4px;
  z-index: 999;
}
ic-settings-prior-inspection-items-panel .settings-prior-inspection-items-panel .settings-prior-inspection-items-panel-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-settings-prior-inspection-items-panel .settings-prior-inspection-items-panel .settings-prior-inspection-items-panel-toolbar .panel-title {
  flex: auto;
}
ic-settings-prior-inspection-items-panel .settings-prior-inspection-items-panel .settings-prior-inspection-items-panel-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-settings-prior-inspection-items-panel .settings-prior-inspection-items-panel .settings-prior-inspection-items-panel-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-settings-prior-inspection-items-panel .settings-prior-inspection-items-panel .settings-prior-inspection-items-panel-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-settings-prior-inspection-items-panel .settings-prior-inspection-items-panel .settings-prior-inspection-items-panel-toolbar .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-settings-prior-inspection-items-panel .settings-prior-inspection-items-panel .settings-prior-inspection-items-panel-info {
  padding: 20px;
}
ic-settings-prior-inspection-items-panel .settings-prior-inspection-items-panel .settings-prior-inspection-items-panel-info > mat-card {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2);
}
ic-settings-prior-inspection-items-panel .settings-prior-inspection-items-panel .settings-prior-inspection-items-panel-info > mat-card > mat-card-content > .two-element-row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: unset;
  align-items: baseline;
  margin-bottom: 0px;
}
ic-settings-prior-inspection-items-panel .settings-prior-inspection-items-panel .settings-prior-inspection-items-panel-info > div {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
ic-settings-prior-inspection-items-panel .settings-prior-inspection-items-panel .settings-prior-inspection-items-panel-info > .settings-prior-inspection-items-panel-title {
  padding-top: 25px;
}
ic-settings-prior-inspection-items-panel .settings-prior-inspection-items-panel .settings-prior-inspection-items-panel-info .edit-button {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  flex-basis: 25%;
}

.dynamic-component-header-text {
  margin-left: 0px;
}

.ic-slide-toggle {
  margin-left: 15px;
  margin-right: 25px;
}

.text-match {
  color: red;
}

.select-if-yes-no {
  flex-basis: 20%;
  padding: 5px;
  margin-left: 5px;
  margin-right: 15px;
}

.code-item {
  margin-left: 25px;
}

.father-item {
  width: 60%;
  margin-left: 15px;
}

.two-element-row {
  margin-bottom: 25px !important;
}

.edit-button {
  margin-left: auto;
}

.warning-text-match {
  color: #ee9a37 !important;
}

ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel .ic-selectable-table mat-header-row {
  justify-content: space-between;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel .ic-selectable-table mat-header-row.ic-selectable-row {
  height: 40px !important;
  top: 66px !important;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel .ic-selectable-table mat-header-row.ic-selectable-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0 0 1.2em;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type label {
  height: 24px;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell {
  min-width: min-content;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(2) {
  padding: 0 0.5em 0 2em;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:last-of-type {
  min-width: min-content;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel .ic-selectable-table mat-row {
  justify-content: space-between;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel .ic-selectable-table mat-row .mat-mdc-cell:first-of-type {
  padding: 0 0.4em;
  min-width: min-content;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(2) {
  min-width: min-content;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel .ic-selectable-table mat-row .mat-mdc-cell:last-of-type {
  min-width: min-content;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel .ic-selectable-table mat-row .mdc-data-table__cell {
  margin-top: 0 !important;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel .ic-selectable-table mat-row .mdc-data-table__cell > span {
  line-height: 16px;
  padding: 5px 0 5px 0;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel ic-detail-table .mat-mdc-row .mdc-data-table__cell {
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
  min-width: 103px;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel ic-detail-table .mat-header-row .mdc-data-table__header-cell,
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel ic-detail-table .mat-mdc-header-row .mdc-data-table__header-cell {
  font-size: 12px;
  font-weight: 600;
  min-width: 103px;
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  max-height: 56px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px 0 4px;
  z-index: 999;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel-toolbar .panel-title {
  flex: auto;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel-toolbar .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel-info {
  min-height: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel-info > mat-card {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2);
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel-info > mat-card > mat-card-content > .five-element-row {
  flex-direction: row;
  display: flex;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel-info > mat-card > mat-card-content > .five-element-row > .five-element-row-impact-level {
  flex-basis: 27%;
  min-width: 27%;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel-info > mat-card > mat-card-content > .five-element-row > .five-element-row-impact-level .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel-info > mat-card > mat-card-content > .five-element-row > .five-element-row-observation-code {
  flex-basis: 17%;
  min-width: 17%;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel-info > mat-card > mat-card-content > .five-element-row > .five-element-row-observation-code .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel-info > mat-card > mat-card-content > .five-element-row > .five-element-row-category {
  flex-basis: 14%;
  min-width: 14%;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel-info > mat-card > mat-card-content > .five-element-row > .five-element-row-category .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel-info > mat-card > mat-card-content > .five-element-row > .five-element-row-visibility {
  flex-basis: 12%;
  min-width: 12%;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel-info > mat-card > mat-card-content > .five-element-row > .five-element-row-visibility .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel-info > mat-card > mat-card-content > .five-element-row > .five-element-row-scipion-user {
  flex-basis: 30%;
  min-width: 30%;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel-info > mat-card > mat-card-content > .five-element-row > .five-element-row-scipion-user .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel-info > mat-card > mat-card-content > .two-element-row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel-info > mat-card > mat-card-content > .two-element-row > .all-row-element {
  flex-basis: 100%;
  min-width: 100%;
  align-self: baseline;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel-info > mat-card > mat-card-content > .two-element-row > .all-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-element {
  flex-basis: 50%;
  min-width: 50%;
  align-self: baseline;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element {
  flex-basis: 40%;
  min-width: 40%;
  align-self: baseline;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-big-element {
  flex-basis: 70%;
  min-width: 70%;
  align-self: baseline;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-big-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-small-element {
  flex-basis: 30%;
  min-width: 30%;
  align-self: baseline;
}
ic-settings-prior-inspection-observations-panel .prior-inspection-observations-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-small-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-prior-inspection-observations-panel > .prior-inspection-observations-panel-title {
  padding-top: 25px;
}

.dynamic-component-header-text {
  margin-left: 0px;
}

.ic-panel-input {
  margin-top: 0.5em;
}

.ic-slide-toggle {
  margin-bottom: 25px;
}

.row-one-element {
  width: 75%;
}

.add-entity {
  cursor: pointer;
  vertical-align: middle;
}

.add-entity-disabled {
  opacity: 0.4;
  vertical-align: middle;
}
.add-entity-disabled:hover {
  background-color: transparent;
}

.delete-entity {
  cursor: pointer;
  margin: 5px;
  padding: 0.9em 0;
  border-top: 0.8437;
}

.text-match {
  color: red;
}

.empty-wordings {
  color: #f05060;
}

.clean-inputs-button {
  float: right;
}

.buttons-bot {
  margin-top: -5px;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 15px;
  flex-basis: 25%;
}

ic-settings-prior-inspection-observations-detail .prior-inspection-observations-detail .button-back {
  margin-top: 10px;
  margin-left: 10px;
  height: min-content;
  width: -moz-fit-content;
  width: fit-content;
}
ic-settings-prior-inspection-observations-detail .prior-inspection-observations-detail .detail-info {
  margin: auto;
  max-width: 1000px;
  width: 100%;
  margin-bottom: 50px;
}
ic-settings-prior-inspection-observations-detail .prior-inspection-observations-detail .ic-selectable-table mat-header-row {
  justify-content: space-between;
}
ic-settings-prior-inspection-observations-detail .prior-inspection-observations-detail .ic-selectable-table mat-header-row.ic-selectable-row {
  height: 40px !important;
  top: 66px !important;
}
ic-settings-prior-inspection-observations-detail .prior-inspection-observations-detail .ic-selectable-table mat-header-row.ic-selectable-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-settings-prior-inspection-observations-detail .prior-inspection-observations-detail .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type label {
  height: 24px;
}
ic-settings-prior-inspection-observations-detail .prior-inspection-observations-detail .ic-selectable-table mat-header-row .mat-mdc-header-cell {
  min-width: min-content;
}
ic-settings-prior-inspection-observations-detail .prior-inspection-observations-detail .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(2) {
  padding: 0 0 0 0.5em;
}
ic-settings-prior-inspection-observations-detail .prior-inspection-observations-detail .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-settings-prior-inspection-observations-detail .prior-inspection-observations-detail .ic-selectable-table mat-header-row .mat-mdc-header-cell:last-of-type {
  min-width: min-content;
}
ic-settings-prior-inspection-observations-detail .prior-inspection-observations-detail .ic-selectable-table mat-row {
  justify-content: space-between;
}
ic-settings-prior-inspection-observations-detail .prior-inspection-observations-detail .ic-selectable-table mat-row .mat-mdc-cell:first-of-type {
  padding: 0 0.4em;
  min-width: min-content;
}
ic-settings-prior-inspection-observations-detail .prior-inspection-observations-detail .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(2) {
  min-width: min-content;
}
ic-settings-prior-inspection-observations-detail .prior-inspection-observations-detail .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-settings-prior-inspection-observations-detail .prior-inspection-observations-detail .ic-selectable-table mat-row .mat-mdc-cell:last-of-type {
  min-width: min-content;
}
ic-settings-prior-inspection-observations-detail .prior-inspection-observations-detail .ic-selectable-table mat-row .mdc-data-table__cell {
  margin-top: 0 !important;
}
ic-settings-prior-inspection-observations-detail .prior-inspection-observations-detail .ic-selectable-table mat-row .mdc-data-table__cell > span {
  line-height: 16px;
  padding: 5px 0 5px 0;
}
ic-settings-prior-inspection-observations-detail .prior-inspection-observations-detail ic-detail-table .mat-mdc-row .mdc-data-table__cell {
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
  min-width: 103px;
}
ic-settings-prior-inspection-observations-detail .prior-inspection-observations-detail ic-detail-table .mat-header-row .mdc-data-table__header-cell,
ic-settings-prior-inspection-observations-detail .prior-inspection-observations-detail ic-detail-table .mat-mdc-header-row .mdc-data-table__header-cell {
  font-size: 12px;
  font-weight: 600;
  min-width: 103px;
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
}
ic-settings-prior-inspection-observations-detail .prior-inspection-observations-detail-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  max-height: 56px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px 0 4px;
  z-index: 999;
}
ic-settings-prior-inspection-observations-detail .prior-inspection-observations-detail-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-settings-prior-inspection-observations-detail .prior-inspection-observations-detail-toolbar .panel-title {
  flex: auto;
}
ic-settings-prior-inspection-observations-detail .prior-inspection-observations-detail-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-settings-prior-inspection-observations-detail .prior-inspection-observations-detail-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-settings-prior-inspection-observations-detail .prior-inspection-observations-detail-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-settings-prior-inspection-observations-detail .prior-inspection-observations-detail-toolbar .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-settings-prior-inspection-observations-detail .prior-inspection-observations-detail-info {
  margin: auto;
  max-width: 1000px;
  width: 100%;
  margin-bottom: 50px;
}
ic-settings-prior-inspection-observations-detail .prior-inspection-observations-detail-info > div {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
ic-settings-prior-inspection-observations-detail > .prior-inspection-observations-detail-title {
  padding-top: 25px;
}
ic-settings-prior-inspection-observations-detail mat-card {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2) !important;
}
ic-settings-prior-inspection-observations-detail mat-card > mat-card-content > .five-element-row {
  flex-direction: row;
  display: flex;
}
ic-settings-prior-inspection-observations-detail mat-card > mat-card-content > .five-element-row > .five-element-row-impact-level {
  flex-basis: 27%;
  min-width: 27%;
}
ic-settings-prior-inspection-observations-detail mat-card > mat-card-content > .five-element-row > .five-element-row-impact-level .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-prior-inspection-observations-detail mat-card > mat-card-content > .five-element-row > .five-element-row-observation-code {
  flex-basis: 17%;
  min-width: 17%;
}
ic-settings-prior-inspection-observations-detail mat-card > mat-card-content > .five-element-row > .five-element-row-observation-code .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-prior-inspection-observations-detail mat-card > mat-card-content > .five-element-row > .five-element-row-category {
  flex-basis: 14%;
  min-width: 14%;
}
ic-settings-prior-inspection-observations-detail mat-card > mat-card-content > .five-element-row > .five-element-row-category .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-prior-inspection-observations-detail mat-card > mat-card-content > .five-element-row > .five-element-row-visibility {
  flex-basis: 12%;
  min-width: 12%;
}
ic-settings-prior-inspection-observations-detail mat-card > mat-card-content > .five-element-row > .five-element-row-visibility .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-prior-inspection-observations-detail mat-card > mat-card-content > .five-element-row > .five-element-row-scipion-user {
  flex-basis: 30%;
  min-width: 30%;
}
ic-settings-prior-inspection-observations-detail mat-card > mat-card-content > .five-element-row > .five-element-row-scipion-user .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-prior-inspection-observations-detail mat-card > mat-card-content > .two-element-row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: baseline;
  margin-bottom: 20px;
}
ic-settings-prior-inspection-observations-detail mat-card > mat-card-content > .two-element-row > .all-row-element {
  flex-basis: 100%;
  min-width: 100%;
  align-self: baseline;
}
ic-settings-prior-inspection-observations-detail mat-card > mat-card-content > .two-element-row > .all-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-prior-inspection-observations-detail mat-card > mat-card-content > .two-element-row > .two-element-row-element {
  flex-basis: 50%;
  min-width: 50%;
  align-self: baseline;
}
ic-settings-prior-inspection-observations-detail mat-card > mat-card-content > .two-element-row > .two-element-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-prior-inspection-observations-detail mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element {
  flex-basis: 40%;
  min-width: 40%;
  align-self: baseline;
}
ic-settings-prior-inspection-observations-detail mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-prior-inspection-observations-detail mat-card > mat-card-content > .two-element-row > .two-element-row-big-element {
  flex-basis: 70%;
  min-width: 70%;
  align-self: baseline;
}
ic-settings-prior-inspection-observations-detail mat-card > mat-card-content > .two-element-row > .two-element-row-big-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-prior-inspection-observations-detail mat-card > mat-card-content > .two-element-row > .two-element-row-small-element {
  flex-basis: 30%;
  min-width: 30%;
  align-self: baseline;
}
ic-settings-prior-inspection-observations-detail mat-card > mat-card-content > .two-element-row > .two-element-row-small-element .mat-mdc-input-element {
  letter-spacing: 0;
}

.dynamic-component-header-text {
  margin-left: 0px;
}

.ic-panel-input {
  margin-top: 0.5em;
}

.ic-slide-toggle {
  margin-bottom: 25px;
}

.row-one-element {
  width: 75%;
}

.add-entity {
  cursor: pointer;
  vertical-align: middle;
}

.delete-entity {
  cursor: pointer;
  margin: 5px;
  padding: 0.9em 0;
  border-top: 0.8437;
}

.text-match {
  color: red;
}

.empty-wordings {
  color: #f05060;
}

.clean-inputs-button {
  float: right;
}

.observations-entity-buttons {
  cursor: pointer;
  vertical-align: middle;
  margin-left: 2px;
}

.observations-entity-buttons-disable {
  opacity: 0.4;
  vertical-align: middle;
  margin-left: 2px;
}
.observations-entity-buttons-disable:hover {
  background-color: transparent;
}

ic-observations-copy .observations-copy .ic-selectable-table mat-header-row {
  justify-content: space-between;
}
ic-observations-copy .observations-copy .ic-selectable-table mat-header-row.ic-selectable-row {
  height: 40px !important;
  top: 66px !important;
}
ic-observations-copy .observations-copy .ic-selectable-table mat-header-row.ic-selectable-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-observations-copy .observations-copy .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0 0 0em;
}
ic-observations-copy .observations-copy .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type label {
  height: 24px;
}
ic-observations-copy .observations-copy .ic-selectable-table mat-header-row .mat-mdc-header-cell {
  min-width: min-content;
}
ic-observations-copy .observations-copy .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(2) {
  padding: 0 0 0 0.5em;
}
ic-observations-copy .observations-copy .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(3) {
  padding: 0 0 0 0.5em;
  min-width: 93px;
}
ic-observations-copy .observations-copy .ic-selectable-table mat-header-row .mat-mdc-header-cell:last-of-type {
  padding: 0 0 0 0.5em;
  min-width: min-content;
}
ic-observations-copy .observations-copy .ic-selectable-table mat-row {
  justify-content: space-between;
}
ic-observations-copy .observations-copy .ic-selectable-table mat-row .mat-mdc-cell:first-of-type {
  padding: 0 0.4em;
  min-width: min-content;
}
ic-observations-copy .observations-copy .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(2) {
  min-width: min-content;
}
ic-observations-copy .observations-copy .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-observations-copy .observations-copy .ic-selectable-table mat-row .mat-mdc-cell:last-of-type {
  min-width: min-content;
}
ic-observations-copy .observations-copy .ic-selectable-table mat-row .mdc-data-table__cell {
  margin-top: 0 !important;
}
ic-observations-copy .observations-copy .ic-selectable-table mat-row .mdc-data-table__cell > span {
  line-height: 16px;
  padding: 5px 0 5px 0;
}
ic-observations-copy .observations-copy ic-detail-table .mat-mdc-row .mdc-data-table__cell {
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
  min-width: 103px;
}
ic-observations-copy .observations-copy ic-detail-table .mat-header-row .mdc-data-table__header-cell,
ic-observations-copy .observations-copy ic-detail-table .mat-mdc-header-row .mdc-data-table__header-cell {
  font-size: 12px;
  font-weight: 600;
  min-width: 103px;
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
}
ic-observations-copy .observations-copy-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  max-height: 56px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px 0 4px;
  z-index: 999;
}
ic-observations-copy .observations-copy-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-observations-copy .observations-copy-toolbar .panel-title {
  flex: auto;
}
ic-observations-copy .observations-copy-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-observations-copy .observations-copy-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-observations-copy .observations-copy-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-observations-copy .observations-copy-toolbar .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-observations-copy .observations-copy-info {
  min-height: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
ic-observations-copy .observations-copy-info > mat-card {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2);
}
ic-observations-copy .observations-copy-info > mat-card > mat-card-content > .five-element-row {
  flex-direction: row;
  display: flex;
}
ic-observations-copy .observations-copy-info > mat-card > mat-card-content > .five-element-row > .five-element-row-impact-level {
  flex-basis: 27%;
  min-width: 27%;
}
ic-observations-copy .observations-copy-info > mat-card > mat-card-content > .five-element-row > .five-element-row-impact-level .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-observations-copy .observations-copy-info > mat-card > mat-card-content > .five-element-row > .five-element-row-observation-code {
  flex-basis: 17%;
  min-width: 17%;
}
ic-observations-copy .observations-copy-info > mat-card > mat-card-content > .five-element-row > .five-element-row-observation-code .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-observations-copy .observations-copy-info > mat-card > mat-card-content > .five-element-row > .five-element-row-category {
  flex-basis: 14%;
  min-width: 14%;
}
ic-observations-copy .observations-copy-info > mat-card > mat-card-content > .five-element-row > .five-element-row-category .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-observations-copy .observations-copy-info > mat-card > mat-card-content > .five-element-row > .five-element-row-visibility {
  flex-basis: 12%;
  min-width: 12%;
}
ic-observations-copy .observations-copy-info > mat-card > mat-card-content > .five-element-row > .five-element-row-visibility .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-observations-copy .observations-copy-info > mat-card > mat-card-content > .five-element-row > .five-element-row-scipion-user {
  flex-basis: 30%;
  min-width: 30%;
}
ic-observations-copy .observations-copy-info > mat-card > mat-card-content > .five-element-row > .five-element-row-scipion-user .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-observations-copy .observations-copy-info > mat-card > mat-card-content > .two-element-row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
}
ic-observations-copy .observations-copy-info > mat-card > mat-card-content > .two-element-row > .all-row-element {
  flex-basis: 100%;
  min-width: 100%;
  align-self: baseline;
}
ic-observations-copy .observations-copy-info > mat-card > mat-card-content > .two-element-row > .all-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-observations-copy .observations-copy-info > mat-card > mat-card-content > .two-element-row > .two-element-row-element {
  flex-basis: 50%;
  min-width: 50%;
  align-self: baseline;
}
ic-observations-copy .observations-copy-info > mat-card > mat-card-content > .two-element-row > .two-element-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-observations-copy .observations-copy-info > mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element {
  flex-basis: 40%;
  min-width: 40%;
  align-self: baseline;
}
ic-observations-copy .observations-copy-info > mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-observations-copy .observations-copy-info > mat-card > mat-card-content > .two-element-row > .two-element-row-big-element {
  flex-basis: 70%;
  min-width: 70%;
  align-self: baseline;
}
ic-observations-copy .observations-copy-info > mat-card > mat-card-content > .two-element-row > .two-element-row-big-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-observations-copy .observations-copy-info > mat-card > mat-card-content > .two-element-row > .two-element-row-small-element {
  flex-basis: 30%;
  min-width: 30%;
  align-self: baseline;
}
ic-observations-copy .observations-copy-info > mat-card > mat-card-content > .two-element-row > .two-element-row-small-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-observations-copy > .observations-copy-title {
  padding-top: 25px;
}

.dynamic-component-header-text {
  margin-left: 0px;
}

.ic-panel-input {
  margin-top: 0.5em;
}

.ic-slide-toggle {
  margin-bottom: 25px;
}

.row-one-element {
  width: 75%;
}

.add-entity {
  cursor: pointer;
  vertical-align: middle;
}

.add-entity-disabled {
  opacity: 0.4;
  vertical-align: middle;
}
.add-entity-disabled:hover {
  background-color: transparent;
}

.delete-entity {
  cursor: pointer;
  margin: 5px;
  padding: 0.9em 0;
  border-top: 0.8437;
}

.text-match {
  color: red;
}

.empty-wordings {
  color: #f05060;
}

.clean-inputs-button {
  float: right;
}

.buttons-bot {
  margin-top: -5px;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 15px;
  flex-basis: 25%;
}

ic-settings-prior-inspection-items-detail .prior-inspection-items-detail .button-back {
  margin-top: 10px;
  margin-left: 10px;
  height: min-content;
  width: -moz-fit-content;
  width: fit-content;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail .detail-info {
  margin: auto;
  max-width: 1000px;
  width: 100%;
  margin-bottom: 50px;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail .ic-selectable-table mat-header-row {
  justify-content: space-between;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail .ic-selectable-table mat-header-row.ic-selectable-row {
  height: 40px !important;
  top: 66px !important;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail .ic-selectable-table mat-header-row.ic-selectable-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0 0 0.5em;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type label {
  height: 24px;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail .ic-selectable-table mat-header-row .mat-mdc-header-cell {
  min-width: min-content;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(2) {
  padding: 0 0.5em 0 2em;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail .ic-selectable-table mat-header-row .mat-mdc-header-cell:last-of-type {
  min-width: min-content;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail .ic-selectable-table mat-row {
  justify-content: space-between;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail .ic-selectable-table mat-row .mat-mdc-cell:first-of-type {
  padding: 0 0.4em;
  min-width: min-content;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(2) {
  min-width: min-content;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail .ic-selectable-table mat-row .mat-mdc-cell:last-of-type {
  min-width: min-content;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail .ic-selectable-table mat-row .mdc-data-table__cell {
  margin-top: 0 !important;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail .ic-selectable-table mat-row .mdc-data-table__cell > span {
  line-height: 16px;
  padding: 5px 0 5px 0;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail ic-detail-table .mat-mdc-row .mdc-data-table__cell {
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
  min-width: 103px;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail ic-detail-table .mat-header-row .mdc-data-table__header-cell,
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail ic-detail-table .mat-mdc-header-row .mdc-data-table__header-cell {
  font-size: 12px;
  font-weight: 600;
  min-width: 103px;
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail .prior-inspection-items-detail-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  max-height: 56px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px 0 4px;
  z-index: 999;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail .prior-inspection-items-detail-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail .prior-inspection-items-detail-toolbar .panel-title {
  flex: auto;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail .prior-inspection-items-detail-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail .prior-inspection-items-detail-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail .prior-inspection-items-detail-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail .prior-inspection-items-detail-toolbar .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail .prior-inspection-items-detail-info {
  margin: auto;
  max-width: 1000px;
  width: 100%;
  margin-bottom: 50px;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail .prior-inspection-items-detail-info > div {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail .prior-inspection-items-detail-info .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail > .prior-inspection-items-detail-title {
  padding-top: 25px;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail mat-card {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2) !important;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail mat-card > mat-card-content > .five-element-row {
  flex-direction: row;
  display: flex;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail mat-card > mat-card-content > .five-element-row > .five-element-row-impact-level {
  flex-basis: 27%;
  min-width: 27%;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail mat-card > mat-card-content > .five-element-row > .five-element-row-impact-level .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail mat-card > mat-card-content > .five-element-row > .five-element-row-observation-code {
  flex-basis: 17%;
  min-width: 17%;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail mat-card > mat-card-content > .five-element-row > .five-element-row-observation-code .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail mat-card > mat-card-content > .five-element-row > .five-element-row-category {
  flex-basis: 14%;
  min-width: 14%;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail mat-card > mat-card-content > .five-element-row > .five-element-row-category .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail mat-card > mat-card-content > .five-element-row > .five-element-row-visibility {
  flex-basis: 12%;
  min-width: 12%;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail mat-card > mat-card-content > .five-element-row > .five-element-row-visibility .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail mat-card > mat-card-content > .five-element-row > .five-element-row-scipion-user {
  flex-basis: 30%;
  min-width: 30%;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail mat-card > mat-card-content > .five-element-row > .five-element-row-scipion-user .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail mat-card > mat-card-content > .two-element-row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: baseline;
  margin-bottom: 20px;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail mat-card > mat-card-content > .two-element-row > .all-row-element {
  flex-basis: 100%;
  min-width: 100%;
  align-self: baseline;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail mat-card > mat-card-content > .two-element-row > .all-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail mat-card > mat-card-content > .two-element-row > .two-element-row-element {
  flex-basis: 50%;
  min-width: 50%;
  align-self: baseline;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail mat-card > mat-card-content > .two-element-row > .two-element-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element {
  flex-basis: 40%;
  min-width: 40%;
  align-self: baseline;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail mat-card > mat-card-content > .two-element-row > .two-element-row-big-element {
  flex-basis: 70%;
  min-width: 70%;
  align-self: baseline;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail mat-card > mat-card-content > .two-element-row > .two-element-row-big-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail mat-card > mat-card-content > .two-element-row > .two-element-row-small-element {
  flex-basis: 30%;
  min-width: 30%;
  align-self: baseline;
}
ic-settings-prior-inspection-items-detail .prior-inspection-items-detail mat-card > mat-card-content > .two-element-row > .two-element-row-small-element .mat-mdc-input-element {
  letter-spacing: 0;
}

.dynamic-component-header-text {
  margin-left: 0px;
}

.ic-panel-input {
  margin-top: 0.5em;
}

.ic-slide-toggle {
  margin-bottom: 25px;
}

.row-one-element {
  width: 75%;
}

.add-entity {
  cursor: pointer;
  vertical-align: middle;
}

.delete-entity {
  cursor: pointer;
  margin: 5px;
  padding: 0.9em 0;
  border-top: 0.8437;
}

.text-match {
  color: red;
}

.empty-wordings {
  color: #f05060;
}

.clean-inputs-button {
  float: right;
}

.items-entity-buttons {
  cursor: pointer;
  vertical-align: middle;
  margin-left: 2px;
}

.items-entity-buttons-disable {
  opacity: 0.4;
  vertical-align: middle;
  margin-left: 2px;
}
.items-entity-buttons-disable:hover {
  background-color: transparent;
}

.edit-button {
  margin-left: auto;
}

ic-observations-manage-in-model .observations-manage-in-model .ic-selectable-table mat-header-row {
  justify-content: space-between;
}
ic-observations-manage-in-model .observations-manage-in-model .ic-selectable-table mat-header-row.ic-selectable-row {
  height: 40px !important;
  top: 66px !important;
}
ic-observations-manage-in-model .observations-manage-in-model .ic-selectable-table mat-header-row.ic-selectable-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-observations-manage-in-model .observations-manage-in-model .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0 0 0em;
}
ic-observations-manage-in-model .observations-manage-in-model .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type label {
  height: 24px;
}
ic-observations-manage-in-model .observations-manage-in-model .ic-selectable-table mat-header-row .mat-mdc-header-cell {
  min-width: min-content;
}
ic-observations-manage-in-model .observations-manage-in-model .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(2) {
  padding: 0 0 0 0.5em;
}
ic-observations-manage-in-model .observations-manage-in-model .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(3) {
  padding: 0 0 0 0.5em;
  min-width: 93px;
}
ic-observations-manage-in-model .observations-manage-in-model .ic-selectable-table mat-header-row .mat-mdc-header-cell:last-of-type {
  padding: 0 0 0 0.5em;
  min-width: min-content;
}
ic-observations-manage-in-model .observations-manage-in-model .ic-selectable-table mat-row {
  justify-content: space-between;
}
ic-observations-manage-in-model .observations-manage-in-model .ic-selectable-table mat-row .mat-mdc-cell:first-of-type {
  padding: 0 0.4em;
  min-width: min-content;
}
ic-observations-manage-in-model .observations-manage-in-model .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(2) {
  min-width: min-content;
}
ic-observations-manage-in-model .observations-manage-in-model .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-observations-manage-in-model .observations-manage-in-model .ic-selectable-table mat-row .mat-mdc-cell:last-of-type {
  min-width: min-content;
}
ic-observations-manage-in-model .observations-manage-in-model .ic-selectable-table mat-row .mdc-data-table__cell {
  margin-top: 0 !important;
}
ic-observations-manage-in-model .observations-manage-in-model .ic-selectable-table mat-row .mdc-data-table__cell > span {
  line-height: 16px;
  padding: 5px 0 5px 0;
}
ic-observations-manage-in-model .observations-manage-in-model ic-detail-table .mat-mdc-row .mdc-data-table__cell {
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
  min-width: 103px;
}
ic-observations-manage-in-model .observations-manage-in-model ic-detail-table .mat-header-row .mdc-data-table__header-cell,
ic-observations-manage-in-model .observations-manage-in-model ic-detail-table .mat-mdc-header-row .mdc-data-table__header-cell {
  font-size: 12px;
  font-weight: 600;
  min-width: 103px;
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
}
ic-observations-manage-in-model .observations-manage-in-model-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  max-height: 56px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px 0 4px;
  z-index: 999;
}
ic-observations-manage-in-model .observations-manage-in-model-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-observations-manage-in-model .observations-manage-in-model-toolbar .panel-title {
  flex: auto;
}
ic-observations-manage-in-model .observations-manage-in-model-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-observations-manage-in-model .observations-manage-in-model-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-observations-manage-in-model .observations-manage-in-model-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-observations-manage-in-model .observations-manage-in-model-toolbar .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-observations-manage-in-model .observations-manage-in-model-info {
  min-height: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
ic-observations-manage-in-model .observations-manage-in-model-info > mat-card {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2);
}
ic-observations-manage-in-model .observations-manage-in-model-info > mat-card > mat-card-content > .five-element-row {
  flex-direction: row;
  display: flex;
}
ic-observations-manage-in-model .observations-manage-in-model-info > mat-card > mat-card-content > .five-element-row > .five-element-row-impact-level {
  flex-basis: 27%;
  min-width: 27%;
}
ic-observations-manage-in-model .observations-manage-in-model-info > mat-card > mat-card-content > .five-element-row > .five-element-row-impact-level .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-observations-manage-in-model .observations-manage-in-model-info > mat-card > mat-card-content > .five-element-row > .five-element-row-observation-code {
  flex-basis: 17%;
  min-width: 17%;
}
ic-observations-manage-in-model .observations-manage-in-model-info > mat-card > mat-card-content > .five-element-row > .five-element-row-observation-code .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-observations-manage-in-model .observations-manage-in-model-info > mat-card > mat-card-content > .five-element-row > .five-element-row-category {
  flex-basis: 14%;
  min-width: 14%;
}
ic-observations-manage-in-model .observations-manage-in-model-info > mat-card > mat-card-content > .five-element-row > .five-element-row-category .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-observations-manage-in-model .observations-manage-in-model-info > mat-card > mat-card-content > .five-element-row > .five-element-row-visibility {
  flex-basis: 12%;
  min-width: 12%;
}
ic-observations-manage-in-model .observations-manage-in-model-info > mat-card > mat-card-content > .five-element-row > .five-element-row-visibility .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-observations-manage-in-model .observations-manage-in-model-info > mat-card > mat-card-content > .five-element-row > .five-element-row-scipion-user {
  flex-basis: 30%;
  min-width: 30%;
}
ic-observations-manage-in-model .observations-manage-in-model-info > mat-card > mat-card-content > .five-element-row > .five-element-row-scipion-user .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-observations-manage-in-model .observations-manage-in-model-info > mat-card > mat-card-content > .two-element-row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
}
ic-observations-manage-in-model .observations-manage-in-model-info > mat-card > mat-card-content > .two-element-row > .all-row-element {
  flex-basis: 100%;
  min-width: 100%;
  align-self: baseline;
}
ic-observations-manage-in-model .observations-manage-in-model-info > mat-card > mat-card-content > .two-element-row > .all-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-observations-manage-in-model .observations-manage-in-model-info > mat-card > mat-card-content > .two-element-row > .two-element-row-element {
  flex-basis: 50%;
  min-width: 50%;
  align-self: baseline;
}
ic-observations-manage-in-model .observations-manage-in-model-info > mat-card > mat-card-content > .two-element-row > .two-element-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-observations-manage-in-model .observations-manage-in-model-info > mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element {
  flex-basis: 40%;
  min-width: 40%;
  align-self: baseline;
}
ic-observations-manage-in-model .observations-manage-in-model-info > mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-observations-manage-in-model .observations-manage-in-model-info > mat-card > mat-card-content > .two-element-row > .two-element-row-big-element {
  flex-basis: 70%;
  min-width: 70%;
  align-self: baseline;
}
ic-observations-manage-in-model .observations-manage-in-model-info > mat-card > mat-card-content > .two-element-row > .two-element-row-big-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-observations-manage-in-model .observations-manage-in-model-info > mat-card > mat-card-content > .two-element-row > .two-element-row-small-element {
  flex-basis: 30%;
  min-width: 30%;
  align-self: baseline;
}
ic-observations-manage-in-model .observations-manage-in-model-info > mat-card > mat-card-content > .two-element-row > .two-element-row-small-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-observations-manage-in-model .observations-manage-in-model-info .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-observations-manage-in-model > .observations-manage-in-model-title {
  padding-top: 25px;
}

.edit-button {
  margin-left: auto;
}

.dynamic-component-header-text {
  margin-left: 0px;
}

.ic-panel-input {
  margin-top: 0.5em;
}

.ic-slide-toggle {
  margin-bottom: 25px;
}

.row-one-element {
  width: 75%;
}

.add-entity {
  cursor: pointer;
  vertical-align: middle;
}

.add-entity-disabled {
  opacity: 0.4;
  vertical-align: middle;
}
.add-entity-disabled:hover {
  background-color: transparent;
}

.delete-entity {
  cursor: pointer;
  margin: 5px;
  padding: 0.9em 0;
  border-top: 0.8437;
}

.text-match {
  color: red;
}

.empty-wordings {
  color: #f05060;
}

.clean-inputs-button {
  float: right;
}

.buttons-bot {
  margin-top: -5px;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 15px;
  flex-basis: 25%;
}

ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel .ic-selectable-table mat-header-row {
  justify-content: space-between;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel .ic-selectable-table mat-header-row.ic-selectable-row {
  height: 40px !important;
  top: 66px !important;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel .ic-selectable-table mat-header-row.ic-selectable-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0 0 1.2em;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type label {
  height: 24px;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell {
  min-width: min-content;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(2) {
  padding: 0 0.5em 0 2em;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel .ic-selectable-table mat-header-row .mat-mdc-header-cell:last-of-type {
  min-width: min-content;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel .ic-selectable-table mat-row {
  justify-content: space-between;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel .ic-selectable-table mat-row .mat-mdc-cell:first-of-type {
  padding: 0 0.4em;
  min-width: min-content;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(2) {
  min-width: min-content;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel .ic-selectable-table mat-row .mat-mdc-cell:last-of-type {
  min-width: min-content;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel .ic-selectable-table mat-row .mdc-data-table__cell {
  margin-top: 0 !important;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel .ic-selectable-table mat-row .mdc-data-table__cell > span {
  line-height: 16px;
  padding: 5px 0 5px 0;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel ic-detail-table .mat-mdc-row .mdc-data-table__cell {
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
  min-width: 103px;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel ic-detail-table .mat-header-row .mdc-data-table__header-cell,
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel ic-detail-table .mat-mdc-header-row .mdc-data-table__header-cell {
  font-size: 12px;
  font-weight: 600;
  min-width: 103px;
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  max-height: 56px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px 0 4px;
  z-index: 999;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel-toolbar .panel-title {
  flex: auto;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel-toolbar .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel-info {
  min-height: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel-info > mat-card {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2);
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel-info > mat-card > mat-card-content > .five-element-row {
  flex-direction: row;
  display: flex;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel-info > mat-card > mat-card-content > .five-element-row > .five-element-row-code {
  flex-basis: 17%;
  min-width: 17%;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel-info > mat-card > mat-card-content > .five-element-row > .five-element-row-code .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel-info > mat-card > mat-card-content > .five-element-row > .five-element-row-type {
  flex-basis: 30%;
  min-width: 30%;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel-info > mat-card > mat-card-content > .five-element-row > .five-element-row-type .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel-info > mat-card > mat-card-content > .two-element-row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel-info > mat-card > mat-card-content > .two-element-row > .all-row-element {
  flex-basis: 100%;
  min-width: 100%;
  align-self: baseline;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel-info > mat-card > mat-card-content > .two-element-row > .all-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-element {
  flex-basis: 50%;
  min-width: 50%;
  align-self: baseline;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element {
  flex-basis: 40%;
  min-width: 40%;
  align-self: baseline;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-big-element {
  flex-basis: 70%;
  min-width: 70%;
  align-self: baseline;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-big-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-small-element {
  flex-basis: 30%;
  min-width: 30%;
  align-self: baseline;
}
ic-category-administrative-file-types-panel .prior-inspection-category-afts-panel-info > mat-card > mat-card-content > .two-element-row > .two-element-row-small-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-category-administrative-file-types-panel > .prior-inspection-category-afts-panel-title {
  padding-top: 25px;
}

.dynamic-component-header-text {
  margin-left: 0px;
}

.ic-panel-input {
  margin-top: 0.5em;
}

.ic-slide-toggle {
  margin-bottom: 25px;
}

.row-one-element {
  width: 75%;
}

.add-entity {
  cursor: pointer;
  vertical-align: middle;
}

.add-entity-disabled {
  opacity: 0.4;
  vertical-align: middle;
}
.add-entity-disabled:hover {
  background-color: transparent;
}

.delete-entity {
  cursor: pointer;
  margin: 5px;
  padding: 0.9em 0;
  border-top: 0.8437;
}

.text-match {
  color: red;
}

.empty-wordings {
  color: #f05060;
}

.clean-inputs-button {
  float: right;
}

.buttons-bot {
  margin-top: -5px;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 15px;
  flex-basis: 25%;
}

ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model .ic-selectable-table mat-header-row {
  justify-content: space-between;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model .ic-selectable-table mat-header-row.ic-selectable-row {
  height: 40px !important;
  top: 66px !important;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model .ic-selectable-table mat-header-row.ic-selectable-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0 0 1.2em;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type label {
  height: 24px;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model .ic-selectable-table mat-header-row .mat-mdc-header-cell {
  min-width: min-content;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(2) {
  padding: 0 0.5em 0 2em;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model .ic-selectable-table mat-header-row .mat-mdc-header-cell:last-of-type {
  min-width: min-content;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model .ic-selectable-table mat-row {
  justify-content: space-between;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model .ic-selectable-table mat-row .mat-mdc-cell:first-of-type {
  padding: 0 0.4em;
  min-width: min-content;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(2) {
  min-width: min-content;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model .ic-selectable-table mat-row .mat-mdc-cell:last-of-type {
  min-width: min-content;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model .ic-selectable-table mat-row .mdc-data-table__cell {
  margin-top: 0 !important;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model .ic-selectable-table mat-row .mdc-data-table__cell > span {
  line-height: 16px;
  padding: 5px 0 5px 0;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model ic-detail-table .mat-mdc-row .mdc-data-table__cell {
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
  min-width: 103px;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model ic-detail-table .mat-header-row .mdc-data-table__header-cell,
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model ic-detail-table .mat-mdc-header-row .mdc-data-table__header-cell {
  font-size: 12px;
  font-weight: 600;
  min-width: 103px;
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  max-height: 56px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px 0 4px;
  z-index: 999;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model-toolbar .panel-title {
  flex: auto;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model-toolbar .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model-info {
  min-height: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model-info > mat-card {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2);
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model-info > mat-card > mat-card-content > .five-element-row {
  flex-direction: row;
  display: flex;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model-info > mat-card > mat-card-content > .five-element-row > .five-element-row-code {
  flex-basis: 17%;
  min-width: 17%;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model-info > mat-card > mat-card-content > .five-element-row > .five-element-row-code .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model-info > mat-card > mat-card-content > .five-element-row > .five-element-row-type {
  flex-basis: 17%;
  min-width: 17%;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model-info > mat-card > mat-card-content > .five-element-row > .five-element-row-type .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model-info > mat-card > mat-card-content > .five-element-row > .five-element-row-parent {
  flex-basis: 40%;
  min-width: 40%;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model-info > mat-card > mat-card-content > .five-element-row > .five-element-row-parent .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model-info > mat-card > mat-card-content > .five-element-row > .five-element-row-legal-base {
  flex-basis: 25%;
  min-width: 25%;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model-info > mat-card > mat-card-content > .five-element-row > .five-element-row-legal-base .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model-info > mat-card > mat-card-content > .two-element-row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model-info > mat-card > mat-card-content > .two-element-row > .all-row-element {
  flex-basis: 100%;
  min-width: 100%;
  align-self: baseline;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model-info > mat-card > mat-card-content > .two-element-row > .all-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model-info > mat-card > mat-card-content > .two-element-row > .two-element-row-element {
  flex-basis: 50%;
  min-width: 50%;
  align-self: baseline;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model-info > mat-card > mat-card-content > .two-element-row > .two-element-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model-info > mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element {
  flex-basis: 40%;
  min-width: 40%;
  align-self: baseline;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model-info > mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model-info > mat-card > mat-card-content > .two-element-row > .two-element-row-big-element {
  flex-basis: 70%;
  min-width: 70%;
  align-self: baseline;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model-info > mat-card > mat-card-content > .two-element-row > .two-element-row-big-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model-info > mat-card > mat-card-content > .two-element-row > .two-element-row-small-element {
  flex-basis: 30%;
  min-width: 30%;
  align-self: baseline;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model-info > mat-card > mat-card-content > .two-element-row > .two-element-row-small-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-category-afts-manage-in-model .prior-inspection-category-afts-manage-in-model-info .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-category-afts-manage-in-model > .prior-inspection-category-afts-manage-in-model-title {
  padding-top: 25px;
}

.dynamic-component-header-text {
  margin-left: 0px;
}

.ic-panel-input {
  margin-top: 0.5em;
}

.ic-slide-toggle {
  margin-bottom: 25px;
}

.row-one-element {
  width: 75%;
}

.add-entity {
  cursor: pointer;
  vertical-align: middle;
}

.add-entity-disabled {
  opacity: 0.4;
  vertical-align: middle;
}
.add-entity-disabled:hover {
  background-color: transparent;
}

.delete-entity {
  cursor: pointer;
  margin: 5px;
  padding: 0.9em 0;
  border-top: 0.8437;
}

.text-match {
  color: red;
}

.empty-wordings {
  color: #f05060;
}

.clean-inputs-button {
  float: right;
}

.buttons-bot {
  margin-top: -5px;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 15px;
  flex-basis: 25%;
}

.edit-button {
  margin-left: auto;
}

ic-category-afts-detail .prior-inspection-category-afts-detail .button-back {
  margin-top: 10px;
  margin-left: 10px;
  height: min-content;
  width: -moz-fit-content;
  width: fit-content;
}
ic-category-afts-detail .prior-inspection-category-afts-detail .detail-info {
  margin: auto;
  max-width: 1000px;
  width: 100%;
  margin-bottom: 50px;
}
ic-category-afts-detail .prior-inspection-category-afts-detail .ic-selectable-table mat-header-row {
  justify-content: space-between;
}
ic-category-afts-detail .prior-inspection-category-afts-detail .ic-selectable-table mat-header-row.ic-selectable-row {
  height: 40px !important;
  top: 66px !important;
}
ic-category-afts-detail .prior-inspection-category-afts-detail .ic-selectable-table mat-header-row.ic-selectable-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-category-afts-detail .prior-inspection-category-afts-detail .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0 0 1.2em;
}
ic-category-afts-detail .prior-inspection-category-afts-detail .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type label {
  height: 24px;
}
ic-category-afts-detail .prior-inspection-category-afts-detail .ic-selectable-table mat-header-row .mat-mdc-header-cell {
  min-width: min-content;
}
ic-category-afts-detail .prior-inspection-category-afts-detail .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(2) {
  padding: 0 0.5em 0 2em;
}
ic-category-afts-detail .prior-inspection-category-afts-detail .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-category-afts-detail .prior-inspection-category-afts-detail .ic-selectable-table mat-header-row .mat-mdc-header-cell:last-of-type {
  min-width: min-content;
}
ic-category-afts-detail .prior-inspection-category-afts-detail .ic-selectable-table mat-row {
  justify-content: space-between;
}
ic-category-afts-detail .prior-inspection-category-afts-detail .ic-selectable-table mat-row .mat-mdc-cell:first-of-type {
  padding: 0 0.4em;
  min-width: min-content;
}
ic-category-afts-detail .prior-inspection-category-afts-detail .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(2) {
  min-width: min-content;
}
ic-category-afts-detail .prior-inspection-category-afts-detail .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-category-afts-detail .prior-inspection-category-afts-detail .ic-selectable-table mat-row .mat-mdc-cell:last-of-type {
  min-width: min-content;
}
ic-category-afts-detail .prior-inspection-category-afts-detail .ic-selectable-table mat-row .mdc-data-table__cell {
  margin-top: 0 !important;
}
ic-category-afts-detail .prior-inspection-category-afts-detail .ic-selectable-table mat-row .mdc-data-table__cell > span {
  line-height: 16px;
  padding: 5px 0 5px 0;
}
ic-category-afts-detail .prior-inspection-category-afts-detail ic-detail-table .mat-mdc-row .mdc-data-table__cell {
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
  min-width: 103px;
}
ic-category-afts-detail .prior-inspection-category-afts-detail ic-detail-table .mat-header-row .mdc-data-table__header-cell,
ic-category-afts-detail .prior-inspection-category-afts-detail ic-detail-table .mat-mdc-header-row .mdc-data-table__header-cell {
  font-size: 12px;
  font-weight: 600;
  min-width: 103px;
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
}
ic-category-afts-detail .prior-inspection-category-afts-detail-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  max-height: 56px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px 0 4px;
  z-index: 999;
}
ic-category-afts-detail .prior-inspection-category-afts-detail-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-category-afts-detail .prior-inspection-category-afts-detail-toolbar .panel-title {
  flex: auto;
}
ic-category-afts-detail .prior-inspection-category-afts-detail-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-category-afts-detail .prior-inspection-category-afts-detail-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-category-afts-detail .prior-inspection-category-afts-detail-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-category-afts-detail .prior-inspection-category-afts-detail-toolbar .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-category-afts-detail .prior-inspection-category-afts-detail-info {
  margin: auto;
  max-width: 1000px;
  width: 100%;
  margin-bottom: 50px;
}
ic-category-afts-detail .prior-inspection-category-afts-detail-info > div {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
ic-category-afts-detail .prior-inspection-category-afts-detail-info .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-category-afts-detail > .prior-inspection-category-afts-detail-title {
  padding-top: 25px;
}
ic-category-afts-detail mat-card {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2) !important;
}
ic-category-afts-detail mat-card > mat-card-content > .five-element-row {
  flex-direction: row;
  display: flex;
}
ic-category-afts-detail mat-card > mat-card-content > .five-element-row > .five-element-row-code {
  flex-basis: 17%;
  min-width: 17%;
}
ic-category-afts-detail mat-card > mat-card-content > .five-element-row > .five-element-row-code .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-category-afts-detail mat-card > mat-card-content > .five-element-row > .five-element-row-type {
  flex-basis: 17%;
  min-width: 17%;
}
ic-category-afts-detail mat-card > mat-card-content > .five-element-row > .five-element-row-type .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-category-afts-detail mat-card > mat-card-content > .five-element-row > .five-element-row-parent {
  flex-basis: 40%;
  min-width: 40%;
}
ic-category-afts-detail mat-card > mat-card-content > .five-element-row > .five-element-row-parent .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-category-afts-detail mat-card > mat-card-content > .five-element-row > .five-element-row-legal-base {
  flex-basis: 25%;
  min-width: 25%;
}
ic-category-afts-detail mat-card > mat-card-content > .five-element-row > .five-element-row-legal-base .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-category-afts-detail mat-card > mat-card-content > .two-element-row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
}
ic-category-afts-detail mat-card > mat-card-content > .two-element-row > .all-row-element {
  flex-basis: 100%;
  min-width: 100%;
  align-self: baseline;
}
ic-category-afts-detail mat-card > mat-card-content > .two-element-row > .all-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-category-afts-detail mat-card > mat-card-content > .two-element-row > .two-element-row-element {
  flex-basis: 50%;
  min-width: 50%;
  align-self: baseline;
}
ic-category-afts-detail mat-card > mat-card-content > .two-element-row > .two-element-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-category-afts-detail mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element {
  flex-basis: 40%;
  min-width: 40%;
  align-self: baseline;
}
ic-category-afts-detail mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-category-afts-detail mat-card > mat-card-content > .two-element-row > .two-element-row-big-element {
  flex-basis: 70%;
  min-width: 70%;
  align-self: baseline;
}
ic-category-afts-detail mat-card > mat-card-content > .two-element-row > .two-element-row-big-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-category-afts-detail mat-card > mat-card-content > .two-element-row > .two-element-row-small-element {
  flex-basis: 30%;
  min-width: 30%;
  align-self: baseline;
}
ic-category-afts-detail mat-card > mat-card-content > .two-element-row > .two-element-row-small-element .mat-mdc-input-element {
  letter-spacing: 0;
}

.dynamic-component-header-text {
  margin-left: 0px;
}

.ic-panel-input {
  margin-top: 0.5em;
}

.ic-slide-toggle {
  margin-bottom: 25px;
}

.row-one-element {
  width: 75%;
}

.add-entity {
  cursor: pointer;
  vertical-align: middle;
}

.add-entity-disabled {
  opacity: 0.4;
  vertical-align: middle;
}
.add-entity-disabled:hover {
  background-color: transparent;
}

.delete-entity {
  cursor: pointer;
  margin: 5px;
  padding: 0.9em 0;
  border-top: 0.8437;
}

.text-match {
  color: red;
}

.empty-wordings {
  color: #f05060;
}

.clean-inputs-button {
  float: right;
}

.buttons-bot {
  margin-top: -5px;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 15px;
  flex-basis: 25%;
}

.edit-button {
  margin-left: auto;
}

ic-category-afts-copy .prior-inspection-category-afts-copy .ic-selectable-table mat-header-row {
  justify-content: space-between;
}
ic-category-afts-copy .prior-inspection-category-afts-copy .ic-selectable-table mat-header-row.ic-selectable-row {
  height: 40px !important;
  top: 66px !important;
}
ic-category-afts-copy .prior-inspection-category-afts-copy .ic-selectable-table mat-header-row.ic-selectable-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-category-afts-copy .prior-inspection-category-afts-copy .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0 0 1.2em;
}
ic-category-afts-copy .prior-inspection-category-afts-copy .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type label {
  height: 24px;
}
ic-category-afts-copy .prior-inspection-category-afts-copy .ic-selectable-table mat-header-row .mat-mdc-header-cell {
  min-width: min-content;
}
ic-category-afts-copy .prior-inspection-category-afts-copy .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(2) {
  padding: 0 0.5em 0 2em;
}
ic-category-afts-copy .prior-inspection-category-afts-copy .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-category-afts-copy .prior-inspection-category-afts-copy .ic-selectable-table mat-header-row .mat-mdc-header-cell:last-of-type {
  min-width: min-content;
}
ic-category-afts-copy .prior-inspection-category-afts-copy .ic-selectable-table mat-row {
  justify-content: space-between;
}
ic-category-afts-copy .prior-inspection-category-afts-copy .ic-selectable-table mat-row .mat-mdc-cell:first-of-type {
  padding: 0 0.4em;
  min-width: min-content;
}
ic-category-afts-copy .prior-inspection-category-afts-copy .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(2) {
  min-width: min-content;
}
ic-category-afts-copy .prior-inspection-category-afts-copy .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-category-afts-copy .prior-inspection-category-afts-copy .ic-selectable-table mat-row .mat-mdc-cell:last-of-type {
  min-width: min-content;
}
ic-category-afts-copy .prior-inspection-category-afts-copy .ic-selectable-table mat-row .mdc-data-table__cell {
  margin-top: 0 !important;
}
ic-category-afts-copy .prior-inspection-category-afts-copy .ic-selectable-table mat-row .mdc-data-table__cell > span {
  line-height: 16px;
  padding: 5px 0 5px 0;
}
ic-category-afts-copy .prior-inspection-category-afts-copy ic-detail-table .mat-mdc-row .mdc-data-table__cell {
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
  min-width: 103px;
}
ic-category-afts-copy .prior-inspection-category-afts-copy ic-detail-table .mat-header-row .mdc-data-table__header-cell,
ic-category-afts-copy .prior-inspection-category-afts-copy ic-detail-table .mat-mdc-header-row .mdc-data-table__header-cell {
  font-size: 12px;
  font-weight: 600;
  min-width: 103px;
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
}
ic-category-afts-copy .prior-inspection-category-afts-copy-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  max-height: 56px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px 0 4px;
  z-index: 999;
}
ic-category-afts-copy .prior-inspection-category-afts-copy-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-category-afts-copy .prior-inspection-category-afts-copy-toolbar .panel-title {
  flex: auto;
}
ic-category-afts-copy .prior-inspection-category-afts-copy-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-category-afts-copy .prior-inspection-category-afts-copy-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-category-afts-copy .prior-inspection-category-afts-copy-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-category-afts-copy .prior-inspection-category-afts-copy-toolbar .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-category-afts-copy .prior-inspection-category-afts-copy-info {
  min-height: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
ic-category-afts-copy .prior-inspection-category-afts-copy-info > mat-card {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2);
}
ic-category-afts-copy .prior-inspection-category-afts-copy-info > mat-card > mat-card-content > .five-element-row {
  flex-direction: row;
  display: flex;
}
ic-category-afts-copy .prior-inspection-category-afts-copy-info > mat-card > mat-card-content > .five-element-row > .five-element-row-code {
  flex-basis: 17%;
  min-width: 17%;
}
ic-category-afts-copy .prior-inspection-category-afts-copy-info > mat-card > mat-card-content > .five-element-row > .five-element-row-code .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-category-afts-copy .prior-inspection-category-afts-copy-info > mat-card > mat-card-content > .five-element-row > .five-element-row-type {
  flex-basis: 17%;
  min-width: 17%;
}
ic-category-afts-copy .prior-inspection-category-afts-copy-info > mat-card > mat-card-content > .five-element-row > .five-element-row-type .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-category-afts-copy .prior-inspection-category-afts-copy-info > mat-card > mat-card-content > .five-element-row > .five-element-row-parent {
  flex-basis: 40%;
  min-width: 40%;
}
ic-category-afts-copy .prior-inspection-category-afts-copy-info > mat-card > mat-card-content > .five-element-row > .five-element-row-parent .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-category-afts-copy .prior-inspection-category-afts-copy-info > mat-card > mat-card-content > .five-element-row > .five-element-row-legal-base {
  flex-basis: 25%;
  min-width: 25%;
}
ic-category-afts-copy .prior-inspection-category-afts-copy-info > mat-card > mat-card-content > .five-element-row > .five-element-row-legal-base .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-category-afts-copy .prior-inspection-category-afts-copy-info > mat-card > mat-card-content > .two-element-row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
}
ic-category-afts-copy .prior-inspection-category-afts-copy-info > mat-card > mat-card-content > .two-element-row > .all-row-element {
  flex-basis: 100%;
  min-width: 100%;
  align-self: baseline;
}
ic-category-afts-copy .prior-inspection-category-afts-copy-info > mat-card > mat-card-content > .two-element-row > .all-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-category-afts-copy .prior-inspection-category-afts-copy-info > mat-card > mat-card-content > .two-element-row > .two-element-row-element {
  flex-basis: 50%;
  min-width: 50%;
  align-self: baseline;
}
ic-category-afts-copy .prior-inspection-category-afts-copy-info > mat-card > mat-card-content > .two-element-row > .two-element-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-category-afts-copy .prior-inspection-category-afts-copy-info > mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element {
  flex-basis: 40%;
  min-width: 40%;
  align-self: baseline;
}
ic-category-afts-copy .prior-inspection-category-afts-copy-info > mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-category-afts-copy .prior-inspection-category-afts-copy-info > mat-card > mat-card-content > .two-element-row > .two-element-row-big-element {
  flex-basis: 70%;
  min-width: 70%;
  align-self: baseline;
}
ic-category-afts-copy .prior-inspection-category-afts-copy-info > mat-card > mat-card-content > .two-element-row > .two-element-row-big-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-category-afts-copy .prior-inspection-category-afts-copy-info > mat-card > mat-card-content > .two-element-row > .two-element-row-small-element {
  flex-basis: 30%;
  min-width: 30%;
  align-self: baseline;
}
ic-category-afts-copy .prior-inspection-category-afts-copy-info > mat-card > mat-card-content > .two-element-row > .two-element-row-small-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-category-afts-copy .prior-inspection-category-afts-copy-info .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-category-afts-copy > .prior-inspection-category-afts-copy-title {
  padding-top: 25px;
}

.dynamic-component-header-text {
  margin-left: 0px;
}

.ic-panel-input {
  margin-top: 0.5em;
}

.ic-slide-toggle {
  margin-bottom: 25px;
}

.row-one-element {
  width: 75%;
}

.add-entity {
  cursor: pointer;
  vertical-align: middle;
}

.add-entity-disabled {
  opacity: 0.4;
  vertical-align: middle;
}
.add-entity-disabled:hover {
  background-color: transparent;
}

.delete-entity {
  cursor: pointer;
  margin: 5px;
  padding: 0.9em 0;
  border-top: 0.8437;
}

.text-match {
  color: red;
}

.empty-wordings {
  color: #f05060;
}

.clean-inputs-button {
  float: right;
}

.buttons-bot {
  margin-top: -5px;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 15px;
  flex-basis: 25%;
}

.edit-button {
  margin-left: auto;
}

ic-items-manage-in-model .items-manage-in-model .ic-selectable-table mat-header-row {
  justify-content: space-between;
}
ic-items-manage-in-model .items-manage-in-model .ic-selectable-table mat-header-row.ic-selectable-row {
  height: 40px !important;
  top: 66px !important;
}
ic-items-manage-in-model .items-manage-in-model .ic-selectable-table mat-header-row.ic-selectable-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-items-manage-in-model .items-manage-in-model .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0 0 0em;
}
ic-items-manage-in-model .items-manage-in-model .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type label {
  height: 24px;
}
ic-items-manage-in-model .items-manage-in-model .ic-selectable-table mat-header-row .mat-mdc-header-cell {
  min-width: min-content;
}
ic-items-manage-in-model .items-manage-in-model .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(2) {
  padding: 0 0 0 0.5em;
}
ic-items-manage-in-model .items-manage-in-model .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(3) {
  padding: 0 0 0 0.5em;
  min-width: 93px;
}
ic-items-manage-in-model .items-manage-in-model .ic-selectable-table mat-header-row .mat-mdc-header-cell:last-of-type {
  padding: 0 0 0 0.5em;
  min-width: min-content;
}
ic-items-manage-in-model .items-manage-in-model .ic-selectable-table mat-row {
  justify-content: space-between;
}
ic-items-manage-in-model .items-manage-in-model .ic-selectable-table mat-row .mat-mdc-cell:first-of-type {
  padding: 0 0.4em;
  min-width: min-content;
}
ic-items-manage-in-model .items-manage-in-model .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(2) {
  min-width: min-content;
}
ic-items-manage-in-model .items-manage-in-model .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-items-manage-in-model .items-manage-in-model .ic-selectable-table mat-row .mat-mdc-cell:last-of-type {
  min-width: min-content;
}
ic-items-manage-in-model .items-manage-in-model .ic-selectable-table mat-row .mdc-data-table__cell {
  margin-top: 0 !important;
}
ic-items-manage-in-model .items-manage-in-model .ic-selectable-table mat-row .mdc-data-table__cell > span {
  line-height: 16px;
  padding: 5px 0 5px 0;
}
ic-items-manage-in-model .items-manage-in-model ic-detail-table .mat-mdc-row .mdc-data-table__cell {
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
  min-width: 103px;
}
ic-items-manage-in-model .items-manage-in-model ic-detail-table .mat-header-row .mdc-data-table__header-cell,
ic-items-manage-in-model .items-manage-in-model ic-detail-table .mat-mdc-header-row .mdc-data-table__header-cell {
  font-size: 12px;
  font-weight: 600;
  min-width: 103px;
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
}
ic-items-manage-in-model .items-manage-in-model-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  max-height: 56px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px 0 4px;
  z-index: 999;
}
ic-items-manage-in-model .items-manage-in-model-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-items-manage-in-model .items-manage-in-model-toolbar .panel-title {
  flex: auto;
}
ic-items-manage-in-model .items-manage-in-model-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-items-manage-in-model .items-manage-in-model-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-items-manage-in-model .items-manage-in-model-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-items-manage-in-model .items-manage-in-model-toolbar .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-items-manage-in-model .items-manage-in-model-info {
  min-height: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
ic-items-manage-in-model .items-manage-in-model-info > mat-card {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2);
}
ic-items-manage-in-model .items-manage-in-model-info > mat-card > mat-card-content > .five-element-row {
  flex-direction: row;
  display: flex;
}
ic-items-manage-in-model .items-manage-in-model-info > mat-card > mat-card-content > .five-element-row > .five-element-row-impact-level {
  flex-basis: 27%;
  min-width: 27%;
}
ic-items-manage-in-model .items-manage-in-model-info > mat-card > mat-card-content > .five-element-row > .five-element-row-impact-level .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-items-manage-in-model .items-manage-in-model-info > mat-card > mat-card-content > .five-element-row > .five-element-row-observation-code {
  flex-basis: 17%;
  min-width: 17%;
}
ic-items-manage-in-model .items-manage-in-model-info > mat-card > mat-card-content > .five-element-row > .five-element-row-observation-code .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-items-manage-in-model .items-manage-in-model-info > mat-card > mat-card-content > .five-element-row > .five-element-row-category {
  flex-basis: 14%;
  min-width: 14%;
}
ic-items-manage-in-model .items-manage-in-model-info > mat-card > mat-card-content > .five-element-row > .five-element-row-category .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-items-manage-in-model .items-manage-in-model-info > mat-card > mat-card-content > .five-element-row > .five-element-row-visibility {
  flex-basis: 12%;
  min-width: 12%;
}
ic-items-manage-in-model .items-manage-in-model-info > mat-card > mat-card-content > .five-element-row > .five-element-row-visibility .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-items-manage-in-model .items-manage-in-model-info > mat-card > mat-card-content > .five-element-row > .five-element-row-scipion-user {
  flex-basis: 30%;
  min-width: 30%;
}
ic-items-manage-in-model .items-manage-in-model-info > mat-card > mat-card-content > .five-element-row > .five-element-row-scipion-user .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-items-manage-in-model .items-manage-in-model-info > mat-card > mat-card-content > .two-element-row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
}
ic-items-manage-in-model .items-manage-in-model-info > mat-card > mat-card-content > .two-element-row > .all-row-element {
  flex-basis: 100%;
  min-width: 100%;
  align-self: baseline;
}
ic-items-manage-in-model .items-manage-in-model-info > mat-card > mat-card-content > .two-element-row > .all-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-items-manage-in-model .items-manage-in-model-info > mat-card > mat-card-content > .two-element-row > .two-element-row-element {
  flex-basis: 50%;
  min-width: 50%;
  align-self: baseline;
}
ic-items-manage-in-model .items-manage-in-model-info > mat-card > mat-card-content > .two-element-row > .two-element-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-items-manage-in-model .items-manage-in-model-info > mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element {
  flex-basis: 40%;
  min-width: 40%;
  align-self: baseline;
}
ic-items-manage-in-model .items-manage-in-model-info > mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-items-manage-in-model .items-manage-in-model-info > mat-card > mat-card-content > .two-element-row > .two-element-row-big-element {
  flex-basis: 70%;
  min-width: 70%;
  align-self: baseline;
}
ic-items-manage-in-model .items-manage-in-model-info > mat-card > mat-card-content > .two-element-row > .two-element-row-big-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-items-manage-in-model .items-manage-in-model-info > mat-card > mat-card-content > .two-element-row > .two-element-row-small-element {
  flex-basis: 30%;
  min-width: 30%;
  align-self: baseline;
}
ic-items-manage-in-model .items-manage-in-model-info > mat-card > mat-card-content > .two-element-row > .two-element-row-small-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-items-manage-in-model .items-manage-in-model-info .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-items-manage-in-model > .items-manage-in-model-title {
  padding-top: 25px;
}

.edit-button {
  margin-left: auto;
}

.dynamic-component-header-text {
  margin-left: 0px;
}

.ic-panel-input {
  margin-top: 0.5em;
}

.ic-slide-toggle {
  margin-bottom: 25px;
}

.row-one-element {
  width: 75%;
}

.add-entity {
  cursor: pointer;
  vertical-align: middle;
}

.add-entity-disabled {
  opacity: 0.4;
  vertical-align: middle;
}
.add-entity-disabled:hover {
  background-color: transparent;
}

.delete-entity {
  cursor: pointer;
  margin: 5px;
  padding: 0.9em 0;
  border-top: 0.8437;
}

.text-match {
  color: red;
}

.empty-wordings {
  color: #f05060;
}

.clean-inputs-button {
  float: right;
}

.buttons-bot {
  margin-top: -5px;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 15px;
  flex-basis: 25%;
}

ic-items-reuse .items-reuse .ic-selectable-table mat-header-row {
  justify-content: space-between;
}
ic-items-reuse .items-reuse .ic-selectable-table mat-header-row.ic-selectable-row {
  height: 40px !important;
  top: 66px !important;
}
ic-items-reuse .items-reuse .ic-selectable-table mat-header-row.ic-selectable-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-items-reuse .items-reuse .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0 0 0em;
}
ic-items-reuse .items-reuse .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type label {
  height: 24px;
}
ic-items-reuse .items-reuse .ic-selectable-table mat-header-row .mat-mdc-header-cell {
  min-width: min-content;
}
ic-items-reuse .items-reuse .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(2) {
  padding: 0 0 0 0.5em;
}
ic-items-reuse .items-reuse .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(3) {
  padding: 0 0 0 0.5em;
  min-width: 93px;
}
ic-items-reuse .items-reuse .ic-selectable-table mat-header-row .mat-mdc-header-cell:last-of-type {
  padding: 0 0 0 0.5em;
  min-width: min-content;
}
ic-items-reuse .items-reuse .ic-selectable-table mat-row {
  justify-content: space-between;
}
ic-items-reuse .items-reuse .ic-selectable-table mat-row .mat-mdc-cell:first-of-type {
  padding: 0 0.4em;
  min-width: min-content;
}
ic-items-reuse .items-reuse .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(2) {
  min-width: min-content;
}
ic-items-reuse .items-reuse .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-items-reuse .items-reuse .ic-selectable-table mat-row .mat-mdc-cell:last-of-type {
  min-width: min-content;
}
ic-items-reuse .items-reuse .ic-selectable-table mat-row .mdc-data-table__cell {
  margin-top: 0 !important;
}
ic-items-reuse .items-reuse .ic-selectable-table mat-row .mdc-data-table__cell > span {
  line-height: 16px;
  padding: 5px 0 5px 0;
}
ic-items-reuse .items-reuse ic-detail-table .mat-mdc-row .mdc-data-table__cell {
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
  min-width: 103px;
}
ic-items-reuse .items-reuse ic-detail-table .mat-header-row .mdc-data-table__header-cell,
ic-items-reuse .items-reuse ic-detail-table .mat-mdc-header-row .mdc-data-table__header-cell {
  font-size: 12px;
  font-weight: 600;
  min-width: 103px;
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
}
ic-items-reuse .items-reuse-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  max-height: 56px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px 0 4px;
  z-index: 999;
}
ic-items-reuse .items-reuse-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-items-reuse .items-reuse-toolbar .panel-title {
  flex: auto;
}
ic-items-reuse .items-reuse-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-items-reuse .items-reuse-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-items-reuse .items-reuse-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-items-reuse .items-reuse-toolbar .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-items-reuse .items-reuse-info {
  min-height: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
ic-items-reuse .items-reuse-info > mat-card {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2);
}
ic-items-reuse .items-reuse-info > mat-card > mat-card-content > .five-element-row {
  flex-direction: row;
  display: flex;
}
ic-items-reuse .items-reuse-info > mat-card > mat-card-content > .five-element-row > .five-element-row-impact-level {
  flex-basis: 27%;
  min-width: 27%;
}
ic-items-reuse .items-reuse-info > mat-card > mat-card-content > .five-element-row > .five-element-row-impact-level .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-items-reuse .items-reuse-info > mat-card > mat-card-content > .five-element-row > .five-element-row-observation-code {
  flex-basis: 17%;
  min-width: 17%;
}
ic-items-reuse .items-reuse-info > mat-card > mat-card-content > .five-element-row > .five-element-row-observation-code .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-items-reuse .items-reuse-info > mat-card > mat-card-content > .five-element-row > .five-element-row-category {
  flex-basis: 14%;
  min-width: 14%;
}
ic-items-reuse .items-reuse-info > mat-card > mat-card-content > .five-element-row > .five-element-row-category .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-items-reuse .items-reuse-info > mat-card > mat-card-content > .five-element-row > .five-element-row-visibility {
  flex-basis: 12%;
  min-width: 12%;
}
ic-items-reuse .items-reuse-info > mat-card > mat-card-content > .five-element-row > .five-element-row-visibility .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-items-reuse .items-reuse-info > mat-card > mat-card-content > .five-element-row > .five-element-row-scipion-user {
  flex-basis: 30%;
  min-width: 30%;
}
ic-items-reuse .items-reuse-info > mat-card > mat-card-content > .five-element-row > .five-element-row-scipion-user .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-items-reuse .items-reuse-info > mat-card > mat-card-content > .two-element-row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
}
ic-items-reuse .items-reuse-info > mat-card > mat-card-content > .two-element-row > .all-row-element {
  flex-basis: 100%;
  min-width: 100%;
  align-self: baseline;
}
ic-items-reuse .items-reuse-info > mat-card > mat-card-content > .two-element-row > .all-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-items-reuse .items-reuse-info > mat-card > mat-card-content > .two-element-row > .two-element-row-element {
  flex-basis: 50%;
  min-width: 50%;
  align-self: baseline;
}
ic-items-reuse .items-reuse-info > mat-card > mat-card-content > .two-element-row > .two-element-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-items-reuse .items-reuse-info > mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element {
  flex-basis: 40%;
  min-width: 40%;
  align-self: baseline;
}
ic-items-reuse .items-reuse-info > mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-items-reuse .items-reuse-info > mat-card > mat-card-content > .two-element-row > .two-element-row-big-element {
  flex-basis: 70%;
  min-width: 70%;
  align-self: baseline;
}
ic-items-reuse .items-reuse-info > mat-card > mat-card-content > .two-element-row > .two-element-row-big-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-items-reuse .items-reuse-info > mat-card > mat-card-content > .two-element-row > .two-element-row-small-element {
  flex-basis: 30%;
  min-width: 30%;
  align-self: baseline;
}
ic-items-reuse .items-reuse-info > mat-card > mat-card-content > .two-element-row > .two-element-row-small-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-items-reuse .items-reuse-info .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-items-reuse > .items-reuse-title {
  padding-top: 25px;
}

.edit-button {
  margin-left: auto;
}

.dynamic-component-header-text {
  margin-left: 0px;
}

.ic-panel-input {
  margin-top: 0.5em;
}

.ic-slide-toggle {
  margin-bottom: 25px;
}

.row-one-element {
  width: 75%;
}

.add-entity {
  cursor: pointer;
  vertical-align: middle;
}

.add-entity-disabled {
  opacity: 0.4;
  vertical-align: middle;
}
.add-entity-disabled:hover {
  background-color: transparent;
}

.delete-entity {
  cursor: pointer;
  margin: 5px;
  padding: 0.9em 0;
  border-top: 0.8437;
}

.text-match {
  color: red;
}

.empty-wordings {
  color: #f05060;
}

.clean-inputs-button {
  float: right;
}

.buttons-bot {
  margin-top: -5px;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 15px;
  flex-basis: 25%;
}

ic-items-add .items-add .ic-selectable-table mat-header-row {
  justify-content: space-between;
}
ic-items-add .items-add .ic-selectable-table mat-header-row.ic-selectable-row {
  height: 40px !important;
  top: 66px !important;
}
ic-items-add .items-add .ic-selectable-table mat-header-row.ic-selectable-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0.4em;
}
ic-items-add .items-add .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type {
  padding: 0 0 0 0em;
}
ic-items-add .items-add .ic-selectable-table mat-header-row .mat-mdc-header-cell:first-of-type label {
  height: 24px;
}
ic-items-add .items-add .ic-selectable-table mat-header-row .mat-mdc-header-cell {
  min-width: min-content;
}
ic-items-add .items-add .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(2) {
  padding: 0 0 0 0.5em;
}
ic-items-add .items-add .ic-selectable-table mat-header-row .mat-mdc-header-cell:nth-of-type(3) {
  padding: 0 0 0 0.5em;
  min-width: 93px;
}
ic-items-add .items-add .ic-selectable-table mat-header-row .mat-mdc-header-cell:last-of-type {
  padding: 0 0 0 0.5em;
  min-width: min-content;
}
ic-items-add .items-add .ic-selectable-table mat-row {
  justify-content: space-between;
}
ic-items-add .items-add .ic-selectable-table mat-row .mat-mdc-cell:first-of-type {
  padding: 0 0.4em;
  min-width: min-content;
}
ic-items-add .items-add .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(2) {
  min-width: min-content;
}
ic-items-add .items-add .ic-selectable-table mat-row .mat-mdc-cell:nth-of-type(3) {
  min-width: 93px;
}
ic-items-add .items-add .ic-selectable-table mat-row .mat-mdc-cell:last-of-type {
  min-width: min-content;
}
ic-items-add .items-add .ic-selectable-table mat-row .mdc-data-table__cell {
  margin-top: 0 !important;
}
ic-items-add .items-add .ic-selectable-table mat-row .mdc-data-table__cell > span {
  line-height: 16px;
  padding: 5px 0 5px 0;
}
ic-items-add .items-add ic-detail-table .mat-mdc-row .mdc-data-table__cell {
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
  min-width: 103px;
}
ic-items-add .items-add ic-detail-table .mat-header-row .mdc-data-table__header-cell,
ic-items-add .items-add ic-detail-table .mat-mdc-header-row .mdc-data-table__header-cell {
  font-size: 12px;
  font-weight: 600;
  min-width: 103px;
  padding: 10px 15px 10px 0px;
  margin: 0;
  margin-top: 0 !important;
}
ic-items-add .items-add-toolbar {
  background-color: #fff;
  color: #666;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 1.19;
  height: 56px;
  max-height: 56px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px 0 4px;
  z-index: 999;
}
ic-items-add .items-add-toolbar .hack-autofocus-disable {
  position: absolute;
  height: 0;
}
ic-items-add .items-add-toolbar .panel-title {
  flex: auto;
}
ic-items-add .items-add-toolbar .panel-close {
  height: 48px;
  margin-right: 16px;
  width: 48px;
}
ic-items-add .items-add-toolbar .panel-close:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
ic-items-add .items-add-toolbar .panel-spacer {
  flex: 1 1 auto;
}
ic-items-add .items-add-toolbar .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-items-add .items-add-info {
  min-height: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
ic-items-add .items-add-info > mat-card {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 2px -1px rgba(64, 80, 182, 0.2), -1px -1px 1px 0px rgba(64, 80, 182, 0.2), 0px 1px 3px 0px rgba(64, 80, 182, 0.2);
}
ic-items-add .items-add-info > mat-card > mat-card-content > .five-element-row {
  flex-direction: row;
  display: flex;
}
ic-items-add .items-add-info > mat-card > mat-card-content > .five-element-row > .five-element-row-impact-level {
  flex-basis: 27%;
  min-width: 27%;
}
ic-items-add .items-add-info > mat-card > mat-card-content > .five-element-row > .five-element-row-impact-level .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-items-add .items-add-info > mat-card > mat-card-content > .five-element-row > .five-element-row-observation-code {
  flex-basis: 17%;
  min-width: 17%;
}
ic-items-add .items-add-info > mat-card > mat-card-content > .five-element-row > .five-element-row-observation-code .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-items-add .items-add-info > mat-card > mat-card-content > .five-element-row > .five-element-row-category {
  flex-basis: 14%;
  min-width: 14%;
}
ic-items-add .items-add-info > mat-card > mat-card-content > .five-element-row > .five-element-row-category .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-items-add .items-add-info > mat-card > mat-card-content > .five-element-row > .five-element-row-visibility {
  flex-basis: 12%;
  min-width: 12%;
}
ic-items-add .items-add-info > mat-card > mat-card-content > .five-element-row > .five-element-row-visibility .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-items-add .items-add-info > mat-card > mat-card-content > .five-element-row > .five-element-row-scipion-user {
  flex-basis: 30%;
  min-width: 30%;
}
ic-items-add .items-add-info > mat-card > mat-card-content > .five-element-row > .five-element-row-scipion-user .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-items-add .items-add-info > mat-card > mat-card-content > .two-element-row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
}
ic-items-add .items-add-info > mat-card > mat-card-content > .two-element-row > .all-row-element {
  flex-basis: 100%;
  min-width: 100%;
  align-self: baseline;
}
ic-items-add .items-add-info > mat-card > mat-card-content > .two-element-row > .all-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-items-add .items-add-info > mat-card > mat-card-content > .two-element-row > .two-element-row-element {
  flex-basis: 50%;
  min-width: 50%;
  align-self: baseline;
}
ic-items-add .items-add-info > mat-card > mat-card-content > .two-element-row > .two-element-row-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-items-add .items-add-info > mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element {
  flex-basis: 40%;
  min-width: 40%;
  align-self: baseline;
}
ic-items-add .items-add-info > mat-card > mat-card-content > .two-element-row > .two-element-row-medium-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-items-add .items-add-info > mat-card > mat-card-content > .two-element-row > .two-element-row-big-element {
  flex-basis: 70%;
  min-width: 70%;
  align-self: baseline;
}
ic-items-add .items-add-info > mat-card > mat-card-content > .two-element-row > .two-element-row-big-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-items-add .items-add-info > mat-card > mat-card-content > .two-element-row > .two-element-row-small-element {
  flex-basis: 30%;
  min-width: 30%;
  align-self: baseline;
}
ic-items-add .items-add-info > mat-card > mat-card-content > .two-element-row > .two-element-row-small-element .mat-mdc-input-element {
  letter-spacing: 0;
}
ic-items-add .items-add-info .mat-icon.mat-icon-inline {
  line-height: unset;
  height: unset;
  font-size: unset;
  width: unset;
}
ic-items-add > .items-add-title {
  padding-top: 25px;
}

.edit-button {
  margin-left: auto;
}

.dynamic-component-header-text {
  margin-left: 0px;
}

.ic-panel-input {
  margin-top: 0.5em;
}

.ic-slide-toggle {
  margin-bottom: 25px;
}

.row-one-element {
  width: 75%;
}

.add-entity {
  cursor: pointer;
  vertical-align: middle;
}

.add-entity-disabled {
  opacity: 0.4;
  vertical-align: middle;
}
.add-entity-disabled:hover {
  background-color: transparent;
}

.delete-entity {
  cursor: pointer;
  margin: 5px;
  padding: 0.9em 0;
  border-top: 0.8437;
}

.text-match {
  color: red;
}

.empty-wordings {
  color: #f05060;
}

.clean-inputs-button {
  float: right;
}

.buttons-bot {
  margin-top: -5px;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 15px;
  flex-basis: 25%;
}

.small-bold {
  font-size: 0.8em; /* Ajusta el tamaño de la letra (pequeño) */
  font-weight: bold; /* Aplica negrita */
  margin-top: 15px;
}